import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IMWEvent,
  IdeaRating,
  IIdea,
  SortIdeas,
  ISubeventQuestion,
  IMWSubevents,
  MWEventsRepository,
  skipWhilePostsCached,
  trackPostRequestsStatus,
  IComments,
  ISubeventAnswer,
} from './mwevent.repository';
import { findIndex, map, switchMap, tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { User } from './users.repository';
const API = '/api/mwevents';
const APIIdea = '/api/idea';
const APIComment = '/api/comment';
const APIRating = '/api/rateideas';
const APISubevents = '/api/mwsubevent';
@Injectable({
  providedIn: 'root',
})
export class MWEventService extends BaseService<IMWEvent> {
  constructor(http: HttpClient, repo: MWEventsRepository) {
    super(API, http, repo);
  }

  getIndex(id: string, n: number) {
    return this.http.put<User>(`${API}/userposition/${id}/${n}`, {});
  }

  postRating(rate: IdeaRating) {
    return this.http.post<IdeaRating>(APIRating, rate);
  }

  clone(id: string) {
    return this.http.get<IMWEvent>(`${API}/clone/${id}`);
  }

  loadIdeasRating(id: string) {
    return this.http.get<SortIdeas[]>(`${API}/${id}/ideas`);
  }

  loadIdeas(id: string) {
    return this.http.get<IIdea>(`${APIIdea}/${id}`);
  }
  AddIdea(idea: IIdea) {
    return this.http.post<IIdea>(APIIdea, idea);
  }

  AddAnswer(answer: ISubeventAnswer) {
    return this.http.post<ISubeventAnswer>(`${API}/answer`, answer);
  }

  AddComment(comment: IComments) {
    return this.http.post<IComments>(APIComment, comment);
  }

  load() {
    return this.http.get<PaginationData & { data: IMWEvent[] }>(API);
  }

  loadAll(): Observable<IMWEvent[]> {
    return this.http.get<IMWEvent[]>(API + '/all');
  }

  loadOne(id: string) {
    return this.http.get<IMWEvent>(`${API}/${id}`);
  }

  add(post: Partial<IMWEvent>): any {
    let SubeventsWithFiles = post.subevents;
    const formData = new FormData();
    formData.append('file', post.file!);
    let istrueObservable = this.http.post<IMWEvent>(API, post).pipe(
      map((post) => {
        this.http
          .post<IMWEvent>(`${API}/${post.id}/file`, formData)
          .subscribe((x) => {
            let SubArray = x.subevents;
            x.subevents.map((y) => {
              if (SubeventsWithFiles) {
                const formDataSub = new FormData();
                formDataSub.append(
                  'file',
                  SubeventsWithFiles[SubArray.indexOf(y)].file!
                );
                this.http
                  .post<IMWSubevents>(
                    `${APISubevents}/${y.id}/file`,
                    formDataSub
                  )
                  .subscribe();
              }
            });
          });
      })
    );
    return istrueObservable;
  }

  update(id: string, post: Partial<IMWEvent>): any {
    let SubeventsWithFiles = post.subevents;
    const formData = new FormData();
    formData.append('file', post.file!);
    let istrueObservable = this.http.put<IMWEvent>(`${API}/${id}`, post).pipe(
      map((post) => {
        this.http
          .post<IMWEvent>(`${API}/${post.id}/file`, formData)
          .subscribe((x) => {
            let SubArray = x.subevents;
            x.subevents.map((y) => {
              if (SubeventsWithFiles) {
                const formDataSub = new FormData();
                formDataSub.append(
                  'file',
                  SubeventsWithFiles[SubArray.indexOf(y)].file!
                );
                this.http
                  .post<IMWSubevents>(
                    `${APISubevents}/${y.id}/file`,
                    formDataSub
                  )
                  .subscribe();
              }
            });
          });
      })
    );
    return istrueObservable;
  }
}
