import { Injectable, Input } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { User } from './users.repository';

export interface IWhistleblow {
  id: string;
  title: string;
  description: string;
  eventDate: string;
  isAnonymous: boolean;
  createdBy: string;
  resolve: boolean;
  file: File | null;
  filePath: string;
}

const { state, config } = createState(
  withEntities<IWhistleblow>(),
  withRequestsStatus(),
  withRequestsCache()
);
const store = new Store({ name: 'posts', state, config });
export const trackWhistleRequestsStatus = createRequestsStatusOperator(store);
export const skipWhileWhistleCached = createRequestsCacheOperator(store);

export const WhistleblowSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'EventDate' },
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class WhistleblowRepository extends BaseRepository<IWhistleblow> {
  name = store.name;

  posts$ = store.pipe(selectAllEntities());
  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: IWhistleblow['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setPosts(posts: IWhistleblow[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: IWhistleblow) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }

  constructor() {
    super('posts', WhistleblowSortOptions);
  }
}
