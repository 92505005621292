<div class="mt15">
  <div class="row p-0">
    <div class="col-lg-8 col-12 ratingsection">
      <div class="">
        <h2 class="text-break col col-xs-2 pb-1 pt-3">Ideas Rating</h2>
      </div>
      <div
        echarts
        [options]="options"
        class="chart"
        (chartClick)="onChartEvent($event)"
      ></div>
    </div>

    <div class="col-lg-4 col-12 ideassection">
      <h4 class="pt-4 pb-2">Ideas</h4>

      <div class="scroll">
        <div *ngFor="let idea of ideas; let i = index">
          <div class="pb-3">
            <div
              *ngIf="ideaindex == i"
              class="chosenidea me-1"
              (click)="click(i, idea.impactAvg, idea.effortAvg)"
            >
              <div id="{{ idea.idea.id }}" class="row">
                <div class="col-2 pt-2 pb-1">
                  <div
                    [style.border-color]="idea.color"
                    id="{{ i }}"
                    class="idearate"
                  >
                    {{ idea.rate ? idea.rate : "-" }}
                  </div>
                </div>
                <div class="col-10 pt-1">
                  <div class="text-break idea_card_text idea_title pb-1 pt-1">
                    {{ idea.idea.title }}
                  </div>
                  <div class="text-break idea_card_text pb-1">
                    {{ idea.idea.text }}
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="ideaindex != i"
              class="ideacard me-1"
              (click)="click(i, idea.impactAvg, idea.effortAvg)"
            >
              <div id="{{ idea.idea.id }}" class="row">
                <div class="col-2 pt-2 pb-1">
                  <div [style.border-color]="idea.color" class="idearate">
                    {{ idea.rate ? idea.rate : "-" }}
                  </div>
                </div>
                <div class="col-10 pt-1">
                  <div class="text-break idea_card_text idea_title pb-1 pt-1">
                    {{ idea.idea.title }}
                  </div>
                  <div class="text-break idea_card_text pb-1">
                    {{ idea.idea.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
