<h2 class="mb-4" *ngIf="type === 'new'" i18n="Create tenant label">
  Create location
</h2>
<h2 class="mb-4" *ngIf="type !== 'new'" i18n="Edit tenant label">
  Edit location
</h2>
<div *ngIf="type === 'new' || companyLocation" class="row mb-4">
  <div class="col-12">
    <app-locations-editform
      [isLoading]="isLoading"
      [editedLocation]="companyLocation"
      (LocationSubmit)="createLocation($event)"
    ></app-locations-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the location"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
