<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="max-900 row">
    <div
      class="col-12 col-lg-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2 d-flex align-items-center"
    >
      <h2 class="page-title oneLineTextHeader">Caller Stats</h2>
      <app-datalist-select
        style="min-width: 200px"
        id="yearsSelect"
        [options]="yearsOptions"
        [multiple]="false"
        class="ms-5"
        [(activeValue)]="selectedYear"
        (activeValueChange)="setYear($event)"
      >
      </app-datalist-select>
    </div>
  </div>
</div>

<app-callers-stats-table [FunnelStats]="funnelStats"></app-callers-stats-table>
