import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  TimelogsRepository,
  daysTotal,
  projectTasksTotal,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';

@Component({
  selector: 'app-timelogs-projects-overview',
  templateUrl: './timelogs-projects-overview.component.html',
  styleUrls: ['./timelogs-projects-overview.component.scss'],
})
export class TimelogsProjectsOverviewComponent implements OnInit {
  Datejs: dayjs.Dayjs = dayjs().startOf('week');
  currentDate = new Date();

  startDate = new Date(this.currentDate.getFullYear(), 0, 1);
  days = Math.floor(
    (+this.currentDate - +this.startDate) / (24 * 60 * 60 * 1000)
  );
  daysTotals: daysTotal[] = [];
  projectsTotals: projectTasksTotal[] = [];
  weekNumber = Math.ceil(this.days / 7);
  year = this.currentDate.getFullYear();
  WeekDateString: string = `${this.year}-W${this.weekNumber}`;

  constructor(
    public service: TimelogsService,
    public repo: TimelogsRepository
  ) {}

  ngOnInit(): void {
    var length = this.WeekDateString?.toString().length;
    if (!length) {
      //smth is wrong
      return;
    }
    var year = +this.WeekDateString?.slice(0, 4);

    if (length == 7) {
      // week is in range of 1 - 9
      var Datejs2 = dayjs()
        .year(year)
        .startOf('year')
        .add(+this.WeekDateString.toString().slice(length - 1, length), 'week');
    } else {
      var Datejs2 = dayjs()
        .year(year)
        .startOf('year')
        .add(+this.WeekDateString.toString().slice(length - 2, length), 'week');
    }

    var endSelectedWeek = Datejs2.endOf('week').add(2, 'day').toISOString();
    var startSelectedWeek = Datejs2.startOf('week').add(2, 'day').toISOString();
    this.service
      .getTimeLogsOverviewProjects(
        startSelectedWeek.slice(0, 10),
        endSelectedWeek.slice(0, 10)
      )
      .subscribe((x) => {
        this.projectsTotals = x;
      });
  }

  changeinput() {
    var length = this.WeekDateString.toString().length;
    var Datejs2 = dayjs()
      .startOf('year')
      .add(+this.WeekDateString.toString().slice(length - 2, length), 'week');
    var endSelectedWeek = Datejs2.endOf('week').add(2, 'day').toISOString();
    var startSelectedWeek = Datejs2.startOf('week').add(2, 'day').toISOString();
    this.service
      .getTimeLogsOverviewProjects(
        startSelectedWeek.slice(0, 10),
        endSelectedWeek.slice(0, 10)
      )
      .subscribe((x) => (this.projectsTotals = x));
  }
}
