import { Component } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ITicket,
  TicketsRepository,
} from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';
import { Location } from '@angular/common';
import { CompaniesService } from 'src/app/state/companies.service';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-edit-tickets-page',
  templateUrl: './edit-tickets-page.component.html',
  styleUrls: ['./edit-tickets-page.component.scss'],
})
export class EditTicketsPageComponent {
  constructor(
    private location: Location,

    public service: TicketsService,
    public clientsService: CompaniesService,

    public repo: TicketsRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  ticket: ITicket | null = null;
  clients: ICompanyMarker[] | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service.loadOneTicket(`${value.get('id')}`).subscribe((x) => {
            this.ticket = x;
          });

          this.clientsService
            .loadAll()
            .subscribe((cli) => (this.clients = cli));
        }
      },
    });
  }

  createTicket(event: Partial<ITicket>) {
    this.submitErrors = null;
    let updateResult$: Observable<ITicket> | undefined;
    if (this.id === 'new') {
      updateResult$ = this.service.postTicket(event);
    } else if (this.id) {
      updateResult$ = this.service.updateTicket(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        next: () => {
          this.locationBack();
          this.service.getTicketsCount().subscribe();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }

  locationBack(goUrl = '/tickets/opened') {
    // this.router.events
    //   .pipe(
    //     filter((evt: any) => evt instanceof RoutesRecognized),
    //     pairwise()
    //   )
    //   .subscribe((events: RoutesRecognized[]) => {
    //     console.log('previous url', events[0].urlAfterRedirects);
    //     console.log('current url', events[1].urlAfterRedirects);
    //   });
    this.router.navigate([goUrl]);

    // let navId = (this.location.getState() as any)?.navigationId;
    // if (navId && navId > 1) this.location.back();
    // else {
    //   this.router.navigate([goUrl]);
  }
}
