import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-emoji-mart-popup',
  templateUrl: './emoji-mart-popup.component.html',
  styleUrls: ['./emoji-mart-popup.component.scss'],
})
export class EmojiMartPopupComponent {
  @Input() bottom = 0;
  @Input() showEmojiPicker?: boolean | null;
  @Output() showEmojiPickerChange = new EventEmitter<any>();

  @Input() right = 0;
  @Output() pickEmoji = new EventEmitter<any>();
  exclude = ['Flags'];
  @ViewChild('emojiContainer') container: ElementRef<HTMLElement> | undefined;

  enableHandler() {
    window.addEventListener('click', this.eventHandler);
  }
  disableHandler() {
    window.removeEventListener('click', this.eventHandler);
  }

  eventHandler = (event: Event) => {
    // Watching for outside clicks
    if (!this.container?.nativeElement.contains(event.target as Node)) {
      this.showEmojiPicker = false;
      window.removeEventListener('click', this.eventHandler);
      this.showEmojiPickerChange.emit(this.showEmojiPicker);
    }
  };
}
