import { Component, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';
import { DocumentTypesRepository } from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  TenantFeatures = TenantFeatures;

  constructor(
    public authRepo: AuthRepository,
    public doctyperepo: DocumentTypesRepository,
    public doctypeservice: DocumentTypesService
  ) {}

  ngOnInit(): void {
    this.doctypeservice.loadShown().subscribe();
  }
}
