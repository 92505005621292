<div *ngIf="post">
  <div class="row justify-content-center">
    <div
      class="row col-12 col-xl-10 col-lg-11 pb-3 pt-0 pt-sm-3 ps-0 pe-0 d-flex justify-content-center"
    >
      <div class="col-12 mb-4 d-flex justify-content-center">
        <div class="butsect d-flex justify-content-center align-items-center">
          <button class="button butActive me-1">All</button>
          <button
            class="button me-1 d-flex flex-row align-items-center justify-content-center"
          >
            Unread
            <span
              class="ms-1 d-inline d-flex justify-content-center align-items-center unreadnumber"
              >1</span
            >
          </button>
          <button class="button me-1">Read</button>
        </div>
      </div>
      <div>
        <div class="date mb-2">
          {{ post.createdAt | date : "d MMM y" }}
        </div>

        <div class="mainTitle text-breaktext-break pb-4">
          {{ post.title }}
        </div>
        <div class="mb-3 mb-md-5">
          <img
            *ngIf="post.documentPath"
            class="img"
            style="width: 100%"
            src="{{ post.documentPath }}"
            alt="Image Title"
          />
        </div>

        <div>
          <p class="text-break content" [innerHTML]="post.content"></p>
        </div>
      </div>
      <div>
        <div
          class="d-flex align-items-center p-0 mt-5"
          style="background: #eaecf0; border-radius: 22px"
        >
          <div
            class="d-flex col-auto align-items-center justify-content-center circle p-0"
          >
            <svg
              width="15"
              height="11"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6.5L16 6.5M16 6.5L10.375 1M16 6.5L10.375 12"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="d-flex col justify-content-center">Mark as read</div>
        </div>
      </div>
      <hr class="mt-sm-5 mt-4" />
      <div>
        <div class="d-flex flex-row align-items-center">
          <div class="comments">Comments</div>
          <div
            class="ms-2 d-flex align-items-center justify-content-center purpcircle"
          >
            {{ post.comments.length }}
          </div>
        </div>
        <div
          *ngFor="let card of post.comments"
          class="d-flex flex-row align-items-start"
        >
          <img
            class="rounded-circle me-2"
            src="{{ card.imagePath }} || DEFAULT_IMAGE "
            alt="avatar"
            width="48"
            height="48"
          />
        </div>
      </div>
    </div>
  </div>
</div>
