import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { IMail, MailRepository } from 'src/app/state/mail.repository';
import { MailService } from 'src/app/state/mail.service';
import { UntypedFormBuilder } from '@angular/forms';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-mail-edit',
  templateUrl: './mail-edit.component.html',
  styleUrls: ['./mail-edit.component.scss'],
})
export class MailEditComponent implements OnInit {
  editor: any;
  editForm: UntypedFormBuilder | any;
  post$: Observable<IMail> | null = null;
  post: IMail | null = null;
  id!: string;
  submitErrors: string[] | null = null;

  constructor(
    private service: MailService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: MailRepository,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.post$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.service.loadOne(id),
      (id) => this.repo.post(id),
      (id) => this.repo.status(id),
      (id) => (this.id = id)
    );
    this.loadevent(this.id);
  }

  loadevent(id: string) {
    this.service.loadOne(id).subscribe((x) => {
      this.post = x;
    });
  }

  updatePost(post: Partial<IMail>) {
    this.submitErrors = null;
    let updateResult: Observable<IMail> | undefined;
    if (this.id === 'new') {
      updateResult = this.service.add(post);
    } else if (this.id) {
      updateResult = this.service.update(this.id, post);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/admin/messages']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
