<div class="box my-2">
  <div class="box-name-header" [class.noBotBorder]="FunnelStats">
    <div class="d-flex justify-content-between p-2">
      <div class="quater-col">
        <span class="txt mt-1">Team</span>
      </div>
      <div class="quater-col">
        <span class="txt mt-1">Averrage attendance %</span>
      </div>
    </div>
  </div>

  <div
    *ngFor="let month of FunnelStats; let curentMonthIndex = index"
    class="b-top-gray"
  >
    <div class="d-flex justify-content-between p-2">
      <div class="txt quater-col">
        {{ month.funnelName }}
      </div>

      <div class="txt quater-col">
        {{ month.rate.toFixed(2) }}
      </div>
    </div>
  </div>
</div>
