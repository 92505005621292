<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="max-900 row">
    <div class="col-12 mt-sm-4 p-sm-0 ps-2 d-flex align-items-center">
      <h2 class="page-title oneLineTextHeader" i18n="Label eventSchedule">
        {{ isDraft ? "Draft events" : "Event Schedule" }}
      </h2>

      <app-datalist-select
        style="min-width: 200px"
        id="yearsSelect"
        [options]="yearsOptions"
        [multiple]="false"
        class="ms-5"
        [(activeValue)]="selectedYear"
        (activeValueChange)="setYear.emit($event)"
      >
      </app-datalist-select>
    </div>
    <div *ngIf="authrepo.isClientUser$ | async">
      <ul class="mt-2">
        <li>Click on each month to expand/subtract.</li>
        <li>
          When an event changes status from "pending" to "confirmed" it has been
          successfully accepted and added to our system.
        </li>
        <li>
          To access registration sheets for your events: either click on "my
          folder", or use the shortcut by clicking on the registration count.
        </li>
      </ul>
    </div>
  </div>
</div>
