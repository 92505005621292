import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'app-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
})
export class EventStatusComponent {
  @Input() status: string = '';
  @Input() isMonthStatus: boolean = false;
  @Input() statusNum: number = 0;
  @Input() tabSelected: string = 'Overview';

  constructor() {}
}
