<button class="editor-call-button" type="button" (click)="openModal()"></button>

<div class="modal-overlay" *ngIf="isModalOpen" [class.fade-out]="fadeOut">
  <div
    class="modal-content"
    [class.larger-textfiled]="largeTextField"
    (click)="$event.stopPropagation()"
  >
    <div class="w-100 d-flex justify-content-between">
      <h4>{{ title }}</h4>
      <img
        src="/assets/img/x_15x15_black.svg"
        alt="Close"
        class="pointer"
        style="width: 20px; height: 20px"
        (click)="closeModal()"
      />
    </div>
    <!-- <div class="form-check form-switch mb-2 d-flex align-items-center">
      <input
        class="form-check-input mt-0"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        [checked]="!forTextOnly"
        (change)="forTextOnly = !forTextOnly"
      />
      <label class="form-check-label ms-2" for="flexSwitchCheckDefault"
        >Advanced editor</label
      >
    </div> -->
    <quill-editor
      *ngIf="!forImageUrl && !forTextOnly; else urlInput"
      [(ngModel)]="editedContent"
      [modules]="editorModules"
      class="editor-content"
      [class.add-spacing]="addSpacing"
      [placeholder]="title"
    ></quill-editor>
    <ng-template #urlInput>
      <input
        *ngIf="!forTextOnly; else textAreaEditor"
        type="url"
        class="inpt"
        [(ngModel)]="editedContent"
        placeholder="{{ title }}"
      />
    </ng-template>
    <ng-template #textAreaEditor>
      <textarea
        class="inpt"
        style="resize: none; min-height: 350px"
        [(ngModel)]="editedContent"
        [placeholder]="title"
      ></textarea>
    </ng-template>

    <div class="modal-actions">
      <button class="btn btn-danger" type="button" (click)="closeModal()">
        Cancel
      </button>
      <button class="btn btn-wfrBlue" type="button" (click)="save()">
        Save
      </button>
    </div>
  </div>
</div>
