import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import { BaseService } from './abstract/base.service';
import { IFunnel, IRoom } from './event-schedule.repository';
import { FunnelsRepository } from './funnels.repository';
import { RoomsRepository } from './rooms.repository';
const DEFAULT_ENTITIES_PER_PAGE = 25;

const API = '/api/rooms';

@Injectable({ providedIn: 'root' })
export class RoomsService extends BaseService<IRoom> {
  constructor(http: HttpClient, repo: RoomsRepository) {
    super(API, http, repo);
  }

  loadOneRoom(id: string) {
    return this.http.get<IRoom>(API + '/loadOneRoom/' + id);
  }

  loadAllRooms() {
    return this.http.get<IRoom[]>(API + '/loadAll');
  }
  loadAllRoomsForLocation(id: string) {
    return this.http.get<IRoom[]>(API + '/loadAllRoomsForLocation/' + id);
  }
  postRoom(contact: Partial<IRoom>) {
    return this.http.post<IRoom>(API + '/postRoom', contact);
  }

  updateRoom(contact: Partial<IRoom>) {
    return this.http.put<IRoom>(API + '/updateRoom', contact);
  }

  loadPageCustom(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    locationId = ''
  ): Observable<PaginationData & { data: IRoom[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `locationId=${locationId}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: IRoom[] }>(
        `${API}/customLoadPage/?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sortCustom(sortBy: SortState, search?: string, locationId = '') {
    this.repo.setSort(sortBy);
    return this.reloadPageCustom(search, DEFAULT_ENTITIES_PER_PAGE, locationId);
  }

  reloadPageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    locationId = ''
  ): Observable<(PaginationData & { data: IRoom[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        data.currentPage,
        data.perPage,
        search,
        locationId
      );
    }
    return this.loadPageCustom(1, defaultTake, search, locationId);
  }

  searchRelodePageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    locationId = ''
  ): Observable<(PaginationData & { data: IRoom[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(1, data.perPage, search, locationId);
    }
    return this.loadPageCustom(1, defaultTake, search, locationId);
  }
}
