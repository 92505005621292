import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { AuthlayoutComponent } from '../modules/core/layouts/authlayout/authlayout.component';
import { ForgetpasswordComponent } from '../modules/core/pages/forgetpassword/forgetpassword.component';
import { PagenotauthorizedComponent } from '../modules/core/pages/pagenotauthorized/pagenotauthorized.component';
import { PagenotfoundComponent } from '../modules/core/pages/pagenotfound/pagenotfound.component';
import { ProfileEditComponent } from '../modules/core/pages/profile-edit/profile-edit.component';
import { ProfileComponent } from '../modules/core/pages/profile/profile.component';
import { ResetpasswordComponent } from '../modules/core/pages/resetpassword/resetpassword.component';
import { SigninComponent } from '../modules/core/pages/signin/signin.component';

import { TenantsCreatePageComponent } from '../modules/core/pages/tenants-create-page/tenants-create-page.component';
import { TenantsPageComponent } from '../modules/core/pages/tenants-page/tenants-page.component';
import { UsersCreatePageComponent } from '../modules/core/pages/users-create-page/users-create-page.component';
import { UsersListPageComponent } from '../modules/core/pages/users-list-page/users-list-page.component';
import { HomepageComponent } from '../modules/core/pages/homepage/homepage.component';
import { Office365authComponent } from '../modules/core/components/office365auth/office365auth.component';
import { MapsPageComponent } from '../modules/core/pages/maps-page/maps-page.component';
import { UserRoles } from '../state/auth.repository';
import { FolderpageComponent } from '../modules/core/pages/folderpage/folderpage.component';
import { FolderPostComponent } from '../modules/core/components/folder-post/folder-post.component';
import { DocumenttypePageComponent } from '../modules/core/pages/documenttype-page/documenttype-page.component';
import { DocumenttypePostComponent } from '../modules/core/components/documenttype-post/documenttype-post.component';

import { DocumentEditpageComponent } from '../modules/core/pages/document-editpage/document-editpage.component';
import { DocumenttypeEditpageComponent } from '../modules/core/pages/documenttype-editpage/documenttype-editpage.component';
import { FolderEditpageComponent } from '../modules/core/pages/folder-editpage/folder-editpage.component';

import { DocumentPostComponent } from '../modules/core/components/document-post/document-post.component';
import { AdminDocumentPageComponent } from '../modules/core/pages/admin-document-page/admin-document-page.component';
import { DocumenttypeFromSideMenuComponent } from '../modules/core/components/documenttype-from-SideMenu/documenttype-from-SideMenu.component';
import { AdminCompaniesPageComponent } from '../modules/core/pages/admin-companies-page/admin-companies-page.component';
import { TenantFeatures } from '../state/feature.repository';
import { ClientsCreatePageComponent } from '../modules/core/pages/clients-create-page/clients-create-page.component';
import { AdminLocationsPageComponent } from '../modules/core/pages/admin-locations-page/admin-locations-page.component';
import { AdminContactsPageComponent } from '../modules/core/pages/admin-contacts-page/admin-contacts-page.component';
import { ContactsCreatePageComponent } from '../modules/core/pages/contacts-create-page/contacts-create-page.component';
import { LocationsCreatePageComponent } from '../modules/core/pages/locations-create-page/locations-create-page.component';
import { AdminRoomsPageComponent } from '../modules/core/pages/admin-rooms-page/admin-rooms-page.component';
import { RoomsCreatePageComponent } from '../modules/core/pages/rooms-create-page/rooms-create-page.component';
import { ClientUsersPageComponent } from '../modules/core/pages/client-users-page/client-users-page.component';
import { ClientUsersCreatePageComponent } from '../modules/core/pages/client-users-create-page/client-users-create-page.component';
import { SeedTimeZonesPageComponent } from '../modules/core/pages/seed-time-zones-page/seed-time-zones-page.component';

const routes: Routes = [
  {
    path: 'unathorized/maps/:tenant',
    component: AuthlayoutComponent,
  },
  {
    path: 'popup/clients/locations/:id',
    component: AdminLocationsPageComponent,
    canActivate: [AuthGuard],
    data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
  },
  {
    path: 'popup/clients/rooms/:id',
    component: AdminRoomsPageComponent,
    canActivate: [AuthGuard],
    data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  {
    path: '',
    component: AuthlayoutComponent,
    children: [
      {
        path: 'signin',
        component: SigninComponent,
      },
      {
        path: 'signin/:error',
        component: SigninComponent,
      },
      {
        path: 'forgotpassword',
        component: ForgetpasswordComponent,
      },
      {
        path: 'resetpassword',
        component: ResetpasswordComponent,
      },
      {
        path: 'office365auth',
        component: Office365authComponent,
      },
    ],
  },
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      //{
      //  path: 'folders',
      //  component: FolderpageComponent,
      //  canActivate: [AuthGuard],
      //  data: {
      //    authorize: [UserRoles.Superamin, UserRoles.TenantAdmin]
      //  }
      //},
      //{
      //  path: 'folders/new',
      //  component: FolderPostComponent,
      //  canActivate: [AuthGuard],
      //  data: {
      //    authorize: [UserRoles.Superamin, UserRoles.TenantAdmin]
      //  }
      //},
      //{
      //  path: 'edit/folder/:id',
      //  component: FolderEditpageComponent,
      //  canActivate: [AuthGuard],
      //  data: {
      //    authorize: [UserRoles.Superamin, UserRoles.TenantAdmin]
      //  }
      //},
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'home',
        component: HomepageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tenants',
        component: TenantsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Superamin },
      },
      {
        path: 'users',
        component: UsersListPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/documents/new',
        component: DocumentPostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [
            UserRoles.Superamin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
          feature: TenantFeatures.Documents,
        },
      },

      {
        path: 'admin/documents/documenttypes',
        component: DocumenttypePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },

      {
        path: 'admin/timezoneSeeder/run',
        component: SeedTimeZonesPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin] },
      },
      {
        path: 'admin/clients/:type/contact/:id',
        component: ContactsCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/:type/clientUser/:id',
        component: ClientUsersCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/:type/room/:id',
        component: RoomsCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/:type/location/:id',
        component: LocationsCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/locations/:id',
        component: AdminLocationsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/rooms/:id',
        component: AdminRoomsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/contacts/:id',
        component: AdminContactsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/clients/clientUsers/:id',
        component: ClientUsersPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/documents',
        component: AdminDocumentPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superamin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Documents,
        },
      },
      {
        path: 'admin/documenttype/new',
        component: DocumenttypePostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superamin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Documents,
        },
      },
      {
        path: 'admin/documents/edit/documenttype/:id',
        component: DocumenttypeEditpageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superamin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Documents,
        },
      },
      {
        path: 'admin/documents/edit/:id',
        component: DocumentEditpageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superamin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Documents,
        },
      },
      {
        path: 'admin/clients',
        component: AdminCompaniesPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superamin, UserRoles.TenantAdmin],
        },
      },
      {
        path: 'admin/clients/:id',
        component: ClientsCreatePageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superamin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Maps,
        },
      },
      //{
      //  path: 'default-documenttype',
      //  component: DefaultDocumenttypePageComponent,
      //  canActivate: [AuthGuard],
      //  data: {
      //    authorize: [UserRoles.Superamin, UserRoles.TenantAdmin]
      //  }
      //},/documenttypeFromSideBar/{{doctype.id}}
      //{
      //  path: 'default-documenttype/edit/:name',
      //  component: DefaultDocumenttypeComponent,
      //  canActivate: [AuthGuard],
      //  data: {
      //    authorize: [UserRoles.Superamin, UserRoles.TenantAdmin]
      //  }
      //}
      {
        path: 'documents/:id',
        component: DocumenttypeFromSideMenuComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.User, UserRoles.TenantAdmin],
          feature: TenantFeatures.Documents,
        },
      },
      {
        path: 'maps',
        component: MapsPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Maps,
        },
      },
    ],
  },
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'tenants/:id',
        component: TenantsCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: UserRoles.Superamin },
      },
      {
        path: 'users/:id',
        component: UsersCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'profile/edit',
        component: ProfileEditComponent,
      },
    ],
  },
  { path: 'unauthorized', component: PagenotauthorizedComponent },
  { path: 'pagenotfound', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
