import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ISubscriber,
  SubscribersRepository,
} from '../../../../state/subscribers.repository';
import { SubscribersService } from '../../../../state/subscribers.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-subscriber-create-page',
  templateUrl: './subscriber-create-page.component.html',
  styleUrls: ['./subscriber-create-page.component.scss'],
})
export class SubscriberCreatePageComponent {
  constructor(
    public service: SubscribersService,
    public repo: SubscribersRepository,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  subscriber: ISubscriber | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        this.type = value.get('type')!;
        if (this.id && this.type !== 'new') {
          this.service
            .loadOneSubscriber(`${value.get('id')}`)
            .subscribe((x) => {
              this.subscriber = x;
            });
        }
      },
    });
  }

  createSubscriber(event: Partial<ISubscriber>) {
    this.submitErrors = null;
    let updateResult$: Observable<ISubscriber> | undefined;
    if (this.type === 'new') {
      event.eventScheduleId = this.id;

      updateResult$ = this.service.postSubscriber(event);
    } else if (this.type) {
      updateResult$ = this.service.updateSubscriber(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          if (this.type === 'new') {
            this.router.navigate([
              `/admin/eventSchedule/registrants/${this.id}`,
            ]);
          } else if (this.type === 'edit') {
            this.router.navigate([
              `/admin/eventSchedule/registrants/${this.subscriber?.eventScheduleId}`,
            ]);
          }
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
