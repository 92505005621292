import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() searchChange = new EventEmitter<string>();
  @Input() enableClearButon: boolean = false;
  // searchControl: FormControl = new FormControl();
  searchStr = '';
  prevSearchStr = '';

  constructor() {}

  checkIfEmpty() {
    if (!this.searchStr && !this.prevSearchStr) this.changeSearch();
  }
  setValue(val: string) {
    this.searchStr = val ?? '';

    // this.searchControl.setValue(val, { emitEvent: false });
  }
  ngOnInit(): void {
    // this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
    //   this.search(res);
    // });
  }

  clearSearch() {
    this.searchStr = '';
    this.prevSearchStr = '';

    this.search('');
  }
  search(filter: string) {
    this.searchChange.emit(filter);
  }
  changeSearch() {
    this.prevSearchStr = this.searchStr ?? '';
    this.searchChange.emit(this.searchStr ?? '');
  }
}
