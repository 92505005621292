<button
  class="btn btn-light shadow-sm w-100"
  (click)="load()"
  data-bs-toggle="modal"
  data-bs-target="#impersonatemodal"
  i18n="Impersonate user button"
>
  Impersonate user
</button>

<!-- Impersonate modal -->
<div
  class="modal fade"
  id="impersonatemodal"
  tabindex="-1"
  aria-labelledby="impersonatemodal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="impersonatemodalLabel"
          i18n="Title for impersonate window"
        >
          View application as..
        </h6>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <app-users-datalist-select
            [options]="userOptions$ | async"
            [(activeValue)]="selectedUserId"
            label="Select user"
            i18n-label="Label select user"
          ></app-users-datalist-select>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          type="button"
          (click)="impersonate()"
          [disabled]="!selectedUserId"
          class="btn btn-wfrBlue d-block w-100 shadow-sm"
          data-bs-dismiss="modal"
          i18n="Button impersonate"
        >
          Impersonate
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Impersonate modal end -->
