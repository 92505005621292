<div class="row mrg-row mbody">
  <div class="pt-2 col-12" style="overflow-y: auto; height: 100vh">
    <!-- <div *ngIf="id === 'new'" class="row pt-4 pe-4">
      <h1 class="mb-4 col" i18n="Create eventSchedule label">Create event</h1>
      <div class="col-6 p-0 d-flex justify-content-end">
        <div class="d-flex" (click)="locationBack()">
          <a
            class="d-flex justify-content-around btn dark-wfrBlue text-white"
            style="
              justify-content: end;
              width: 140px !important;
              height: 40px !important;
            "
          >
            <img src="assets/img/backArrow.svg" />
            <span class="">Back</span>
          </a>
        </div>
      </div>
    </div>

    <div class="row pt-2 pe-4">
      <h1
        class="mb-4 col"
        *ngIf="id !== 'new'"
        i18n="Create eventSchedule label"
      >
        Update event
      </h1>
      <div *ngIf="id !== 'new'" class="col-6 p-0 d-flex justify-content-end">
        <div class="d-flex" (click)="locationBack()">
          <a
            class="d-flex justify-content-around btn dark-wfrBlue text-white"
            style="
              justify-content: end;
              width: 140px !important;
              height: 40px !important;
            "
          >
            <img src="assets/img/backArrow.svg" />
            <span class="">Back</span>
          </a>
        </div>
      </div>
    </div> -->
    <div *ngIf="id === 'new' || eventSchedule" class="row mb-4">
      <div class="col-12">
        <app-event-schedule-editform
          [editedEvent]="eventSchedule"
          (EventSubmit)="createEvent($event)"
        ></app-event-schedule-editform>
        <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
        <app-error-alert
          title="Could not submit the eventSchedule"
          i18n-title="EventSchedule submit error alert"
          [errors]="submitErrors"
        ></app-error-alert>
      </div>
    </div>
  </div>
  <!-- TEAM FEE 
    <div class="col-12 col-md-5 teamfeedsection" *ngIf="eventSchedule">
    <h3 class="pt-2">Teem Feed</h3>
    <div class="ms-lg-2 teamfeedscroll">
      <div *ngFor="let comment of eventSchedule.comments">
        <div *ngIf="comment">
          <div class="d-flex flex-row flex-start me-2 mb-3 mt-1">
            <img
              *ngIf="comment.imagePath"
              class="rounded-circle me-2"
              src="{{ comment.imagePath }} "
              alt="avatar"
              width="40"
              height="40"
            />
            <img
              *ngIf="!comment.imagePath"
              class="rounded-circle me-2"
              src="{{ DEFAULT_IMAGE }}"
              alt="avatar"
              width="40"
              height="40"
              style="background: grey"
            />
            <div
              class="d-flex flex-column align-items-start"
              style="width: 100%"
            >
              <div class="flex-grow-1 order-1" style="width: 100%">
                <div
                  class="d-flex flex-row align-items-center justify-content-between mb-1"
                >
                  <div class="commname">{{ comment.userName }}</div>
                  <div class="commdate">
                    {{ comment.createdAt | date : "EEEE HH:mma" }}
                  </div>
                </div>
                <div
                  class="text-break d-flex align-items-center flex-row commtext pt-2 pb-3 p-3"
                >
                  {{ comment.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      style="width: 100%"
    >
      <div class="d-flex flex-start align-items-center" style="width: 100%">
        <div class="d-flex flex-row flex-start pt-2" style="width: 100%">
          <img
            class="rounded-circle me-2 bg-secondary"
            [src]="(authrepo.image$ | async) || DEFAULT_IMAGE"
            [alt]="authrepo.displayName$ | async"
            alt="avatar"
            width="40"
            height="40"
          />
          <div class="d-flex flex-column align-items-start" style="width: 100%">
            <form
              [formGroup]="Form"
              (ngSubmit)="onSubmit()"
              enctype="multipart/form-data"
              style="width: 100%"
            >
              <div class="row">
                <div
                  class="form-group form-floating p-0 pe-1 ps-2 col"
                  [appValidateState]="Form.get('title')"
                >
                  <textarea
                    id="title"
                    formControlName="title"
                    type="text"
                    class="inpt"
                    placeholder="Your Comment"
                    i18n-placeholder="@@YourComment"
                    autocomplete="off"
                  ></textarea>
                </div>
                <div class="col-auto pt-2 mx-1">
                  <button
                    type="button"
                    class="btn-wfrBlue"
                    (click)="onSubmit()"
                    style="height: 50px"
                  >
                    <svg
                      width="30"
                      height="18"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.49952 12.5001L19.9995 2.00005M9.6271 12.8281L12.2552 19.5861C12.4867 20.1815 12.6025 20.4791 12.7693 20.566C12.9139 20.6414 13.0862 20.6415 13.2308 20.5663C13.3977 20.4796 13.5139 20.1821 13.7461 19.587L20.3364 2.69925C20.5461 2.16207 20.6509 1.89348 20.5935 1.72185C20.5437 1.5728 20.4268 1.45583 20.2777 1.40604C20.1061 1.34871 19.8375 1.45352 19.3003 1.66315L2.41258 8.25349C1.8175 8.48572 1.51997 8.60183 1.43326 8.76873C1.35809 8.91342 1.35819 9.08567 1.43353 9.23027C1.52043 9.39707 1.81811 9.51283 2.41345 9.74436L9.17146 12.3725C9.29231 12.4195 9.35273 12.443 9.40361 12.4793C9.44871 12.5114 9.48815 12.5509 9.52031 12.596C9.55661 12.6468 9.58011 12.7073 9.6271 12.8281Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
