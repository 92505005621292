import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../abstract/base.service';
import { SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import {
  AllTicketsPage,
  CloseReason,
  ITicket,
  ITicketComment,
  ITicketCommentDocument,
  MoreComments,
  OneTicketsPage,
  TicketStatus,
  TicketsRepository,
} from './tickets.repository';
import { tap } from 'rxjs';
import { UserOption } from '../users.repository';
import { map } from 'rxjs/operators';

const API = '/api/tickets';

@Injectable({
  providedIn: 'root',
})
export class TicketsService extends BaseService<ITicket> {
  constructor(http: HttpClient, public repo: TicketsRepository) {
    super(API, http, repo);
  }

  // testFunctionsToDelete
  testFILEUPLOAD() {
    return this.http.post<ITicket>(API + '/testPostCommentDocument/', {});
  }

  testDeleteAll() {
    return this.http.delete<ITicket>(API + '/testDeleteAllFromAzure/');
  }
  //

  downloadDoc(id: string) {
    return this.http.get(API + `/getCommentDocument/${id}`, { responseType: 'blob', observe: 'response' })
      .pipe(
        map((res: HttpResponse<Blob>) => {
          let fileName = res.headers.get('fileName');

          return { blob: res.body, fileName: fileName };
        }))
  }
  createMockDocument(doc: FormData) {
    return this.http.post<ITicketCommentDocument>(API + '/createMockDocument/', doc);
  }

  deleteDocument(id: string) {
    return this.http.delete(API + '/deleteDocument/' + id);
  }
  loadOneTicket(id: string) {
    return this.http.get<ITicket>(API + '/loadOneTicket/' + id);
  }
  loadTicketsDisplayInfo(id: string) {
    return this.http.get<ITicket>(API + '/loadTicketsDisplayInfo/' + id);
  }
  loadOneComment(id: string) {
    return this.http.get<ITicketComment>(API + '/loadOneComment/' + id);
  }
  loadMoreComments(id: string, skip = 0) {
    const query = [`id=${id}`, `skip=${skip}`];
    return this.http.get<MoreComments>(
      API + `/loadMoreComments/?${query.join('&')}`
    );
  }
  loadOneTicketPage(id: string, take = 1) {
    const query = [`id=${id}`, `take=${take}`];

    return this.http.get<OneTicketsPage>(
      API + `/loadOneTicketPage/?${query.join('&')}`
    );
  }
  getTicketsCount() {
    return this.http.get<string>(API + `/getTicketsCount`).pipe(
      tap((res) => {
        this.repo.updateTicketsCount(res);
      })
    );
  }
  loadAllTickets(status: TicketStatus = TicketStatus.opened) {
    const query = [`status=${status}`];
    return this.http.get<AllTicketsPage>(API + `/loadAll/?${query.join('&')}`);
  }
  postTicket(contact: Partial<ITicket>) {
    return this.http.post<ITicket>(API, contact);
  }
  updateTicket(contact: Partial<ITicket>) {
    return this.http.put<ITicket>(API + '/updateTicket', contact);
  }
  sortCustom(sortBy: SortState, search?: string, take: number = 25) {
    this.repo.setSort(sortBy);
    return this.reloadPage(search, take);
  }
  changeTicketStatus(
    id: string,
    tickStatus: TicketStatus,
    closeReason?: CloseReason
  ) {
    return this.http.put<ITicket>(API + `/changeTicketStatus`, {
      id: id,
      ticketStatus: tickStatus,
      closeReason: closeReason,
    });
  }

  // coments
  postComment(entity: Partial<ITicketComment>) {
    return this.http.post<ITicketComment>(API + `/comment`, entity);
  }
  deleteComment(id: string) {
    return this.http.delete<void>(API + `/comment/${id}`);
  }
  updateComment(entity: Partial<ITicketComment>) {
    return this.http.put<ITicketComment>(API + `/comment`, entity);
  }
  loadLastComment(ticketId: string) {
    return this.http.get<ITicketComment>(API + `/loadLastComment/` + ticketId);
  }
  loadCreatorsOptions() {
    return this.http.get<UserOption[]>(API + '/loadCreatorsOptions');
  }
  loadAssigneesOptions() {
    return this.http.get<UserOption[]>(API + '/loadAssigneesOptions');
  }
}
