<div class="box my-2 showScroll scroll-x">
  <div class="box-name-header">
    <div class="d-flex justify-content-between p-2">
      <div class="txt mt-1 w-50px bald">Code</div>
      <div class="txt mt-1 bald">Client Name</div>
      <div class="txt mt-1 w-100px bald">Date</div>
      <div class="txt mt-1 bald">Location Name</div>
      <div class="txt mt-1 bald">Address</div>
      <div class="txt mt-1 bald">Time</div>
      <div class="txt mt-1 bald">Topic</div>
      <div class="txt mt-1 bald w-100px text-center">Registrants</div>
      <div class="txt mt-1 bald w-100px text-center">Attendance</div>
      <div class="txt mt-1 bald w-100px text-center">Show Up %</div>
      <div class="txt mt-1 bald w-100px text-center">Ad spent</div>
      <div class="txt mt-1 bald w-100px text-center">Flat fee</div>
      <div class="txt mt-1 bald w-100px text-center">Cost per HH</div>
    </div>
  </div>
  <div>
    <div
      *ngFor="let event of repo.events$ | async; let ind = index"
      class="b-top-gray min-w-2000p"
    >
      <div class="d-flex justify-content-between p-2 maxh-30px">
        <div class="txt pointer w-50px" (click)="showModal(event.id)">
          {{ event.client.clientCode }}
        </div>
        <div class="txt">
          <span>
            {{ event.client.title }}
          </span>
        </div>
        <div class="txt w-100px">
          {{ event.formatedDateAdmin }}
          <span *ngIf="event.atCost && event.atCost != 'No'">
            {{ "(" + event.atCost + ")" }}</span
          >
        </div>

        <div class="txt">
          {{ event.eventScheduleLocation?.title }}
        </div>
        <div class="txt">
          {{ event.eventScheduleLocation?.address }}
        </div>
        <div class="txt">
          {{ event.time ?? event.prebuildTime }}
        </div>
        <div class="txt">
          {{ event.topic }}
        </div>
        <div class="txt w-100px text-center">
          {{
            event.finalRegistrants ? event.finalRegistrants : event.registrants
          }}
        </div>
        <div class="txt w-100px text-center">
          {{ +event.attendees }}
        </div>
        <div class="txt w-100px text-center">
          {{
            this.setMax100(
              event.attendees,
              +(event.finalRegistrants
                ? event.finalRegistrants
                : event.registrants)
            )
          }}%
        </div>
        <div
          class="txt w-100px text-break twoLineText d-flex align-items-center"
          [class.doubleRowMargin]="event.doubleEvent"
        >
          {{ event.invoicedAds ?? 0 }}
        </div>
        <div class="txt w-100px text-center">
          {{ event.flatFee ?? 0 }}
        </div>
        <div
          class="txt w-100px text-break twoLineText d-flex align-items-center"
          [class.doubleRowMargin]="event.doubleEvent"
        >
          {{ event.costLead ?? 0 }}
        </div>
      </div>
      <div
        *ngIf="event.doubleEvent"
        class="d-flex justify-content-between p-2 min-w-2000p maxh-30px"
      >
        <div class="txt pointer w-50px" (click)="showModal(event.id)">
          {{ event.client.clientCode }}
        </div>
        <div class="txt">
          {{ event.client.title }}
        </div>
        <div class="txt w-100px">
          {{ event.formatedDate2Admin }}
          <span *ngIf="event.atCostD2 && event.atCostD2 != 'No'">
            {{ "(" + event.atCostD2 + ")" }}</span
          >
        </div>
        <div class="txt">
          {{ event.eventScheduleLocation?.title }}
        </div>
        <div class="txt">
          {{ event.eventScheduleLocation?.address }}
        </div>
        <div class="txt">
          {{ event.time2 ?? event.prebuildTime2 }}
        </div>
        <div class="txt">
          {{ event.topic }}
        </div>
        <div class="txt w-100px text-center">
          {{
            event.finalRegistrantsD2
              ? event.finalRegistrantsD2
              : event.registrantsD2
          }}
        </div>
        <div class="txt w-100px text-center">
          {{ +event.attendeesD2 }}
        </div>
        <div class="txt w-100px text-center">
          {{
            this.setMax100(
              event.attendeesD2,
              +(event.finalRegistrantsD2
                ? event.finalRegistrantsD2
                : event.registrantsD2)
            )
          }}%
        </div>
        <div class="txt w-100px text-center"></div>
        <div class="txt w-100px text-center">
          {{ event.flatFee ?? 0 }}
        </div>
        <div class="txt w-100px text-center"></div>
      </div>
    </div>
  </div>
</div>
<div id="myModal" class="modal">
  <div class="responsive">
    <div class="modal-content hide-scrollbar">
      <div class="d-flex modalHeader pb-2">
        <div
          class="d-flex justify-content-center align-items-center BlowModalIcon"
        >
          <svg
            width="22"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              s
            />
          </svg>
        </div>
        <div
          class="w-100 d-flex justify-content-between align-items-center ps-2"
        >
          <h5 class="m-0">Event Schedule</h5>
          <div class="w-50 d-flex align-items-center pe-5">
            <div class="w-100 d-flex justify-content-end add pe-5">
              <a
                [routerLink]="[
                  '/admin/eventSchedule/eventSchedule/',
                  eventScheduleOnPopUp?.id
                ]"
                class="d-flex justify-content-center btn btn-lg dark-wfrBlue text-white w-140px justify-content-end"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="text-btn-add" i18n="@@addCompany">Edit Event</span>
              </a>
            </div>
          </div>
        </div>
        <span class="close" (click)="hidemodal()">
          <svg
            width="12"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <div class="marginsModal">
        <form enctype="multipart/form-data">
          <app-event-pop-up
            [eventScheduleOnPopUp]="eventScheduleOnPopUp"
          ></app-event-pop-up>
        </form>
      </div>
    </div>
  </div>
</div>
