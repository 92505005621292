<div class="row pe-4">
  <h1 class="mb-4 col" *ngIf="id === 'new'">Create Ticket</h1>
  <div *ngIf="id === 'new'" class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex">
      <div
        (click)="locationBack()"
        class="d-flex justify-content-around btn dark-wfrBlue text-white h40w140"
      >
        <img src="assets/img/backArrow.svg" />
        <span>Back</span>
      </div>
    </div>
  </div>
</div>

<div class="row pe-4">
  <h1 class="mb-4 col" *ngIf="id !== 'new'">Update Ticket</h1>
  <div *ngIf="id !== 'new'" class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex">
      <div
        (click)="locationBack()"
        class="d-flex justify-content-around btn dark-wfrBlue text-white h40w140"
      >
        <img src="assets/img/backArrow.svg" />
        <span>Back</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="id === 'new' || ticket" class="row mb-4">
  <div class="col-12">
    <app-edit-ticket-form
      [editedTicket]="ticket"
      (ticketSubmit)="createTicket($event)"
    ></app-edit-ticket-form>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the team"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
