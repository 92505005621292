import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { BaseRepository } from './abstract/base.repository';
import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';
import { DocumentsRepository, IDocument } from './document.repository';

export interface Post {
  id: string;
  title: string;
  content: string;
  comments: IComments[];
  createdAt: Date;
  documentInserted: File | null;
  document: IDocument;
  documentId: string;
  documentPath: string;
  documentTitle: string;
  documentTypeId: string;
  folderId: string;
}
export interface IComments {
  id?: string;
  mwSubeventId?: string;
  subeventIdeaId?: string;
  userId?: string;
  commentId?: string;
  postId?: string;
  comments?: IComments[] | null;
  createdAt?: Date;
  text: string;
  imagePath?: string;
  userName?: string;
  eventScheduleId?: string;
}

const { state, config } = createState(
  withEntities<Post>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'posts', state, config });
export const trackPostRequestsStatus = createRequestsStatusOperator(store);
export const skipWhilePostsCached = createRequestsCacheOperator(store);

export const postSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class PostsRepository extends BaseRepository<Post> {
  name = store.name;

  posts$ = store.pipe(selectAllEntities());
  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: Post['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setPosts(posts: Post[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: Post) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }

  constructor() {
    super('posts', postSortOptions);
  }
}
