import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { WhistleblowService } from '../../../state/whistleblow.service';
import {
  IWhistleblow,
  WhistleblowRepository,
  WhistleblowSortOptions,
} from '../../../state/whistleblow.repository';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-whistleblow-admin',
  templateUrl: './whistleblow-admin.component.html',
  styleUrls: ['./whistleblow-admin.component.scss'],
})
export class WhistleblowAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = WhistleblowSortOptions;
  searchFilter: string = '';

  constructor(
    private router: Router,
    public authRepo: AuthRepository,
    public service: WhistleblowService,
    public repo: WhistleblowRepository
  ) {}

  whistleblows: IWhistleblow[] = [];
  deleteConfirmation: IWhistleblow | null = null;

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(event: IWhistleblow) {
    this.service.delete(event.id).subscribe((x) => {
      this.refreshData();
    });
  }

  Resolve(id: string) {
    this.service.resolve(id).subscribe();
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
