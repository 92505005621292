import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import {
  CirclesOptions,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
  CirclesOptionsKm,
  ColorsOptions,
  ICellPhone,
  IInvoice,
  IContact,
  CompanyStatusesOptions,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { DatePipe, Location } from '@angular/common';
import { StatusesOptions } from 'src/app/state/event-schedule.repository';
import { IReferralSource } from 'src/app/state/referralSource.repository';

@Component({
  selector: 'app-companies-editform',
  templateUrl: './companies-editform.component.html',
  styleUrls: ['./companies-editform.component.scss'],
})
export class CompaniesEditformComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  @Input() editedCompany?: ICompanyMarker | null;
  @Input() isLoading = false;

  submitErrors: string[] | null = null;
  locationIndex: number = 1;
  formattedaddress = ' ';
  myOptions: any;
  CirclesOptions: DatalistOption[] = CirclesOptions;
  ColorsOptions: DatalistOption[] = ColorsOptions;
  RefferalSourcesOptions: DatalistOption[] | null = null;
  testsLocation = 'localhost:9876';
  mapInfo: IMapInfo | null = null;
  @Output() ClientSubmit = new EventEmitter<Partial<ICompanyMarker>>();
  StatusesOptions: DatalistOption[] = CompanyStatusesOptions;

  @Input() set referralSources(value: IReferralSource[] | null) {
    if (!value) {
      this.RefferalSourcesOptions = null;
    } else {
      this.RefferalSourcesOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    public datepipe: DatePipe,
    private service: CompaniesService
  ) {
    this.myOptions = new Options({});
  }
  httpsUrlValidator(control: any) {
    if (control.value && !control.value.startsWith('https://')) {
      return { invalidUrl: true };
    }
    return null;
  }
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      title: [this.editedCompany?.title, Validators.required],
      longitude: [this.editedCompany?.longitude],
      status: [this.editedCompany?.status],
      feePerEvent: [this.editedCompany?.feePerEvent],
      latitude: [this.editedCompany?.latitude],
      address: [this.editedCompany?.address],
      clientSince: [this.editedCompany?.formatedClientSince],
      acquiredBy: [this.editedCompany?.acquiredBy],
      description: [this.editedCompany?.description],
      aeAdvisor: [this.editedCompany?.aeAdvisor],
      clientCode: [this.editedCompany?.clientCode],
      pricing: [this.editedCompany?.pricing],
      invoice: this.formBuilder.group({
        id: this.editedCompany?.invoice?.id,
        billTo: this.editedCompany?.invoice?.billTo,
        email: this.editedCompany?.invoice?.email,
      }),
      notes: [this.editedCompany?.notes],
      fillColor: [this.editedCompany?.fillColor],
      folder: [this.editedCompany?.folder || ''],
      clientSpecificInstructions: [
        this.editedCompany?.clientSpecificInstructions || '',
      ],
      referralSourceId: this.editedCompany?.referralSourceId,
    });

    this.service.loadMapInfo().subscribe((x) => {
      this.mapInfo = x;
      if (this.mapInfo.useKmAsValues) {
        this.CirclesOptions = CirclesOptionsKm;
      }
    });
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  onSubmit() {
    if (this.isLoading) {
      return
    }
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    const id = this.editedCompany?.id?.toString();
    const title = this.editForm.value?.title?.toString();
    const status = this.editForm.value?.status;
    const folder = this.editForm?.value?.folder;
    const clientSpecificInstructions =
      this.editForm?.value?.clientSpecificInstructions;

    const description = this.editForm.value?.description?.toString();
    const address = this.editForm.value?.address?.toString();
    const aeAdvisor = this.editForm.value?.aeAdvisor;
    const longitude = this.editForm.value?.longitude;
    const latitude = this.editForm.value?.latitude;
    const fillColor = this.editForm.value?.fillColor;
    const parsedClientSince = this.datepipe.transform(
      this.editForm.value?.clientSince,
      'yyyy-MM-dd'
    ) as any;

    var tempClientSince;
    if (parsedClientSince === '') {
      tempClientSince = undefined;
    } else {
      tempClientSince = parsedClientSince;
    }
    const clientSince = tempClientSince;
    const acquiredBy = this.editForm.value?.acquiredBy?.toString();
    const tenantId = this.editedCompany?.tenantId?.toString();
    const clientCode = this.editForm.value?.clientCode;
    const pricing = this.editForm.value?.pricing;
    const invoice: IInvoice = this.editForm.value?.invoice;
    const notes = this.editForm.value?.notes;
    const referralSourceId = this.editForm.value?.referralSourceId;

    const feePerEvent = this.editForm.value?.feePerEvent;

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    var addressNotSet = false;

    if (addressNotSet) {
      this.editForm.markAllAsTouched();
      return;
    }
    this.ClientSubmit.emit({
      id,
      status,
      title,
      description,
      address,
      clientSince,
      acquiredBy,
      tenantId,
      aeAdvisor,
      longitude,
      latitude,
      clientCode,
      pricing,
      invoice,
      notes,
      fillColor,
      feePerEvent,
      folder,
      clientSpecificInstructions,
      referralSourceId,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  setStartDate(event: any) {
    this.editForm.controls.clientSince.setValue(event.value);
  }

  updateCompanyLocation(address: any) {
    const lng = this.editForm?.get('longitude');
    lng?.setValue(address.geometry.location.lng());
    lng?.markAsTouched();
    const lat = this.editForm?.get('latitude');
    lat?.setValue(address.geometry.location.lat());
    lat?.markAsTouched();
    const addressobject = this.editForm?.get('address');
    addressobject?.setValue(address.formatted_address);
    addressobject?.markAsTouched();
  }
}
