import { Component, Inject, OnInit } from '@angular/core';
import { IEvent } from 'src/app/state/event.repository';
import { EventService } from 'src/app/state/event.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-event-popup',
  templateUrl: './event-popup.component.html',
  styleUrls: ['./event-popup.component.scss'],
})
export class EventPopupComponent implements OnInit {
  constructor(
    public service: EventService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}
  CurrentEvent: any | null = null;
  Events: any[] | null = null;
  index: number = 0;

  ngOnInit(): void {
    this.service.loadAllEventsDate(this.data.date).subscribe((x) => {
      this.Events = x;
      if (this.Events?.length) {
        this.CurrentEvent = this.Events[this.index];
      }
    });
  }

  subscribe(event: IEvent, value: boolean) {
    if (value) {
      this.service.deleteFromAppList(event.id).subscribe();
    } else {
      this.service.subscribe(event.id).subscribe();
    }
    event.subscribe = !event.subscribe;
  }

  next() {
    if (this.Events?.length) {
      if (this.index !== this.Events?.length - 1) {
        this.index += 1;
        this.CurrentEvent = this.Events[this.index];
      }
    }
  }

  prev() {
    if (this.Events?.length) {
      if (this.index !== 0) {
        this.index -= 1;
        this.CurrentEvent = this.Events[this.index];
      }
    }
  }
}
