<div class="row h-100">
  <div
    class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto mt-auto pt-4 text-center mb-100px"
  >
    <img
      class="mw-100 mb-4"
      [alt]="env.name"
      src="/assets/img/logoTransparent.png"
    />
    <h3 class="mb-1" i18n="Button forgot password">Forgot your password?</h3>
    <p
      *ngIf="state === 'idle' || state === 'processing'"
      class="text-muted mb-4"
      i18n="Provide email label"
    >
      Please provide your<br />registered email address
    </p>
    <form
      *ngIf="state === 'idle' || state === 'processing'"
      class="d-grid gap-2"
      [formGroup]="form"
      (ngSubmit)="reset()"
    >
      <div
        class="form-group form-floating"
        [appValidateState]="form.get('email')"
      >
        <input
          formControlName="email"
          type="text"
          class="inpt"
          id="email"
          placeholder="Email"
          i18n-placeholder="Email placeholder @@email"
        />
      </div>
      <button
        type="submit"
        class="btn btn-lg btn-default btn-block mt-3"
        [disabled]="state === 'processing'"
      >
        <ng-container *ngIf="state === 'idle'" i18n="Label reset password"
          >Reset your password</ng-container
        >
        <app-spinner *ngIf="state === 'processing'"></app-spinner>
      </button>
    </form>
    <p
      class="h1 text-center my-3"
      *ngIf="state === 'success' || state === 'error'"
    >
      <i
        *ngIf="state === 'success'"
        class="bi bi-patch-check-fill text-success"
      ></i>
      <i
        *ngIf="state === 'error'"
        class="bi bi-patch-exclamation-fill text-danger"
      ></i>
    </p>
    <p
      *ngIf="state === 'success'"
      class="text-muted mb-0"
      i18n="Check password reset email instruction"
    >
      We received your request. If your email address is registered, you will
      receive a letter with password reset instructions. Please also check the
      spam folder.
    </p>
    <p
      *ngIf="state === 'error'"
      class="text-muted mb-0"
      i18n="Unknown error message in Angular UI"
    >
      An error occured while completing your request. Please try again in a
      moment or contact the support if the problem persists.
    </p>
  </div>
</div>
