import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { ITicketCommentDocument } from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';

@Component({
  selector: 'app-attach-file-popup',
  templateUrl: './attach-file-popup.component.html',
  styleUrls: ['./attach-file-popup.component.scss'],
})
export class AttachFilePopupComponent {
  @Input() set docs(value: ITicketCommentDocument[] | null) {
    if (!value) {
      this.localDocs = [];
    } else {
      this.localDocs = ([] as ITicketCommentDocument[]).concat(value);
    }
  }
  localDocs: ITicketCommentDocument[] = [];
  @Output() docSubmit = new EventEmitter<Partial<ITicketCommentDocument>[]>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public service: TicketsService
  ) {}
  @ViewChild('attachCloseButton') attachCloseButton: ElementRef | null = null;
  ngOnInit(): void {}

  isLoading = false;

  addDoc($event: any) {
    const file: File = $event.target.files[0];
    const fileName = file?.name;
    if (fileName) {
      const formData = new FormData();
      if (file) {
        formData.append(file.name, file);
      }
      this.isLoading = true;
      this.service.createMockDocument(formData).subscribe({
        next: (x) => {
          this.isLoading = false;
          if (x) {
            if (this.localDocs != null) this.localDocs.push(x);
          }
        },
        complete: () => {
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
    }
  }

  deleteDoc(ind: number) {
    if (this.localDocs[ind]) this.localDocs.splice(ind, 1);
  }
}
