<div class="absoluteSearch d-flex align-items-center justify-content-between">
  <div>
    <input
      name="focus"
      type="text"
      placeholder="Search"
      #absSearchInpt
      class="inpt w-0"
      [class.smoothWidth]="expanded"
      ngx-google-places-autocomplete
      #palceRef="ngx-places"
      (onAddressChange)="ChangeLocationMapBySearch($event)"
      (change)="ClearMapSearch()"
      [options]="myOptions"
    />
    <button
      class="btn imgSize h-100"
      (click)="absSearchInpt.value = ''; clear.emit()"
      *ngIf="expanded"
    >
      X
    </button>
  </div>

  <img
    id="mapSearch"
    class="imgSize h-100"
    src="assets/img/search.svg"
    (click)="expanded = !expanded; absSearchInpt.blur()"
  />
</div>
