import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UserCreateUpdateDto, UsersService } from 'src/app/state/users.service';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';

@Component({
  selector: 'app-users-create-page',
  templateUrl: './users-create-page.component.html',
  styleUrls: ['./users-create-page.component.scss'],
})
export class UsersCreatePageComponent implements OnInit {
  user$: Observable<User> | null = null;
  id?: string;
  submitErrors: string[] | null = null;
  constructor(
    private usersService: UsersService,
    private tenantService: TenantsService,
    public tenantRepo: TenantsRepository,
    private auth: AuthRepository,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: UsersRepository,
    private urlId: UrlIdResolverService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.auth.isInRole(UserRoles.Superamin)) {
      this.tenantService.load().subscribe();
    }

    this.user$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.usersService.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
  }
  reloadUser() {
    if (this.id) this.usersService.loadOne(this.id).subscribe();
  }
  onInfoSubmit(user: UserCreateUpdateDto) {
    this.submitErrors = null;
    if (!this.id) {
      throw 'Can not identify current user';
    }
    let updateResult: Observable<User> | undefined;
    if (this.id === 'new') {
      updateResult = this.usersService.add(user);
    } else if (this.id) {
      updateResult = this.usersService.update(this.id, user);
    }
    if (updateResult) {
      updateResult
        .pipe(
          switchMap(() =>
            this.auth.getId() === this.id ? this.authService.refresh() : of()
          )
        )
        .subscribe({
          complete: () => {
            this.router.navigate(['/users']);
          },
          error: (data) => (this.submitErrors = data),
        });
    }
  }
}
