import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IFunnel } from '../../../../state/event-schedule.repository';
import { FunnelsRepository } from '../../../../state/funnels.repository';
import { FunnelsService } from '../../../../state/funnels.service';

@Component({
  selector: 'app-funnel-editform',
  templateUrl: './funnel-editform.component.html',
  styleUrls: ['./funnel-editform.component.scss'],
})
export class FunnelEditformComponent {
  editForm: UntypedFormBuilder | any;
  @Input() editedFunnel?: IFunnel | null;
  submitErrors: string[] | null = null;
  @Output() FunnelSubmit = new EventEmitter<Partial<IFunnel>>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: FunnelsService,
    private repository: FunnelsRepository
  ) {}

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedFunnel?.name, Validators.required],
    });
  }
  onSubmit() {
    const id = this.editedFunnel?.id?.toString();
    const tenantId = this.editedFunnel?.tenantId?.toString();
    const name = this.editForm.value?.name?.toString();

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.FunnelSubmit.emit({
      id,
      tenantId,
      name,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
