import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from '../abstract/base.repository';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../users.repository';

export const TicketsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];
export interface ITicketComment {
  id: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  userName: string;
  imagePath: string;
  ticketId: string;
  myMessage: boolean;
  expanded: boolean;
  formatedCreatedAt: string;
  isEdited: boolean;
  documents: ITicketCommentDocument[];
}
export interface ITicket {
  id: string;
  topic: string;
  formatedTopic: string;
  comments: ITicketComment[];
  createdAt: Date;
  preview?: string;
  formatedCreatedAt: string;
  status: TicketStatus;
  formatedStatus?: string;
  closeReason?: CloseReason;
  formatedCloseReason?: string;
  closedBy?: string;
  clientId?: string;
  locationIds: string[];
  eventIds: string[];
  userId?: string;
  closedByName?: string;
  closedAtFormated?: string;
  closedAt?: Date;
  uniqueOrder?: number;
  description?: string;
  isUrgent: boolean;
  createdById?: string;
  formatedAssignor?: string;
  assignorId?: string;
  formatedCreatedBy?: string;
}

export interface TicketCommentAction {
  ticketId: string;
  commentId: string;
  action: CRUD;
}
export interface ITicketCommentDocument {
  ticketCommentId: string;
  id: string;
  docName: string;
  docPath: string;
  updatedAt: Date;
  uploadFile: any;
}
export interface AllTicketsPage {
  tickets: ITicket[];
  userInfos: ICommUserInfo[];
}
export interface ICommUserInfo {
  id: string;
  userName: string;
  imagePath: string;
}
export interface OneTicketsPage {
  ticket: ITicket;
  userInfos: ICommUserInfo[];
  hasMoreComments: boolean;
}
export interface MoreComments {
  comments: ITicketComment[];
  hasMoreComments: boolean;
}
export enum TicketStatus {
  opened = 0,
  closed,
  inProgress,
}
export enum CloseReasonDesc {
  'was solved' = 0,
  'is not relevant' = 1,
}

export enum CloseReason {
  'solved' = 0,
  'notRelevant' = 1,
}
export enum CRUD {
  create = 0,
  read,
  update,
  delete,
}
@Injectable({ providedIn: 'root' })
export class TicketsRepository extends BaseRepository<ITicket> {
  constructor() {
    super('TicketsSortOptions', TicketsSortOptions);
  }
  ticketsCountSubject = new BehaviorSubject<string | undefined>(undefined);

  getTicketsCount(): Observable<string | undefined> {
    return this.ticketsCountSubject.asObservable();
  }

  updateTicketsCount(newValue: string): void {
    if (+newValue > 99) newValue = '99+';
    if (+newValue <= 0) newValue = '';
    this.ticketsCountSubject.next(newValue);
  }
}
