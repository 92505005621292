<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="editForm.get('title')"
  >
    <div class="inpt-title">Title</div>
    <input
      id="locationTitle"
      formControlName="title"
      class="inpt"
      placeholder="Title"
      i18n-placeholder="Title placeholder @@title"
      autocomplete="off"
    />
  </div>
  <div
    class="form-group form-floating mb-3"
    [class.is-invalid]="
      (!editForm.value?.latitude || !editForm.value?.latitude) &&
      editForm.get('address')?.touched
    "
    *ngIf="locationCheck()"
  >
    <div class="inpt-title">Address</div>

    <input
      id="location"
      formControlName="address"
      class="inpt"
      ngx-google-places-autocomplete
      [options]="myOptions"
      placeholder="Address"
      #palceRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)"
      i18n-placeholder="Title placeholder @@CompanyAddress"
    />
  </div>
  <div
    class="col-12 form-group form-floating mb-2"
    [class.is-invalid]="
      editForm.get('defaultRadius')?.hasError('required') &&
      editForm.get('defaultRadius')?.touched
    "
  >
    <app-datalist-select
      class=""
      [activeValue]="editForm.get('defaultRadius')?.value"
      [options]="CirclesOptions"
      (activeValueChange)="updateValue('defaultRadius', $event)"
      [multiple]="false"
      label="Default radius on map"
      i18n-label="Default radius on map"
    ></app-datalist-select>
  </div>

  <div class="form-floating mb-2">
    <div class="inpt-title">Venue Specific Notes</div>
    <textarea
      id="LastEventDescription"
      class="inpt inpt-lg"
      formControlName="lastEventDescription"
      type="text"
      placeholder="Venue Specific Notes"
      autocomplete="off"
    ></textarea>
  </div>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="editForm.get('timeZoneId')"
  >
    <div class="inpt-title">Time Zone Id</div>
    <input
      *ngIf="editForm.get('timeZoneId')?.value; else notTimeZoneId"
      readonly
      id="timeZoneId"
      formControlName="timeZoneId"
      class="inpt"
      i18n-placeholder="Title placeholder @@title"
      autocomplete="off"
    />
    <ng-template #notTimeZoneId>
      <div class="text-danger">
        {{ editForm.get("timeZoneError")?.value }}
      </div>
    </ng-template>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button
      *ngIf="!isLoading; else loadingContent"
      type="submit"
      class="btn btn-wfrBlue shadow-sm"
      i18n="@@save"
    >
      Save
    </button>
    <ng-template #loadingContent>
      <app-spinner></app-spinner>
    </ng-template>
  </div>
</form>
