import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IClientUser } from 'src/app/state/clientUsers.repository';
import { formatDate } from '@angular/common';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';

@Component({
  selector: 'app-client-users-editform',
  templateUrl: './client-users-editform.component.html',
  styleUrls: ['./client-users-editform.component.scss'],
})
export class ClientUsersEditformComponent {
  editForm: UntypedFormBuilder | any;
  constructor(private formBuilder: UntypedFormBuilder) {}
  clientsOptions: DatalistOption[] | null = null;
  @Input() set clients(value: ICompanyMarker[] | null) {
    if (!value) {
      this.clientsOptions = null;
    } else {
      this.clientsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }

  ngOnInit(): void {
    const birthday =
      this.editedClientUser && this.editedClientUser.birthday
        ? formatDate(this.editedClientUser.birthday, 'yyyy-MM-dd', 'en')
        : '';
    this.editForm = this.formBuilder.group({
      name: [this.editedClientUser?.name || ''],
      id: [this.editedClientUser?.id],
      clientIds: [this.editedClientUser?.clientIds, Validators.required],

      surname: [this.editedClientUser?.surname || ''],
      email: [
        this.editedClientUser?.email,
        [Validators.required, Validators.email],
      ],

      phone: [this.editedClientUser?.phoneNumber || ''],
      picture: [this.editedClientUser?.image || ''],
      location: [this.editedClientUser?.location || ''],
      title: [this.editedClientUser?.title || ''],

      birthday: [birthday],
      password: [''],
      roles: [this.editedClientUser?.roles ?? ['ClientUser']],
      tenant: [this.editedClientUser?.tenantId || ''],
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.ClientUserSubmit.emit({
      email: this.editForm?.value?.email,
      id: this.editForm?.value?.id ?? null,
      clientIds: this.editForm?.value?.clientIds ?? [],
      phoneNumber: this.editForm?.value?.phone,
      name: this.editForm?.value?.name,
      surname: this.editForm?.value?.surname,
      title: this.editForm?.value?.title,

      location: this.editForm?.value?.location,
      birthday: this.editForm?.value?.birthday || null,
      image: this.editForm?.value?.picture,
      password: this.editForm?.value?.password,
      tenantId: this.editForm?.value?.tenant || null,
      roles: this.editForm?.value?.roles || ['ClientUser'],
    });
    this.editForm?.markAsUntouched();
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  @Input() editedClientUser?: IClientUser | null;
  @Output() ClientUserSubmit = new EventEmitter<Partial<IClientUser>>();
}
