import { Component, OnInit } from '@angular/core';
import { EventService } from '../../../state/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { IEvent, EventsRepository } from 'src/app/state/event.repository';
import { FormBuilder } from '@angular/forms';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-event-sharing',
  templateUrl: './event-sharing.component.html',
  styleUrls: ['./event-sharing.component.scss'],
})
export class EventSharingComponent implements OnInit {
  constructor(
    private service: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: EventsRepository,
    private urlId: UrlIdResolverService
  ) {}

  EventsList?: IEvent[];
  Ids: any[] = [];
  selectedEvents: any = [];

  filterByUser() {
    this.service.getEventSharing().subscribe((data) => {
      this.EventsList = data;
    });
  }
  ngOnInit(): void {
    this.filterByUser();
  }
}
