import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  IMWEvent,
  MWEventsRepository,
  MWEventsSortOptions,
} from 'src/app/state/mwevent.repository';
import { MWEventService } from 'src/app/state/mwevent.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mwevent',
  templateUrl: './mwevent.component.html',
  styleUrls: ['./mwevent.component.scss'],
})
export class MweventComponent implements OnInit {
  events: IMWEvent[] | null = null;

  constructor(
    public repo: MWEventsRepository,
    public service: MWEventService,
    public authRepo: AuthRepository
  ) {}

  sortOptions = MWEventsSortOptions;
  ngOnInit(): void {
    this.service.loadAll().subscribe((x) => {
      this.events = x;
    });
    //this.service.reloadPage().subscribe();
  }
}
