import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  TimelogsRepository,
  daysTotal,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';

@Component({
  selector: 'app-timelogs-users-overview',
  templateUrl: './timelogs-users-overview.component.html',
  styleUrls: ['./timelogs-users-overview.component.scss'],
})
export class TimelogsUsersOverviewComponent implements OnInit {
  Datejs: dayjs.Dayjs = dayjs().startOf('week');
  currentDate = new Date();

  startDate = new Date(this.currentDate.getFullYear(), 0, 1);
  days = Math.floor(
    (+this.currentDate - +this.startDate) / (24 * 60 * 60 * 1000)
  );
  daysTotals: daysTotal[] = [];

  monTotal: number = 0;
  tueTotal: number = 0;
  wedTotal: number = 0;
  thuTotal: number = 0;
  friTotal: number = 0;
  satTotal: number = 0;
  sunTotal: number = 0;
  genTotal: number = 0;

  weekNumber = Math.ceil(this.days / 7);
  year = this.currentDate.getFullYear();
  WeekDateString: string = `${this.year}-W${this.weekNumber}`;

  constructor(
    public service: TimelogsService,
    public repo: TimelogsRepository
  ) {}

  loadData() {
    var length = this.WeekDateString?.toString().length;
    if (!length) {
      //smthn is wrong
      return;
    }
    var year = +this.WeekDateString?.slice(0, 4);
    if (length == 7) {
      // week is in range of 1 - 9
      var Datejs2 = dayjs()
        .year(year)
        .startOf('year')
        .add(+this.WeekDateString.toString().slice(length - 1, length), 'week');
    } else {
      var Datejs2 = dayjs()
        .year(year)
        .startOf('year')
        .add(+this.WeekDateString.toString().slice(length - 2, length), 'week');
    }
    var endSelectedWeek = Datejs2.endOf('week').add(2, 'day').toISOString();
    var startSelectedWeek = Datejs2.startOf('week').add(2, 'day').toISOString();
    this.service
      .getTimeLogsOverviewUsers(
        startSelectedWeek.slice(0, 10),
        endSelectedWeek.slice(0, 10)
      )
      .subscribe((x) => {
        this.daysTotals = x;
        this.evaluateTotal();
      });
  }

  ngOnInit(): void {
    this.loadData();
  }
  changeinput() {
    this.loadData();
  }

  evaluateTotal() {
    this.monTotal = 0;
    this.tueTotal = 0;
    this.wedTotal = 0;
    this.thuTotal = 0;
    this.friTotal = 0;
    this.satTotal = 0;
    this.sunTotal = 0;
    this.genTotal = 0;

    this.daysTotals.forEach((x) => {
      this.monTotal += x.monHours;
      this.genTotal += x.monHours;
      this.tueTotal += x.tueHours;
      this.genTotal += x.tueHours;
      this.wedTotal += x.wedHours;
      this.genTotal += x.wedHours;
      this.thuTotal += x.thuHours;
      this.genTotal += x.thuHours;
      this.friTotal += x.friHours;
      this.genTotal += x.friHours;
      this.satTotal += x.satHours;
      this.genTotal += x.satHours;
      this.sunTotal += x.sunHours;
      this.genTotal += x.sunHours;
    });
    this.genTotal = +this.genTotal.toFixed(2);
    this.monTotal = +this.sunTotal.toFixed(2);
    this.tueTotal = +this.sunTotal.toFixed(2);
    this.wedTotal = +this.sunTotal.toFixed(2);
    this.thuTotal = +this.sunTotal.toFixed(2);
    this.friTotal = +this.sunTotal.toFixed(2);
    this.satTotal = +this.sunTotal.toFixed(2);
    this.sunTotal = +this.sunTotal.toFixed(2);
  }
}
