<div class="row mrg-row mbody">
  <div class="col-2 col-lg-1 p-0 topmenu">
    <div
      [class.align-items-center]="sublength < 6"
      class="icosection d-flex justify-content-center"
    >
      <div class="mt-lg-0 mt-5">
        <div class="icons pt-4">
          <div
            *ngIf="index !== -1"
            (click)="navigate(-1)"
            class="icon d-flex justify-content-center align-items-center"
          >
            <svg
              width="16"
              height="20"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1.25L2.09344 11.9379C1.74463 12.3564 1.57023 12.5657 1.56756 12.7425C1.56524 12.8961 1.63372 13.0423 1.75324 13.1389C1.89073 13.25 2.16316 13.25 2.70802 13.25H9.99998L8.99998 21.25L17.9065 10.5621C18.2553 10.1436 18.4297 9.93429 18.4324 9.75754C18.4347 9.60388 18.3663 9.4577 18.2467 9.36111C18.1092 9.25 17.8368 9.25 17.292 9.25H9.99998L11 1.25Z"
                stroke="#0B5087"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            *ngIf="index === -1"
            class="icon chosenicon d-flex justify-content-center align-items-center"
          >
            <svg
              width="16"
              height="20"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1.25L2.09344 11.9379C1.74463 12.3564 1.57023 12.5657 1.56756 12.7425C1.56524 12.8961 1.63372 13.0423 1.75324 13.1389C1.89073 13.25 2.16316 13.25 2.70802 13.25H9.99998L8.99998 21.25L17.9065 10.5621C18.2553 10.1436 18.4297 9.93429 18.4324 9.75754C18.4347 9.60388 18.3663 9.4577 18.2467 9.36111C18.1092 9.25 17.8368 9.25 17.292 9.25H9.99998L11 1.25Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div id="mpopup-1" class="popup" (mouseleave)="hidepopup(-1)">
            <span class="popuptext" id="myPopup-1" style="top: -15px">
              <span
                *ngFor="let visitor of mwevent?.usersThatView; let y = index"
              >
                <span class="d-flex align-items-center">
                  <img
                    class="rounded-circle bg-secondary me-1"
                    src="{{
                      visitor.imagePath ? visitor.imagePath : DEFAULT_IMAGE
                    }}"
                    title="{{ visitor.nameSurname }}"
                    width="15"
                    height="15"
                  />
                  {{ visitor.nameSurname }}
                  <span *ngIf="y !== (mwevent?.usersThatView)!.length - 1"
                    >,</span
                  >
                </span>
              </span>
            </span>
          </div>
          <div class="d-flex" style="position: relative">
            <div
              class="visitorssection"
              *ngFor="let user of mwevent?.usersThatView; let k = index"
            >
              <img
                *ngIf="k <= 1"
                [class.k0]="k === 0"
                [class.k1]="k === 1"
                class="uimg rounded-circle bg-secondary"
                src="{{ user.imagePath ? user.imagePath : DEFAULT_IMAGE }}"
                title="{{ user.nameSurname }}"
              />
              <div
                *ngIf="k === 2"
                class="rounded-circle bg-white k2 d-flex align-items-start"
                (mouseenter)="popup(-1)"
              >
                <svg
                  id="hoverico-1"
                  class="svg-more"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                    stroke="#6c757d"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div *ngFor="let subevent of mwevent?.subevents; let i = index">
          <div class="d-flex justify-content-center">
            <hr *ngIf="index === i" class="choosenvl vl" style="0 auto" />
            <hr *ngIf="index < i" class="vl" style="0 auto" />
            <hr *ngIf="index > i" class="passedvl vl" style="0 auto" />
          </div>
          <div
            *ngIf="index !== i && index < i"
            (click)="navigate(i)"
            class="icon d-flex justify-content-center align-items-center"
          >
            <div *ngIf="sublength - 1 !== i">{{ i + 1 }}</div>
            <svg
              *ngIf="sublength - 1 === i"
              width="16"
              height="20"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.91937 14L7.91938 21M3.91938 6.30813V8.43875C3.91938 8.64677 3.91938 8.75078 3.89896 8.85026C3.88084 8.93852 3.85088 9.02393 3.80989 9.10417C3.76369 9.19461 3.69871 9.27583 3.56877 9.43826L1.99899 11.4005C1.33325 12.2327 1.00038 12.6487 1 12.9989C0.999672 13.3035 1.13814 13.5916 1.37615 13.7815C1.64983 14 2.18268 14 3.24837 14H12.5904C13.6561 14 14.1889 14 14.4626 13.7815C14.7006 13.5916 14.8391 13.3035 14.8387 12.9989C14.8384 12.6487 14.5055 12.2327 13.8398 11.4005L12.27 9.43826C12.14 9.27583 12.0751 9.19461 12.0289 9.10417C11.9879 9.02393 11.9579 8.93852 11.9398 8.85026C11.9194 8.75078 11.9194 8.64677 11.9194 8.43875V6.30813C11.9194 6.19301 11.9194 6.13544 11.9259 6.07868C11.9317 6.02825 11.9413 5.97833 11.9546 5.92937C11.9697 5.87424 11.9911 5.8208 12.0338 5.71391L13.0417 3.19423C13.3357 2.45914 13.4827 2.09159 13.4214 1.79655C13.3678 1.53853 13.2145 1.31211 12.9949 1.1665C12.7437 1 12.3478 1 11.5561 1H4.28263C3.49091 1 3.09506 1 2.84388 1.1665C2.62423 1.31211 2.47094 1.53853 2.41732 1.79655C2.35601 2.09159 2.50303 2.45914 2.79707 3.19423L3.80494 5.71391C3.84769 5.8208 3.86907 5.87424 3.88411 5.92937C3.89747 5.97833 3.90709 6.02825 3.91287 6.07868C3.91938 6.13544 3.91938 6.19301 3.91938 6.30813Z"
                stroke="#D0D5DD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            *ngIf="index === i"
            class="icon chosenicon d-flex justify-content-center align-items-center"
          >
            <div *ngIf="sublength - 1 !== i">{{ i + 1 }}</div>
            <svg
              *ngIf="sublength - 1 === i"
              width="16"
              height="20"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.91937 14L7.91938 21M3.91938 6.30813V8.43875C3.91938 8.64677 3.91938 8.75078 3.89896 8.85026C3.88084 8.93852 3.85088 9.02393 3.80989 9.10417C3.76369 9.19461 3.69871 9.27583 3.56877 9.43826L1.99899 11.4005C1.33325 12.2327 1.00038 12.6487 1 12.9989C0.999672 13.3035 1.13814 13.5916 1.37615 13.7815C1.64983 14 2.18268 14 3.24837 14H12.5904C13.6561 14 14.1889 14 14.4626 13.7815C14.7006 13.5916 14.8391 13.3035 14.8387 12.9989C14.8384 12.6487 14.5055 12.2327 13.8398 11.4005L12.27 9.43826C12.14 9.27583 12.0751 9.19461 12.0289 9.10417C11.9879 9.02393 11.9579 8.93852 11.9398 8.85026C11.9194 8.75078 11.9194 8.64677 11.9194 8.43875V6.30813C11.9194 6.19301 11.9194 6.13544 11.9259 6.07868C11.9317 6.02825 11.9413 5.97833 11.9546 5.92937C11.9697 5.87424 11.9911 5.8208 12.0338 5.71391L13.0417 3.19423C13.3357 2.45914 13.4827 2.09159 13.4214 1.79655C13.3678 1.53853 13.2145 1.31211 12.9949 1.1665C12.7437 1 12.3478 1 11.5561 1H4.28263C3.49091 1 3.09506 1 2.84388 1.1665C2.62423 1.31211 2.47094 1.53853 2.41732 1.79655C2.35601 2.09159 2.50303 2.45914 2.79707 3.19423L3.80494 5.71391C3.84769 5.8208 3.86907 5.87424 3.88411 5.92937C3.89747 5.97833 3.90709 6.02825 3.91287 6.07868C3.91938 6.13544 3.91938 6.19301 3.91938 6.30813Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            *ngIf="index > i"
            (click)="navigate(i)"
            class="passedicon icon d-flex justify-content-center align-items-center"
          >
            <div>{{ i + 1 }}</div>
          </div>
          <div id="mpopup{{ i }}" class="popup" (mouseleave)="hidepopup(i)">
            <span class="popuptext" id="myPopup{{ i }}" style="top: -15px">
              <span
                *ngFor="let visitor of subevent?.usersThatView; let y = index"
              >
                <span class="d-flex align-items-center">
                  <img
                    class="rounded-circle bg-secondary me-1"
                    src="{{
                      visitor.imagePath ? visitor.imagePath : DEFAULT_IMAGE
                    }}"
                    title="{{ visitor.nameSurname }}"
                    width="15"
                    height="15"
                  />
                  {{ visitor.nameSurname }}
                  <span *ngIf="y !== (mwevent?.usersThatView)!.length - 1"
                    >,</span
                  >
                </span>
              </span>
            </span>
          </div>
          <div class="d-flex" style="position: relative">
            <div
              class="visitorssection"
              *ngFor="let user of subevent.usersThatView; let k = index"
            >
              <img
                *ngIf="k <= 1"
                [class.k0]="k === 0"
                [class.k1]="k === 1"
                class="uimg rounded-circle bg-secondary"
                src="{{ user.imagePath ? user.imagePath : DEFAULT_IMAGE }}"
                title="{{ user.nameSurname }}"
              />
              <div
                *ngIf="k === 2"
                class="rounded-circle bg-white k2 popup d-flex align-items-start"
                (mouseenter)="popup(i)"
              >
                <svg
                  id="hoverico{{ i }}"
                  class="svg-more"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                    stroke="#6c757d"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <!--<div>
          <div class="d-flex justify-content-center">
            <hr *ngIf="index === mwevent?.subevents?.length" class="choosenvl vl " style="0 auto" />
            <hr *ngIf="index !== mwevent?.subevents?.length" class="vl " style="0 auto" />
          </div>
          <div *ngIf="index !== mwevent?.subevents?.length" (click)="navigateByIcons(sublength) " class="icon d-flex justify-content-center align-items-center">
            <svg width="16" height="20" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.91937 14L7.91938 21M3.91938 6.30813V8.43875C3.91938 8.64677 3.91938 8.75078 3.89896 8.85026C3.88084 8.93852 3.85088 9.02393 3.80989 9.10417C3.76369 9.19461 3.69871 9.27583 3.56877 9.43826L1.99899 11.4005C1.33325 12.2327 1.00038 12.6487 1 12.9989C0.999672 13.3035 1.13814 13.5916 1.37615 13.7815C1.64983 14 2.18268 14 3.24837 14H12.5904C13.6561 14 14.1889 14 14.4626 13.7815C14.7006 13.5916 14.8391 13.3035 14.8387 12.9989C14.8384 12.6487 14.5055 12.2327 13.8398 11.4005L12.27 9.43826C12.14 9.27583 12.0751 9.19461 12.0289 9.10417C11.9879 9.02393 11.9579 8.93852 11.9398 8.85026C11.9194 8.75078 11.9194 8.64677 11.9194 8.43875V6.30813C11.9194 6.19301 11.9194 6.13544 11.9259 6.07868C11.9317 6.02825 11.9413 5.97833 11.9546 5.92937C11.9697 5.87424 11.9911 5.8208 12.0338 5.71391L13.0417 3.19423C13.3357 2.45914 13.4827 2.09159 13.4214 1.79655C13.3678 1.53853 13.2145 1.31211 12.9949 1.1665C12.7437 1 12.3478 1 11.5561 1H4.28263C3.49091 1 3.09506 1 2.84388 1.1665C2.62423 1.31211 2.47094 1.53853 2.41732 1.79655C2.35601 2.09159 2.50303 2.45914 2.79707 3.19423L3.80494 5.71391C3.84769 5.8208 3.86907 5.87424 3.88411 5.92937C3.89747 5.97833 3.90709 6.02825 3.91287 6.07868C3.91938 6.13544 3.91938 6.19301 3.91938 6.30813Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div *ngIf="index === mwevent?.subevents?.length" class="icon  chosenicon d-flex justify-content-center align-items-center">
            <svg width="16" height="20" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.91937 14L7.91938 21M3.91938 6.30813V8.43875C3.91938 8.64677 3.91938 8.75078 3.89896 8.85026C3.88084 8.93852 3.85088 9.02393 3.80989 9.10417C3.76369 9.19461 3.69871 9.27583 3.56877 9.43826L1.99899 11.4005C1.33325 12.2327 1.00038 12.6487 1 12.9989C0.999672 13.3035 1.13814 13.5916 1.37615 13.7815C1.64983 14 2.18268 14 3.24837 14H12.5904C13.6561 14 14.1889 14 14.4626 13.7815C14.7006 13.5916 14.8391 13.3035 14.8387 12.9989C14.8384 12.6487 14.5055 12.2327 13.8398 11.4005L12.27 9.43826C12.14 9.27583 12.0751 9.19461 12.0289 9.10417C11.9879 9.02393 11.9579 8.93852 11.9398 8.85026C11.9194 8.75078 11.9194 8.64677 11.9194 8.43875V6.30813C11.9194 6.19301 11.9194 6.13544 11.9259 6.07868C11.9317 6.02825 11.9413 5.97833 11.9546 5.92937C11.9697 5.87424 11.9911 5.8208 12.0338 5.71391L13.0417 3.19423C13.3357 2.45914 13.4827 2.09159 13.4214 1.79655C13.3678 1.53853 13.2145 1.31211 12.9949 1.1665C12.7437 1 12.3478 1 11.5561 1H4.28263C3.49091 1 3.09506 1 2.84388 1.1665C2.62423 1.31211 2.47094 1.53853 2.41732 1.79655C2.35601 2.09159 2.50303 2.45914 2.79707 3.19423L3.80494 5.71391C3.84769 5.8208 3.86907 5.87424 3.88411 5.92937C3.89747 5.97833 3.90709 6.02825 3.91287 6.07868C3.91938 6.13544 3.91938 6.19301 3.91938 6.30813Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>-->
      </div>
    </div>
  </div>

  <div class="col-10 col-lg-11 pt-3 mrg-row">
    <div
      class="mwevent row d-flex justify-content-center align-items-center"
      *ngIf="index === -1"
    >
      <div class="col-lg-9 col-12">
        <h4 class="title d-flex justify-content-center text-break">
          <span [innerHtml]="mwevent?.title"></span>
        </h4>
        <div class="text-break descr">
          <span [innerHtml]="mwevent?.description"></span>
        </div>
        <div
          class="pt-4 d-flex align-items-center justify-content-center"
          *ngIf="mwevent?.filePath"
        >
          <video class="justify-content-center mainVideo col-12" controls>
            <source src="{{ mwevent?.filePath }}" type="video/mp4" />
            <source src="{{ mwevent?.filePath }}" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>

    <div *ngIf="index === mwevent?.subevents?.length" class="mwevent">
      <h1>END</h1>
    </div>

    <div class="" *ngFor="let subevent of mwevent?.subevents; let i = index">
      <div class="row" *ngIf="index == i">
        <div class="col-lg-7 col-12 subevsection">
          <div
            class="mwsubdata d-flex align-content-center justify-content-center"
          >
            <div
              *ngIf="subevent?.text"
              [class.mt-5]="(subevent?.text)!.length > 900"
              class="row d-flex justify-content-center align-items-center"
              style="width: 100%"
            >
              <div class="col-lg-11 col-12 mt-5">
                <div class="text-break title pb-1">
                  <span [innerHtml]="subevent?.title"></span>
                </div>
                <div class="text-break descr pb-4">
                  <span [innerHtml]="subevent?.description"></span>
                </div>
                <div
                  class="pt-2 text-break text"
                  *ngIf="subevent?.text"
                  [innerHTML]="subevent?.text"
                ></div>
              </div>
            </div>

            <div
              *ngIf="subevent?.url"
              class="row d-flex justify-content-center align-items-center"
              style="width: 100%"
            >
              <div class="col-lg-11 col-12">
                <div class="watchtext">
                  <svg
                    width="19"
                    height="14"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.26387 8.71318C1.12769 8.49754 1.05959 8.38972 1.02147 8.22342C0.992842 8.0985 0.992842 7.9015 1.02147 7.77658C1.05959 7.61028 1.12769 7.50246 1.26387 7.28682C2.38928 5.50484 5.73915 1 10.8442 1C15.9492 1 19.299 5.50484 20.4244 7.28682C20.5606 7.50246 20.6287 7.61028 20.6668 7.77658C20.6955 7.9015 20.6955 8.0985 20.6668 8.22342C20.6287 8.38972 20.5606 8.49754 20.4244 8.71318C19.299 10.4952 15.9492 15 10.8442 15C5.73915 15 2.38928 10.4952 1.26387 8.71318Z"
                      stroke="#98A2B3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.8442 11C12.501 11 13.8442 9.65685 13.8442 8C13.8442 6.34315 12.501 5 10.8442 5C9.1873 5 7.84415 6.34315 7.84415 8C7.84415 9.65685 9.1873 11 10.8442 11Z"
                      stroke="#98A2B3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Watch the video
                </div>
                <div class="text-break title pb-1">
                  <span [innerHtml]="subevent?.title"></span>
                </div>
                <div class="text-break descr pb-4">
                  <span [innerHtml]="subevent?.description"></span>
                </div>
                <iframe
                  class="urlvideo"
                  [src]="safeUrls[i]"
                  allowscriptaccess="always"
                  frameborder="0"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>

            <div
              *ngIf="subevent?.filePath"
              class="row d-flex justify-content-center align-items-center"
            >
              <div class="col-lg-11 col-12">
                <div class="watchtext">
                  <svg
                    width="19"
                    height="14"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.26387 8.71318C1.12769 8.49754 1.05959 8.38972 1.02147 8.22342C0.992842 8.0985 0.992842 7.9015 1.02147 7.77658C1.05959 7.61028 1.12769 7.50246 1.26387 7.28682C2.38928 5.50484 5.73915 1 10.8442 1C15.9492 1 19.299 5.50484 20.4244 7.28682C20.5606 7.50246 20.6287 7.61028 20.6668 7.77658C20.6955 7.9015 20.6955 8.0985 20.6668 8.22342C20.6287 8.38972 20.5606 8.49754 20.4244 8.71318C19.299 10.4952 15.9492 15 10.8442 15C5.73915 15 2.38928 10.4952 1.26387 8.71318Z"
                      stroke="#98A2B3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.8442 11C12.501 11 13.8442 9.65685 13.8442 8C13.8442 6.34315 12.501 5 10.8442 5C9.1873 5 7.84415 6.34315 7.84415 8C7.84415 9.65685 9.1873 11 10.8442 11Z"
                      stroke="#98A2B3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Watch the video
                </div>
                <div class="text-break title pb-1">
                  <span [innerHtml]="subevent?.title"></span>
                </div>
                <div class="text-break descr pb-4">
                  <span [innerHtml]="subevent?.description"></span>
                </div>
                <video class="SubVideo" controls>
                  <source src="{{ subevent?.filePath }}" type="video/mp4" />
                  <source src="{{ subevent?.filePath }}" type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div
              *ngIf="subevent?.subeventType === 'Questions'"
              class="row d-flex justify-content-center align-items-center"
              style="width: 100%"
            >
              <div class="col-lg-11 col-12">
                <div class="text-break title pb-1">
                  <span [innerHtml]="subevent?.title"></span>
                </div>
                <div class="text-break descr pb-1">
                  <span [innerHtml]="subevent?.description"></span>
                </div>
                <div class="text-break headline pb-3">
                  <span [innerHtml]="subevent?.headline"></span>
                </div>
                <div
                  *ngFor="let question of subevent?.questions; let i = index"
                  class=""
                >
                  <div class="descr">Question {{ i + 1 }}</div>
                  <div class="text-break question pb-2">
                    {{ question.question }}
                  </div>
                  <div
                    *ngIf="!question.answer"
                    class="row d-flex justify-content-between pb-1"
                  >
                    <input
                      id="{{ question.id }}"
                      type="text"
                      class="inpt mb-2 ms-2 me-2 col-8"
                      placeholder="Your answer"
                    />
                    <button
                      [disabled]="canAnswer(question.id)"
                      (click)="postAnswer(question.id)"
                      class="sendbtn col-sm-2 col-3"
                    >
                      Send
                    </button>
                  </div>

                  <div *ngIf="question.answer" class="question text-break mb-2">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="pb-2 row buttsection mt-4">
            <div class="col-6">
              <button
                *ngIf="index != -1"
                type="button"
                class="button backbtn d-inline d-flex align-items-center justify-content-center"
                (click)="navigate(index - 1)"
                title="Previous"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5771 7.00033H0.910439M0.910439 7.00033L6.74377 12.8337M0.910439 7.00033L6.74377 1.16699"
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="col-6">
              <button
                *ngIf="index !== sublength - 1"
                type="button"
                class="button d-flex align-items-center justify-content-center nextbtn d-inline"
                (click)="navigate(index + 1)"
                title="Next"
              >
                <span class="pe-2">Next</span>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.16669 7.00033H12.8334M12.8334 7.00033L7.00002 1.16699M12.8334 7.00033L7.00002 12.8337"
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-12 teamfeedsection">
          <div class="pt-3 pb-2 ms-lg-2 tftext">Team Feed</div>
          <div [class.reducescrol]="clicked" class="ms-lg-2 teamfeedscroll">
            <div
              *ngFor="let item of subevent.sortCommentsAndIdeas; let k = index"
            >
              <div *ngIf="item.idea">
                <div class="d-flex flex-row flex-start me-2 mb-3 mt-1">
                  <div
                    *ngIf="!item.ideaRating?.impact || !item.ideaRating?.effort"
                    class="ideapict me-2 d-flex align-items-center justify-content-center"
                  >
                    <svg
                      width="14"
                      height="20"
                      viewBox="0 0 16 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.44901 21H10.5511M5.95922 9H10.0409M8.00004 9L8.00004 15M11.0613 14.3264C13.4745 13.2029 15.1429 10.7924 15.1429 8C15.1429 4.13401 11.9449 1 8.00004 1C4.05514 1 0.857178 4.13401 0.857178 8C0.857178 10.7924 2.5256 13.2029 4.93881 14.3264V15C4.93881 15.9319 4.93881 16.3978 5.09416 16.7654C5.30129 17.2554 5.69858 17.6448 6.19864 17.8478C6.57368 18 7.04913 18 8.00004 18C8.95094 18 9.42639 18 9.80143 17.8478C10.3015 17.6448 10.6988 17.2554 10.9059 16.7654C11.0613 16.3978 11.0613 15.9319 11.0613 15V14.3264Z"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div
                    *ngIf="item.ideaRating?.impact && item.ideaRating?.effort"
                    class="ideapict me-2 d-flex align-items-center justify-content-center"
                  >
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 7.81429V8.50429C15.9991 10.1216 15.4754 11.6953 14.507 12.9907C13.5386 14.286 12.1775 15.2336 10.6265 15.6922C9.07557 16.1508 7.41794 16.0957 5.90085 15.5352C4.38376 14.9747 3.08849 13.9389 2.20822 12.5821C1.32795 11.2253 0.909843 9.62034 1.01626 8.00653C1.12267 6.39271 1.7479 4.85654 2.79871 3.6271C3.84951 2.39766 5.26959 1.54083 6.84714 1.1844C8.42469 0.827975 10.0752 0.991046 11.5525 1.64929M16 2.5L8.5 10.0075L6.25 7.7575"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div class="d-flex align-items-start" style="width: 100%">
                    <div
                      [class.borderIdea]="
                        !item.ideaRating?.impact || !item.ideaRating?.effort
                      "
                      class="d-flex align-items-center flex-row ideadata pt-3 pb-0 p-3"
                      style="width: 100%"
                    >
                      <div class="row">
                        <div class="text-break ideatitle col-12 mb-1">
                          {{ item.idea.title }}
                        </div>
                        <div class="text-break ideatext col-12 mb-2">
                          {{ item.idea.text }}
                        </div>

                        <div
                          *ngIf="item.idea.userId !== mwevent?.userThatViewId"
                          class="col-12 d-flex row"
                        >
                          <div
                            class="d-flex col align-items-center pb-2 pe-0"
                            style="flex: 0.2 0 0"
                          >
                            <small class="me-1 ratetext"> Impact</small>
                            <bar-rating
                              [rate]="item.ideaRating?.impact"
                              (rateChange)="
                                SetRating(
                                  item.ideaRating?.id,
                                  item.idea.id,
                                  $event,
                                  item.ideaRating?.effort
                                )
                              "
                              [max]="5"
                            >
                              <ng-template ratingActive>
                                <i
                                  class="bi bi-circle-fill rateico"
                                  style="margin: 2px; color: #fac515"
                                ></i>
                              </ng-template>
                              <ng-template ratingInactive>
                                <i
                                  class="bi bi-circle-fill rateico"
                                  style="margin: 2px; color: #ffffff"
                                ></i>
                              </ng-template>
                            </bar-rating>
                            <hr class="ms-2 vlidea" style="0 auto" />
                          </div>
                          <div
                            class="d-flex col align-items-center justify-content-start pb-2"
                            style=""
                          >
                            <small class="me-1 ratetext"> Effort</small>
                            <bar-rating
                              [rate]="item.ideaRating?.effort"
                              (rateChange)="
                                SetRating(
                                  item.ideaRating?.id,
                                  item.idea.id,
                                  item.ideaRating?.impact,
                                  $event
                                )
                              "
                              [max]="5"
                            >
                              <ng-template ratingActive>
                                <i
                                  class="bi bi-hexagon-fill rateico"
                                  style="margin: 2px; color: #fac515"
                                ></i>
                              </ng-template>
                              <ng-template ratingInactive>
                                <i
                                  class="bi bi-hexagon-fill rateico"
                                  style="margin: 2px; color: #ffffff"
                                ></i>
                              </ng-template>
                            </bar-rating>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="item.comment">
                <div class="d-flex flex-row flex-start me-2 mb-3 mt-1">
                  <img
                    *ngIf="item.comment.imagePath"
                    class="rounded-circle me-2"
                    src="{{ item.comment.imagePath }} "
                    alt="avatar"
                    width="40"
                    height="40"
                  />
                  <img
                    *ngIf="!item.comment.imagePath"
                    class="rounded-circle me-2"
                    src="{{ DEFAULT_IMAGE }}"
                    alt="avatar"
                    width="40"
                    height="40"
                    style="background: grey"
                  />
                  <div
                    class="d-flex flex-column align-items-start"
                    style="width: 100%"
                  >
                    <div class="flex-grow-1 order-1" style="width: 100%">
                      <div
                        class="d-flex flex-row align-items-center justify-content-between mb-1"
                      >
                        <div class="commname">{{ item.comment.userName }}</div>
                        <div class="commdate">
                          {{ item.comment.createdAt | date : "EEEE HH:mma" }}
                        </div>
                      </div>
                      <div
                        class="text-break d-flex align-items-center flex-row commtext pt-2 pb-3 p-3"
                      >
                        {{ item.comment.text }}
                      </div>
                    </div>
                    <!--<div class="row">
                  <button *ngIf="!item.isShowing" class="comment-btn col-6" (click)="item.isShowing = true"><small>Show({{item.comment.comments?.length}})<i class="bi bi-caret-down-fill"></i></small> </button>
                  <button *ngIf="item.isShowing" class="comment-btn col-6" (click)="item.isShowing = false"><small>Hide<i class="bi bi-caret-up-fill"></i></small> </button>
                  <button class="comment-btn col-6" (click)="reply(k)"><small><i class="bi bi-reply-fill"></i>Reply</small></button>
                </div>-->
                  </div>
                </div>

                <!--<div class="" *ngIf="replyIndex === k ">
              <form [formGroup]="ReplyForm" enctype="multipart/form-data">
                <div class="row pt-2 ">
                  <div class="col-1"></div>
                  <div class=" form-floating  col-8" [appValidateState]="ReplyForm.get('text')">
                    <input maxlength="200" id="text" formControlName="text" type="text" class="form-control form-reply" placeholder="Title" i18n-placeholder="@@title" autocomplete="off">
                    <label for="text" i18n="@@text">Comment</label>
                  </div>
                  <div class="form-group  col-2 ">
                    <button (click)="postComToCom(item.comment.id)" class="btn btn-primary shadow-sm form-reply-btn" i18n="@@save"><i class="bi bi-send"></i></button>
                  </div>
                </div>
              </form>
            </div>-->
              </div>
            </div>
          </div>
          <hr *ngIf="!clicked" />

          <div
            [class.expandedpost]="clicked"
            class="postsection ps-2 pe-2 pt-3"
            *ngIf="index !== -1 && index != mwevent?.subevents?.length"
          >
            <div
              *ngIf="!isIdea"
              class="d-flex flex-start align-items-center"
              style="width: 100%"
            >
              <div class="d-flex flex-row flex-start" style="width: 100%">
                <img
                  class="rounded-circle me-2 bg-secondary"
                  [src]="(repo.image$ | async) || DEFAULT_IMAGE"
                  [alt]="repo.displayName$ | async"
                  alt="avatar"
                  width="40"
                  height="40"
                />
                <div
                  class="d-flex flex-column align-items-start"
                  style="width: 100%"
                >
                  <form
                    [formGroup]="Form"
                    (ngSubmit)="onSubmit()"
                    enctype="multipart/form-data"
                    style="width: 100%"
                  >
                    <div
                      class="form-group form-floating mb-1"
                      [appValidateState]="Form.get('title')"
                    >
                      <textarea
                        *ngIf="!clicked"
                        id="title"
                        formControlName="title"
                        type="text"
                        class="datainput"
                        placeholder="Add comment or idea"
                        i18n-placeholder="@@Add comment or idea"
                        autocomplete="off"
                        (click)="changePost()"
                      ></textarea>
                      <textarea
                        *ngIf="clicked"
                        [class.expandedinput]="clicked"
                        id="title"
                        formControlName="title"
                        type="text"
                        class="inpt datainput"
                        placeholder="Your comment"
                        i18n-placeholder="@@Add comment or idea"
                        autocomplete="off"
                        (click)="changePost()"
                      ></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              *ngIf="isIdea"
              class="d-flex flex-start align-items-center"
              style="width: 100%"
            >
              <div class="d-flex flex-row flex-start" style="width: 100%">
                <img
                  class="rounded-circle me-2 bg-secondary"
                  [src]="(repo.image$ | async) || DEFAULT_IMAGE"
                  [alt]="repo.displayName$ | async"
                  width="40"
                  height="40"
                />

                <div
                  class="d-flex flex-column align-items-start"
                  style="width: 100%"
                >
                  <form
                    *ngIf="isIdea"
                    [formGroup]="IdeaForm"
                    (ngSubmit)="onSubmit()"
                    enctype="multipart/form-data"
                    style="width: 100%"
                  >
                    <div class="row">
                      <div
                        class="form-group form-floating mb-1 col-12"
                        [appValidateState]="IdeaForm.get('title')"
                      >
                        <input
                          id="title"
                          formControlName="title"
                          type="text"
                          class="inpt datainput"
                          placeholder="Idea title"
                          i18n-placeholder="@@title"
                          autocomplete="off"
                        />
                      </div>
                      <div
                        class="form-group form-floating mb-2 col-12"
                        [appValidateState]="IdeaForm.get('text')"
                      >
                        <textarea
                          id="text"
                          formControlName="text"
                          type="text"
                          class="inpt datainput datainputidea"
                          placeholder="Idea"
                          i18n-placeholder="@@idea content"
                          autocomplete="off"
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              *ngIf="clicked"
              class="d-flex justify-content-between align-items-center"
              style="width: 100%"
            >
              <label class="toggle ms-xl-5 ms-lg-0 ms-sm-5">
                <input
                  value="isIdea"
                  class="toggle-checkbox"
                  type="checkbox"
                  id="isIdea"
                  (change)="changeForm()"
                />
                <span class="toggle-switch"></span>
                <span class="switchtext ms-2">Turn into idea</span>
              </label>

              <div>
                <div class="">
                  <button
                    *ngIf="!isIdea"
                    type="submit"
                    class="sbmt-btn"
                    (click)="onSubmit()"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00008 1.16699V12.8337M1.16675 7.00033H12.8334"
                        stroke="white"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="ms-2">Add input</span>
                  </button>
                  <button
                    *ngIf="isIdea"
                    type="submit"
                    class="sbmt-btn"
                    (click)="onSubmit()"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00008 1.16699V12.8337M1.16675 7.00033H12.8334"
                        stroke="white"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="ms-2">Add idea</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div
      *ngIf="index === -1 || index === mwevent?.subevents?.length"
      class="mt-4 row buttsection"
    >
      <div class="col-6">
        <button
          *ngIf="index != -1"
          type="button"
          class="button backbtn d-inline d-flex align-items-center justify-content-center"
          (click)="navigate(index - 1)"
          title="Previous"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5771 7.00033H0.910439M0.910439 7.00033L6.74377 12.8337M0.910439 7.00033L6.74377 1.16699"
              stroke="#344054"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="col-6">
        <button
          *ngIf="index !== mwevent?.subevents?.length"
          type="button"
          class="button d-flex align-items-center justify-content-center nextbtn d-inline"
          (click)="navigate(index + 1)"
          title="Next"
        >
          <span class="pe-2">Next</span>
          <svg
            width="11"
            height="11"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16669 7.00033H12.8334M12.8334 7.00033L7.00002 1.16699M12.8334 7.00033L7.00002 12.8337"
              stroke="#344054"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
