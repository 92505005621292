import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { BaseRepository } from './abstract/base.repository';
import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';
import { User } from './users.repository';

export interface IMWEvent {
  id: string;
  title: string;
  description?: string;
  file: File | null;
  filePath: string;
  subevents: IMWSubevents[];
  //viewer?: string;
  viewersIds?: string[];
  isViewer?: boolean;
  //responsible?: string;
  respIds?: string[];
  isActive: boolean;
  isResponsible?: boolean;
  createdAt: Date;
  usersIds?: string[];
  userThatViewId?: string | null;
  userThatViewImg?: string | null;
  currentPosition?: number;
  usersThatView?: UserInfo[];
}

export interface IMWSubevents {
  id: string;
  title: string;
  filePath?: string;
  file: File | null;
  description?: string;
  headline?: string;
  subeventType: string;
  url: string;
  text: string;
  ideas: IIdea[];
  order: number;
  comments: IComments[];
  questions: ISubeventQuestion[];
  sortCommentsAndIdeas: SortComentsAndIdeas[];
  usersThatView?: UserInfo[];
}

export interface UserInfo {
  id: string;
  imagePath: string;
  nameSurname: string;
}

export interface ISubeventQuestion {
  id: string;
  question: string;
  answer?: string;
}

export interface ISubeventAnswer {
  id?: string;
  subeventQuestionId: string;
  userId?: string;
  answer: string;
}

export interface SortIdeas {
  idea: IIdea;
  effortAvg: number;
  impactAvg: number;
  rate: number;
  color: string;
}

export interface SortComentsAndIdeas {
  createdAt: Date;
  type: string;
  idea: IIdea | null;
  comment: IComments | null;
  ideaRating?: IdeaRating | null;
  isShowing: boolean | false;
  effortAvg?: number;
  impactAvg?: number;
}

export interface IdeaRating {
  id?: string;
  subeventIdeaId?: string;
  userId?: string;
  impact?: number;
  effort?: number;
}

export interface IIdea {
  id?: string;
  mwSubeventId: string;
  userId?: string;
  title: string;
  text: string;
  ideaRating?: IdeaRating[];
  comments?: IComments[];
  createdAt?: Date;
}

export interface IComments {
  id?: string;
  mwSubeventId?: string;
  subeventIdeaId?: string;
  userId?: string;
  commentId?: string;
  postId?: string;
  comments?: IComments[] | null;
  createdAt?: Date;
  text: string;
  imagePath?: string;
  userName?: string;
}

const { state, config } = createState(
  withEntities<IMWEvent>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'posts', state, config });
export const trackPostRequestsStatus = createRequestsStatusOperator(store);
export const skipWhilePostsCached = createRequestsCacheOperator(store);

export const MWEventsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class MWEventsRepository extends BaseRepository<IMWEvent> {
  name = store.name;

  posts$ = store.pipe(selectAllEntities());
  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: IMWEvent['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setPosts(posts: IMWEvent[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: IMWEvent) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }

  constructor() {
    super('apvs', MWEventsSortOptions);
  }
}
