import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { UserRoles } from '../state/auth.repository';
import { TenantFeatures } from '../state/feature.repository';
import { EditTicketsPageComponent } from '../modules/ticket-system/pages/edit-tickets-page/edit-tickets-page.component';
import { TicketsPageComponent } from '../modules/ticket-system/pages/tickets-page/tickets-page.component';
import { PrefillTicketEsComponent } from '../modules/ticket-system/pages/prefill-ticket-es/prefill-ticket-es.component';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'tickets/:status',
        component: TicketsPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Tickets,
        },
      },
      {
        path: 'ticket/:id',
        component: EditTicketsPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Tickets,
        },
      },
      {
        path: 'prefill/ticket/:id',
        component: PrefillTicketEsComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Tickets,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class TicketsRoutingModule { }
