import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  IDocument,
  DocumentsRepository,
} from '../../../../state/document.repository';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from '../../../../state/document.service';
import { Location } from '@angular/common';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import {
  FoldersRepository,
  IFolder,
} from '../../../../state/folders.repository';
import { FoldersService } from '../../../../state/folders.service';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
@Component({
  selector: 'app-document-editform',
  templateUrl: './document-editform.component.html',
  styleUrls: ['./document-editform.component.scss'],
})
export class DocumentEditformComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private repo: DocumentsRepository,
    private service: DocumentsService,
    private location: Location,
    public foldersRepo: FoldersRepository,
    public foldersService: FoldersService,
    public documentypesRepo: DocumentTypesRepository,
    public documenttypesService: DocumentTypesService
  ) {}

  editForm: UntypedFormBuilder | any;
  file: File | null = null;
  @Input() editedDocument: Partial<IDocument> | null = null;
  submitErrors: string[] | null = null;
  ParentFolderOptions: DatalistOption[] | null = null;
  DocumentTypeOptions: DatalistOption[] | null = null;

  @Input() set folders(value: IFolder[] | null) {
    if (!value) {
      this.ParentFolderOptions = null;
    } else {
      this.ParentFolderOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set documenttypes(value: IDocumentType[] | null) {
    if (!value) {
      this.DocumentTypeOptions = null;
    } else {
      this.DocumentTypeOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      title: [this.editedDocument?.title, Validators.required],
      documentTypeId: [
        this.editedDocument?.documentTypeId,
        Validators.required,
      ],
      document: this.editedDocument?.document,
    });
    this.foldersService.loadAll().subscribe((x) => {
      this.folders = x;
    });
    this.documenttypesService.loadNonSys().subscribe((x) => {
      this.documenttypes = x.data;
    });
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.editForm) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.editForm.value.document = this.file;
      }
    }
  }

  onSubmit() {
    const title = this.editForm.value?.title?.toString();
    const documentTypeId = this.editForm.value?.documentTypeId;
    //const folderId = this.editForm.value?.folderId;
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.submitErrors = null;
    let updateResult: Observable<IDocument> | undefined;

    this.service
      .updateDocument({
        id: this.editedDocument?.id,
        title,
        documentTypeId /*, folderId */,
      })
      .subscribe((x) => {
        const id = this.editedDocument?.id;
        updateResult = this.service.patch({
          id,
          title,
          documentTypeId,
          /* folderId, */ document: this.file,
        });
        if (updateResult) {
          updateResult.subscribe({
            complete: () => {
              this.location.back();
            },
            error: (data) => (this.submitErrors = data),
          });
        }
      });
  }
  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
