import { Component, EventEmitter, Input, Output } from '@angular/core';
import dayjs from 'dayjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AuthRepository } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-event-schedules-title',
  templateUrl: './event-schedules-title.component.html',
  styleUrls: ['./event-schedules-title.component.scss'],
})
export class EventSchedulesTitleComponent {
  constructor(public authrepo: AuthRepository) {}
  @Output() setYear = new EventEmitter<number>();
  @Input() selectedYear: number | null = null;
  @Input() yearsOptions: DatalistOption[] | null = null;
  @Input() isDraft: boolean = false;
}
