import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { IApv, ApvRepository } from 'src/app/state/apv.repository';
import { ApvService } from 'src/app/state/apv.service';

@Component({
  selector: 'app-apv-post',
  templateUrl: './apv-post.component.html',
  styleUrls: ['./apv-post.component.scss'],
})
export class ApvPostComponent implements OnInit {
  post$!: Observable<IApv>;
  submitErrors: string[] | null = null;

  constructor(
    private service: ApvService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: ApvRepository
  ) {}

  ngOnInit(): void {}

  post($event: Partial<IApv>) {
    this.submitErrors = null;
    let updateResult: Observable<IApv> | undefined;
    updateResult = this.service.add($event);
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['admin/apv']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
