import { DOCUMENT, NgForOf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IApv, ApvRepository } from 'src/app/state/apv.repository';
import { ApvService } from 'src/app/state/apv.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { User } from 'src/app/state/users.repository';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-apv-form',
  templateUrl: './apv-form.component.html',
  styleUrls: ['./apv-form.component.scss'],
})
export class ApvFormComponent implements OnInit {
  ApvForm: UntypedFormBuilder | any;
  @Input() editedAPV?: IApv | null;
  @Output() ApvSubmit = new EventEmitter<Partial<IApv>>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public authRepo: AuthRepository,
    private repo: ApvRepository,
    private service: ApvService
  ) {}

  ngOnInit() {
    this.ApvForm = this.formBuilder.group({
      title: [this.editedAPV?.title, Validators.required],
      description: [this.editedAPV?.description, Validators.required],
      apvQuestions: this.formBuilder.array([]),
    });
    this.setApvQuestion();
  }

  getControls() {
    if (this.ApvForm) {
      return (this.ApvForm.get('apvQuestions') as UntypedFormArray).controls;
    }
    return [];
  }

  deleteQuestion(index: number) {
    let control = <UntypedFormArray>this.ApvForm?.controls.apvQuestions;
    control.removeAt(index);
  }

  setApvQuestion() {
    let control = <UntypedFormArray>this.ApvForm?.controls.apvQuestions;
    this.editedAPV?.apvQuestions.forEach((x) => {
      control.push(
        this.formBuilder.group({
          id: x.id,
          title: [x.title, Validators.required],
        })
      );
    });
  }

  addNewApvQuestion() {
    let control = <UntypedFormArray>this.ApvForm?.controls.apvQuestions;
    control.push(
      this.formBuilder.group({
        title: ['', Validators.required],
      })
    );
  }

  onSubmit() {
    if (this.ApvForm && !this.ApvForm.valid) {
      this.ApvForm.markAllAsTouched();
      return;
    }

    const title = this.ApvForm.value?.title.toString();
    const description = this.ApvForm.value?.description;
    const apvQuestions = this.ApvForm.value?.apvQuestions;
    this.ApvSubmit.emit({ title, description, apvQuestions });
  }
}
