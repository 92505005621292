import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AdsOptions,
  IEventSchedule,
  IEventScheduleLocation,
  IFunnel,
  IRoom,
  ITopic,
  PayStatsOptions,
  StatusesOptions,
  AtCostOptions,
  IActivityLog,
  EventScheduleRepository,
} from '../../../../state/event-schedule.repository';
import { DatePipe, Location } from '@angular/common';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { CompaniesService } from '../../../../state/companies.service';
import { LocationsService } from '../../../../state/locations.service';
import { FunnelsService } from '../../../../state/funnels.service';
import { RoomsService } from '../../../../state/rooms.service';
import { TopicsService } from '../../../../state/topics.service';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import * as dayjs from 'dayjs';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { EventInfosService } from '../../../../state/event-infos.service';
import { IEventInfo } from '../../../../state/event-infos.repository';
import { PopUpIframeComponent } from '../pop-up-iframe/pop-up-iframe.component';
import { VenueSpecificNotesComponent } from '../../modals/venue-specific-notes/venue-specific-notes.component';
import { ErrorAlertModalComponent } from '../../modals/error-alert-modal/error-alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { UiRepository } from 'src/app/state/ui.repository';
@Component({
  selector: 'app-event-schedule-editform',
  templateUrl: './event-schedule-editform.component.html',
  styleUrls: ['./event-schedule-editform.component.scss'],
})
export class EventScheduleEditformComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  date1formated?: string = '';
  date2formated?: string = '';
  emailBlast1stDate = '';
  emailBlast2ndDate = '';
  @Input() editedEvent?: IEventSchedule | null;
  Clients: ICompanyMarker[] | null = null;
  submitErrors: string[] | null = null;
  popUpTab: string | null = null;
  allRooms: IRoom[] = [];
  @ViewChild(VenueSpecificNotesComponent)
  VSNComponent: VenueSpecificNotesComponent | null = null;
  @ViewChild('#errorAlertModal')
  ErrorAlertModalComponent: any;
  id: string | null = null;
  deleteConfirmation = false;

  @Output() EventSubmit = new EventEmitter<Partial<IEventSchedule>>();
  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private service: EventScheduleService,
    private clientService: CompaniesService,
    private clientLocationsService: LocationsService,
    private funnelsService: FunnelsService,
    private roomService: RoomsService,
    public datepipe: DatePipe,
    private topicService: TopicsService,
    private eventInfosService: EventInfosService,
    private router: Router,
    private route: ActivatedRoute,
    private repo: EventScheduleRepository,
    private ui: UiRepository
  ) {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        if (this.id && this.id != 'new')
          this.service
            .loadOneEventSchedule(`${value.get('id')}`)
            .subscribe((x) => {
              this.editedEvent = x;
              this.resetForm();
            });
      },
    });
  }
  @ViewChild(PopUpIframeComponent) popUpForm: PopUpIframeComponent | null =
    null;
  ClientsOptions: DatalistOption[] | null = null;
  FunnelsOptions: DatalistOption[] | null = null;
  TopicsOptions: DatalistOption[] | null = null;
  EventInfosOptions: DatalistOption[] | null = null;
  AdsOptions: DatalistOption[] = AdsOptions;
  StatusesOptions: DatalistOption[] = StatusesOptions;
  AtCostOptions: DatalistOption[] = AtCostOptions;
  scheduleLocationsOptions: DatalistOption[] | null = null;
  payStatsOptions: DatalistOption[] = PayStatsOptions;
  RoomsOptions: DatalistOption[] | null = null;
  SelectedClientId: string | null = null;
  SelectedroomId: string | null = null;
  SelectedroomD2Id: string | null = null;
  SelectedLocationId: string | null = null;
  SelectedClient: ICompanyMarker | null = null;
  SelectedLocation: IEventScheduleLocation | null = null;
  isDuplicated: boolean = false;

  isDoubleEvent: boolean = false;
  isDoubleSession: boolean = false;

  @Input() set clients(value: ICompanyMarker[] | null) {
    if (!value) {
      this.ClientsOptions = null;
    } else {
      this.ClientsOptions = value.map((x) => ({
        value: x.id,
        label: x.clientCode ? x.clientCode + ' - ' + x.title : x.title,
      }));
    }
  }
  @Input() set topics(value: ITopic[] | null) {
    if (!value) {
      this.TopicsOptions = null;
    } else {
      this.TopicsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  @Input() set eventInfos(value: IEventInfo[] | null) {
    if (!value) {
      this.EventInfosOptions = null;
    } else {
      this.EventInfosOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set funnels(value: IFunnel[] | null) {
    if (!value) {
      this.FunnelsOptions = null;
    } else {
      this.FunnelsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set locations(value: IEventScheduleLocation[] | null) {
    if (!value) {
      this.scheduleLocationsOptions = null;
    } else {
      this.scheduleLocationsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }

  @Input() set rooms(value: IRoom[] | null) {
    if (!value) {
      this.RoomsOptions = null;
    } else {
      this.RoomsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  resetForm() {
    this.isDoubleEvent = this.editedEvent?.doubleEvent ?? false;
    this.isDoubleSession = this.editedEvent?.doubleSession ?? false;
    this.editForm = this.formBuilder.group({
      use_catchlight: [this.editedEvent?.use_catchlight],
      use_catchlight_2: [this.editedEvent?.use_catchlight_2],
      waitComplianceApproval: [this.editedEvent?.waitComplianceApproval],

      rescheduledFromId: [this.editedEvent?.rescheduledFromId],
      timeZoneInfo: [this.editedEvent?.timeZoneInfo],

      topicNotes: [this.editedEvent?.topicNotes],
      startTime: [this.editedEvent?.startTime],
      startTime2: [this.editedEvent?.startTime2],

      eventType: [this.editedEvent?.eventType ?? 'workshopsSeminarType'],
      status: [this.editedEvent?.status],
      statusD2: [this.editedEvent?.statusD2],
      asap: [this.editedEvent?.asap],
      clientId: [this.editedEvent?.clientId, Validators.required],
      doubleEvent: [this.editedEvent?.doubleEvent],
      doubleSession: [this.editedEvent?.doubleSession],
      atCost: [this.editedEvent?.atCost ? this.editedEvent?.atCost : 'No'],
      atCostD2: [
        this.editedEvent?.atCostD2 ? this.editedEvent?.atCostD2 : 'No',
      ],

      date: [this.editedEvent?.date],
      time: [this.editedEvent?.time],
      landingPageUrl: [this.editedEvent?.landingPageUrl],
      landingPageId: [this.editedEvent?.landingPageId],
      landingPageId2: [this.editedEvent?.landingPageId2],
      googleSheetId: [this.editedEvent?.googleSheetId],
      googleSheetId2: [this.editedEvent?.googleSheetId2],

      wfrDinnerOrLunch: [this.editedEvent?.wfrDinnerOrLunch],
      twoPartSetup: [this.editedEvent?.twoPartSetup],

      eventScheduleLocationId: [
        this.editedEvent?.eventScheduleLocationId,
        Validators.required,
      ],
      roomId: [this.editedEvent?.roomId],
      roomD2Id: [this.editedEvent?.roomD2Id],

      parking: [this.editedEvent?.parking],
      parkingD2: [this.editedEvent?.parkingD2],

      topic: [this.editedEvent?.topic, Validators.required],
      newTopic: [
        !this.editedEvent?.prebuildTopicId && !this.editedEvent?.newTopic
          ? this.editedEvent?.topic
          : this.editedEvent?.newTopic,
      ],
      prebuildTopicId: [this.editedEvent?.prebuildTopicId],

      desiredBU: [this.editedEvent?.desiredBU],
      desiredBUD2: [this.editedEvent?.desiredBUD2],

      notes: [this.editedEvent?.notes],
      venueSpecificNotes: [this.editedEvent?.venueSpecificNotes],

      wfrReminder: [this.editedEvent?.wfrReminder],

      //todo table
      emailBlast1st: [this.editedEvent?.emailBlast1st],
      emailBlast2nd: [this.editedEvent?.emailBlast2nd],
      emailBlast1stD2: [this.editedEvent?.emailBlast1stD2],
      emailBlast2ndD2: [this.editedEvent?.emailBlast2ndD2],

      emailBlastScheduled: [this.editedEvent?.emailBlastScheduled],
      emailBlastD2Scheduled: [this.editedEvent?.emailBlastD2Scheduled],

      fewSpots: [this.editedEvent?.fewSpots],
      fewSpotsD2: [this.editedEvent?.fewSpotsD2],

      fewSpotsScheduled: [this.editedEvent?.fewSpotsScheduled],
      fewSpotsD2Scheduled: [this.editedEvent?.fewSpotsD2Scheduled],

      vmCode: [this.editedEvent?.vmCode],
      vmCodeD2: [this.editedEvent?.vmCodeD2],

      fiveDayWfrCallDate: [this.editedEvent?.fiveDayWfrCallDate],
      fiveDayWfrCallD2Date: [this.editedEvent?.fiveDayWfrCallD2Date],

      fiveDayWfrCall: [this.editedEvent?.fiveDayWfrCall],
      fiveDayWfrCallD2: [this.editedEvent?.fiveDayWfrCallD2],

      fiveDayWfrCallDone: [this.editedEvent?.fiveDayWfrCallDone],
      fiveDayWfrCallD2Done: [this.editedEvent?.fiveDayWfrCallD2Done],

      //calls table
      wfrCall1: [this.editedEvent?.wfrCall1],
      wfrCall1Date: [this.editedEvent?.wfrCall1Date],
      wfrCall1FunnelId: [this.editedEvent?.wfrCall1FunnelId],
      wfrCall1Done: [this.editedEvent?.wfrCall1Done],

      wfrCall2: [this.editedEvent?.wfrCall2],
      wfrCall2Date: [this.editedEvent?.wfrCall2Date],
      wfrCall2FunnelId: [this.editedEvent?.wfrCall2FunnelId],
      wfrCall2Done: [this.editedEvent?.wfrCall2Done],

      wfrCall1D2: [this.editedEvent?.wfrCall1D2],
      wfrCall1D2Date: [this.editedEvent?.wfrCall1D2Date],
      wfrCall1D2FunnelId: [this.editedEvent?.wfrCall1D2FunnelId],
      wfrCall1D2Done: [this.editedEvent?.wfrCall1D2Done],

      wfrCall2D2: [this.editedEvent?.wfrCall2D2],
      wfrCall2D2Date: [this.editedEvent?.wfrCall2D2Date],
      wfrCall2D2FunnelId: [this.editedEvent?.wfrCall2D2FunnelId],
      wfrCall2D2Done: [this.editedEvent?.wfrCall2D2Done],

      //set up table
      funnelId: [this.editedEvent?.funnelId],
      funnelSecondId: [this.editedEvent?.funnelSecondId],

      funnelDone: [this.editedEvent?.funnelDone],
      funnelSecondDone: [this.editedEvent?.funnelSecondDone],

      funnelQAbyId: [this.editedEvent?.funnelQAbyId],
      funnelSecondQAbyId: [this.editedEvent?.funnelSecondQAbyId],

      funnelQADone: [this.editedEvent?.funnelQADone],
      funnelSecondQADone: [this.editedEvent?.funnelSecondQADone],

      funnelAdsId: [this.editedEvent?.funnelAdsId],
      funnelSecondAdsId: [this.editedEvent?.funnelSecondAdsId],

      funnelAdsDone: [this.editedEvent?.funnelAdsDone],
      funnelSecondAdsDone: [this.editedEvent?.funnelSecondAdsDone],

      funnelAdsQAbyId: [this.editedEvent?.funnelAdsQAbyId],
      funnelSecondAdsQAbyId: [this.editedEvent?.funnelSecondAdsQAbyId],

      funnelAdsQADone: [this.editedEvent?.funnelAdsQADone],
      funnelSecondAdsQADone: [this.editedEvent?.funnelSecondAdsQADone],

      //end table
      adSpent: [this.editedEvent?.adSpent],
      adSpentNotes: [this.editedEvent?.adSpentNotes],
      payStats: [this.editedEvent?.payStats],
      reminder: [this.editedEvent?.reminder],
      reminderReady: [this.editedEvent?.reminderReady],

      //maybe we will need them at future
      attendees: [this.editedEvent?.attendees],
      attendeesD2: [this.editedEvent?.attendeesD2],
      finalRegistrants: [this.editedEvent?.finalRegistrants],
      finalRegistrantsD2: [this.editedEvent?.finalRegistrantsD2],

      totalAttendees: [this.editedEvent?.totalAttendees],
      googleSheet: [this.editedEvent?.googleSheet],
      googleSheetD2: [this.editedEvent?.googleSheetD2],

      clientTouch: [this.editedEvent?.clientTouch],
      clientTouchReady: [this.editedEvent?.clientTouchReady],
      emailBlast1Ready: [this.editedEvent?.emailBlast1Ready],
      emailBlast2Ready: [this.editedEvent?.emailBlast2Ready],
      wfrReminderReady: [this.editedEvent?.wfrReminderReady],
      date2: [this.editedEvent?.date2],
      time2: [this.editedEvent?.time2],

      prebuildTime2Id: [this.editedEvent?.prebuildTime2Id],
      prebuildTimeId: [this.editedEvent?.prebuildTimeId],

      landingPageUrl2: [this.editedEvent?.landingPageUrl2],
      fewSpotsReady: [this.editedEvent?.fewSpotsReady],
      clientUrl: [this.editedEvent?.clientUrl],
      locationUrl: [this.editedEvent?.locationUrl],
      funneladsQADone: [this.editedEvent?.funnelAdsQADone],
      adsD2QAby: [this.editedEvent?.adsD2QAby],
      adsD2QADone: [this.editedEvent?.adsD2QADone],
      formatedDate: [this.editedEvent?.formatedDate],
      formatedDate2: [this.editedEvent?.formatedDate2],

      metaAds: [this.editedEvent?.metaAds],
      otherAds: [this.editedEvent?.otherAds],

      utC_Offset_Date: [this.editedEvent?.utC_Offset_Date],
      utC_Offset_Date2: [this.editedEvent?.utC_Offset_Date2],
      promptInvestibleAssets: [this.editedEvent?.promptInvestibleAssets],
    });
  }
  refreshData() {
    this.topicService.loadGeneral().subscribe((x) => {
      this.topics = x;
    });
    this.eventInfosService.loadGeneral().subscribe((x) => {
      this.eventInfos = x;
    });
    this.funnelsService.loadAllFunnels().subscribe((x) => {
      this.funnels = x;
    });
    if (this.editedEvent?.clientId) {
      this.SelectedClientId = this.editedEvent?.clientId;
      this.clientService
        .loadOneCompany(this.editedEvent?.clientId)
        .subscribe((x) => (this.SelectedClient = x));
      this.clientLocationsService
        .loadAllLocationsForClient(this.editedEvent?.clientId)
        .subscribe((x) => {
          this.locations = x;
        });
    }
    if (this.editedEvent?.eventScheduleLocationId) {
      this.SelectedLocationId = this.editedEvent?.eventScheduleLocationId;
      this.clientLocationsService
        .loadOneLocation(this.SelectedLocationId)
        .subscribe((x) => {
          this.SelectedLocation = x;
          this.updateValue('venueSpecificNotes', x.lastEventDescription);
        });

      this.roomService
        .loadAllRoomsForLocation(this.SelectedLocationId)
        .subscribe((x) => {
          this.rooms = x;
          this.allRooms = x;
        });
    }
    this.clientService.loadAll().subscribe((x) => {
      this.clients = x;
    });

    this.resetForm();
  }
  ngOnInit(): void {
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
    this.refreshData();
  }
  duplicate() {
    if (this.editedEvent?.id) {
      this.service.duplicate(this.editedEvent?.id).subscribe((x) => {
        this.router.navigate(['/admin/eventSchedule/eventSchedule/', x.id]);
      });
    }
  }

  setValidatorToControll(controlName: string, value: boolean) {
    let control = this.editForm.get(controlName);
    if (control) {
      if (value) control.setValidators(Validators.required);
      else control.removeValidators(Validators.required);
      control.updateValueAndValidity();
    }
    this.editForm.updateValueAndValidity();
  }
  manageLandingPageValidators() {
    if (this.editForm) {
      this.setValidatorToControll('date', true);
      this.setValidatorToControll(
        'date2',
        (this.editForm.value?.doubleEvent &&
          this.editForm.value?.landingPageId2) ||
          (this.editForm.value?.doubleSession &&
            this.editForm.value?.landingPageId)
      );

      this.setValidatorToControll('startTime', true);
      this.setValidatorToControll(
        'startTime2',
        (this.editForm.value?.doubleEvent &&
          this.editForm.value?.landingPageId2) ||
          (this.editForm.value?.doubleSession &&
            this.editForm.value?.landingPageId)
      );
    }
  }

  onSubmit(duplicate: boolean = false) {
    if (this.editedEvent?.hasReschedules) {
      this.ui.openSnackBar(
        'Changes will not be applied - Event is rescheduled'
      );
      return;
    }
    const eventScheduleLocationId =
      this.editForm.value?.eventScheduleLocationId?.toString();

    if (eventScheduleLocationId == this.repo.Default_LocationId) {
      this.ui.openSnackBar(this.repo.Default_Location_Error);
      return;
    }

    this.manageLandingPageValidators();
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      this.ui.openSnackBar('Please fill all required fields');

      return;
    }
    let submitEventSchedule = this.getFormVals();
    if (!duplicate && submitEventSchedule) {
      if (this.editedEvent?.isInReview) {
        let activityLog = {
          eventScheduleId: this.editedEvent.id,
          type: 'Approved',
          description: 'User Approved Event',
        } as Partial<IActivityLog>;
        this.service.postActivityLog(activityLog).subscribe();
      } else if (this.editedEvent?.id) {
        let activityLog = {
          eventScheduleId: this.editedEvent?.id,
          type: 'Edited',
          description: 'User Edited Event',
        } as Partial<IActivityLog>;
        this.service.postActivityLog(activityLog).subscribe();
      }
      this.EventSubmit.emit(submitEventSchedule);
      this.isDuplicated = false;
    } else if (duplicate && submitEventSchedule) {
      this.isDuplicated = true;

      this.service
        .updateEventSchedule(submitEventSchedule)
        .subscribe({ complete: () => this.duplicate() });
    }
  }

  getFormVals(): Partial<IEventSchedule> | null {
    const id = this.editedEvent?.id?.toString();
    const rescheduledFromId = this.editedEvent?.rescheduledFromId?.toString();
    const rescheduledFromIndex = this.editedEvent?.rescheduledFromIndex;
    const use_catchlight = this.editForm.value?.use_catchlight ?? false;
    const use_catchlight_2 = this.editForm.value?.use_catchlight_2 ?? false;

    const topicNotes = this.editForm.value?.topicNotes;
    const timeZoneInfo = this.editForm.value?.timeZoneInfo;

    const registrants = this.editedEvent?.registrants;
    const registrantsD2 = this.editedEvent?.registrantsD2;

    const finalRegistrants = this.editForm.value?.finalRegistrants;
    const finalRegistrantsD2 = this.editForm.value?.finalRegistrantsD2;
    const tenantId = this.editedEvent?.tenantId?.toString();
    const topic = this.editForm.value?.topic?.toString();
    const prebuildTopicId = this.editForm.value?.prebuildTopicId?.toString();

    const clientId = this.editForm.value?.clientId?.toString();
    const doubleEvent = this.editForm.value?.doubleEvent;
    const doubleSession = this.editForm.value.doubleSession;
    const asap = this.editForm.value?.asap ?? false;

    const atCost = this.editForm.value?.atCost ?? 'No';
    const atCostD2 = this.editForm.value?.atCostD2 ?? 'No';

    const date = this.datepipe.transform(
      this.editForm.value?.date,
      'yyyy-MM-dd'
    ) as any;
    const date2 = this.datepipe.transform(
      this.editForm.value?.date2,
      'yyyy-MM-dd'
    ) as any;
    var status = this.editForm.value?.status?.toString();
    var statusD2 = this.editForm.value?.statusD2?.toString();
    if (this.editedEvent?.isDraft || this.editedEvent?.isInReview) {
      status = 'Automatic';
      statusD2 = 'Automatic';
    }

    const time = this.editForm.value?.time?.toString();
    const time2 = this.editForm.value?.time2?.toString();

    const startTime = this.editForm.value?.startTime?.toString();
    const startTime2 = this.editForm.value?.startTime2?.toString();
    const wfrDinnerOrLunch = this.editForm.value?.wfrDinnerOrLunch;
    const twoPartSetup = this.editForm.value?.twoPartSetup;
    const eventScheduleLocationId =
      this.editForm.value?.eventScheduleLocationId?.toString();

    const roomId = this.editForm.value?.roomId?.toString();
    const roomD2Id = this.editForm.value?.roomD2Id?.toString();
    const notes = this.editForm.value?.notes?.toString();
    const venueSpecificNotes =
      this.editForm.value?.venueSpecificNotes?.toString();

    const wfrReminder = this.editForm.value?.wfrReminder?.toString();

    const emailBlast1st = this.editForm.value?.emailBlast1st?.toString();
    const emailBlast2nd = this.editForm.value?.emailBlast2nd?.toString();
    const emailBlast1stD2 = this.editForm.value?.emailBlast1stD2?.toString();
    const emailBlast2ndD2 = this.editForm.value?.emailBlast2ndD2?.toString();
    const emailBlastScheduled = this.editForm.value?.emailBlastScheduled;
    const emailBlastD2Scheduled = this.editForm.value?.emailBlastD2Scheduled;

    const fewSpots = this.editForm.value?.fewSpots?.toString();
    const fewSpotsD2 = this.editForm.value?.fewSpotsD2?.toString();
    const fewSpotsScheduled = this.editForm.value?.fewSpotsScheduled;
    const fewSpotsD2Scheduled = this.editForm.value?.fewSpotsD2Scheduled;
    const vmCode = this.editForm.value?.vmCode?.toString();
    const vmCodeD2 = this.editForm.value?.vmCodeD2?.toString();

    const fiveDayWfrCallDate = this.datepipe.transform(
      this.editForm.value?.fiveDayWfrCallDate,
      'yyyy-MM-dd'
    ) as any;
    const fiveDayWfrCallD2Date = this.datepipe.transform(
      this.editForm.value?.fiveDayWfrCallD2Date,
      'yyyy-MM-dd'
    ) as any;

    const fiveDayWfrCall = this.editForm.value?.fiveDayWfrCall?.toString();
    const fiveDayWfrCallD2 = this.editForm.value?.fiveDayWfrCallD2?.toString();

    const fiveDayWfrCallDone = this.editForm.value?.fiveDayWfrCallDone;
    const fiveDayWfrCallD2Done = this.editForm.value?.fiveDayWfrCallD2Done;

    const funnelId = this.editForm.value?.funnelId?.toString();
    const funnelSecondId = this.editForm.value?.funnelSecondId?.toString();

    const funnelDone = this.editForm.value?.funnelDone;
    const funnelSecondDone = this.editForm.value?.funnelSecondDone;

    const funnelQAbyId = this.editForm.value?.funnelQAbyId?.toString();
    const funnelSecondQAbyId =
      this.editForm.value?.funnelSecondQAbyId?.toString();

    const funnelQADone = this.editForm.value?.funnelQADone;
    const funnelSecondQADone = this.editForm.value?.funnelSecondQADone;

    const funnelAdsId = this.editForm.value?.funnelAdsId?.toString();
    const funnelSecondAdsId =
      this.editForm.value?.funnelSecondAdsId?.toString();

    const funnelAdsDone = this.editForm.value?.funnelAdsDone;
    const funnelSecondAdsDone = this.editForm.value?.funnelSecondAdsDone;

    const funnelAdsQAbyId = this.editForm.value?.funnelAdsQAbyId?.toString();
    const funnelSecondAdsQAbyId =
      this.editForm.value?.funnelSecondAdsQAbyId?.toString();

    const funnelAdsQADone = this.editForm.value?.funnelAdsQADone;
    const funnelSecondAdsQADone = this.editForm.value?.funnelSecondAdsQADone;

    const adSpent = this.editForm.value?.adSpent?.toString();
    const parking = this.editForm.value?.parking;
    const parkingD2 = this.editForm.value?.parkingD2;

    const desiredBU = this.editForm.value?.desiredBU?.toString();
    const desiredBUD2 = this.editForm.value?.desiredBUD2?.toString();

    const reminder = this.editForm.value?.reminder?.toString();
    const reminderReady = this.editForm.value?.reminderReady ?? false;

    const payStats = this.editForm.value?.payStats?.toString();
    const adSpentNotes = this.editForm.value?.adSpentNotes?.toString();
    const attendees = this.editForm.value?.attendees;
    const attendeesD2 = this.editForm.value?.attendeesD2;

    const totalAttendees = this.editForm.value?.totalAttendees;

    const landingPageUrl = this.editForm.value?.landingPageUrl?.toString();
    const landingPageUrl2 = this.editForm.value?.landingPageUrl2?.toString();

    const landingPageId = this.editForm.value?.landingPageId?.toString();
    const landingPageId2 = this.editForm.value?.landingPageId2?.toString();

    const googleSheet = this.editForm.value?.googleSheet;
    const googleSheetD2 = this.editForm.value?.googleSheetD2;

    const googleSheetId = this.editForm.value?.googleSheetId;
    const googleSheetId2 = this.editForm.value?.googleSheetId2;

    const sheetError = this.editForm.value?.sheetError;
    const sheetErrorD2 = this.editForm.value?.sheetErrorD2;

    const prebuildTime2Id = this.editForm.value?.prebuildTime2Id;
    const prebuildTimeId = this.editForm.value?.prebuildTimeId;

    const wfrCall1Date = this.datepipe.transform(
      this.editForm.value?.wfrCall1Date,
      'yyyy-MM-dd'
    ) as any;
    const wfrCall1FunnelId = this.editForm.value?.wfrCall1FunnelId;
    const wfrCall1Done = this.editForm.value?.wfrCall1Done ?? false;

    const wfrCall2Date = this.datepipe.transform(
      this.editForm.value?.wfrCall2Date,
      'yyyy-MM-dd'
    ) as any;

    const wfrCall2FunnelId = this.editForm.value?.wfrCall2FunnelId;
    const wfrCall2Done = this.editForm.value?.wfrCall2Done ?? false;

    const wfrCall1D2Date = this.datepipe.transform(
      this.editForm.value?.wfrCall1D2Date,
      'yyyy-MM-dd'
    ) as any;

    const wfrCall1D2FunnelId = this.editForm.value?.wfrCall1D2FunnelId;
    const wfrCall1D2Done = this.editForm.value?.wfrCall1D2Done ?? false;

    const wfrCall2D2Date = this.datepipe.transform(
      this.editForm.value?.wfrCall2D2Date,
      'yyyy-MM-dd'
    ) as any;
    const wfrCall2D2FunnelId = this.editForm.value?.wfrCall2D2FunnelId;
    const metaAds = +this.editForm.value?.metaAds;
    const otherAds = +this.editForm.value?.otherAds;

    const wfrCall2D2Done = this.editForm.value?.wfrCall2D2Done ?? false;

    const invoicedAds = this.editedEvent?.invoicedAds;
    const qts = this.editedEvent?.qts;
    const flatFee = this.editedEvent?.flatFee;
    const totalInvoices = this.editedEvent?.totalInvoices;
    const costLead = this.editedEvent?.costLead;
    const grossProfit = this.editedEvent?.grossProfit;

    const invoicedAdsD2 = this.editedEvent?.invoicedAdsD2;
    const qtsD2 = this.editedEvent?.qtsD2;
    const flatFeeD2 = this.editedEvent?.flatFeeD2;
    const totalInvoicesD2 = this.editedEvent?.totalInvoicesD2;
    const costLeadD2 = this.editedEvent?.costLeadD2;
    const grossProfitD2 = this.editedEvent?.grossProfitD2;

    let submitEventSchedule = null as Partial<IEventSchedule> | null;

    if (doubleEvent) {
      submitEventSchedule = {
        waitComplianceApproval: !!this.editForm.value?.waitComplianceApproval,
        newTopic: this.editForm.value?.newTopic?.toString(),
        eventType: this.editForm.value?.eventType ?? 'workshopsSeminarType',
        topicNotes,
        prebuildTime2Id,
        prebuildTimeId,
        id,
        rescheduledFromId,
        rescheduledFromIndex,
        registrants,
        registrantsD2,
        finalRegistrants,
        finalRegistrantsD2,
        tenantId,
        clientId,
        topic,
        prebuildTopicId,
        doubleEvent,
        asap,
        atCost,
        atCostD2,
        date,
        date2,
        status,
        statusD2,
        time,
        time2,
        startTime2,
        startTime,
        landingPageUrl,
        landingPageUrl2,
        wfrDinnerOrLunch,
        twoPartSetup,
        eventScheduleLocationId,
        roomId,
        roomD2Id,
        parking,
        parkingD2,

        desiredBU,
        desiredBUD2,
        notes,
        venueSpecificNotes,

        wfrReminder,
        emailBlast1st,
        emailBlast2nd,
        emailBlast1stD2,
        emailBlast2ndD2,
        emailBlastD2Scheduled,
        emailBlastScheduled,
        fewSpots,
        fewSpotsD2,
        fewSpotsScheduled,
        fewSpotsD2Scheduled,
        vmCode,
        vmCodeD2,
        fiveDayWfrCall,
        fiveDayWfrCallDate,
        fiveDayWfrCallD2Date,
        fiveDayWfrCallD2,
        fiveDayWfrCallDone,
        fiveDayWfrCallD2Done,
        funnelId,
        funnelDone,
        funnelQAbyId,
        funnelQADone,
        funnelSecondId,
        funnelSecondDone,
        funnelSecondQAbyId,
        funnelSecondQADone,
        adSpent,
        payStats,
        reminder,
        reminderReady,
        adSpentNotes,
        funnelAdsId,
        funnelAdsDone,
        funnelAdsQAbyId,
        funnelAdsQADone,
        funnelSecondAdsId,
        funnelSecondAdsDone,
        funnelSecondAdsQAbyId,
        funnelSecondAdsQADone,
        attendees,
        attendeesD2,

        totalAttendees,
        googleSheet,
        googleSheetD2,
        landingPageId,
        landingPageId2,
        googleSheetId,
        googleSheetId2,
        sheetErrorD2,
        sheetError,
        orangeDot: this.editedEvent?.orangeDot,
        pinkDot: this.editedEvent?.pinkDot,
        orangeDotD2: this.editedEvent?.orangeDotD2,
        pinkDotD2: this.editedEvent?.pinkDotD2,

        wfrCall1Date: wfrCall1Date,
        wfrCall1FunnelId: wfrCall1FunnelId,
        wfrCall1Done: wfrCall1Done,

        wfrCall2Date: wfrCall2Date,
        wfrCall2FunnelId: wfrCall2FunnelId,
        wfrCall2Done: wfrCall2Done,

        wfrCall1D2Date: wfrCall1D2Date,
        wfrCall1D2FunnelId: wfrCall1D2FunnelId,
        wfrCall1D2Done: wfrCall1D2Done,

        wfrCall2D2Date: wfrCall2D2Date,
        wfrCall2D2FunnelId: wfrCall2D2FunnelId,
        wfrCall2D2Done: wfrCall2D2Done,
        metaAds,
        otherAds,
        invoicedAds,
        qts,
        flatFee,
        totalInvoices,
        costLead,
        grossProfit,
        invoicedAdsD2,
        qtsD2,
        flatFeeD2,
        totalInvoicesD2,
        costLeadD2,
        grossProfitD2,
        use_catchlight,
        use_catchlight_2,
        timeZoneInfo,
        utC_Offset_Date: this.editForm.value.utC_Offset_Date,
        utC_Offset_Date2: this.editForm.value.utC_Offset_Date2,
        promptInvestibleAssets: this.editForm.value.promptInvestibleAssets,
      };
    } else {
      submitEventSchedule = {
        waitComplianceApproval: !!this.editForm.value?.waitComplianceApproval,

        newTopic: this.editForm.value?.newTopic?.toString(),
        topicNotes,
        eventType: this.editForm.value?.eventType ?? 'workshopsSeminarType',
        prebuildTime2Id,
        prebuildTimeId,
        id,
        rescheduledFromId,
        rescheduledFromIndex,
        registrants,
        registrantsD2,
        finalRegistrants,
        tenantId,
        clientId,
        topic,
        prebuildTopicId,
        doubleEvent,
        doubleSession,
        asap,
        atCost,
        date,
        date2,
        status,
        time,
        time2,
        startTime2,
        startTime,
        landingPageUrl,
        wfrDinnerOrLunch,
        twoPartSetup,
        eventScheduleLocationId,
        roomId,
        roomD2Id,
        parking,
        desiredBU,
        notes,
        venueSpecificNotes,
        wfrReminder,
        emailBlast1st,
        emailBlast2nd,
        emailBlastScheduled,
        fewSpots,
        fewSpotsScheduled,
        vmCode,
        fiveDayWfrCallDate,
        fiveDayWfrCall,
        fiveDayWfrCallDone,
        funnelId,
        funnelDone,
        funnelQAbyId,
        funnelQADone,
        adSpent,
        payStats,
        reminder,
        reminderReady,

        adSpentNotes,
        funnelAdsId,
        funnelAdsDone,
        funnelAdsQAbyId,
        funnelAdsQADone,
        attendees,
        attendeesD2,
        totalAttendees,
        googleSheet,
        sheetError,
        orangeDot: this.editedEvent?.orangeDot,
        pinkDot: this.editedEvent?.pinkDot,

        wfrCall1Date: wfrCall1Date,
        wfrCall1FunnelId: wfrCall1FunnelId,
        wfrCall1Done: wfrCall1Done,

        wfrCall2Date: wfrCall2Date,
        wfrCall2FunnelId: wfrCall2FunnelId,
        wfrCall2Done: wfrCall2Done,
        metaAds,
        otherAds,
        invoicedAds,
        qts,
        flatFee,
        totalInvoices,
        costLead,
        grossProfit,
        invoicedAdsD2,
        qtsD2,
        flatFeeD2,
        totalInvoicesD2,
        costLeadD2,
        grossProfitD2,
        landingPageId,
        googleSheetId,
        use_catchlight,
        use_catchlight_2,
        timeZoneInfo,
        utC_Offset_Date: this.editForm.value.utC_Offset_Date,
        promptInvestibleAssets: this.editForm.value.promptInvestibleAssets,
      };
      if (doubleSession) {
        Object.assign(submitEventSchedule, {
          atCostD2,
          finalRegistrantsD2,
          statusD2,
        });
      }
    }
    return submitEventSchedule;
  }
  isTimeZoneLoading = false;
  loadTimeZone() {
    if (!this.isTimeZoneLoading && this.SelectedLocation) {
      this.isTimeZoneLoading = true;
      this.service
        .loadTimeZone({
          lng: this.SelectedLocation.longitude,
          lat: this.SelectedLocation.latitude,
        })
        .subscribe({
          complete: () => {
            this.isTimeZoneLoading = false;
          },
          next: (data) => {
            this.editForm?.get('timeZoneInfo')?.setValue(data?.timeZoneInfo);
          },
          error: (data) => (this.isTimeZoneLoading = false),
        });
    }
  }
  prebuiltTopic(value: any) {
    const controlObject = this.editForm?.get('topic');
    controlObject?.setValue(null);
    controlObject?.markAsTouched();

    let label = this.TopicsOptions?.find((x) => x.value === value)?.label;
    if (label) {
      controlObject?.setValue(label);
      controlObject?.markAsTouched();
    }

    const prebuildTopicIdObject = this.editForm?.get('prebuildTopicId');
    if (prebuildTopicIdObject) {
      prebuildTopicIdObject?.setValue(value);
      prebuildTopicIdObject?.markAsTouched();
    }

    const newTopicObject = this.editForm?.get('newTopic');
    if (newTopicObject) {
      newTopicObject?.setValue(label);
      newTopicObject?.markAsTouched();
    }
  }
  prebuiltEventInfo(value: any, name: string) {
    let label = this.EventInfosOptions?.find((x) => x.value === value)?.label;
    if (label) {
      const controlObject = this.editForm?.get(name);
      controlObject?.setValue(label);
      controlObject?.markAsTouched();
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  changeRoom(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();

    let parking_control_string = '';
    let desiredBU_control_string = '';
    if (control == 'roomId') {
      parking_control_string = 'parking';
      desiredBU_control_string = 'desiredBU';
    } else {
      parking_control_string = 'parkingD2';
      desiredBU_control_string = 'desiredBUD2';
    }

    if (value) {
      let roomselected = this.allRooms?.find((x) => x.id == value);
      if (roomselected) {
        if (roomselected.parking)
          this.editForm
            .get(parking_control_string)
            .setValue(roomselected.parking);
        if (roomselected.desiredBUCapasity)
          this.editForm
            .get(desiredBU_control_string)
            .setValue(roomselected.desiredBUCapasity);
      }
    } else {
      this.editForm.get(parking_control_string).setValue(undefined);
      this.editForm.get(desiredBU_control_string).setValue(undefined);
    }
  }
  changeClient(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if (value) {
      const eventScheduleLocationController = this.editForm?.get(
        'eventScheduleLocationId'
      );
      eventScheduleLocationController?.setValue(null);
      const timeZoneContr = this.editForm?.get('timeZoneInfo');
      timeZoneContr?.setValue(null);
      timeZoneContr?.markAsTouched();
      const roomController = this.editForm?.get('roomId');
      roomController?.setValue(null);
      const roomD2Controller = this.editForm?.get('roomD2Id');
      roomD2Controller?.setValue(null);
      eventScheduleLocationController?.markAsTouched();
      this.SelectedClientId = value;
      this.clientService
        .loadOneCompany(value)
        .subscribe((x) => (this.SelectedClient = x));
      this.clientLocationsService
        .loadAllLocationsForClient(value)
        .subscribe((x) => {
          this.locations = x;
        });
      this.topicService.loadByClientId(value).subscribe((x) => {
        this.topics = x;
      });
    } else {
      this.SelectedClient = null;
      this.SelectedClientId = null;
      this.scheduleLocationsOptions = null;
      this.RoomsOptions = null;
      this.SelectedLocation = null;
      this.updateValue('venueSpecificNotes', '');

      this.SelectedLocationId = null;
      this.editForm.get('parking').setValue(undefined);
      this.editForm.get('roomId').setValue(undefined);
      this.editForm.get('roomD2Id').setValue(undefined);
      this.editForm.get('locationId').setValue(undefined);
    }
  }

  getDateStr(event: any) {
    var datestr = null;
    if (
      event &&
      event.value &&
      event.value.month() &&
      event.value.year() &&
      event.value.date()
    ) {
      datestr =
        event.value.month() +
        1 +
        '/' +
        event.value.date() +
        '/' +
        event.value.year();
    } else {
      datestr = null;
    }
    return datestr;
  }

  setFiveDay(event: any, dateIndx: number) {
    var datestr = this.getDateStr(event);

    switch (dateIndx) {
      case 1:
        this.editForm.controls.fiveDayWfrCallDate.setValue(event.value);
        this.editForm.controls.fiveDayWfrCall.setValue(datestr);
        break;
      case 2:
        this.editForm.controls.fiveDayWfrCallD2Date.setValue(event.value);
        this.editForm.controls.fiveDayWfrCallD2.setValue(datestr);
        break;
      default:
        break;
    }
  }
  setStartDate(event: any) {
    this.editForm.controls.date.setValue(event.value);
    var emailBlast1stdate = dayjs(event.value).add(11, 'hour').add(-14, 'day');
    var emailBlast2nddate = dayjs(event.value).add(11, 'hour').add(-7, 'day');
    var fewSpotsdate = dayjs(event.value).add(11, 'hour').add(-9, 'day');
    var gendate = dayjs(event.value).add(11, 'hour');
    this.editForm.controls.formatedDate.setValue(
      moment(gendate.toDate()).format('M/DD/YYYY')
    );
    //this.editForm.controls.formatedDate.setValue(
    //  gendate.month() + 1 + '/' + gendate.date() + '/' + gendate.year()
    //);

    this.editForm.controls.emailBlast1st.setValue(
      emailBlast1stdate.month() +
        1 +
        '/' +
        emailBlast1stdate.date() +
        '/' +
        emailBlast1stdate.year()
    );
    this.editForm.controls.emailBlast2nd.setValue(
      emailBlast2nddate.month() +
        1 +
        '/' +
        emailBlast2nddate.date() +
        '/' +
        emailBlast2nddate.year()
    );

    this.editForm.controls.fewSpots.setValue(
      fewSpotsdate.month() +
        1 +
        '/' +
        fewSpotsdate.date() +
        '/' +
        fewSpotsdate.year()
    );
  }

  setStartDate2(event: any) {
    this.editForm.controls.date2.setValue(event.value);
    var emailBlast1stdate = dayjs(event.value).add(11, 'hour').add(-14, 'day');
    var emailBlast2nddate = dayjs(event.value).add(11, 'hour').add(-7, 'day');
    var fewSpotsdate = dayjs(event.value).add(11, 'hour').add(-9, 'day');
    var gendate = dayjs(event.value).add(11, 'hour');
    this.editForm.controls.formatedDate2.setValue(
      moment(gendate.toDate()).format('M/DD/YYYY')
    );

    //this.editForm.controls.formatedDate2.setValue(
    //  gendate.month() + 1 + '/' + gendate.date() + '/' + gendate.year()
    //);

    this.editForm.controls.emailBlast1stD2.setValue(
      emailBlast1stdate.month() +
        1 +
        '/' +
        emailBlast1stdate.date() +
        '/' +
        emailBlast1stdate.year()
    );
    this.editForm.controls.emailBlast2ndD2.setValue(
      emailBlast2nddate.month() +
        1 +
        '/' +
        emailBlast2nddate.date() +
        '/' +
        emailBlast2nddate.year()
    );
    this.editForm.controls.fewSpotsD2.setValue(
      fewSpotsdate.month() +
        1 +
        '/' +
        fewSpotsdate.date() +
        '/' +
        fewSpotsdate.year()
    );
  }

  changeLocation(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if (value) {
      const roomContr = this.editForm?.get('roomId');
      roomContr?.setValue(null);
      roomContr?.markAsTouched();
      const room2Contr = this.editForm?.get('roomD2Id');
      room2Contr?.setValue(null);
      room2Contr?.markAsTouched();
      const timeZoneContr = this.editForm?.get('timeZoneInfo');
      timeZoneContr?.setValue(null);
      timeZoneContr?.markAsTouched();
      this.SelectedLocationId = value;
      this.clientLocationsService.loadOneLocation(value).subscribe((x) => {
        this.SelectedLocation = x;
        this.updateValue('venueSpecificNotes', x.lastEventDescription);
      });

      this.roomService.loadAllRoomsForLocation(value).subscribe((x) => {
        this.rooms = x;
        this.allRooms = x;
      });
    } else {
      this.RoomsOptions = null;
      this.SelectedLocation = null;
      this.updateValue('venueSpecificNotes', '');

      this.SelectedLocationId = null;
      this.editForm.get('parking').setValue(undefined);
      this.editForm.get('roomId').setValue(undefined);
      this.editForm.get('roomD2Id').setValue(undefined);
    }
  }
  changeWFRor2part(check: string, value: boolean) {
    if (value)
      if (check == 'WFR') {
        this.editForm.get('twoPartSetup').setValue(false);
      } else if (check == 'twoPartSetup') {
        this.editForm.get('wfrDinnerOrLunch').setValue(false);
      }
  }

  locationBack() {
    this.location.back();
  }
  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) {
      modal.style.display = 'none';
      this.popUpForm?.clear();
    }
  }

  showModal(tab: string) {
    this.popUpTab = tab;
    var modal = document.getElementById('myModal') as HTMLModElement;
    modal.style.display = 'block';
  }

  reloadRooms() {
    var location = this.editForm.get('eventScheduleLocationId')?.value;
    if (location)
      this.roomService.loadAllRoomsForLocation(location).subscribe((x) => {
        this.rooms = x;
        this.allRooms = x;

        this.popUpForm!.clear();
        this.popUpForm!.isLoading = false;
        this.hidemodal();
      });
  }
  reloadlocations() {
    var client = this.editForm.get('clientId')?.value;
    if (client)
      this.clientLocationsService
        .loadAllLocationsForClient(client)
        .subscribe((x) => {
          this.locations = x;
          this.popUpForm!.clear();
          this.popUpForm!.isLoading = false;
          this.popUpForm!.locations = x;
          this.hidemodal();
        });
  }
  // changeTopic() {
  //   let val = this.editForm?.get('newTopic');
  //   if (val) {
  //     const controlObject = this.editForm?.get('topic');
  //     const prebuildTopicIdObject = this.editForm?.get('prebuildTopicId');

  //     controlObject?.setValue(val?.value);
  //     controlObject?.markAsTouched();

  //     prebuildTopicIdObject?.setValue(null);
  //     prebuildTopicIdObject?.markAsTouched();
  //   }
  // }
  saveWithoutApprove(duplicate: boolean = false) {
    console.log(this.editedEvent?.hasReschedules);
    if (this.editedEvent?.hasReschedules) {
      this.ui.openSnackBar('Changes will not be applied\nEvent is rescheduled');
      return;
    }
    this.manageLandingPageValidators();

    const eventScheduleLocationId =
      this.editForm.value?.eventScheduleLocationId?.toString();

    if (eventScheduleLocationId == this.repo.Default_LocationId) {
      this.ui.openSnackBar(this.repo.Default_Location_Error);
      return;
    }
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      this.ui.openSnackBar('Please fill all required fields');
      return;
    }

    let submitEventSchedule = this.getFormVals();
    if (submitEventSchedule) {
      submitEventSchedule.isDraft = this.editedEvent?.isDraft;
      submitEventSchedule.isInReview = this.editedEvent?.isInReview;

      if (this.editedEvent?.id) {
        let activityLog = {
          eventScheduleId: this.editedEvent?.id,
          type: 'Edited',
          description: 'User Edited Event',
        } as Partial<IActivityLog>;
        this.service.postActivityLog(activityLog).subscribe();
      }
      if (!duplicate) {
        this.EventSubmit.emit(submitEventSchedule);
        this.isDuplicated = false;
      } else {
        this.isDuplicated = true;
        this.service
          .updateEventSchedule(submitEventSchedule)
          .subscribe({ complete: () => this.duplicate() });
      }
    }
  }
  openUrlInNewTab(url?: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  updateVenueSpecifiNotes(control: string, value: any) {
    if (this.SelectedLocation?.lastEventDescription)
      this.SelectedLocation.lastEventDescription = value;
    this.updateValue(control, value);
  }
  disabledReschedule = false;
  rescheduleDate(date: number) {
    if (this.id && !this.disabledReschedule) {
      this.disabledReschedule = true;

      this.service.rescheduleDate(this.id, date).subscribe(
        (x) => {
          this.disabledReschedule = false;
          if (x.id) {
            window.open(
              new URL(
                `/admin/eventSchedule/eventSchedule/${x.id}`,
                new URL(window.location.href).origin
              ).href
            );
          }
        },
        (err) => {
          if (err.error) {
            this.disabledReschedule = false;
            this.dialog.open(ErrorAlertModalComponent, {
              data: err.error,
            });
          }
        }
      );
    }
  }
  deleteLoading = false;

  deleteEvent() {
    // deleteType: DeleteType = DeleteType.Both
    if (this.id && this.id != 'new' && this.editedEvent?.isInReview) {
      // const requestMap = new Map([
      //   [DeleteType.Both, this.service.delete(this.id!)],
      //   [DeleteType.First, this.service.setSecondDateAsPrimary(this.id!)],
      //   [DeleteType.Second, this.service.deleteSecond(this.id!)],
      // ]);

      let _request = this.service.delete(this.id!);

      if (_request) {
        this.deleteLoading = true;
        _request.subscribe({
          complete: () => {
            this.locationBack();
            this.deleteLoading = false;
            this.deleteConfirmation = false;
          },
          error: (err) => {
            this.deleteLoading = false;
            this.deleteConfirmation = false;

            console.log(err);
          },
        });
      }
    }
  }

  doubleCheck(type: 'Event' | 'Session') {
    const doubleEvent = this.editForm.get('doubleEvent');
    const doubleSession = this.editForm.get('doubleSession');

    if (type === 'Event') doubleSession.setValue(false);
    else if (type === 'Session') doubleEvent.setValue(false);

    this.isDoubleEvent = doubleEvent.value;
    this.isDoubleSession = doubleSession.value;
  }
}
export enum DeleteType {
  Both,
  First,
  Second,
}
