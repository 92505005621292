import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { StatisticElement } from 'src/app/state/event.repository';

@Component({
  selector: 'app-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.scss'],
})
export class StatisticsPageComponent implements OnInit {
  constructor(public service: EventScheduleService) {
    service.getPosibleYears().subscribe((x) => {
      if (x && x.length) {
        service.getStatsByYear(x[0]).subscribe((y) => (this.monStats = y));
        this.yearsOptions = x.map(
          (x) =>
            ({ value: x.toString(), label: x.toString() } as DatalistOption)
        );
      }
    });
  }
  monStats: StatisticElement[] = [];
  ngOnInit(): void {
    this.selectedYear = this.yearsOptions[0].value;
  }

  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  selectedYear: number | null = null;

  yearsOptions: DatalistOption[] = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];
  setYear(event: any) {
    this.service.getStatsByYear(+event).subscribe((y) => (this.monStats = y));
  }
}
