import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IEvent, EventsRepository } from './event.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { User } from './users.repository';
import { BaseService } from './abstract/base.service';

const API = '/api/events';

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService<IEvent> {
  constructor(http: HttpClient, repo: EventsRepository) {
    super(API, http, repo);
  }

  load() {
    return this.http.get<PaginationData & { data: IEvent[] }>(API);
  }

  loadAll() {
    return this.http.get<IEvent[]>(API + '/all');
  }

  loadOne(id: string) {
    return this.http.get<IEvent>(`${API}/${id}`);
  }

  add(post: Partial<IEvent>) {
    return this.http.post<IEvent>(API, post);
  }

  update(id: string, post: Partial<IEvent>) {
    return this.http.put<IEvent>(`${API}/${id}`, post);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(API + '/users');
  }

  GetEventsWithoutPersonal(): Observable<IEvent[]> {
    return this.http.get<IEvent[]>(API + '/personal');
  }

  getEventSharing(): Observable<IEvent[]> {
    return this.http.get<IEvent[]>(API + '/event-sharing');
  }

  getEventList(): Observable<IEvent[]> {
    return this.http.get<IEvent[]>(API);
  }

  getByDate(date: string) {
    return this.http.get<any[]>(`${API}/date/${date}`);
  }

  getCurrentUser(): Observable<any[]> {
    return this.http.get<any[]>(`${API}/currentuser`);
  }
  deleteFromAppList(id: any): Observable<any> {
    return this.http.delete<any>(`${API}/deleteFromAppList/${id}`);
  }
  subscribe(id: string) {
    return this.http.put<any>(`${API}/subscribe/${id}`, id);
  }

  loadAllEventsDate(date: string) {
    return this.http.get<any>(`${API}/loadAllEventsDate/${date}`);
  }
}
