import { Component, Input } from '@angular/core';
import { IEventSchedule } from '../../../../state/event-schedule.repository';
@Component({
  selector: 'app-event-pop-up',
  templateUrl: './event-pop-up.component.html',
  styleUrls: ['./event-pop-up.component.scss'],
})
export class EventPopUpComponent {
  @Input() eventScheduleOnPopUp: IEventSchedule | null = null;
}
