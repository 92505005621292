<div class="row h-100">
  <div
    class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center d-grid gap-2 align-self-center"
  >
    <figure class="mw-100 text-center mb-3">
      <img src="assets/img/error404.webp" alt="" class="mw-100" />
    </figure>
    <h2 class="mb-0 fw-bold" i18n="Oops message">Oops!...</h2>
    <h4 class="mb-4" i18n="Page not found message">Page not found</h4>
    <p class="text-muted mb-4" i18n="Try again or go back to home message">
      The page you are looking for is not found or removed. Please try again
      sometime or go back to home page.
    </p>

    <a
      routerLink="/"
      target="_self"
      class="btn btn-primary btn-block"
      i18n="@@backHome"
      >Back to Home</a
    >
  </div>
</div>
