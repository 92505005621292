import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { IFunnel, ITopic } from './event-schedule.repository';

export const TopicsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];

@Injectable({ providedIn: 'root' })
export class TopicsRepository extends BaseRepository<ITopic> {
  constructor() {
    super('TopicsSortOptions', TopicsSortOptions);
  }
}
