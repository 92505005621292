<span>
  <div
    class="d-flex justify-content-between p-2 h60 align-items-center bTopGray last-event"
    [class.col-orange]="event.status == 'Rescheduled'"
    [class.text-danger]="event.status == 'Cancelled'"
  >
    <div
      class="d-flex align-items-center pointer payStatsBinding"
      style="width: 130px"
      (click)="mouseEnterPayStats($event, event.id, mainParentId)"
    >
      <div class="text-break oneLineText">
        <span [class.payStatsOrange]="event.atCost && event.atCost != 'No'">
          {{ event.payStats ?? "" }}
        </span>
        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 100px">
      <div
        class="text-break oneLineText pointer ccBinding"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.firstReschedules?.length"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.client_blue]="event.twoPartSetup"
            >{{ event.client.title }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      style="width: 300px"
      [class.double-session-first]="event.doubleSession"
    >
      <div class="text-break oneLineText">
        {{ event.formatedDateAdmin }}
        <span>{{
          event.atCost && event.atCost != "No" ? "(" + event.atCost + ")" : ""
        }}</span>
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 100px">
      <div class="text-break oneLineText">
        {{ event.ae ? "Yes" : "No" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        <span
          [class.enableUrl]="event.landingPageUrl"
          (click)="goToLink(event.landingPageUrl)"
        >
          {{ event.eventScheduleLocation?.title ?? "" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div
        class="text-break oneLineText"
        [class.pointer]="event.prebuildTopicId"
        (click)="searchTopicById.emit(event.prebuildTopicId)"
      >
        {{ event.topic }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center pointer" style="min-width: 100px">
      <div
        class="d-flex align-items-center justify-content-end flex-column"
        title="Registrants"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div
            class="d-flex p-0 m-0 regBinding"
            title="Registrants"
            i18n-title="Registrants button"
            (click)="goToLink(event.googleSheet)"
          >
            <span class="me-1 p-0 m-0">
              <sup class="p-0 m-0"> {{ +event.attendees }} </sup>/<sub
                class="me-1 p-0 m-0"
              >
                {{
                  +(event.finalRegistrants
                    ? event.finalRegistrants
                    : event.registrants)
                }}
              </sub>
              <span>
                ({{
                  this.setMax100(
                    event.attendees,
                    +(event.finalRegistrants
                      ? event.finalRegistrants
                      : event.registrants)
                  )
                }}%)
              </span>
            </span>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.adSpent ?? event.otherAds + event.metaAds }}

        <br />
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 50px"
    >
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 1,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            title="Edit"
            i18n-title="Edit button"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
          >
            <img class="editBinding" src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            (click)="deleteConfirmation.emit({ entity: event, date: 1 })"
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            title="Delete"
            i18n-title="Delete button"
          >
            <img class="deleteBinding" src="assets/img/delete18x20.svg" />
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <span *ngIf="event.firstReschedules?.length">
    <div *ngFor="let firstEv of event.firstReschedules">
      <app-es-pay-stats-double-row
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        [mainParentId]="mainParentId"
        [paddStart]="paddStart + 20"
        [event]="firstEv"
        (enterPayStats)="enterPayStats.emit($event)"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-pay-stats-double-row>
    </div>
  </span>

  <div
    *ngIf="event.doubleEvent || event.doubleSession"
    class="d-flex justify-content-between p-2 h60 align-items-center last-event"
    [class.col-orange]="event.statusD2 == 'Rescheduled'"
    [class.text-danger]="event.statusD2 == 'Cancelled'"
  >
    <div
      class="d-flex align-items-center pointer payStatsBinding"
      style="width: 130px"
      (click)="mouseEnterPayStats($event, event.id, mainParentId)"
    >
      <div class="text-break oneLineText">
        <span [class.color-orange]="event.atCostD2 && event.atCostD2 != 'No'">
          {{ event.payStats }}
        </span>
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 100px">
      <div
        class="text-break oneLineText pointer ccBinding"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.secondReschedules?.length"
        (click)="goToLink(event.clientUrl)"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.client_orange]="event.twoPartSetup"
            >{{ event.client.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.formatedDate2Admin }}
        <span>{{
          event.atCost && event.atCostD2 != "No"
            ? "(" + event.atCostD2 + ")"
            : ""
        }}</span>

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 100px">
      <div class="text-break oneLineText">
        {{ event.ae ? "Yes" : "No" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        <span
          [class.enableUrl]="event.landingPageUrl2"
          (click)="goToLink(event.landingPageUrl2)"
        >
          {{ event.eventScheduleLocation?.title ?? "" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        {{ event.topic }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center pointer" style="min-width: 100px">
      <div
        class="d-flex align-items-center justify-content-end flex-column py-2"
        title="Registrants"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div
            class="d-flex p-0 m-0 regBinding"
            title="Registrants"
            i18n-title="Registrants button"
            (click)="goToLink(event.googleSheetD2)"
          >
            <span class="me-1 p-0 m-0">
              <sup class="p-0 m-0"> {{ +event.attendeesD2 }} </sup>/<sub
                class="me-1 p-0 m-0"
              >
                {{
                  +(event.finalRegistrantsD2
                    ? event.finalRegistrantsD2
                    : event.registrantsD2)
                }}
              </sub>
              <span>
                ({{
                  this.setMax100(
                    event.attendeesD2,
                    +(event.finalRegistrantsD2
                      ? event.finalRegistrantsD2
                      : event.registrantsD2)
                  )
                }}%)
              </span>
            </span>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="d-flex align-items-center" style="width: 300px"></div>

    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 50px; right: 0"
    >
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 2,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>

        <!-- <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div> -->
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            title="Edit"
            i18n-title="Edit button"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
          >
            <img class="editBinding" src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            type="button"
            (click)="deleteConfirmation.emit({ entity: event, date: 2 })"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            title="Delete"
            i18n-title="Delete button"
          >
            <img
              type="button"
              class="deleteBinding"
              src="assets/img/delete18x20.svg"
            />
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <span *ngIf="event.secondReschedules?.length">
    <div *ngFor="let secondEv of event.secondReschedules">
      <app-es-pay-stats-double-row
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        [mainParentId]="mainParentId"
        [paddStart]="paddStart + 20"
        [event]="secondEv"
        (enterPayStats)="enterPayStats.emit($event)"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-pay-stats-double-row>
    </div>
  </span>
</span>
