import { DOCUMENT, NgForOf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  IMWEvent,
  IMWSubevents,
  MWEventsRepository,
} from 'src/app/state/mwevent.repository';
import { MWEventService } from 'src/app/state/mwevent.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { User } from 'src/app/state/users.repository';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  Validators,
} from '@angular/forms';
import { number } from 'echarts';
import { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { UsersService } from '../../../state/users.service';

declare var ClassicEditor: any;
@Component({
  selector: 'app-mwevent-form',
  templateUrl: './mwevent-form.component.html',
  styleUrls: ['./mwevent-form.component.scss'],
})
export class MweventFormComponent implements OnInit {
  index: number = -1;
  Form: UntypedFormBuilder | any;
  file: File | null = null;
  MwDescription: any;
  CKdescription: any[] = [];
  CKtext: any[] = [];
  newEditor: boolean = false;
  viewerOptions: DatalistOption[] | null = null;
  userOptions: DatalistOption[] | null = null;
  respOptions: DatalistOption[] | null = null;

  @Input() set users(value: User[] | null) {
    if (!value) {
      this.userOptions = null;
      this.viewerOptions = null;
      this.respOptions = null;
    } else {
      this.viewerOptions = value.map((x) => ({
        value: x.id,
        imagePath: x.imagePath,
        label: x.name + ' ' + x.surname,
      }));
      this.respOptions = value.map((x) => ({
        value: x.id,
        imagePath: x.imagePath,
        label: x.name + ' ' + x.surname,
      }));
      this.userOptions = value.map((x) => ({
        value: x.id,
        imagePath: x.imagePath,
        label: x.name + ' ' + x.surname,
      }));
    }
  }

  @Input() editedMWEvent?: IMWEvent | null;
  @Output() MWEventSubmit = new EventEmitter<Partial<IMWEvent>>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    public authRepo: AuthRepository,
    private repo: MWEventsRepository,
    private service: MWEventService,
    public userservice: UsersService
  ) {}

  ngOnInit() {
    this.userservice.loadAll().subscribe((x) => {
      this.users = x;
    });
    this.Form = this.formBuilder.group({
      title: [this.editedMWEvent?.title, Validators.required],
      description: this.editedMWEvent?.description,
      file: this.editedMWEvent?.file,
      subType: null,
      viewersIds: [this.editedMWEvent?.viewersIds],
      respIds: [this.editedMWEvent?.respIds],
      isActive: this.editedMWEvent?.isActive,
      usersIds: [this.editedMWEvent?.usersIds],
      subevents: this.formBuilder.array([]),
    });
    this.setSubevents();
  }

  ngAfterViewInit() {
    if (typeof ClassicEditor !== 'function') {
      var script = this.document.createElement('script');
      script.id = 'CkId';
      script.type = 'text/javascript';
      script.src = '/assets/js/ckeditor.js';
      this.document.body.appendChild(script);
      script.onload = () => {
        this.initEditor();
      };
    } else {
      this.initEditor();
    }
  }

  private initEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      if (this.editedMWEvent?.description) {
        newEditor.setData(this.editedMWEvent?.description);
      }
      this.MwDescription = newEditor;
    });

    this.getControls().forEach((x, index) => {
      var str = '#ck' + index.toString();
      var txt = '#ckt' + index.toString();

      ClassicEditor.create(document.querySelector(str), {
        toolbar: [
          'heading',
          'bold',
          'italic',
          '|',
          'undo',
          'redo',
          '|',
          'numberedList',
          'bulletedList',
        ],
      }).then((newEditor: any) => {
        if (x.value.description) {
          newEditor.setData(x.value.description);
        }
        this.CKdescription.push(newEditor);
      });

      ClassicEditor.create(document.querySelector(txt), {
        toolbar: [
          'heading',
          'bold',
          'italic',
          '|',
          'undo',
          'redo',
          '|',
          'numberedList',
          'bulletedList',
        ],
      }).then((newEditor: any) => {
        if (x.value.text) {
          newEditor.setData(x.value.text);
        }
        this.CKtext.push(newEditor);
      });
    });
  }

  ngAfterViewChecked() {
    if (this.newEditor) {
      this.initNewEditor();
      this.newEditor = false;
    }
  }

  private initNewEditor() {
    var n = this.getControls().length - 1;
    var str = '#ck' + n.toString();
    var txt = '#ckt' + n.toString();
    ClassicEditor.create(document.querySelector(str), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      this.CKdescription.push(newEditor);
    });
    ClassicEditor.create(document.querySelector(txt), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      this.CKtext.push(newEditor);
    });
  }

  getControls() {
    if (this.Form) {
      return (this.Form.get('subevents') as UntypedFormArray).controls;
    }
    return [];
  }

  getQuestControls(i: number) {
    if (this.Form) {
      return (
        this.Form.get('subevents').controls[i].get(
          'questions'
        ) as UntypedFormArray
      ).controls;
    }
    return [];
  }

  deleteSubevent(index: number) {
    let control = <UntypedFormArray>this.Form?.controls.subevents;
    control.removeAt(index);
    this.CKdescription.splice(index, 1);
    this.CKtext.splice(index, 1);
    this.index -= 1;
  }

  deleteQuestion(k: number) {
    let control = <UntypedFormArray>(
      this.Form?.controls.subevents.controls[this.index].controls.questions
    );
    control.removeAt(k);
  }

  setSubevents() {
    let control = <UntypedFormArray>this.Form?.controls.subevents;
    this.editedMWEvent?.subevents.forEach((x, index) => {
      control.push(
        this.formBuilder.group({
          id: [x.id],
          title: [x.title, Validators.required],
          description: x.description,
          subeventType: x.subeventType,
          text: x.text,
          url: x.url,
          file: x.file,
          order: x.order,
          headline: x.headline,
          questions: this.formBuilder.array([]),
        })
      );
      this.setQuestions(index);
    });
  }

  setQuestions(i: number) {
    let control = <UntypedFormArray>(
      this.Form?.controls.subevents.controls[i].controls.questions
    );
    this.editedMWEvent?.subevents[i].questions.forEach((x) => {
      control.push(
        this.formBuilder.group({
          id: [x.id],
          question: [x.question, Validators.required],
        })
      );
    });
  }

  uploadFile(event: Event, i: number) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      if (i != -1) {
        this.Form.value.subevents[i].file = fileList[0];
      } else {
        this.file = fileList[0];
      }
    }
  }

  addNewSubevents() {
    let control = <UntypedFormArray>this.Form?.controls.subevents;
    control.push(
      this.formBuilder.group({
        title: ['', Validators.required],
        description: '',
        headline: null,
        subeventType: this.Form.value?.subType,
        file: null,
        text: null,
        url: null,
        order: this.getOrder(),
        questions: this.formBuilder.array([]),
      })
    );
    this.newEditor = true;
    this.index = control.length - 1;
  }

  getOrder() {
    var order = 0;
    this.getControls().forEach((x) => {
      if (x.value.order > order) {
        order = x.value.order;
      }
    });
    return order + 1;
  }

  addNewSubeventQuest() {
    let control = <UntypedFormArray>(
      this.Form?.controls.subevents.controls[this.index].controls.questions
    );
    control.push(
      this.formBuilder.group({
        question: ['', Validators.required],
      })
    );
  }

  onSubmit() {
    this.getControls().forEach((x: any, index: number) => {
      var descr = this.CKdescription[index].getData();
      var txt = this.CKtext[index].getData();
      x.value.description = descr;
      x.value.text = txt;
    });
    if (this.Form && !this.Form.valid) {
      this.Form.markAllAsTouched();
      return;
    }
    const title = this.Form.value?.title.toString();
    const description = this.MwDescription.getData();
    const subevents = this.Form.value?.subevents;
    const viewersIds = this.Form.value?.viewersIds;
    const respIds = this.Form.value?.respIds;
    const usersIds = this.Form.value?.usersIds;
    const isActive =
      this.Form.value?.isActive === null ? true : this.Form.value?.isActive;

    this.MWEventSubmit.emit({
      title,
      description,
      viewersIds,
      respIds,
      usersIds,
      isActive,
      file: this.file,
      subevents,
    });
  }

  navigateByIcons(icoindex?: number) {
    if (icoindex != null) {
      this.index = icoindex;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  moveSubevent(i: number, k: number) {
    var ar = this.getControls();
    if (k === 1) {
      var order = ar[i].value.order;
      ar[i].value.order = ar[i - 1].value.order;
      ar[i - 1].value.order = order;

      var moving = ar[i];
      ar[i] = ar[i - 1];
      ar[i - 1] = moving;
      this.moveEditors(i, k);
      this.index = i - 1;
    }
    if (k === -1) {
      var order = ar[i].value.order;
      ar[i].value.order = ar[i + 1].value.order;
      ar[i + 1].value.order = order;

      var moving = ar[i];
      ar[i] = ar[i + 1];
      ar[i + 1] = moving;
      this.moveEditors(i, k);
      this.index = i + 1;
    }
  }

  moveEditors(i: number, k: number) {
    if (k === 1) {
      var order = this.CKdescription[i];
      this.CKdescription[i] = this.CKdescription[i - 1];
      this.CKdescription[i - 1] = order;

      var ordert = this.CKtext[i];
      this.CKtext[i] = this.CKtext[i - 1];
      this.CKtext[i - 1] = ordert;
    }
    if (k === -1) {
      var order = this.CKdescription[i];
      this.CKdescription[i] = this.CKdescription[i + 1];
      this.CKdescription[i + 1] = order;

      var ordert = this.CKtext[i];
      this.CKtext[i] = this.CKtext[i + 1];
      this.CKtext[i + 1] = ordert;
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.Form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
