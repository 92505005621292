import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  CompaniesRepository,
  CompanyStatusesOptions,
  ICompanyMarker,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { EventScheduleRepository } from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-companies-status-pop-up',
  templateUrl: './companies-status-pop-up.component.html',
  styleUrls: ['./companies-status-pop-up.component.scss'],
})
export class CompaniesStatusPopUpComponent {
  StatusesOptions: DatalistOption[] = CompanyStatusesOptions;
  @Input() companyOnPopUp: ICompanyMarker | null = null;
  @Input() statusIndex: number | null = null;
  @Input() isOrange: boolean = false;

  @Input() top: number = 0;
  @Input() left: number = 0;
  @Output() submit = new EventEmitter<ICompanyMarker>();
  constructor(
    public repo: CompaniesRepository,
    public service: CompaniesService
  ) {}
  updateValue($event?: DatalistOption) {
    if (this.companyOnPopUp && $event) {
      this.service
        .changeCompanyStatus($event.value, this.companyOnPopUp.id)
        .subscribe((x) => this.submit.emit(x));
    } else if (!$event && this.companyOnPopUp) {
      this.service
        .changeCompanyStatus('clear', this.companyOnPopUp.id)
        .subscribe((x) => this.submit.emit(x));
    }
  }
}
