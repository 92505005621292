import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  tenantSortOptions,
  TenantsRepository,
} from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';

@Component({
  selector: 'app-tenants-page',
  templateUrl: './tenants-page.component.html',
  styleUrls: ['./tenants-page.component.scss'],
})
export class TenantsPageComponent implements OnInit {
  sortOptions = tenantSortOptions;
  innerWidth = 0;
  constructor(
    public repo: TenantsRepository,
    public service: TenantsService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {
    this.service.load().subscribe();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
