import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, tap } from 'rxjs';
import { sortBy, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { IDocumentType } from './documenttype.repository';
import { IFolder } from './folders.repository';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';

export interface ISubscriber {
  id: string;
  tenantId: string;
  lastName: string;
  firstName: string;
  email: string;
  timeStamp: string;
  spouceGuest: boolean;
  show: string;
  confirmed: string;
  phoneNumber: string;
  eventScheduleId: string;
  callStatus: string;
  callStatus2: string;
  callStatus3: string;
  callStatus4: string;
}
export const ConfirmedOptions: DatalistOption[] = [
  { label: 'LVM', value: 'LVM' },
  { label: 'MBF', value: 'MBF' },
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Hanged Up', value: 'Hanged Up' },
  { label: "Can't Connect", value: "Can't Connect" },
];

export const SubscriberSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'firstName' },
];

@Injectable({ providedIn: 'root' })
export class SubscribersRepository extends BaseRepository<ISubscriber> {
  constructor() {
    super('SubscriberOptions', SubscriberSortOptions);
  }
}
