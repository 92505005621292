<div *ngFor="let month of months; let curentMonthIndex = index" class="p-0 m-0">
  <div class="d-flex align-items-center">
    <button
      class="ms-0 btn mt-2 px-1"
      style="padding-top: 3px; padding-bottom: 3px"
      (mouseleave)="mouseLeave()"
    >
      <div
        class="d-flex align-items-center"
        style="width: 20px; height: 20px"
        (click)="showSortStatus(curentMonthIndex, $event, 'changeMonStatus-')"
      >
        <app-event-status
          [isMonthStatus]="true"
          [status]="month.sortStatus ?? ''"
          [statusNum]="month.sortStatusNum ?? 0"
          [tabSelected]="tabSelected"
        >
        </app-event-status>
      </div>
      <app-change-status-modal
        [isMonthStatus]="true"
        [top]="statusTop"
        [left]="statusLeft"
        [statusIndex]="1"
        (submit)="sortmonthStatus(curentMonthIndex, $event); mouseLeave()"
        [hidden]="
          clickedMonthIndex != curentMonthIndex ||
          clickedDateIndex != 'changeMonStatus-'
        "
      ></app-change-status-modal>
    </button>

    <button
      class="ms-0 btn btn-wfrBlue w-100 mt-2"
      style="padding-top: 3px; padding-bottom: 3px"
      (click)="expand.emit(month.id)"
      id="ads-view-button-{{ curentMonthIndex }}"
    >
      <span class="d-flex justify-content-start">
        <span>
          {{ month.name }} | Events: {{ month.eventsCount }} | AE:
          {{ month.aeCount }} | Rescheduled: {{ month.rescheduledCount }} |
          Cancelled: {{ month.cancelledCount }} | Net-Events:
          {{ month.netEventsCount }}
        </span>
      </span>
    </button>

    <div
      class="d-flex align-items-center ms-2"
      style="width: 25px"
      title="Export to CSV"
    >
      <input
        type="checkbox"
        class="form-check-input"
        [checked]="month.exportCheck"
        (change)="exportCheck.emit(month.id)"
      />
    </div>
  </div>
  <div *ngIf="month.expanded">
    <div class="box my-2">
      <div class="es-box-name-header posSticky">
        <div
          class="h-100 d-flex justify-content-between align-items-center p-2"
        >
          <div style="width: 150px">
            <span class="txt mt-1">Status</span>
          </div>
          <div style="width: 70px">
            <i class="bi bi-exclamation-triangle text-danger"></i>
          </div>
          <div style="width: 100px">
            <span class="txt mt-1">CC</span>
          </div>
          <div style="width: 500px">
            <span class="txt mt-1">Client</span>
          </div>
          <div style="width: 400px">
            <span class="txt mt-1">Date</span>
          </div>
          <div style="width: 500px">
            <span class="txt mt-1">Time</span>
          </div>
          <div style="width: 500px">
            <span class="txt mt-1">Location</span>
          </div>
          <div style="width: 500px">
            <span class="txt mt-1">Target</span>
          </div>
          <div style="width: 300px">
            <span class="txt mt-1">Avg. Attendance</span>
          </div>
          <div style="min-width: 100px">
            <span class="txt mt-1">
              <img src="assets/img/users19x19.svg" />
            </span>
          </div>
          <div style="width: 300px">
            <span class="txt mt-1">Ads Notes</span>
          </div>
          <div style="min-width: 50px">
            <span class="txt mt-1"></span>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          month.showEventSchedules &&
          !month.showEventSchedules.length &&
          month.sortStatus
        "
      >
        <span>There are no events with such status</span>
      </div>
      <ng-container *ngVar="month.showEventSchedules as events" class="">
        <div infiniteScroll (scrolled)="onScrollDown(month)">
          <div
            *ngFor="let event of events; let eventIndx = index"
            class="p-0 m-0 brdNotFirstChild evenBg"
          >
            <app-es-ads-view-double-row
              (actionPop)="actionPop.emit($event)"
              (enterChangeStatus)="enterChangeStatus.emit($event)"
              [event]="event"
              [mainParentId]="event.id"
              (showModal)="showModal.emit($event)"
              (deleteConfirmation)="deleteConfirmation.emit($event)"
            ></app-es-ads-view-double-row>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
