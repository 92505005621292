import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICompanyLocation } from '../../../../state/companies.repository';
import { IRoom } from '../../../../state/event-schedule.repository';
import { LocationsRepository } from '../../../../state/locations.repository';
import { LocationsService } from '../../../../state/locations.service';
import {
  RoomsRepository,
  RoomsSortOptions,
} from '../../../../state/rooms.repository';
import { RoomsService } from '../../../../state/rooms.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-rooms-create-page',
  templateUrl: './rooms-create-page.component.html',
  styleUrls: ['./rooms-create-page.component.scss'],
})
export class RoomsCreatePageComponent {
  constructor(
    public service: RoomsService,
    public repo: RoomsRepository,

    public locationsService: LocationsService,
    public locationsRepo: LocationsRepository,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location
  ) {}

  companyLocation: ICompanyLocation | null = null;
  room: IRoom | null = null;
  id?: string;
  type?: string;
  submitErrors: string[] | null = null;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type')!;
        this.id = value.get('id')!;
        if (this.id && this.type != 'new') {
          this.service.loadOneRoom(`${value.get('id')}`).subscribe((y) => {
            this.room = y;
          });
        }
      },
    });
  }

  createRoom(room: Partial<IRoom>) {
    this.submitErrors = null;
    let updateResult$: Observable<IRoom> | undefined;
    if (this.type === 'new') {
      room.companyLocationId = this.id;

      updateResult$ = this.service.postRoom(room);
    } else if (this.id) {
      room.id = this.id;

      updateResult$ = this.service.updateRoom(room);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
