import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthRepository } from './state/auth.repository';
import { TenantFeatures } from './state/feature.repository';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authRepo: AuthRepository) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authRepo.isAuthenticated()) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['signin'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    let allowedRoles = route.data.authorize || [];
    let allowFeature = route.data.feature || null;
    let featureCheck = true;
    this.authRepo.features$.subscribe((x) => {
      if (allowFeature) {
        featureCheck = x.includes(allowFeature);
      }
    });

    if (!allowedRoles.length && featureCheck) {
      // authorized and a specific role is not required - allow to proceed
      return true;
    }
    if (!Array.isArray(allowedRoles)) {
      allowedRoles = [allowedRoles];
    }
    if (featureCheck) {
      for (let role of allowedRoles) {
        if (this.authRepo.isInRole(role)) {
          // authorized and has at least one role that allows to view the route
          return true;
        }
      }
    }

    this.router.navigate(['unauthorized']);
    return false;
  }
}
