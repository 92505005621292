import { Component, HostListener, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';

import {
  CompaniesRepository,
  ICompanyMarker,
  CompaniesSortOptions,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';

@Component({
  selector: 'app-admin-companies-page',
  templateUrl: './admin-companies-page.component.html',
  styleUrls: ['./admin-companies-page.component.scss'],
})
export class AdminCompaniesPageComponent implements OnInit {
  sortOptions = CompaniesSortOptions;
  innerWidth = 0;
  searchFilter: string = '';
  deleteError: string | null = null;
  symbolsCount: number = 30;
  searchForCC: boolean = false;
  searchForName: boolean = false;
  deleteConfirmation: ICompanyMarker | null = null;
  statusPopTop: number = 0;
  statusPopLeft: number = 0;
  clickedIndex: number | null = null;

  constructor(
    public repo: CompaniesRepository,
    public service: CompaniesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  mouseEnter(eve: number, event: any) {
    this.clickedIndex = eve;
    this.statusPopLeft = event.pageX;
    this.statusPopTop = event.pageY;
  }
  changeStatus(entity: ICompanyMarker) {
    this.repo.upsert(entity);
  }
  mouseLeave() {
    this.clickedIndex = null;
    this.statusPopLeft = 0;
    this.statusPopTop = 0;
  }
  changeCheckboxes(indx: number) {
    switch (indx) {
      case 1:
        this.searchForCC = false;
        break;
      case 2:
        this.searchForName = false;
        break;
      default:
    }
  }
  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
    //this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service
      .reloadPage(this.searchFilter, 25, this.searchForCC, this.searchForName)
      .subscribe();
  }

  handleDeleteClick(post: ICompanyMarker) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.clearError();
        this.refreshData();
      },
      error: (data) => (this.deleteError = data),
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service
      .searchReloadPage(
        this.searchFilter,
        25,
        this.searchForCC,
        this.searchForName
      )
      .subscribe();
  }

  clearError() {
    this.deleteError = null;
  }
}
