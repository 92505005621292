<div
  class="d-flex responsiveMedia"
  *ngIf="
    (authRepo.isUser$ | async) || (authRepo.isTenantAdmin$ | async);
    else elseBlock
  "
>
  <div
    class="p-2 w-100 page"
    style=""
    [style.background-image]="'url(' + 'assets/img/homepage.png' + ')'"
  >
    <div class="mt-2 row m-0 p-0">
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.News) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/posts"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="32"
                height="30"
                viewBox="0 0 32 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4129 11.751H16.4136M10.4129 17.0016H20.914M17.1636 27.5027C24.206 27.5027 29.915 21.7937 29.915 14.7514C29.915 7.70898 24.206 2 17.1636 2C10.1213 2 4.41229 7.70898 4.41229 14.7514C4.41229 16.1765 4.64609 17.547 5.07742 18.8267C5.23973 19.3083 5.32089 19.549 5.33553 19.734C5.34999 19.9167 5.33906 20.0448 5.29387 20.2224C5.2481 20.4022 5.14706 20.5892 4.94499 20.9632L2.49124 25.505C2.14124 26.1529 1.96624 26.4768 2.0054 26.7268C2.03952 26.9445 2.16767 27.1363 2.35583 27.251C2.57186 27.3828 2.93808 27.345 3.67052 27.2692L11.3529 26.4751C11.5855 26.4511 11.7018 26.439 11.8079 26.4431C11.9121 26.4471 11.9858 26.4569 12.0875 26.4803C12.1908 26.5042 12.3209 26.5542 12.5809 26.6544C14.0031 27.2023 15.5483 27.5027 17.1636 27.5027Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >News</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.Calendar) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/events"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="31"
                height="34"
                viewBox="0 0 31 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29 14H2M21.5 2V8M9.5 2V8M9.2 32H21.8C24.3202 32 25.5804 32 26.543 31.5095C27.3897 31.0781 28.0781 30.3897 28.5095 29.543C29 28.5804 29 27.3202 29 24.8V12.2C29 9.67976 29 8.41965 28.5095 7.45704C28.0781 6.61031 27.3897 5.9219 26.543 5.49047C25.5804 5 24.3202 5 21.8 5H9.2C6.67976 5 5.41965 5 4.45704 5.49047C3.61031 5.9219 2.9219 6.61031 2.49047 7.45704C2 8.41965 2 9.67976 2 12.2V24.8C2 27.3202 2 28.5804 2.49047 29.543C2.9219 30.3897 3.61031 31.0781 4.45704 31.5095C5.41965 32 6.67976 32 9.2 32Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >Calendar</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.TimeLogs) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/timelogs"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="30"
                height="35"
                viewBox="0 0 30 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 13.625V19.825L18.8235 22.15M15 6.65C7.8203 6.65 2 12.5486 2 19.825C2 27.1014 7.8203 33 15 33C22.1797 33 28 27.1014 28 19.825C28 12.5486 22.1797 6.65 15 6.65ZM15 6.65V2M11.9412 2H18.0588M27.7384 7.56766L25.4443 5.24266L26.5914 6.40516M2.26156 7.56766L4.55568 5.24266L3.40862 6.40516"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >Time Logs</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.Collaborations) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/mwevents"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="29"
                height="34"
                viewBox="0 0 29 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.1487 2L2.7889 18.0318C2.26569 18.6596 2.00408 18.9736 2.00009 19.2387C1.99661 19.4692 2.09932 19.6885 2.2786 19.8333C2.48484 20 2.89348 20 3.71077 20H14.6487L13.1487 32L26.5085 15.9682C27.0317 15.3404 27.2934 15.0264 27.2974 14.7613C27.3008 14.5308 27.1981 14.3115 27.0188 14.1667C26.8126 14 26.404 14 25.5867 14H14.6487L16.1487 2Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >Collaborations</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.Whistleblow) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/whistleblow"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="31"
                height="34"
                viewBox="0 0 31 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 10.7841V16.7841M15.5 22.7841H15.515M2 10.6962V22.8719C2 23.3859 2 23.6428 2.07573 23.872C2.14272 24.0748 2.25224 24.2609 2.39695 24.418C2.56053 24.5955 2.78517 24.7203 3.23446 24.9699L14.3345 31.1365C14.7599 31.3729 14.9726 31.491 15.1978 31.5374C15.3972 31.5784 15.6028 31.5784 15.8022 31.5374C16.0274 31.491 16.2401 31.3729 16.6655 31.1365L27.7655 24.9699C28.2148 24.7203 28.4395 24.5955 28.6031 24.418C28.7478 24.2609 28.8573 24.0748 28.9243 23.872C29 23.6428 29 23.3859 29 22.8719V10.6962C29 10.1823 29 9.92528 28.9243 9.69609C28.8573 9.49332 28.7478 9.3072 28.6031 9.15016C28.4395 8.97266 28.2148 8.84786 27.7655 8.59825L16.6655 2.43159C16.2401 2.19525 16.0274 2.07708 15.8022 2.03075C15.6028 1.98975 15.3972 1.98975 15.1978 2.03075C14.9726 2.07708 14.7599 2.19525 14.3345 2.43159L3.23446 8.59825C2.78517 8.84786 2.56053 8.97266 2.39695 9.15016C2.25224 9.3072 2.14272 9.49332 2.07573 9.69609C2 9.92528 2 10.1823 2 10.6962Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >Whistleblow</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.Messages) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/messages"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="34"
                height="28"
                viewBox="0 0 34 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75 14H7.82295C8.85076 14 9.79035 14.5807 10.25 15.5C10.7096 16.4193 11.6492 17 12.6771 17H21.3229C22.3508 17 23.2904 16.4193 23.75 15.5C24.2096 14.5807 25.1492 14 26.1771 14H31.25M12.4498 2H21.5502C23.1655 2 23.9731 2 24.6862 2.24595C25.3167 2.46344 25.8911 2.81839 26.3676 3.28512C26.9064 3.81289 27.2676 4.5353 27.99 5.98006L31.2399 12.4798C31.5234 13.0467 31.6651 13.3302 31.7651 13.6273C31.8538 13.8912 31.9179 14.1627 31.9565 14.4384C32 14.7488 32 15.0658 32 15.6997V18.8C32 21.3202 32 22.5804 31.5095 23.543C31.0781 24.3897 30.3897 25.0781 29.543 25.5095C28.5804 26 27.3202 26 24.8 26H9.2C6.67976 26 5.41965 26 4.45704 25.5095C3.61031 25.0781 2.9219 24.3897 2.49047 23.543C2 22.5804 2 21.3202 2 18.8V15.6997C2 15.0658 2 14.7488 2.04346 14.4384C2.08206 14.1627 2.14616 13.8912 2.23493 13.6273C2.33489 13.3302 2.47664 13.0467 2.76012 12.4798L6.00997 5.98006C6.73236 4.53529 7.09355 3.8129 7.63242 3.28512C8.10895 2.81839 8.68327 2.46344 9.31383 2.24595C10.0269 2 10.8345 2 12.4498 2Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >Messages</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.Maps) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/maps"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="30"
                fill="white"
                class="bi bi-globe-central-south-asia"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM4.882 1.731a.482.482 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.721.721 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a.996.996 0 0 0-.462-.04 7.03 7.03 0 0 1 2.45-2.027Zm-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.78.78 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.454.454 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282a.61.61 0 0 0 .04-.001 7.003 7.003 0 0 1-12.658.905Z"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >Maps</span
            >
          </a>
        </div>
      </div>
      <div
        *ngIf="authRepo.hasFeature$(TenantFeatures.Apv) | async"
        class="col-4 d-flex justify-content-center"
      >
        <div class="m-1 navcard">
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/apv"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 17L15.5 20L22.25 13.25M25.8518 6.49776C26.1606 7.2448 26.7535 7.8386 27.5001 8.14861L30.1179 9.23297C30.8649 9.54242 31.4585 10.136 31.7679 10.8831C32.0774 11.6302 32.0774 12.4696 31.7679 13.2167L30.6844 15.8327C30.3748 16.5801 30.3744 17.4204 30.6854 18.1675L31.767 20.7827C31.9204 21.1527 31.9994 21.5494 31.9995 21.9499C31.9995 22.3505 31.9207 22.7471 31.7674 23.1172C31.6141 23.4873 31.3894 23.8235 31.1061 24.1067C30.8228 24.3899 30.4865 24.6145 30.1164 24.7677L27.5005 25.8513C26.7535 26.1602 26.1597 26.7531 25.8497 27.4997L24.7654 30.1175C24.456 30.8646 23.8624 31.4582 23.1154 31.7677C22.3683 32.0771 21.5289 32.0771 20.7818 31.7677L18.1659 30.6841C17.4188 30.3754 16.5797 30.376 15.8331 30.6858L13.2153 31.7686C12.4687 32.0774 11.63 32.0771 10.8836 31.7679C10.1371 31.4587 9.54388 30.8658 9.23422 30.1195L8.14958 27.5009C7.8407 26.7538 7.24782 26.1601 6.50127 25.85L3.8835 24.7657C3.13675 24.4564 2.54338 23.8632 2.23382 23.1165C1.92426 22.3698 1.92384 21.5308 2.23266 20.7838L3.3162 18.1678C3.62488 17.4207 3.62425 16.5816 3.31444 15.8349L2.23246 13.2152C2.07905 12.8451 2.00006 12.4485 2 12.0479C1.99994 11.6474 2.0788 11.2507 2.23208 10.8806C2.38537 10.5106 2.61007 10.1743 2.89335 9.89113C3.17663 9.60793 3.51294 9.38333 3.88305 9.23016L6.49895 8.14658C7.24531 7.83796 7.83873 7.2458 8.14895 6.50008L9.23327 3.88221C9.54272 3.13512 10.1363 2.54155 10.8833 2.23209C11.6304 1.92264 12.4698 1.92264 13.2169 2.23209L15.8328 3.31567C16.5799 3.62437 17.419 3.62374 18.1656 3.31392L20.7845 2.23377C21.5314 1.92449 22.3706 1.92455 23.1176 2.23395C23.8645 2.54335 24.458 3.13674 24.7675 3.88365L25.8521 6.50229L25.8518 6.49776Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              >APV</span
            >
          </a>
        </div>
      </div>
      <div
        *ngFor="let doc of doctyperepo.all$ | async"
        class="col-4 d-flex justify-content-center"
      >
        <div
          *ngIf="authRepo.hasFeature$(TenantFeatures.Documents) | async"
          class="m-1 navcard"
        >
          <a
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
            href="/documenttypeFromSideBar/{{ doc.id }}"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                fill="currentColor"
                class="bi bi-file-earmark-plus"
                viewBox="0 0 16 16"
                style="color: white"
              >
                <path
                  d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z"
                />
                <path
                  d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                />
              </svg>
            </div>
            <span
              class="ps-1 pe-1 d-flex justify-content-center align-items-center mt-1 text-white cardtext doctext"
              >{{ doc.name.slice(0, 15) }}
              <span *ngIf="doc.name.length > 15">...</span></span
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <!--head administrator-->
</ng-template>
