export class EnvState {
  name = 'AppName';
  apiUrl = '';
  features = {
    news: false,
    offlineChanges: false,
    whistleblow: false,
    mail: false,
    calendar: false,
    apv: false,
    mwevents: false,
    timelogs: false,
    tickets: false,
  };
  locale = 'en-gb';
  releaseV = 1.0;
}
