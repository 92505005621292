import { Component, Input } from '@angular/core';
import { TfaSetup } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-show2fa-creds-pop-up',
  templateUrl: './show2fa-creds-pop-up.component.html',
  styleUrls: ['./show2fa-creds-pop-up.component.scss'],
})
export class Show2faCredsPopUpComponent {
  @Input() Tfa?: TfaSetup;
}
