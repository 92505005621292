<div
  class="reminderHover text-break text-center"
  style="display: block; position: absolute; top:{{ top }}px; left: {{
    left
  }}px; max-height: 600px;  "
>
  <div
    *ngFor="let status of StatusesOptions; let i = index"
    class="d-flex"
    style="min-width: 100px"
  >
    <div
      [class.gen-cont]="status.subMenu && status.subMenu.length"
      (click)="updateValue(status)"
      class="dropdown-menu-item option w-100"
    >
      {{ status.value }}
    </div>
  </div>
</div>
