import { Component } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AssignCallsRepository } from 'src/app/state/assignCalls.repository';

@Component({
  selector: 'app-assign-calls-page',
  templateUrl: './assign-calls-page.component.html',
  styleUrls: ['./assign-calls-page.component.scss'],
})
export class AssignCallsPageComponent {
  constructor(public repo: AssignCallsRepository) {}
  teamsOptions: DatalistOption[] = [];
  teamId: string | null = null;
}
