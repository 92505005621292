import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import { BaseService } from './abstract/base.service';

import {
  CompaniesRepository,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
} from './companies.repository';
import {
  EventScheduleRepository,
  IEventSchedule,
} from './event-schedule.repository';
import { ISubscriber, SubscribersRepository } from './subscribers.repository';

const DEFAULT_ENTITIES_PER_PAGE = 25;
const API = '/api/subscribers';

@Injectable({ providedIn: 'root' })
export class SubscribersService extends BaseService<ISubscriber> {
  constructor(http: HttpClient, repo: SubscribersRepository) {
    super(API, http, repo);
  }

  loadOneSubscriber(id: string) {
    return this.http.get<ISubscriber>(API + '/loadOneSubscriber/' + id);
  }

  postSubscriber(entity: Partial<ISubscriber>) {
    return this.http.post<ISubscriber>(API + '/postSubscriber/', entity);
  }

  updateSubscriber(entity: Partial<ISubscriber>) {
    return this.http.patch<ISubscriber>(API + '/updateSubscriber/', entity);
  }

  loadPageCustom(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    eventScheduleId = ''
  ): Observable<PaginationData & { data: ISubscriber[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `eventScheduleId=${eventScheduleId}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: ISubscriber[] }>(
        `${API}/customLoadPage/?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sortCustom(sortBy: SortState, search?: string, eventScheduleId = '') {
    this.repo.setSort(sortBy);
    return this.reloadPageCustom(
      search,
      DEFAULT_ENTITIES_PER_PAGE,
      eventScheduleId
    );
  }

  reloadPageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    eventScheduleId = ''
  ): Observable<(PaginationData & { data: ISubscriber[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        data.currentPage,
        data.perPage,
        search,
        eventScheduleId
      );
    }
    return this.loadPageCustom(1, defaultTake, search, eventScheduleId);
  }

  searchRelodePageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    eventScheduleId = ''
  ): Observable<(PaginationData & { data: ISubscriber[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(1, data.perPage, search, eventScheduleId);
    }
    return this.loadPageCustom(1, defaultTake, search, eventScheduleId);
  }
}
