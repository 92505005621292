import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import {
  Post,
  IComments,
  PostsRepository,
} from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UrlIdResolverService } from '../../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-news-info',
  templateUrl: './news-info.component.html',
  styleUrls: ['./news-info.component.scss'],
})
export class NewsInfoComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';
  post: Post | null = null;
  Form: UntypedFormBuilder | any;
  id: string | null = null;
  submitErrors: string[] | null = null;

  constructor(
    private postService: PostsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: PostsRepository,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      text: [null, Validators.required],
    });
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        this.postService.loadOne(`${this.id}`).subscribe((x) => {
          this.post = x;
        });
      },
    });
  }

  postComment(comment: IComments) {
    this.postService.AddComment(comment).subscribe((x) => {
      let newComment: IComments = {
        id: x.id,
        createdAt: x.createdAt!,
        userName: x.userName,
        userId: x.userId,
        postId: x.postId,
        subeventIdeaId: x.subeventIdeaId,
        mwSubeventId: x.mwSubeventId,
        text: x.text,
        comments: [],
        imagePath: x.imagePath,
      };
      this.post?.comments.push(newComment);
    });
  }

  onSubmit() {
    if (this.Form && !this.Form.valid) {
      this.Form.markAllAsTouched();
      return;
    }
  }
}
