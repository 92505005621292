<app-event-schedules-title
  [selectedYear]="selectedYear"
  [yearsOptions]="yearsOptions"
  (setYear)="setYear($event)"
>
</app-event-schedules-title>
<div class="enableScroll">
  <app-error-alert
    *ngIf="errorMessage"
    title="Could not load the data"
    [errors]="[errorMessage]"
    (cancel)="errorMessage = ''"
    [enableCancel]="true"
  ></app-error-alert>
</div>
<!-- <div class="alert alert-danger enableScroll" *ngIf="errorMessage">
  
  {{ errorMessage }}
</div> -->
<div class="padx075rem">
  <app-client-month-table
    (expand)="expand($event)"
    (refreshData)="reloadMonths()"
  ></app-client-month-table>
</div>
