import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CompaniesService } from '../../../../state/companies.service';

import { ContactsService } from '../../../../state/contacts.service';
import { Location } from '@angular/common';
import {
  ClientUsersRepository,
  ClientUsersSortOptions,
  IClientUser,
} from 'src/app/state/clientUsers.repository';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import { ClientUsersService } from 'src/app/state/clientUsers.service';

@Component({
  selector: 'app-client-users-page',
  templateUrl: './client-users-page.component.html',
  styleUrls: ['./client-users-page.component.scss'],
})
export class ClientUsersPageComponent {
  sortOptions = ClientUsersSortOptions;
  innerWidth = 0;
  searchFilter: string = '';

  client: ICompanyMarker | null = null;
  id: string | null = null;
  deleteConfirmation: IClientUser | null = null;

  constructor(
    public repo: ClientUsersRepository,
    public service: ClientUsersService,
    public clientService: CompaniesService,
    private activatedRoute: ActivatedRoute,
    private pagelocation: Location
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');

        if (this.id) {
          this.service
            .reloadPageCustom(this.searchFilter, 25, this.id)
            .subscribe();

          this.clientService
            .loadOneCompany(this.id)
            .subscribe((x) => (this.client = x));
        }
      },
    });
  }

  refreshData() {
    this.service
      .reloadPageCustom(this.searchFilter, 25, `${this.id}`)
      .subscribe();
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service
      .searchRelodePageCustom(this.searchFilter, 25, `${this.id}`)
      .subscribe();
  }

  handleDeleteClick(post: IClientUser) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  locationBack() {
    this.pagelocation.back();
  }
}
