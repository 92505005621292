<form [formGroup]="editForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <div class="form-group mb-3 row p-0">
    <div class="form-floating mb-3" [appValidateState]="editForm.get('title')">
      <div class="inpt-title" for="title" i18n="@@title">Title</div>
      <input id="title" formControlName="title" type="text" class="inpt" placeholder="Title" i18n-placeholder="@@title"
        autocomplete="off" />
    </div>
    <div class="col-12 col-md-6 form-floating mb-3">
      <div class="inpt-title" for="clientCode" i18n="@@clientCode">
        Client Code
      </div>
      <input class="inpt" id="clientCode" formControlName="clientCode" type="text" placeholder="Client Code"
        i18n-placeholder="@@ClientCode" autocomplete="off" />
    </div>
    <div class="form-floating mb-3" [appValidateState]="editForm.get('status')">
      <app-datalist-select class="" [activeValue]="editForm?.value.status" [options]="StatusesOptions"
        (activeValueChange)="updateValue('status', $event)" [multiple]="false" label="Status"
        i18n-label="Status"></app-datalist-select>
    </div>
    <div class="form-floating mb-4 col-6">
      <div class="inpt-title" i18n="@@firstEvent">First Event</div>

      <div class="d-flex pe-50">
        <input class="inpt" (dateChange)="setStartDate($event)" formControlName="clientSince" matInput
          [matDatepicker]="picker" />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="form-floating mb-3" [appValidateState]="editForm.get('descripion')">
      <div class="inpt-title" for="description" i18n="@@description">
        Description
      </div>
      <textarea id="description" formControlName="description" type="text" class="inpt inpt-lg minHeight100"
        placeholder="Description" i18n-placeholder="@@title" autocomplete="off"></textarea>
    </div>

    <div class="form-floating mb-3" [appValidateState]="editForm.get('address')" *ngIf="locationCheck()">
      <div class="inpt-title" for="address" i18n="@@CompanyAddress">
        Address
      </div>
      <input id="address" formControlName="address" class="inpt" ngx-google-places-autocomplete [options]="myOptions"
        placeholder="Address" #palceRef="ngx-places" (onAddressChange)="updateCompanyLocation($event)"
        i18n-placeholder="Title placeholder @@CompanyAddress" />
    </div>
    <div class="col-12 col-md-8 form-floating mb-2">
      <div class="inpt-title" for="acquiredBy" i18n="@@Contacts">Contacts</div>
      <textarea class="mb-1 inpt-lg inpt inpt-2height" id="contacts" formControlName="acquiredBy" type="text"
        placeholder="Contacts" i18n-placeholder="@@Contacts" autocomplete="off"></textarea>
    </div>
    <div class="col-6 form-group form-floating mb-2">
      <app-datalist-select class="" [activeValue]="editForm?.value.referralSourceId" [options]="RefferalSourcesOptions"
        (activeValueChange)="updateValue('referralSourceId', $event)" [multiple]="false"
        label="Source"></app-datalist-select>
    </div>

    <!-- <div class="col-12 col-md-4 form-floating my-2">
      <div class="inpt-title" for="aeAdvisor" >
        AE Advisor
      </div>
      <input
        class="form-check-input"
        id="aeAdvisor"
        formControlName="aeAdvisor"
        type="checkbox"
        placeholder="AE Advisor"
        i18n-placeholder="@@AEAdvisor"
        autocomplete="off"
      />
    </div> -->

    <div class="col-12 col-md-12 form-group form-floating mb-2">
      <app-datalist-select class="" [activeValue]="editForm?.value.fillColor" [options]="ColorsOptions"
        (activeValueChange)="updateValue('fillColor', $event)" [multiple]="false" label="Color"
        i18n-label="Color"></app-datalist-select>
    </div>
  </div>

  <h5 class="mainfont">Client Notes</h5>
  <div class="form-floating mb-2">
    <div class="inpt-title" for="pricing" i18n="@@pricing">Pricing</div>
    <textarea class="mb-1 inpt-lg inpt inpt-2height" id="pricing" formControlName="pricing" type="text"
      placeholder="Pricing" i18n-placeholder="@@Pricing" autocomplete="off"></textarea>
  </div>
  <div formGroupName="invoice" class="form-floating mb-2">
    <h6 class="mainfont mb-1">Invoice</h6>
    <div class="row px-1">
      <div class="col-12 col-md-6">
        <div class="inpt-title" for="billTo" i18n="@@billTo">Bill To</div>
        <input class="mb-1 inpt" id="billTo" formControlName="billTo" type="text" placeholder="Bill To"
          i18n-placeholder="@@billTo" autocomplete="off" />
      </div>
      <div class="col-12 col-md-6">
        <div class="inpt-title" for="email" i18n="@@email">Email To</div>
        <input class="mb-1 inpt" id="email" formControlName="email" type="text" placeholder="Email to"
          i18n-placeholder="@@emailTo" autocomplete="off" />
      </div>
    </div>
  </div>
  <div class="form-floating mb-3" [appValidateState]="editForm.get('feePerEvent')">
    <div class="inpt-title" for="flatFee" i18n="@@flatFee">Fee per event</div>
    <input id="flatFee" formControlName="feePerEvent" type="number" class="inpt" placeholder="Fee per event"
      i18n-placeholder="@@flatFee" autocomplete="off" />
  </div>
  <div class="col-12">
    <div class="form-group form-floating mb-3">
      <div class="inpt-title">Client Folder</div>
      <input formControlName="folder" type="text" class="inpt" placeholder="Folder" />
    </div>
  </div>
  <div class="col-12">
    <div class="form-group form-floating mb-3">
      <div class="inpt-title">Client Specific Instructions</div>
      <input formControlName="clientSpecificInstructions" type="text" class="inpt"
        placeholder="Client Specific Instructions" />
    </div>
  </div>
  <div class="form-floating mb-3">
    <div class="inpt-title" for="notes" i18n="@@notes">Notes</div>
    <textarea class="inpt-lg inpt" id="notes" formControlName="notes" type="text" placeholder="Notes"
      i18n-placeholder="@@notes" autocomplete="off"></textarea>
  </div>

  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-wfrBlue shadow-sm" [disabled]="isLoading">
      Save
    </button>
  </div>
</form>