<div class="row justify-content-center">
  <div class="row col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3 ps-0 pe-0">
    <h2 class="sm-del page-title col-sm-4 col-12">News</h2>

    <div
      class="col-sm-8 col-12 mb-sm-5 mb-3 d-flex justify-content-sm-end justify-content-center"
    >
      <div class="butsect d-flex justify-content-center align-items-center">
        <button class="button butActive me-1">All</button>
        <button
          class="button me-1 d-flex flex-row align-items-center justify-content-center"
        >
          Unread
          <span
            class="ms-1 d-inline d-flex justify-content-center align-items-center unreadnumber"
            >1</span
          >
        </button>
        <button class="button me-1">Read</button>
      </div>
    </div>

    <div *ngFor="let post of repo.page$ | async" class="mb-sm-4 mb-5">
      <div class="row p-0 m-0" style="width: 100%">
        <div class="col-sm-auto p-sm-0 col-12">
          <img
            *ngIf="post.documentPath"
            class="news_img"
            src="{{ post.documentPath }} "
            alt="newsimg"
          />
          <img
            *ngIf="!post.documentPath"
            class="news_img"
            src="{{ DEFAULT_NEWS }} "
            alt="newsimg"
          />
        </div>

        <div class="col-sm col-12">
          <div class="ms-sm-3 text-div">
            <div
              class="news_title text-break mt-xl-3 mt-lg-2 mt-sm-0 mt-2"
              [routerLink]="['/posts', post.id]"
            >
              {{ post.title | slice : 0 : 50 }}
              <span *ngIf="post.title.length > 50">...</span>
            </div>
            <p
              class="news_content text-break mt-2"
              [innerHTML]="post.content.slice(0, 80)"
            >
              <span *ngIf="post.content.length > 86">...</span>
            </p>
            <div
              class="d-flex flex-row align-items-center mt-lg-2 mt-xl-4 mt-2"
            >
              <div class="d-flex justify-content-center align-items-center">
                <img
                  class="rounded-circle me-2"
                  src="{{ DEFAULT_IMAGE }}"
                  alt="avatar"
                  width="40"
                  height="40"
                  style="background: #ff6a00"
                />
              </div>
              <div class="d-flex flex-column flex-start">
                <div class="authorName">Name Surname</div>
                <div class="date">{{ post.createdAt | date : "d MMM y" }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <hr />
      <app-custom-pagination
        [innerWidth]="innerWidth"
        [page]="(repo.pageNumber$ | async) || 1"
        [total]="(repo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="service.loadPage($event).subscribe()"
      ></app-custom-pagination>
    </div>
  </div>
</div>
