import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import {
  UserInfo,
  IMWEvent,
  IIdea,
  IMWSubevents,
  MWEventsRepository,
  IComments,
  SortComentsAndIdeas,
  IdeaRating,
} from 'src/app/state/mwevent.repository';
import { MWEventService } from 'src/app/state/mwevent.service';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import {
  AbstractControl,
  FormArray,
  UntypedFormBuilder,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  Validators,
} from '@angular/forms';
import { AuthRepository } from '../../../state/auth.repository';
import { async } from 'rxjs';
import { number } from 'echarts';

@Component({
  selector: 'app-mwevent-user',
  templateUrl: './mwevent-user.component.html',
  styleUrls: ['./mwevent-user.component.scss'],
})
export class MweventUserComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';
  clicked: boolean = false;
  mwevent: IMWEvent | null = null;
  id: string | null = null;
  index: number = -1;
  safeUrls: SafeResourceUrl[] = [];
  Form: UntypedFormBuilder | any;
  IdeaForm: UntypedFormBuilder | any;
  isIdea: boolean = false;
  ReplyForm: UntypedFormBuilder | any;
  replyIndex: number = -1;
  sublength: number = 0;
  innerWidth = 0;

  constructor(
    public elRef: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private service: MWEventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public repo: AuthRepository,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      title: [null, Validators.required],
    });
    this.IdeaForm = this.formBuilder.group({
      title: [null, Validators.required],
      text: [null, Validators.required],
    });

    this.ReplyForm = this.formBuilder.group({
      text: [null, Validators.required],
    });

    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        this.service.loadOne(`${this.id}`).subscribe((x) => {
          this.mwevent = x;
          this.setSafeUrls();
          this.sublength = this.mwevent.subevents.length;
          this.index = this.mwevent.currentPosition!;
        });
      },
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  popup(k: number) {
    var ico = document.getElementById('hoverico' + k);
    if (ico) {
      var top = ico.getBoundingClientRect().top;
      var popup = document.getElementById('myPopup' + k);
      var mpopup = document.getElementById('mpopup' + k);
      popup?.classList.toggle('show');
      this.innerWidth > 767
        ? (mpopup!.style['top'] = top + 'px')
        : (mpopup!.style['top'] = top - 64 + 'px');
    }
  }
  hidepopup(k: number) {
    var popup = document.getElementById('myPopup' + k);
    popup?.classList.toggle('show');
  }

  postAnswer(id: string) {
    var answer = (<HTMLInputElement>document.getElementById(id)).value;
    if (answer.length > 5) {
      this.service
        .AddAnswer({ subeventQuestionId: id, answer: answer })
        .subscribe();
      var t = this.mwevent?.subevents[this.index].questions.filter(
        (x) => x.id == id
      )[0];
      if (t) {
        t.answer = answer;
      }
    }
  }

  canAnswer(id: string) {
    var answer = (<HTMLInputElement>document.getElementById(id)).value;
    if (answer.length > 5 && answer != null) {
      return false;
    } else {
      return true;
    }
  }

  changePost() {
    this.clicked = true;
  }

  changeForm() {
    if (this.isIdea == true) {
      this.isIdea = false;
    } else {
      this.isIdea = true;
    }
  }

  navigate(index: number) {
    this.manageVisitors(this.index, index);
    this.index = index;
    this.scroll();
  }

  manageVisitors(indxbefore: number, index: number) {
    this.service.getIndex(this.mwevent?.id!, index).subscribe((y) => {
      let newVisitor: UserInfo = {
        id: y.id,
        imagePath: y.imagePath,
        nameSurname: y.name + y.surname,
      };
      indxbefore !== -1
        ? this.mwevent?.subevents[indxbefore].usersThatView?.map((x, indx) => {
            if (x.id === y.id) {
              this.mwevent?.subevents[indxbefore].usersThatView?.splice(
                indx,
                1
              );
            }
          })
        : this.mwevent?.usersThatView?.map((x, index) => {
            if (x.id === y.id) {
              this.mwevent?.usersThatView?.splice(index, 1);
            }
          });
      index === -1
        ? this.mwevent?.usersThatView?.push(newVisitor)
        : this.mwevent?.subevents[index].usersThatView?.push(newVisitor);
    });
  }

  scroll(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.clicked = false;
    this.isIdea = false;
  }

  videoUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setSafeUrls() {
    var subevents = this.mwevent?.subevents;
    if (subevents) {
      for (var element of subevents) {
        this.safeUrls.push(
          this.sanitizer.bypassSecurityTrustResourceUrl(element.url)
        );
      }
    }
  }
  onSubmit() {
    const mwSubeventId = this.mwevent!.subevents[this.index].id;
    if (this.isIdea == true) {
      if (this.IdeaForm && !this.IdeaForm.valid) {
        this.IdeaForm.markAllAsTouched();
        return;
      }
      const title = this.IdeaForm.value?.title;
      const text = this.IdeaForm.value?.text;
      this.postIdea({ title, text, mwSubeventId });
      this.IdeaForm.reset({
        title: null,
        text: null,
      });
    } else {
      if (this.Form && !this.Form.valid) {
        this.Form.markAllAsTouched();
        return;
      }
      const title = this.Form.value?.title;
      this.postComment({ text: title, mwSubeventId });
      this.Form.reset({
        title: null,
      });
    }
  }

  postIdea(idea: IIdea) {
    this.service.AddIdea(idea).subscribe((x) => {
      let newIdea: SortComentsAndIdeas = {
        idea: {
          id: x.id,
          createdAt: x.createdAt!,
          mwSubeventId: x.mwSubeventId,
          comments: [],
          text: x.text,
          title: x.title,
          ideaRating: x.ideaRating,
          userId: x.userId,
        },
        isShowing: false,
        type: '1',
        createdAt: x.createdAt!,
        comment: null,
        ideaRating: null,
      };
      this.mwevent?.subevents
        .filter((f) => f.id == x.mwSubeventId)[0]
        .sortCommentsAndIdeas.push(newIdea);
      this.clicked = false;
      this.isIdea = false;
    });
  }

  postComment(comment: IComments) {
    this.service.AddComment(comment).subscribe((x) => {
      let newComment: SortComentsAndIdeas = {
        comment: {
          id: x.id,
          createdAt: x.createdAt!,
          userName: x.userName,
          userId: x.userId,
          subeventIdeaId: x.subeventIdeaId,
          mwSubeventId: x.mwSubeventId,
          text: x.text,
          comments: [],
          imagePath: x.imagePath,
        },
        isShowing: false,
        type: '1',
        createdAt: x.createdAt!,
        idea: null,
        ideaRating: null,
      };
      this.mwevent?.subevents
        .filter((f) => f.id == x.mwSubeventId)[0]
        .sortCommentsAndIdeas.push(newComment);
      this.clicked = false;
    });
  }

  SetRating(
    Id?: string,
    IdeaId?: string,
    impactrate?: number,
    effortrate?: number
  ) {
    this.service
      .postRating({
        id: Id,
        subeventIdeaId: IdeaId,
        impact: impactrate,
        effort: effortrate,
      })
      .subscribe((x) => {
        let newRating: IdeaRating = {
          id: x.id,
          effort: x.effort,
          impact: x.impact,
          subeventIdeaId: x.subeventIdeaId,
          userId: x.userId,
        };
        this.mwevent!.subevents[this.index].sortCommentsAndIdeas.filter(
          (h) => h.idea?.id == x.subeventIdeaId
        )[0].ideaRating = newRating;
      });
  }

  postComToCom(CommId?: string) {
    const text = this.ReplyForm.value?.text;
    this.service
      .AddComment({ text: text, commentId: CommId })
      .subscribe((x) => {
        let newComment: IComments = {
          id: x.id,
          createdAt: x.createdAt,
          userName: x.userName,
          userId: x.userId,
          subeventIdeaId: x.subeventIdeaId,
          mwSubeventId: x.mwSubeventId,
          text: x.text,
          imagePath: x.imagePath,
        };
        this.mwevent?.subevents[this.index]?.sortCommentsAndIdeas
          .filter((h) => h.comment?.id == CommId)[0]
          .comment?.comments?.push(newComment);
      });
    this.reply(-1);
  }

  postComToIdea(IdeaId?: string) {
    const text = this.ReplyForm.value?.text;
    this.service
      .AddComment({ text: text, subeventIdeaId: IdeaId })
      .subscribe((x) => {
        let newComment: IComments = {
          id: x.id,
          createdAt: x.createdAt,
          userName: x.userName,
          userId: x.userId,
          subeventIdeaId: x.subeventIdeaId,
          mwSubeventId: x.mwSubeventId,
          text: x.text,
          imagePath: x.imagePath,
        };
        this.mwevent?.subevents[this.index]?.sortCommentsAndIdeas
          .filter((h) => h.idea?.id == IdeaId)[0]
          .idea?.comments?.push(newComment);
      });
    this.reply(-1);
  }

  reply(index: number) {
    this.ReplyForm.reset({
      text: null,
    });
    this.replyIndex = index;
  }

  get comtitle() {
    return this.Form.get('title');
  }
  get ideatitle() {
    return this.IdeaForm.get('title');
  }
  get ideatext() {
    return this.IdeaForm.get('text');
  }
}
