import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { ITopic, IWebContent } from './event-schedule.repository';
import { TopicsRepository } from './topics.repository';

const API = '/api/topics';

@Injectable({ providedIn: 'root' })
export class TopicsService extends BaseService<ITopic> {
  constructor(http: HttpClient, repo: TopicsRepository) {
    super(API, http, repo);
  }
  loadAll(queryFilter: string) {
    return this.http.get<ITopic[]>(`${API}/loadPage${queryFilter}`);
  }
  loadOneTopic(id: string) {
    return this.http.get<ITopic>(API + '/loadOneTopic/' + id);
  }
  postTopic(contact: Partial<ITopic>) {
    return this.http.post<ITopic>(API + '/postTopic', contact);
  }
  updateTopic(contact: Partial<ITopic>) {
    return this.http.put<ITopic>(API + '/updateTopic', contact);
  }
  updateCustomContent(customContent: IWebContent) {
    return this.http.patch(API + '/updateCustomContent', customContent);
  }
  removeCustomContent(eventId: string) {
    return this.http.delete<ITopic>(API + '/removeCustomContent/' + eventId);
  }
  loadGeneral() {
    return this.http.get<ITopic[]>(API + '/loadGeneral');
  }
  loadByClientId(id: string) {
    return this.http.get<ITopic[]>(API + '/loadByClientId/' + id);
  }
  loadByEventId(id: string) {
    return this.http.get<ITopic>(API + '/loadByEventId/' + id);
  }
  loadForDuplicate(id: string) {
    return this.http.get<ITopic>(API + '/loadForDuplicate/' + id);
  }
}
