import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  EventScheduleSortOptions,
  IEventSchedule,
  IMonthState,
  IMonthWithEvents,
  IPageProperties,
} from '../../../../state/event-schedule.repository';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import * as dayjs from 'dayjs';
import { DatePipe } from '@angular/common';
import { TopicsService } from '../../../../state/topics.service';
import {
  IActionPopEmitter,
  IChangePayStatsEmitter,
  IDeleteConfirmation,
  IEnterChangeStatus,
} from 'src/app/state/emitters/emiters-interfaces';

@Component({
  selector: 'app-financials-es',
  templateUrl: './financials-es.component.html',
  styleUrls: ['./financials-es.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinancialsEsComponent {
  @Input() months: IMonthWithEvents[] = [];
  @Input() pageProperties: IPageProperties | null = null;
  @Input() monthsState: IMonthState[] = [];
  @Input() eventSchedules: IEventSchedule[] = [];
  @Input() tabSelected: string = 'overview';
  @Input() loadEvInd: number | null = null;
  @Input() loadMonInd: number | null = null;

  @Output() exportCsv = new EventEmitter<IMonthWithEvents>();
  @Output() exportCheck = new EventEmitter<string>();
  @Output() addListeners = new EventEmitter<string>();
  @Output() showModal = new EventEmitter<string>();
  @Output() refreshOne = new EventEmitter<Partial<IEventSchedule>>();
  @Output() deleteConfirmation = new EventEmitter<IDeleteConfirmation>();

  @Output() refreshMonth = new EventEmitter<IMonthWithEvents>();
  @Output() refreshData = new EventEmitter<void>();
  @Output() expand = new EventEmitter<string>();
  @Output() enterPayStats = new EventEmitter<IChangePayStatsEmitter>();
  @Output() enterActivityLogs = new EventEmitter<string>();
  @Output() enterChangeStatus = new EventEmitter<IEnterChangeStatus>();
  @Output() actionPop = new EventEmitter<IActionPopEmitter>();
  @Output() errorAlert = new EventEmitter<string>();

  searchFilter: string = '';
  sortOptions = EventScheduleSortOptions;
  IEventSchedule?: IEventSchedule;
  secondDeleteConfirmation: IEventSchedule | null = null;

  deleteError: string[] | null = null;
  rawYears: number[] | null = null;
  eventScheduleOnPopUp: IEventSchedule | null = null;
  payStatsIdPopUp: string | null = null;

  searchForCC: boolean = false;
  monthIndex: number | null = null;
  eventIndex: number | null = null;
  reminderRight: number | null = null;

  statusLeft = 0;
  statusTop = 0;
  payStatsTop = 0;
  payStatsLeft = 0;

  constructor(
    public repo: EventScheduleRepository,
    public datepipe: DatePipe,

    public service: EventScheduleService,
    public topicService: TopicsService
  ) {}

  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  yearsOptions: DatalistOption[] | null = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];
  mouseEnter(mon: number, eve: number, event: any) {
    this.monthIndex = mon;
    this.eventIndex = eve;
    this.statusLeft = event.pageX;
    this.statusTop = event.pageY;
  }
  mouseEnterPayStats(event: any, esMainId: string) {
    if (esMainId) {
      this.payStatsLeft = Math.max(event.pageX - 10, 0);
      this.payStatsTop = Math.max(event.pageY - 10, 0);
      this.enterPayStats.emit({
        id: esMainId,
        payStatsLeft: this.payStatsLeft,
        payStatsTop: this.payStatsTop,
      });
    }
  }
  mouseLeave() {
    this.monthIndex = null;
    this.clickedDateIndex = null;
    this.clickedEventIndex = null;
    this.payStatsLeft = 0;
    this.payStatsTop = 0;

    this.eventIndex = null;
    this.statusLeft = 0;
    this.statusTop = 0;
  }
  changePayStats(value: DatalistOption) {
    if (this.eventScheduleOnPopUp) {
      this.eventScheduleOnPopUp.payStats = value.value;
      this.refreshOne.emit(this.eventScheduleOnPopUp);
    }
  }
  selectedYear: number | null = null;
  @Input() set years(value: number[] | null) {
    if (!value) {
      this.yearsOptions = null;
    } else {
      this.yearsOptions = value.map((x) => ({
        value: x,
        label: `${x}`,
      }));
    }
  }
  searchTopicById(id?: string) {
    if (id) {
      this.topicService.loadOneTopic(id).subscribe((x) => {
        if (x.googleSheetUrl) {
          this.goToLink(x.googleSheetUrl);
        }
      });
    }
  }

  appendItems(ev: IMonthWithEvents) {
    this.addListeners.emit();
    var curMonth = this.months.find((x) => x.id == ev.id);
    if (curMonth) {
      let temp = this.setLastStatusEvents(ev);
      for (let i = ev.showEventsCount!; i < ev.showEventsCount! + 20; ++i) {
        if (temp[i]) {
          curMonth.showEventSchedules?.push(temp[i]);
        }
      }
    }
  }

  setLastStatusEvents(month: IMonthWithEvents) {
    let res = [] as IEventSchedule[];
    var lastStatus = this.pageProperties?.monthStates.find((prop) =>
      month.name.includes(prop.name)
    )?.statusFilter;
    var lastStatusNum = this.pageProperties?.monthStates.find((prop) =>
      month.name.includes(prop.name)
    )?.statusFilterNum;

    if (
      lastStatus == 'Automatic' &&
      lastStatusNum != undefined &&
      lastStatusNum != null &&
      lastStatusNum != 0
    ) {
      res = month.eventSchedules?.filter(
        (event) =>
          (event.status == lastStatus && +event.statusNum == lastStatusNum) ||
          (event.doubleEvent &&
            event.statusD2 == lastStatus &&
            +event.statusNumD2 == lastStatusNum)
      );
    } else if (
      lastStatus === 'Few Spots' &&
      this.pageProperties?.tabSelected == 'to-Do'
    ) {
      switch (lastStatusNum) {
        case 1: {
          res = month.eventSchedules.sort(
            (a, b) =>
              new Date(a.fewSpots).getTime() - new Date(b.fewSpots).getTime()
          );
          break;
        }

        case 2: {
          res = month.eventSchedules.sort(
            (a, b) =>
              new Date(b.fewSpots).getTime() - new Date(a.fewSpots).getTime()
          );
          break;
        }
        default: {
          res = month.eventSchedules.sort(
            (a, b) =>
              new Date(a.fewSpots).getTime() - new Date(b.fewSpots).getTime()
          );
          break;
        }
      }
    } else if (
      lastStatus === 'Few Spots' &&
      this.pageProperties?.tabSelected !== 'to-Do'
    ) {
      res = month.eventSchedules;
    } else if (lastStatus) {
      res = month.eventSchedules?.filter((event) =>
        this.service.checkStatusRecursive(event, lastStatus!)
      );
    } else {
      res = month.eventSchedules;
    }
    return res;
  }

  onScrollDown(ev: any) {
    var curMonth = this.months.find((x) => x.id == ev.id);
    if (
      curMonth &&
      curMonth.eventSchedules.length &&
      curMonth.showEventsCount! < curMonth.eventSchedules.length
    ) {
      this.appendItems(ev);
      curMonth.showEventsCount! += 20;
    }
  }

  ngOnInit(): void {
    // this.refreshData.emit();
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;

      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
  }

  setSecondDateAsPrimary(post: IEventSchedule) {
    this.service.setSecondDateAsPrimary(post.id).subscribe({
      complete: () => {
        this.refreshData.emit();
      },
    });
  }

  handleDeleteClick(post: IEventSchedule) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.refreshData.emit();
      },
    });
  }

  secondHandleDeleteClick(post: IEventSchedule) {
    this.service.deleteSecond(post.id).subscribe({
      complete: () => {
        this.refreshData.emit();
      },
    });
  }

  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }
  isMonthClicked: boolean = false;
  clickedDateIndex: string | null = null;
  clickedMonthIndex: number | null = null;
  clickedEventIndex: number | null = null;

  showSortStatus(mon: number, event: any, name: string) {
    this.clickedDateIndex = name;
    this.isMonthClicked = true;
    this.clickedMonthIndex = mon;
    this.statusLeft = event.pageX - 20;
    this.statusTop = event.pageY;
  }
  sortmonthStatus(curentMonthIndex: number, $event: any) {
    let filtered = this.months[curentMonthIndex];
    if (!$event && filtered) {
      filtered.sortStatus = undefined;
      filtered.sortStatusNum = undefined;
      this.refreshMonth.emit(filtered);
    } else if (filtered) {
      filtered.sortStatus = $event.label;
      filtered.sortStatusNum = $event.value;
      this.refreshMonth.emit(filtered);
    }
  }

  calcTotalInvoices(invAds: number, flatFee: number, qts: number): number {
    return +(invAds + flatFee * qts).toFixed(2);
  }
  calcCostLead(totInv: number, atendees: number): number {
    if (atendees != 0) {
      return +(totInv / atendees).toFixed(2);
    } else return 0;
  }
  calcGrossProfit(totInv: number, totAds: number) {
    return +(totInv - totAds).toFixed(2);
  }
  calcFinances(event: IEventSchedule) {
    if (
      !event.eventScheduleLocationId ||
      event.eventScheduleLocationId == this.repo.Default_LocationId
    ) {
      this.errorAlert.emit(this.repo.Default_Location_Error);
      return;
    }

    event.totalInvoices = this.calcTotalInvoices(
      event.invoicedAds ?? 0,
      event.qts ?? 0,
      event.flatFee ?? 0
    );

    event.costLead = this.calcCostLead(
      event.totalInvoices ?? 0,
      (event.attendeesD2 ?? 0) + (event.attendees ?? 0)
    );

    event.grossProfit = this.calcGrossProfit(
      event.totalInvoices ?? 0,
      (event.metaAds ?? 0) + (event.otherAds ?? 0)
    );

    this.service.updateEventFinancials(event).subscribe((x) => {});
  }
}
