import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { async } from 'rxjs';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from '../../../../state/auth.service';
import { UiRepository } from '../../../../state/ui.repository';
import { EnvState } from '../../../shared/helpers/env-state';

@Component({
  selector: 'app-menuuser-card',
  templateUrl: './menuuser-card.component.html',
  styleUrls: ['./menuuser-card.component.scss'],
})
export class MenuUserCardComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';

  //@Input() preventClick = false;
  @Output() profileClick = new EventEmitter<Event>();

  constructor(
    public repo: AuthRepository,
    private auth: AuthService,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState
  ) {}

  ngOnInit(): void {}

  logout() {
    this.auth.logout().subscribe(() => this.router.navigate(['/signin']));
    return false; // prevent default
  }

  unimpersonate() {
    this.auth.unimpersonate().subscribe(() => this.router.navigate(['/']));
    return false; // prevent default
  }
}
