import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICompanyLocation } from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { IRoom } from '../../../../state/event-schedule.repository';
import { LocationsRepository } from '../../../../state/locations.repository';
import { LocationsService } from '../../../../state/locations.service';
import {
  RoomsRepository,
  RoomsSortOptions,
} from '../../../../state/rooms.repository';
import { RoomsService } from '../../../../state/rooms.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-rooms-page',
  templateUrl: './admin-rooms-page.component.html',
  styleUrls: ['./admin-rooms-page.component.scss'],
})
export class AdminRoomsPageComponent {
  sortOptions = RoomsSortOptions;
  innerWidth = 0;
  searchFilter: string = '';
  location: ICompanyLocation | null = null;
  room: IRoom | null = null;
  deleteError: string | null = null;

  id: string | null = null;
  deleteConfirmation: IRoom | null = null;

  constructor(
    public repo: RoomsRepository,
    public service: RoomsService,
    public locationsRepo: LocationsRepository,
    public locationsService: LocationsService,
    public clientService: CompaniesService,
    private activatedRoute: ActivatedRoute,
    private pagelocation: Location
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        if (this.id) {
          this.service
            .reloadPageCustom(this.searchFilter, undefined, this.id)
            .subscribe();
          this.locationsService
            .loadOneLocation(this.id)
            .subscribe((x) => (this.location = x));
        }
      },
    });
  }

  refreshData() {
    this.service
      .reloadPageCustom(this.searchFilter, undefined, `${this.id}`)
      .subscribe();
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service
      .searchRelodePageCustom(this.searchFilter, undefined, `${this.id}`)
      .subscribe();
  }

  handleDeleteClick(post: IRoom) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.clearError();
        this.refreshData();
      },
      error: (data) => (this.deleteError = data),
    });
  }

  clearError() {
    this.deleteError = null;
  }
  locationBack() {
    this.pagelocation.back();
  }
}
