import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Tenant, TenantsRepository } from 'src/app/state/tenants.repository';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  @Input() page: number = 1;
  @Input() total!: number;
  @Output() pageChange = new EventEmitter<number>();
  pages: number[] = [];

  @Input() innerWidth = 0;
  @Input() users: User[] | null = null;
  @Input() tenants: Tenant[] | null = null;
  @Input() isDeleteDisabled = true;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: User | null = null;

  constructor(
    public repo: UsersRepository,
    public tenantRepo: TenantsRepository,
    public service: UsersService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {}

  handleDeleteClick(user: User) {
    this.delete.emit(user.id);
  }
}
