import { HttpClient } from '@angular/common/http';
import { PaginationData } from '@ngneat/elf-pagination';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './base.repository';

export const DEFAULT_ENTITIES_PER_PAGE = 10;

export class BaseService<T extends { id: string }> {
  constructor(
    private api: string,
    protected http: HttpClient,
    protected repo: BaseRepository<T>
  ) {}

  loadWithoutPagination(): Observable<T[]> {
    return this.http
      .get<Observable<T[]>>(this.api)
      .pipe(this.repo.track(), this.repo.skipWhileCached());
  }

  load(): Observable<PaginationData & { data: T[] }> {
    return this.http.get<PaginationData & { data: T[] }>(this.api).pipe(
      tap((res) => this.repo.set(res.data)),
      this.repo.track(),
      this.repo.skipWhileCached()
    );
  }

  loadFilterPage(
    search: string = '',
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: T[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: T[] }>(`${this.api}?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = ''
  ): Observable<PaginationData & { data: T[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: T[] }>(`${this.api}?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sort(sortBy: SortState, search?: string) {
    this.repo.setSort(sortBy);
    return this.reloadPage(search, DEFAULT_ENTITIES_PER_PAGE);
  }

  reloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: T[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(data.currentPage, data.perPage, search);
    }
    return this.loadPage(1, defaultTake, search);
  }

  searchReloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: T[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(1, data.perPage, search);
    }
    return this.loadPage(1, defaultTake, search);
  }

  protected tapReloadPage<R>() {
    return switchMap((sourceResponse: R) =>
      combineLatest([of(sourceResponse), this.reloadPage()]).pipe(
        map(([res, _]) => res)
      )
    );
  }

  loadOne(id: string): Observable<T> {
    return this.http.get<T>(`${this.api}/${id}`).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id),
      this.repo.skipWhileCached(id)
    );
  }

  add(model: Partial<T>): Observable<T> {
    return this.http.post<T>(this.api, model).pipe(
      tap((res) => this.repo.upsert(res)),
      this.tapReloadPage(),
      this.repo.track('add')
    );
  }

  update(id: string, model: Partial<T>): Observable<T> {
    return this.http.patch<T>(`${this.api}/${id}`, model).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id)
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.api}/${id}`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(),
      this.repo.trackOne(id)
    );
  }

  export(): Observable<string> {
    return this.http
      .get<Blob>(`${this.api}/csv`, { responseType: 'blob' as any })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  import(file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post<any>(`${this.api}/csv`, formData)
      .pipe(this.tapReloadPage());
  }
}
