<!-- Tenant list items  -->

<div class="box mt-3 m-0 p-0 row" style="width: 100%">
  <div class="box-name-header" style="width: 100%">
    <div class="mt-1 ms-1 row pt-1 m-0 p-0">
      <div class="col-xl-7 col-md-7 col-sm-8 col-9 d-flex psm0">
        <input class="check form-check-input" type="checkbox" />
        <span class="txt mt-1 ms-2">Name</span>
      </div>

      <div class="col-xl-5 col-md-5 col-sm-4 col-3">
        <span class="txt me-1">Created</span>
      </div>
    </div>
  </div>

  <br />
  <div
    *ngFor="let tenant of tenants"
    class="row brd"
    style="margin-left: 0.1rem"
  >
    <div
      class="col-xl-7 col-md-7 col-sm-7 col-9 pt-3 pb-3 d-flex align-items-center psm0"
    >
      <input
        class="check form-check-input me-2"
        type="checkbox"
        style="margin-left: 0.1rem"
      />
      <app-entity-avatar
        [img]="tenant.imagePath"
        [alt]="tenant.name"
        icon="bi-building"
      ></app-entity-avatar>
      <p>
        <span
          class="txt-name text-break ms-1"
          [routerLink]="['/tenants', tenant.id]"
          >{{ tenant.name }}</span
        >
        <br />
        <small>
          <span class="txt-sub text-break ms-1" i18n="Users counter">
            {tenant.countOfUsers, plural, =1 {1 user} other
            {{{tenant.countOfUsers}} users}}</span
          >
        </small>
      </p>
    </div>
    <div class="col-sm-3 col-3 d-flex align-items-center">
      <span class="role ms-1" i18n="Label created date">
        {{ tenant.createdAt | date : "longDate" }}</span
      >
    </div>
    <div
      *ngIf="(auth.isAnyAdmin$ | async) && innerWidth > 575"
      class="col-2 d-flex align-items-center p-0 justify-content-end"
    >
      <div>
        <a
          class="btn btn-md btn-link w-100"
          [routerLink]="['/tenants', tenant.id]"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
              stroke="#475467"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-md btn-link w-100 text-danger"
          data-bs-target="#deleteusermodal"
          (click)="deleteConfirmation = tenant"
          title="Delete"
          i18n-title="Delete button"
        >
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3333 5.00033V4.33366C12.3333 3.40024 12.3333 2.93353 12.1517 2.57701C11.9919 2.2634 11.7369 2.00844 11.4233 1.84865C11.0668 1.66699 10.6001 1.66699 9.66667 1.66699H8.33333C7.39991 1.66699 6.9332 1.66699 6.57668 1.84865C6.26308 2.00844 6.00811 2.2634 5.84832 2.57701C5.66667 2.93353 5.66667 3.40024 5.66667 4.33366V5.00033M7.33333 9.58366V13.7503M10.6667 9.58366V13.7503M1.5 5.00033H16.5M14.8333 5.00033V14.3337C14.8333 15.7338 14.8333 16.4339 14.5608 16.9686C14.3212 17.439 13.9387 17.8215 13.4683 18.0612C12.9335 18.3337 12.2335 18.3337 10.8333 18.3337H7.16667C5.76654 18.3337 5.06647 18.3337 4.53169 18.0612C4.06129 17.8215 3.67883 17.439 3.43915 16.9686C3.16667 16.4339 3.16667 15.7338 3.16667 14.3337V5.00033"
              stroke="#475467"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <br />
</div>
<app-confirm-delete
  id="deleteusermodal"
  *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="handleDeleteClick(deleteConfirmation); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
