<div
  *ngIf="location && shown"
  class="absolute-popover"
  style="left: {{ posLeft }}px; top:{{ posTop }}px"
>
  <app-datalist-select
    class=""
    [activeValue]="location.radius ?? 4828.03"
    [options]="CirclesOptions"
    [multiple]="false"
    (activeValueChange)="changeCircleRadius($event)"
  ></app-datalist-select>
</div>
