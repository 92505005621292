<h2 class="mb-4" *ngIf="!id" i18n="Create post label">Create post</h2>
<h2 class="mb-4" *ngIf="id" i18n="Update post label">Update post</h2>
<div class="row" *ngIf="post">
  <app-news-form
    [editedPost]="post"
    (newsSubmit)="updatePost($event)"
  ></app-news-form>
  <app-error-alert
    title="Could not submit the post"
    i18n-title="News post submit error alert"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
