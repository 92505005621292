<div style="min-height: 700px">
  <div *ngIf="isLoading; else formTemplate">
    <app-spinner></app-spinner>
  </div>
  <ng-template #formTemplate>
    <div *ngIf="tabSelected && tabSelected == 'location'">
      <form
        [formGroup]="editLocationForm"
        (ngSubmit)="onSubmitLocation()"
        enctype="multipart/form-data"
      >
        <div>
          <div
            class="form-floating mb-3"
            [appValidateState]="editLocationForm.get('companyId')"
          >
            <app-datalist-select
              [options]="clientsOptions"
              [multiple]="false"
              class="w-100 ms-5"
              label="Select Client"
              [(activeValue)]="editLocationForm.value.companyId"
              (activeValueChange)="updateValue($event, 'companyId')"
            >
            </app-datalist-select>
          </div>

          <div
            class="form-group form-floating mb-3"
            [appValidateState]="editLocationForm.get('title')"
          >
            <div class="inpt-title">Title</div>
            <input
              id="locationTitle"
              formControlName="title"
              class="inpt"
              placeholder="Title"
              i18n-placeholder="Title placeholder @@title"
              autocomplete="off"
            />
          </div>
          <div
            class="form-group form-floating mb-3"
            [class.is-invalid]="
              (!editLocationForm.value?.latitude ||
                !editLocationForm.value?.latitude) &&
              editLocationForm.get('address')?.touched
            "
            *ngIf="locationCheck()"
          >
            <div class="inpt-title">Address</div>

            <input
              id="location"
              formControlName="address"
              class="inpt"
              ngx-google-places-autocomplete
              [options]="myOptions"
              placeholder="Address"
              #palceRef="ngx-places"
              (onAddressChange)="handleAddressChange($event)"
              i18n-placeholder="Title placeholder @@CompanyAddress"
            />
          </div>
          <div
            class="col-12 form-group form-floating mb-2"
            [class.is-invalid]="
              editLocationForm.get('defaultRadius')?.hasError('required') &&
              editLocationForm.get('defaultRadius')?.touched
            "
          >
            <app-datalist-select
              class=""
              [activeValue]="editLocationForm.get('defaultRadius')?.value"
              [options]="CirclesOptions"
              (activeValueChange)="updateValue($event, 'defaultRadius')"
              [multiple]="false"
              label="Default radius on map"
              i18n-label="Default radius on map"
            ></app-datalist-select>
          </div>

          <div class="form-floating mb-2">
            <div class="inpt-title" for="" i18n="@@LastEventNotes">Notes</div>
            <textarea
              id="LastEventDescription"
              class="inpt inpt-lg"
              formControlName="lastEventDescription"
              type="text"
              placeholder="Notes"
              i18n-placeholder="@@LastEventNotes"
              autocomplete="off"
            ></textarea>
          </div>
          <div
            class="form-group form-floating mb-3"
            [appValidateState]="editLocationForm.get('parking')"
          >
            <div class="inpt-title">Parking</div>
            <input
              id="locationTitle"
              formControlName="parking"
              class="inpt"
              placeholder="Parking"
              i18n-placeholder="Title placeholder @@parking"
              autocomplete="off"
            />
          </div>

          <div class="form-group form-floating h-100 pb-3 d-grid">
            <button
              type="submit"
              class="btn btn-wfrBlue shadow-sm"
              i18n="@@save"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="tabSelected && tabSelected == 'room'">
      <form
        [formGroup]="editForm"
        (ngSubmit)="onSubmit()"
        enctype="multipart/form-data"
      >
        <div class="form-group form-floating mb-3">
          <div>
            <div
              class="form-floating"
              [appValidateState]="editForm.get('clientId')"
            >
              <app-datalist-select
                [options]="clientsOptions"
                [multiple]="false"
                class="w-100 ms-5"
                label="Select Client"
                [(activeValue)]="clientId"
                (activeValueChange)="loadLocations($event)"
              >
              </app-datalist-select>
            </div>
            <div
              class="form-floating"
              [appValidateState]="editForm.get('companyLocationId')"
            >
              <div *ngIf="clientId">
                <app-datalist-select
                  [options]="locationsOptions"
                  [multiple]="false"
                  class="w-100 ms-5"
                  label="Select Location"
                  [(activeValue)]="editForm.value.companyLocationId"
                  (activeValueChange)="
                    updateValue($event, 'companyLocationId', 'room')
                  "
                >
                </app-datalist-select>
              </div>
              <div *ngIf="!clientId" class="form-floating">
                <div class="inpt-title" for="location">Select Location</div>
                <input
                  class="inpt"
                  style="height: 40px"
                  placeholder="Please set Client"
                  disabled
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div
            class="form-group form-floating"
            [appValidateState]="editForm.get('name')"
          >
            <div class="inpt-title">Name</div>
            <input
              [appValidateState]="editForm.get('name')"
              id="name"
              formControlName="name"
              class="inpt"
              placeholder="Name"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="form-group form-floating h-100 pb-3 d-grid">
          <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
            Save
          </button>
        </div>
      </form>
    </div>
    <app-error-alert
      *ngIf="submitErrors && submitErrors.length"
      title="Could not submit the post"
      i18n-title="Message post submit error alert"
      [errors]="submitErrors"
    ></app-error-alert>
  </ng-template>
</div>
