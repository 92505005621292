import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { CalendarComponent } from '../modules/calendar/calendar/calendar.component';
import { EventEditComponent } from '../modules/calendar/event-edit/event-edit.component';
import { EventPostComponent } from '../modules/calendar/event-post/event-post.component';
import { UserRoles } from '../state/auth.repository';
import { EventSharingComponent } from '../modules/calendar/event-sharing/event-sharing.component';
import { EventAdminComponent } from '../modules/calendar/event-admin/event-admin.component';
import { EventInfoComponent } from '../modules/calendar/event-info/event-info.component';
import { TenantFeatures } from '../state/feature.repository';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'events',
        component: CalendarComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Calendar,
        },
      },

      {
        path: 'admin/events/new',
        component: EventPostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Calendar,
        },
      },
      {
        path: 'admin/events/:id',
        component: EventEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Calendar,
        },
      },
      {
        path: 'events/event-sharing',
        component: EventSharingComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Calendar,
        },
      },
      {
        path: 'admin/events',
        component: EventAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Calendar,
        },
      },
      {
        path: 'events/:id',
        component: EventInfoComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Calendar,
        },
      },
    ],
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    canActivateChild: [AuthGuard],

    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CalendarRoutingModule {}
