<h2 class="mb-4" *ngIf="type === 'new'" i18n="Create tenant label">
  Create Room
</h2>
<h2 class="mb-4" *ngIf="type !== 'new'" i18n="Edit tenant label">Edit Room</h2>
<div *ngIf="type === 'new' || room" class="row mb-4">
  <div class="col-12">
    <app-rooms-editform
      [editedRoom]="room"
      (RoomSubmit)="createRoom($event)"
    ></app-rooms-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the room"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
