import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';

import { IClientOption } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { ILocationOption } from 'src/app/state/locations.repository';
import { LocationsService } from 'src/app/state/locations.service';
import { ITicket } from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';
import { User, UserOption } from 'src/app/state/users.repository';

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss'],
})
export class TicketsListComponent implements OnInit {
  constructor(
    public locService: LocationsService,
    public clientsService: CompaniesService,
    public ticketService: TicketsService
  ) {}
  @ViewChild('filterCollapse', { static: false }) accordion: ElementRef | null =
    null;
  @Input() displayName: string = 'Opened Tickets';
  @Input() selectedTicketId: string | null = null;
  @Output() selectedTicketIdChange = new EventEmitter<string>();
  @Input() set tickets(value: ITicket[]) {
    this.allTickets = value;
    this.search();
  }
  @Input() set clients(value: IClientOption[] | null) {
    if (!value) {
      this.clientsOptions = null;
    } else {
      this.clientsOptions = value.map((x) => ({
        value: x.id,
        label: `${x.clientCode} - ${x.title}`,
      }));
    }
  }
  @Input() set locations(value: ILocationOption[] | null) {
    if (!value) {
      this.locationsOptions = null;
    } else {
      this.locationsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }

  @Input() set creators(value: UserOption[] | null) {
    if (!value) {
      this.creatorOptions = null;
    } else {
      this.creatorOptions = value.map((x) => ({
        value: x.id,
        label: x.userName,
      }));
    }
  }

  @Input() set assignees(value: UserOption[] | null) {
    if (!value) {
      this.assigneeOptions = null;
    } else {
      this.assigneeOptions = value.map((x) => ({
        value: x.id,
        label: x.userName,
      }));
    }
  }
  @Output() selectTicket = new EventEmitter<string>();
  reload() {
    this.selectedTicketId = null;
  }

  ngOnInit(): void {
    this.reloadFilterOptions();
  }

  reloadFilterOptions() {
    this.clientsService
      .loaOptFromTickets()
      .subscribe((x) => (this.clients = x));
    this.locService.loadOptions().subscribe((x) => (this.locations = x));
    this.ticketService
      .loadAssigneesOptions()
      .subscribe((x) => (this.assignees = x));
    this.ticketService
      .loadCreatorsOptions()
      .subscribe((x) => (this.creators = x));
  }
  clientsOptions: DatalistOption[] | null = null;
  locationsOptions: DatalistOption[] | null = null;
  assigneeOptions: DatalistOption[] | null = null;
  creatorOptions: DatalistOption[] | null = null;

  showFilter = false;
  filterClient: string | null = null;
  filterLocation: string | null = null;
  filterCreator: string | null = null;
  filterAssignee: string | null = null;

  filteredTickets: ITicket[] = [];
  allTickets: ITicket[] = [];

  // defaultImg =
  //   'https://static.vecteezy.com/system/resources/previews/012/027/723/non_2x/admit-one-ticket-icon-black-and-white-isolated-wite-free-vector.jpg';
  searchControl: FormControl = new FormControl();

  search() {
    let search = this.searchControl.value ?? '';
    this.showFilter =
      this.accordion?.nativeElement?.classList?.contains('show');
    if (this.showFilter) {
      let filterClient = this.filterClient ?? '';
      let filterLocation = this.filterLocation ?? '';
      let filterCreator = this.filterCreator ?? '';
      let filterAssignee = this.filterAssignee ?? '';

      this.filteredTickets =
        this.allTickets.filter(
          (x) =>
            x.formatedTopic.includes(search) &&
            (filterClient ? x.clientId == filterClient : true) &&
            (filterLocation ? x.locationIds?.includes(filterLocation) : true) &&
            (filterCreator ? x.createdById == filterCreator : true) &&
            (filterAssignee ? x.userId == filterAssignee : true)
        ) ?? [];
    } else
      this.filteredTickets =
        this.allTickets.filter((x) => x.formatedTopic?.includes(search)) ?? [];
  }
  clearSearch() {
    this.searchControl.setValue('');
    this.search();
  }
  select(id: string) {
    if (this.selectedTicketId != id) {
      this.selectedTicketId = id;
      this.selectTicket.emit(id);
    }
  }
}
