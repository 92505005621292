import { Component, OnInit, ViewChild } from '@angular/core';
import { EditTicketFormComponent } from '../../components/edit-ticket-form/edit-ticket-form.component';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IEventSchedule } from 'src/app/state/event-schedule.repository';
import { Observable } from 'rxjs';
import { ITicket } from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';

@Component({
  selector: 'app-prefill-ticket-es',
  templateUrl: './prefill-ticket-es.component.html',
  styleUrls: ['./prefill-ticket-es.component.scss']
})
export class PrefillTicketEsComponent {
  @ViewChild(EditTicketFormComponent)
  ticketForm: EditTicketFormComponent | null = null;
  id: string | null = null;

  constructor(
    public eventService: EventScheduleService,
    private route: ActivatedRoute,
    private router: Router,
    public service: TicketsService,

  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id') ?? null;
        if (this.id) {
          this.eventService.loadOneEventSchedule(this.id).subscribe({

            error: () => { this.router.navigateByUrl("pagenotfound") },
            next: (x) => { this.prefillData(x) },
          });
        }
      },
    });
  }
  prefillData(eve: IEventSchedule) {
    if (this.ticketForm) {
      if (eve.clientId) {
        this.ticketForm!.updateClient('clientId', eve.clientId)
      }
      if (eve.id)
        this.ticketForm!.updateValue("eventIds", [eve.id])
    }
  }
  submitErrors: string[] | null = null;

  createTicket(event: Partial<ITicket>) {
    let updateResult$: Observable<ITicket> | undefined;
    this.submitErrors = null;

    updateResult$ = this.service.postTicket(event);

    if (updateResult$) {
      updateResult$.subscribe({
        next: () => {
          this.locationBack();
          this.service.getTicketsCount().subscribe();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }

  locationBack(goUrl = '/tickets/opened') {
    this.router.navigate([goUrl]);
  }
}
