import { DOCUMENT, NgForOf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IEvent } from 'src/app/state/event.repository';
import { EventsRepository } from 'src/app/state/event.repository';
import { EventService } from 'src/app/state/event.service';
declare var ClassicEditor: any;
import { AuthRepository } from 'src/app/state/auth.repository';
import { User } from 'src/app/state/users.repository';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  AbstractControl,
  UntypedFormBuilder,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  editor: any;
  UsersOptions: DatalistOption[] | null = null;
  formatedDate?: string;
  TitleError: boolean | null = null;

  DateError: boolean | null = null;
  @Input() editedEvent?: IEvent | null;
  @Input() set users(value: User[] | null) {
    if (!value) {
      this.UsersOptions = null;
    } else {
      this.UsersOptions = value.map((x) => ({
        value: x.id,
        imagePath: x.imagePath,
        label: x.name,
      }));
    }
  }
  @Output() eventsSubmit = new EventEmitter<Partial<IEvent>>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    private repo: EventsRepository,
    private service: EventService,
    public authRepo: AuthRepository
  ) {}

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      title: [this.editedEvent?.title, Validators.required],
      editor: this.editedEvent?.description,
      eventDate: this.editedEvent?.eventDate,
      formatedDate: [
        this.editedEvent?.formatedDateForInput,
        Validators.required,
      ],
      applicationUsersIds: [
        this.editedEvent?.applicationUsers?.map((x) => x.id),
      ],
    });
    this.service.getUsers().subscribe((x) => {
      this.users = x;
    });
  }

  ngAfterViewInit() {
    if (typeof ClassicEditor !== 'function') {
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/assets/js/ckeditor.js';
      this.document.body.appendChild(script);
      script.onload = () => this.initEditor();
    } else {
      this.initEditor();
    }
  }

  private initEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      if (this.editedEvent?.description) {
        newEditor.setData(this.editedEvent?.description);
      }
      this.editor = newEditor;
    });
  }

  handleValidation() {
    if (!this.editForm.value?.formatedDate) {
      this.DateError = true;
    } else this.DateError = false;
    if (!this.editForm.value?.title) {
      this.TitleError = true;
    } else this.TitleError = false;
  }

  onSubmit() {
    this.handleValidation();
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const title = this.editForm.value?.title.toString();
    const description = this.editor.getData();
    const eventDate = this.editForm.value?.formatedDate;
    const applicationUsersIds = this.editForm.value?.applicationUsersIds;
    this.eventsSubmit.emit({
      title,
      description,
      eventDate,
      applicationUsersIds,
    });
  }
}
