import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  IDocument,
  DocumentsRepository,
} from '../../../../state/document.repository';
import { combineLatest, map } from 'rxjs';

import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from '../../../../state/document.service';
import { Location } from '@angular/common';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import {
  FoldersRepository,
  IFolder,
} from '../../../../state/folders.repository';
import { FoldersService } from '../../../../state/folders.service';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
@Component({
  selector: 'app-documenttype-from-SideMenu',
  templateUrl: './documenttype-from-SideMenu.component.html',
  styleUrls: ['./documenttype-from-SideMenu.component.scss'],
})
export class DocumenttypeFromSideMenuComponent implements OnInit {
  innerWidth = 0;
  documents: IDocument[] | null = null;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private repo: DocumentsRepository,
    private service: DocumentsService,
    public documentypesRepo: DocumentTypesRepository,
    public documenttypesService: DocumentTypesService
  ) {}

  id: string | null = null;

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        if (this.id) {
          this.loadDocuments(this.id);
        }
      },
    });
  }

  loadDocuments(id: string) {
    this.service.getAllFrom(id).subscribe((x) => {
      this.documents = x;
    });
  }
}
