import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Post,
  IComments,
  PostsRepository,
  skipWhilePostsCached,
  trackPostRequestsStatus,
} from './posts.repository';
import { switchMap, tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

const API = '/api/posts';
const APIComment = '/api/comment';

@Injectable({ providedIn: 'root' })
export class PostsService extends BaseService<Post> {
  constructor(http: HttpClient, repo: PostsRepository) {
    super(API, http, repo);
  }

  load() {
    return this.http.get<PaginationData & { data: Post[] }>(API);
  }

  AddComment(comment: IComments) {
    return this.http.post<IComments>(APIComment, comment);
  }

  loadAll(): Observable<Post[]> {
    return this.http.get<Post[]>(API + '/all').pipe(
      tap((posts) => {
        this.repo.clear();
        this.repo.set(posts);
      })
    );
  }

  loadOne(id: string) {
    return this.http.put<Post>(`${API}/${id}`, {});
  }

  add(post: Partial<Post>) {
    const formData = new FormData();
    formData.append('file', post.documentInserted!);
    return this.http.post<Post>(`/api/posts`, post).pipe(
      switchMap((post) =>
        this.http.post<Post>(`${API}/${post.id}/file`, formData)
      ),
      trackPostRequestsStatus(`${this.repo.name}_add`)
    );
  }

  update(id: string, post: Partial<Post>) {
    const formData = new FormData();
    formData.append('file', post.documentInserted!);
    return this.http.put<Post>(`/api/posts/edit/${id}`, post).pipe(
      switchMap((post) =>
        this.http.post<Post>(`${API}/${post.id}/file`, formData)
      ),
      trackPostRequestsStatus(`${this.repo.name}_add`)
    );
  }
}
