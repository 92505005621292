<div class="modal modal-md fade" id="attachDocsModal" tabindex="-1" aria-labelledby="attachDocumentsLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="attachDocumentsLabel">
          Attach Documents
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body customScroll d-flex w-100">
        <div *ngIf="!isLoading; else loaderBlock" class="w-100">
          <button class="btn btn-success" (click)="attachFileInput?.click()">
            Attach Document
          </button>
          <input #attachFileInput style="display: none" type="file" (change)="addDoc($event)"
            (click)="attachFileInput.value = ''" />

          <div class="w-100 attachSroll">
            <div class="w-100 d-flex justify-content-between align-items-center my-2"
              *ngFor="let doc of localDocs; let i = index">
              <div class="w-100 elipsesOverflow">
                <span>{{ i+1 }}:</span> {{ doc?.docName }}
              </div>
              <div *ngIf="doc.ticketCommentId; else notSavedDoc" class="bg-success h30w30 me-2 infobrdr iconBtn "
                [title]="'This file has been saved'">
                <img class="whiteColorFilter " src="assets/img/check/check.svg" />
              </div>
              <ng-template #notSavedDoc>
                <div class="bg-secondary h30w30 me-2 infobrdr iconBtn" [title]="'This file will be saved once comment posted'">

                  <img class="whiteColorFilter " src="assets/img/info/infoCircle.svg" />
                </div>
              </ng-template>
              <button class="btn btn-small btn-danger h30w30 me-2" (click)="deleteDoc(i)">
                <img class="whiteColorFilter" src="assets/img/delete18x20.svg" />
              </button>
            </div>
          </div>
        </div>

        <ng-template #loaderBlock>
          <app-spinner></app-spinner>
        </ng-template>
      </div>

      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-success" (click)="onSubmit()">
          Submit
        </button> -->
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #attachCloseButton>
          Close
        </button>
      </div>
    </div>
  </div>
</div>