<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
  autocomplete="off"
>
  <div class="form-group mb-3 row p-0">
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('firstName')"
    >
      <div class="inpt-title" for="firstName" i18n="@@firstName">
        First Name
      </div>
      <input
        id="firstName"
        formControlName="firstName"
        type="text"
        class="inpt"
        placeholder="First Name"
        i18n-placeholder="@@firstName"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('lastName')"
    >
      <div class="inpt-title" for="LastName" i18n="@@lastName">Last Name</div>
      <input
        id="lastName"
        formControlName="lastName"
        type="text"
        class="inpt"
        placeholder="Last Name"
        i18n-placeholder="@@lastName"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('timeStamp')"
    >
      <div class="inpt-title" for="timeStamp" i18n="@@timeStamp">
        Time Stamp
      </div>
      <input
        id="timeStamp"
        formControlName="timeStamp"
        type="text"
        class="inpt"
        placeholder="Time Stamp"
        i18n-placeholder="@@timeStamp"
        autocomplete="off"
      />
    </div>
    <div class="form-floating mb-3" [appValidateState]="editForm.get('email')">
      <div class="inpt-title" for="email" i18n="@@email">Email</div>
      <input
        id="email"
        formControlName="email"
        type="email"
        class="inpt"
        placeholder="Email"
        i18n-placeholder="@@email"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('phoneNumber')"
    >
      <div class="inpt-title" for="phoneNumber" i18n="@@phoneNumber">
        Phone Number
      </div>
      <input
        id="phoneNumber"
        formControlName="phoneNumber"
        type="text"
        class="inpt"
        placeholder="Phone Number"
        i18n-placeholder="@@phoneNumber"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('spouceGuest')"
    >
      <div class="inpt-title" for="spouceGuest" i18n="@@spouceGuest">
        Spouce / Guest
      </div>
      <input
        class="form-check-input"
        id="spouceGuest"
        formControlName="spouceGuest"
        type="checkbox"
      />
    </div>
    <div class="form-floating mb-3" [appValidateState]="editForm.get('show')">
      <div class="inpt-title" for="show" i18n="@@show">Show</div>
      <input
        class="form-check-input"
        id="show"
        formControlName="show"
        type="checkbox"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('callStatus')"
    >
      <div class="inpt-title" for="callStatus" i18n="@@callStatus">
        Call Status
      </div>
      <input
        id="callStatus"
        formControlName="callStatus"
        type="callStatus"
        class="inpt"
        placeholder="Call Status"
        i18n-placeholder="@@callStatus"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('callStatus2')"
    >
      <div class="inpt-title" for="callStatus2" i18n="@@callStatus2">
        Call Status 2
      </div>
      <input
        id="callStatus2"
        formControlName="callStatus2"
        type="callStatus2"
        class="inpt"
        placeholder="Call Status 2"
        i18n-placeholder="@@callStatus2"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('callStatus3')"
    >
      <div class="inpt-title" for="callStatus3" i18n="@@callStatus3">
        Call Status 3
      </div>
      <input
        id="callStatus3"
        formControlName="callStatus3"
        type="callStatus3"
        class="inpt"
        placeholder="Call Status 3"
        i18n-placeholder="@@callStatus3"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('callStatus4')"
    >
      <div class="inpt-title" for="callStatus4" i18n="@@callStatus4">
        Call Status 4
      </div>
      <input
        id="callStatus4"
        formControlName="callStatus4"
        type="callStatus4"
        class="inpt"
        placeholder="Call Status 4"
        i18n-placeholder="@@callStatus4"
        autocomplete="off"
      />
    </div>

    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('confirmed')"
    >
      <app-datalist-select
        class=""
        [activeValue]="editForm?.value.confirmed"
        [options]="ConfirmedOptions"
        (activeValueChange)="updateValue('confirmed', $event)"
        [multiple]="false"
        label="Confirmed"
        i18n-label="Confirmed"
      ></app-datalist-select>
    </div>
    <div class="form-group form-floating h-100 pb-3 d-grid">
      <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
        Save
      </button>
    </div>
  </div>
</form>
