import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  IClientOption,
  ICompanyMarker,
} from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import {
  IEventOption,
  IEventSchedule,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { LocationsService } from 'src/app/state/locations.service';
import {
  CloseReason,
  CloseReasonDesc,
  ITicket,
  TicketStatus,
} from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';
import { UserOption } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';

@Component({
  selector: 'app-edit-ticket-form',
  templateUrl: './edit-ticket-form.component.html',
  styleUrls: ['./edit-ticket-form.component.scss'],
})
export class EditTicketFormComponent {
  editForm: UntypedFormBuilder | any;
  @Input() editedTicket?: ITicket | null;
  clientsOptions: DatalistOption[] | null = null;
  usersOptions: DatalistOption[] | null = null;
  stat = TicketStatus;
  reason = CloseReasonDesc;
  isLoadingStatus = false;
  notValidClient_Title = 'Please select Client to select upcoming Event';

  @Input() set clients(value: IClientOption[] | null) {
    if (!value) {
      this.clientsOptions = null;
    } else {
      this.clientsOptions = value.map((x) => ({
        value: x.id,
        label: `${x.clientCode} - ${x.title}`,
      }));
    }
  }
  @Input() set users(value: UserOption[] | null) {
    if (!value) {
      this.usersOptions = null;
    } else {
      this.usersOptions = value.map((x) => ({
        value: x.id,
        label: x.userName,
      }));
    }
  }
  eventsOptions: DatalistOption[] | null = null;
  @Input() set events(value: IEventOption[] | null) {
    if (!value) {
      this.eventsOptions = null;
    } else {
      this.eventsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }
  submitErrors: string[] | null = null;
  @Output() ticketSubmit = new EventEmitter<Partial<ITicket>>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public clientService: CompaniesService,
    public userService: UsersService,
    public eventService: EventScheduleService,
    public ticketService: TicketsService,
    public authRepo: AuthRepository
  ) {}

  ngOnInit(): void {
    this.clientService.loadOptions().subscribe((x) => {
      this.clients = x;
    });
    this.userService.loadOptAdmins().subscribe((x) => {
      this.users = x;
    });
    if (this.editedTicket?.clientId)
      this.eventService
        .loadOptions([this.editedTicket?.clientId])
        .subscribe((x) => (this.events = x));

    this.editForm = this.formBuilder.group({
      topic: [this.editedTicket?.topic, Validators.required],
      clientId: [this.editedTicket?.clientId],
      eventIds: [this.editedTicket?.eventIds],
      userId: [this.editedTicket?.userId],
      description: [this.editedTicket?.description],
      assignorId: [this.editedTicket?.assignorId],
      formatedAssignor: [this.editedTicket?.formatedAssignor],
      isUrgent: [this.editedTicket?.isUrgent ?? false],
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.ticketSubmit.emit({
      id: this.editedTicket?.id?.toString(),
      status: this.editedTicket?.status ?? 0,
      topic: this.editForm.value?.topic?.toString(),
      closedBy: this.editForm.value?.closedBy?.toString(),
      clientId: this.editForm.value?.clientId,
      eventIds: this.editForm.value?.eventIds ?? [],
      userId: this.editForm.value?.userId,
      description: this.editForm.value?.description?.toString(),
      isUrgent: this.editForm.value?.isUrgent ?? false,
      closeReason: this.editForm.value?.closeReason?.toString(),
      assignorId: this.editForm.value?.assignorId?.toString(),
    });
  }

  updateClient(control: string, value: any) {
    this.updateValue(control, value);
    let clientIds = [value] ?? [];
    const eventsObject = this.editForm?.get('eventIds');
    eventsObject?.setValue(null);
    this.eventService.loadOptions(clientIds).subscribe((x) => {
      this.events = x;
    });
  }

  updateAssignee(control: string, value: any) {
    let id = this.authRepo.getId();
    if (id) {
      let user = this.usersOptions?.find((x) => x.value == id);
      if (user) {
        this.updateValue(control, value);
        if (value) {
          this.updateValue('assignorId', user.value);
          this.updateValue('formatedAssignor', user.label);
        } else {
          this.updateValue('assignorId', null);
          this.updateValue('formatedAssignor', null);
        }
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  changeTicketStatus(
    ticketStatus: TicketStatus = 0,
    ticketReason?: CloseReason
  ) {
    if (this.editedTicket?.id) {
      this.isLoadingStatus = true;
      this.ticketService
        .changeTicketStatus(this.editedTicket.id, ticketStatus, ticketReason)
        .subscribe({
          complete: () => {
            this.isLoadingStatus = false;
          },
          next: (x) => {
            this.editedTicket!.status = x.status;
            this.editedTicket!.closedBy = x.closedBy;
            this.editedTicket!.closedAtFormated = x.closedAtFormated;
            this.editedTicket!.closedByName = x.closedByName;
            this.editedTicket!.closedAt = x.closedAt;
            this.editedTicket!.closeReason = x.closeReason;
            this.editedTicket!.formatedCloseReason = x.formatedCloseReason;
            this.editedTicket!.formatedStatus = x.formatedStatus;

            this.ticketService.getTicketsCount().subscribe();
          },
          error: () => {
            this.isLoadingStatus = false;
          },
        });
    }
  }
}
