import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import {
  AuthRepository,
  Office365Settings,
  TfaSetup,
} from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  errorMessage: string | null = null;
  testsLocation = 'localhost:9876';
  settings$: Observable<Office365Settings> | null = null;
  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
    rememberMe: false,
  });
  isTfaProccess: boolean = false;
  activeGtfa?: TfaSetup;
  private returnUrl$: Observable<string>;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public env: EnvState
  ) {
    this.returnUrl$ = this.route.queryParamMap.pipe(
      map((params) => params.get('returnUrl') || '/')
    );
  }

  ngOnInit(): void {
    this.repo.isAuthenticated$
      .pipe(
        filter((x) => x),
        take(1),
        withLatestFrom(this.returnUrl$)
      )
      .subscribe(([_, returnUrl]) => this.router.navigateByUrl(returnUrl));
  }

  private renderError(err: any) {
    this.errorMessage = err.error;
  }
  back() {
    this.isTfaProccess = false;
    this.activeGtfa = undefined;
  }
  login(): void {
    this.repo.setToken(null);
    this.auth
      .login(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.loginForm.value.rememberMe
      )
      .subscribe({
        next: (val) => {
          if (val && val.tfaSetup) {
            this.activeGtfa = val.tfaSetup;
            if (val.tfaSetup) {
              this.isTfaProccess = true;
            }
          }
        },
        error: (err) => this.renderError(err),
      });
  }
}
