<div class="box my-2">
  <div class="box-name-header">
    <div class="d-flex justify-content-between p-2">
      <div class="quater-col">
        <span class="txt mt-1">Month</span>
      </div>
      <div class="quater-col">
        <span class="txt mt-1"># of registrants</span>
      </div>
      <div class="quater-col">
        <span class="txt mt-1"># of attendees</span>
      </div>
      <div class="quater-col">
        <span class="txt mt-1">Averrage attendance %</span>
      </div>
    </div>
  </div>

  <div *ngFor="let month of statistics; let curentMonthIndex = index" class=" ">
    <div
      class="d-flex justify-content-between p-2"
      [class.b-top-gray]="
        statistics.length &&
        curentMonthIndex !== 0 &&
        curentMonthIndex !== statistics.length
      "
    >
      <div class="txt quater-col">
        {{ month.name }}
      </div>

      <div class="txt quater-col">{{ month.registrants }}</div>
      <div class="txt quater-col">{{ month.attendees }}</div>
      <div class="txt quater-col">
        {{ (month.attendance * 100).toFixed(2) }}
      </div>
    </div>
  </div>
</div>
