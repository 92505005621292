import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IActionPopEmitter,
  ICalcFinancesEmitter,
  IChangePayStatsEmitter,
  IDeleteConfirmation,
} from 'src/app/state/emitters/emiters-interfaces';
import { IEventSchedule } from 'src/app/state/event-schedule.repository';

@Component({
  selector: 'app-es-finance-double-row',
  templateUrl: './es-finance-double-row.component.html',
  styleUrls: ['./es-finance-double-row.component.scss'],
})
export class EsFinanceDoubleRowComponent {
  @Input() paddStart = 0;
  @Input() event: any | null = null;
  @Input() curentMonthIndex: number = 0;
  @Input() eventIndx: number = 0;
  @Input() mainParentId: string = '';

  @Output() showModal = new EventEmitter<string>();
  @Output() calcFinances = new EventEmitter<IEventSchedule>();
  @Output() deleteConfirmation = new EventEmitter<IDeleteConfirmation>();
  @Output() enterPayStats = new EventEmitter<IChangePayStatsEmitter>();
  @Output() actionPop = new EventEmitter<IActionPopEmitter>();

  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }
  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  mouseEnterPayStats(event: any, esMainId: string, mainParentId: string) {
    if (esMainId) {
      let payStatsLeft = Math.max(event.pageX - 10, 0);
      let payStatsTop = Math.max(event.pageY - 10, 0);
      this.enterPayStats.emit({
        id: esMainId,
        payStatsLeft: payStatsLeft,
        payStatsTop: payStatsTop,
        parentId: mainParentId,
      });
    }
  }
  calcFinance(event: IEventSchedule) {
    this.calcFinances.emit(event);
  }
}
