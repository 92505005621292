<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">
      <div class="inpt-title">Title</div>
      <input
        id="title"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="Title"
        i18n-placeholder="@@title"
        autocomplete="off"
      />
    </div>
    <div>
      <div class="inpt-title">Description</div>
      <textarea
        maxlength="1000"
        id="editor"
        formControlName="editor"
        type="text"
        class="form-control"
        placeholder="Description"
        i18n-placeholder="Label description"
        autocomplete="off"
      >
      </textarea>
    </div>
    <div
      [appValidateState]="editForm.get('formatedDate')"
      class="form-floating mt-3"
    >
      <input
        id="eventDate"
        formControlName="formatedDate"
        type="date"
        class="inpt"
      />
    </div>

    <div class="mt-3" *ngIf="authRepo.isAnyAdmin$ | async">
      <app-users-datalist-select
        id="usersSelect"
        [options]="UsersOptions"
        [multiple]="true"
        class=" "
        label="Users:"
        [(activeValue)]="editForm?.value.applicationUsersIds"
        (activeValueChange)="updateValue('applicationUsersIds', $event)"
      >
      </app-users-datalist-select>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button
      type="submit"
      class="btn btn-wfrBlue shadow-sm"
      i18n="@@save"
      (click)="(handleValidation)"
    >
      Save
    </button>
  </div>
</form>
