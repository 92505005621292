<div class="row justify-content-center ms-1 me-1 mt-md-5">
  <div class="col-12 col-lg-8 apvcard">
    <div class="col-12 row pt-4 pb-4">
      <div
        class="col-12 col-lg-2 col-xl-1 col-sm-2 pb-2 pb-md-0 d-flex justify-content-sm-end"
      >
        <div
          class="mt-1 icon rounded-circle d-flex align-items-center justify-content-center"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 12L11 14L15.5 9.5M17.9012 4.99851C18.1071 5.49653 18.5024 5.8924 19.0001 6.09907L20.7452 6.82198C21.2433 7.02828 21.639 7.42399 21.8453 7.92206C22.0516 8.42012 22.0516 8.97974 21.8453 9.47781L21.1229 11.2218C20.9165 11.7201 20.9162 12.2803 21.1236 12.7783L21.8447 14.5218C21.9469 14.7685 21.9996 15.0329 21.9996 15.2999C21.9997 15.567 21.9471 15.8314 21.8449 16.0781C21.7427 16.3249 21.5929 16.549 21.4041 16.7378C21.2152 16.9266 20.991 17.0764 20.7443 17.1785L19.0003 17.9009C18.5023 18.1068 18.1065 18.5021 17.8998 18.9998L17.1769 20.745C16.9706 21.2431 16.575 21.6388 16.0769 21.8451C15.5789 22.0514 15.0193 22.0514 14.5212 21.8451L12.7773 21.1227C12.2792 20.9169 11.7198 20.9173 11.2221 21.1239L9.47689 21.8458C8.97912 22.0516 8.42001 22.0514 7.92237 21.8453C7.42473 21.6391 7.02925 21.2439 6.82281 20.7464L6.09972 19.0006C5.8938 18.5026 5.49854 18.1067 5.00085 17.9L3.25566 17.1771C2.75783 16.9709 2.36226 16.5754 2.15588 16.0777C1.94951 15.5799 1.94923 15.0205 2.1551 14.5225L2.87746 12.7786C3.08325 12.2805 3.08283 11.7211 2.8763 11.2233L2.15497 9.47678C2.0527 9.2301 2.00004 8.96568 2 8.69863C1.99996 8.43159 2.05253 8.16715 2.15472 7.92043C2.25691 7.67372 2.40671 7.44955 2.59557 7.26075C2.78442 7.07195 3.00862 6.92222 3.25537 6.8201L4.9993 6.09772C5.49687 5.89197 5.89248 5.4972 6.0993 5.00006L6.82218 3.25481C7.02848 2.75674 7.42418 2.36103 7.92222 2.15473C8.42027 1.94842 8.97987 1.94842 9.47791 2.15473L11.2218 2.87712C11.7199 3.08291 12.2793 3.08249 12.7771 2.87595L14.523 2.15585C15.0209 1.94966 15.5804 1.9497 16.0784 2.15597C16.5763 2.36223 16.972 2.75783 17.1783 3.25576L17.9014 5.00153L17.9012 4.99851Z"
              stroke="#0B5087"
              stroke-width="2.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div class="l col-12 col-lg-9 col-xl-10 col-sm-9">
        <div class="col-12 title">
          {{ editedAPV?.title }}
        </div>
        <div class="col-12 description">
          {{ editedAPV?.description }}
        </div>

        <form
          class="mt-sm-5 mt-3"
          [formGroup]="ApvForm"
          (ngSubmit)="onSubmit()"
          enctype="multipart/form-data"
        >
          <div class="" formArrayName="apvQuestions">
            <div *ngFor="let item of getQuestions(); let i = index">
              <hr />
              <div class="mt-sm-4 mt-lg-5 mt-3 row">
                <small class="qsttitletext" i18n="Basic info label"
                  >Question {{ i + 1 }}</small
                >
              </div>
              <div class="row mb-2">
                <div [formGroupName]="i" class="col-12">
                  <div class="pb-1 text-break question">
                    {{ item.value.title }}
                  </div>

                  <div id="i" class="form-group form-floating mb-3">
                    <input
                      formControlName="answer"
                      type="radio"
                      class="me-1"
                      [value]="true"
                    />Yes
                    <input
                      class="ms-3 me-1"
                      formControlName="answer"
                      type="radio"
                      [value]="false"
                    />No
                    <div
                      *ngIf="
                        item.get('answer')?.hasError('required') &&
                        item.get('answer')?.touched
                      "
                    >
                      <small class="error">Please select either answer</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="smhr" />
            <div class="form-group form-floating pb-3 mt-4 d-grid ms-sm-0 ms-3">
              <button
                type="submit"
                class="btn btn-primary"
                i18n="@@saveMyAnswer"
              >
                Save my answer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
