<div *ngIf="post">
  <div class="main-container container form-control">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-8 mx-auto">
        <div class="col-auto text-center align-self-center">
          <h1 class="text-break">{{ post.title }}</h1>
        </div>
        <h6 class="text-center align-self-center small text-muted">
          <i class="bi bi-clock">{{ post.eventDate | date : "dd-MM-yyyy" }}</i>
        </h6>
        <br />
        <p class="text-break" [innerHTML]="post.description"></p>

        <div *ngIf="post.filePath">
          User Attached a File:
          <a href="{{ post.filePath }}" target="_blank"
            ><input type="button" value="Open File"
          /></a>
        </div>
      </div>
    </div>
  </div>
</div>
