<div
  class="modal fade"
  id="eventSnapshotModal"
  tabindex="-1"
  aria-labelledby="eventSnapshotModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <div
          class="d-flex justify-content-center align-items-center BlowModalIcon"
        >
          <svg
            width="22"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              s
            />
          </svg>
        </div>

        <h5 class="modal-title fs-5 w-75" id="eventSnapshotModalLabel">
          Event Schedule
        </h5>
        <div class="w-25 d-flex align-items-center pe-5">
          <div class="w-100 d-flex justify-content-end add pe-5">
            <a
              [routerLink]="[
                '/admin/eventSchedule/eventSchedule/',
                eventScheduleOnPopUp?.id
              ]"
              data-bs-dismiss="modal"
              aria-label="Close"
              class="d-flex justify-content-center btn btn-lg dark-wfrBlue text-white"
              style="justify-content: end; width: 140px !important"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="text-btn-add">Edit Event</span>
            </a>
          </div>
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="modal-body customScroll d-flex modal-body-height hide-scrollbar"
      >
        <div
          class="w-100 p-1"
          *ngIf="!isLoading && eventScheduleOnPopUp; else loaderBlock"
        >
          <form enctype="multipart/form-data">
            <app-event-pop-up
              [eventScheduleOnPopUp]="eventScheduleOnPopUp"
            ></app-event-pop-up>
          </form>
        </div>
        <ng-template #loaderBlock>
          <app-spinner></app-spinner>
        </ng-template>
      </div>
    </div>
  </div>
</div>
