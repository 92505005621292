import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import {
  IMark,
  IProject,
  ITask,
  daysTotal,
  projectTasksTotal,
  TimelogsRepository,
} from './timelogs.repository';

const API = '/api/timelogs';

@Injectable({ providedIn: 'root' })
export class TimelogsService extends BaseService<IProject> {
  constructor(http: HttpClient, repo: TimelogsRepository) {
    super(API, http, repo);
  }

  loadAll() {
    TimelogsRepository;
    return this.http.get<IProject[]>(API + '/getprojects');
  }
  // [deprecated]
  loadPersonalProjects() {
    return this.http.get<any[]>(API + '/getpersonalprojects');
  }
  loadPersonalProjectsWithMarksForWeek(start_date: string, end_date: string) {
    return this.http.get<IProject[]>(
      API + `/getPersonalProjectsWithMarksForWeek/${start_date}/${end_date}`
    );
  }
  loadPersonalTasks(id: string) {
    return this.http.get<ITask[]>(API + `/getpersonaltasks/${id}`);
  }
  loadTasks() {
    return this.http.get<ITask[]>(API + `/gettasks`);
  }
  addProject(entity: Partial<IProject>) {
    return this.http.post<Partial<IProject>>(API + '/project', entity);
  }
  addMark(entity: Partial<IMark>) {
    return this.http.post<any>(API + '/mark', entity);
  }
  addTask(entity: Partial<ITask>) {
    return this.http.post<Partial<IProject>>(API + '/task', entity);
  }
  // [deprecated]
  getMarksForWeek(project_id: string, start_date: string, end_date: string) {
    return this.http.get<IMark[]>(
      API + `/getMarksForweek/${project_id}/${start_date}/${end_date}`
    );
  }
  deleteProject(ProjectId: string) {
    return this.http.delete<any>(API + `/project/${ProjectId}`);
  }
  deleteTask(ProjectId: string) {
    return this.http.delete<any>(API + `/task/${ProjectId}`);
  }
  updateProject(entity: Partial<IProject>) {
    return this.http.patch<Partial<IProject>>(API + '/updateProject', entity);
  }
  updateTask(entity: Partial<ITask>) {
    return this.http.patch<Partial<IProject>>(API + '/updateTask', entity);
  }
  loadOneProject(ProjectId: string) {
    return this.http.get<any>(API + `/loadOneProject/${ProjectId}`);
  }
  loadOneTask(ProjectId: string) {
    return this.http.get<any>(API + `/loadOneTask/${ProjectId}`);
  }
  loadTaskMarksForTeam(
    project_id: string,
    task_id: string,
    start_date: string,
    end_date: string
  ) {
    return this.http.get<IMark[]>(
      API +
        `/loadTaskMarksForTeam/${project_id}/${task_id}/${start_date}/${end_date}`
    );
  }
  loadTaskMarksForTeamHours(
    project_id: string,
    start_date: string,
    end_date: string
  ) {
    return this.http.get<IProject>(
      API +
        `/LoadProjectsWithTotalHours/${project_id}/${start_date}/${end_date}`
    );
  }
  totalByDayForPersonalProjects(start_date: string, end_date: string) {
    return this.http.get<daysTotal>(
      API + `/totalByDayForPersonalProjects/${start_date}/${end_date}`
    );
  }

  getTimeLogsOverviewUsers(start_date: string, end_date: string) {
    return this.http.get<daysTotal[]>(
      API + `/getTimeLogsOverviewUsers/${start_date}/${end_date}`
    );
  }
  getTimeLogsOverviewTasks(start_date: string, end_date: string) {
    return this.http.get<projectTasksTotal[]>(
      API + `/getTimeLogsOverviewTasks/${start_date}/${end_date}`
    );
  }
  getTimeLogsOverviewProjects(start_date: string, end_date: string) {
    return this.http.get<projectTasksTotal[]>(
      API + `/getTimeLogsOverviewProjects/${start_date}/${end_date}`
    );
  }
}
