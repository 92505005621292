<h4>Summuary</h4>
<div class="row">
  <div *ngIf="repo.activeAeSummary" class="col-6">
    <div class="box showScroll txt mb-3" style="overflow: hidden">
      <div class="d-flex justify-content-between p-2 box-name-header">
        <div><b>Total number of AE events</b></div>
        <div>
          <b>{{ repo.activeAeSummary?.eventsCount }}</b>
        </div>
      </div>

      <div>
        <div class="d-flex justify-content-between p-2 box-name-header">
          <div><b>- Excempt events</b></div>
        </div>

        <div class="d-flex justify-content-between p-2">
          <div class="ps-3">At cost (C)</div>
          <div>{{ repo.activeAeSummary?.atCost }}</div>
        </div>
        <div class="d-flex justify-content-between p-2">
          <div class="ps-3">At cost (C1-3)</div>
          <div>{{ repo.activeAeSummary?.atCostWithNums }}</div>
        </div>
        <div class="d-flex justify-content-between p-2">
          <div class="ps-3">Rescheduled</div>
          <div>{{ repo.activeAeSummary?.rescheduled }}</div>
        </div>

        <div class="d-flex justify-content-between p-2">
          <div class="ps-3">Canceled</div>
          <div>{{ repo.activeAeSummary?.cancelled }}</div>
        </div>

        <div class="d-flex justify-content-between p-2 b-top-gray">
          <div class="ps-3">= total number of exempt events</div>
          <div>{{ repo.activeAeSummary?.sumEvents }}</div>
        </div>
      </div>
      <div>
        <div class="p-2 box-name-header row">
          <th class="col-7">- Excempt offices</th>
          <th class="col-1 d-flex justify-content-end">C</th>
          <th class="col-1 d-flex justify-content-end">C1-3</th>
          <th class="col-1 d-flex justify-content-end">Resch.</th>
          <th class="col-1 d-flex justify-content-end">Canc.</th>
          <th class="col-1 d-flex justify-content-end">Total</th>
        </div>

        <div
          class="p-2 row"
          *ngFor="let excempt of repo.activeAeSummary?.exceptClients"
        >
          <div class="col-7">
            <span class="ps-3">{{ excempt.name }}</span>
          </div>
          <div class="col-1 d-flex justify-content-end">
            {{ excempt.atCost }}
          </div>
          <div class="col-1 d-flex justify-content-end">
            {{ excempt.atCostNums }}
          </div>
          <div class="col-1 d-flex justify-content-end">
            {{ excempt.rescheduled }}
          </div>
          <div class="col-1 d-flex justify-content-end">
            {{ excempt.cancelled }}
          </div>
          <div class="col-1 d-flex justify-content-end">
            {{ excempt.count }}
          </div>
        </div>
        <div class="d-flex justify-content-between p-2 b-top-gray">
          <div class="ps-3">= total number of exempt office events</div>
          <div>
            {{ repo.activeAeSummary?.sumClients }}
          </div>
        </div>
        <div class="d-flex justify-content-between p-2 box-name-header">
          <div><b>= Chargable events </b></div>
          <div>
            <b>{{ repo.activeAeSummary?.chargableEvents }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6" *ngIf="repo.activeBestPerforming">
    <div class="box showScroll txt mb-3" style="overflow: hidden">
      <div class="d-flex justify-content-between p-2 box-name-header">
        <div><b>Best performing events</b></div>
      </div>

      <div>
        <div class="d-flex justify-content-between p-2 box-name-header">
          <div><b>- Lowest cost per HH</b></div>
        </div>

        <div class="d-flex justify-content-between p-2">
          <div class="ps-3 txtTitle">
            {{
              repo.activeBestPerforming?.highestAttendanceEvent?.client
                ?.clientCode
            }}
            {{
              repo.activeBestPerforming?.lowestCostPerHH?.topic ??
                "No Event Found"
            }}
          </div>
          <div>
            {{ repo.activeBestPerforming?.lowestCostPerHH?.costLead ?? 0 }}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="d-flex justify-content-between p-2 box-name-header">
            <div><b>- Highest attendance rate</b></div>
          </div>

          <div class="d-flex justify-content-between p-2">
            <div class="ps-3 txtTitle">
              {{
                repo.activeBestPerforming?.highestAttendanceEvent?.client
                  ?.clientCode
              }}
              {{
                repo.activeBestPerforming?.highestAttendanceEvent?.topic ??
                  "No Event Found"
              }}
            </div>

            <div>
              {{ repo.activeBestPerforming?.highestAttendanceRate ?? 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
