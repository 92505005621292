<div
  class="bg-chat chat-section d-flex flex-column align-items-end"
  [class.closedNoClick]="closed"
>
  <div class="w-100 ticketTitle d-flex align-items-center ps-3">
    <div
      class="d-flex justify-content-center align-items-center ticketModalIcon"
    >
      <img
        class="imgSizing"
        src="../../../../../assets/img/tickets/ticket.svg"
        loading="lazy"
      />
    </div>
    <h5 class="p-0 m-0 ps-3 ticketHeader">
      {{ selectedTicket?.formatedTopic }}
    </h5>
  </div>
  <div #messageScroll class="w-100 messages-height reverse-comments">
    <div
      class="p-2 comment"
      *ngFor="let comment of selectedTicket?.comments; let comi = index"
      [class.myMessage]="comment.myMessage"
    >
      <div class="text-muted commentAuthor d-flex justify-content-between">
        <span class="elipsesOverflow">{{ comment.userName }}</span>
        <span
          class="date-sizing d-flex justify-content-end"
          *ngIf="comment.formatedCreatedAt"
        >
          <span *ngIf="comment.isEdited">(edited)</span>

          {{ comment.formatedCreatedAt }}</span
        >
      </div>
      <div
        (contextmenu)="onRightClick($event, comment.id, comment.myMessage)"
        class="bg-white b-radius p-2 commentText emojiFonts"
        [class.expanded]="comment.expanded"
      >
        <span>{{ comment.content }} </span>
      </div>
      <div *ngIf="comment.documents">
        <div *ngFor="let doc of comment.documents">
          <span
            (click)="downloadTicketDocument(doc.id)"
            class="docBtn elipsesOverflow d-flex align-items-center"
            title="Download"
          >
            <img
              src="../../../../../assets/img/download/download.svg"
              class="downloadImg"
            />
            {{ doc.docName }}</span
          >
        </div>
      </div>
    </div>
    <div class="w-100 p-3" *ngIf="hasMoreComments">
      <button class="btn btn-secondary w-100" (click)="loadMoreComms()">
        Load more
      </button>
    </div>
  </div>
  <div class="w-100 send-form">
    <form
      [formGroup]="sendForm"
      enctype="multipart/form-data"
      autocomplete="off"
      class="w-100 h-100 px-3 d-flex flex-column align-items-center justify-content-between"
    >
      <!-- (ngSubmit)="sendComment()" -->

      <div class="w-100 h-100 d-flex justify-content-around align-items-center">
        <div
          class="btn btn-attach p-0"
          title="Attach File"
          data-bs-toggle="modal"
          data-bs-target="#attachDocsModal"
        >
          <span class="topbadgePos filebadge rounded-pill bg-danger">
            {{ this.attachPop?.localDocs?.length ?? 0 }}
          </span>

          <img src="assets/img/clip/clip.svg" />
        </div>

        <div class="inpt-text-sizing">
          <span *ngIf="editedComment?.id">
            <small class="text-muted">You are now editing comment</small>
            <small
              class="ms-1 btn-danger px-1 stopEditing"
              (click)="stopEditing()"
              >Stop Editing</small
            >
          </span>

          <textarea
            #commentText
            matInput
            id="content"
            type="text"
            placeholder="Write Message .."
            autocomplete="off"
            formControlName="content"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMaxRows="2"
            class="inpt matFieldSize emojiFonts b-radius"
            (keydown.Enter)="handleKeyPress($event)"
          ></textarea>
        </div>
        <div
          class="btn btn-attach p-0"
          title="Send"
          (click)="toggleEmojiPicker()"
        >
          <img src="assets/img/emoji/smile.svg" />
        </div>
        <div class="btn btn-attach p-0" title="Send" (click)="sendComment()">
          <img src="assets/img/send/send.svg" />
        </div>
      </div>
    </form>
  </div>
</div>

<app-comment-actions-pop
  *ngIf="editedCommentId"
  (delete)="setDeleteComment()"
  (edit)="setEditComment()"
  (mouseLeave)="mouseLeave()"
  [top]="comActionsTop"
  [left]="comActionsLeft"
></app-comment-actions-pop>

<app-confirm-delete
  id="deleteusermodal"
  *ngIf="deleteConfirmation"
  (confirm)="handleDeleteClick(); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>

<app-emoji-mart-popup
  [showEmojiPicker]="showEmojiPicker"
  [bottom]="100"
  (pickEmoji)="insertEmoji($event)"
  (showEmojiPickerChange)="showEmojiPickerChange($event)"
>
</app-emoji-mart-popup>

<app-attach-file-popup
  [docs]="this.sendForm.get('documents').value"
  (docSubmit)="setDocuments($event)"
></app-attach-file-popup>

<app-confirm-unsaved-data
  *ngIf="showUnsavedPopUp"
  (cancel)="showUnsavedPopUp = false"
  (confirmSubmit)="clearForm()"
>
</app-confirm-unsaved-data>
