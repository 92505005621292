import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import {
  IReferralSource,
  ReferralSourcesRepository,
} from './referralSource.repository';

const API = '/api/referralsource';

@Injectable({ providedIn: 'root' })
export class ReferralSourcesService extends BaseService<IReferralSource> {
  constructor(http: HttpClient, repo: ReferralSourcesRepository) {
    super(API, http, repo);
  }

  loadOneReferralSource(id: string) {
    return this.http.get<IReferralSource>(API + '/loadOneReferralSource/' + id);
  }

  loadAllReferralSources() {
    return this.http.get<IReferralSource[]>(API + '/loadAll');
  }

  postReferralSource(contact: Partial<IReferralSource>) {
    return this.http.post<IReferralSource>(
      API + '/postReferralSource',
      contact
    );
  }

  updateReferralSource(contact: Partial<IReferralSource>) {
    return this.http.put<IReferralSource>(
      API + '/updateReferralSource',
      contact
    );
  }

  sortCustom(sortBy: SortState, search?: string, take: number = 25) {
    this.repo.setSort(sortBy);
    return this.reloadPage(search, take);
  }
}
