import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, switchMap } from 'rxjs';
import { AuthRepository } from '../../../state/auth.repository';
import { AuthService } from '../../../state/auth.service';
import {
  IProject,
  ITask,
  TimelogsRepository,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';
import { UiRepository } from '../../../state/ui.repository';
import { User, UsersRepository } from '../../../state/users.repository';
import { UsersService } from '../../../state/users.service';
import { DatalistOption } from '../../shared/components/datalist-select/datalist-select.component';
import { EnvState } from '../../shared/helpers/env-state';

@Component({
  selector: 'app-timelogs-form',
  templateUrl: './timelogs-form.component.html',
  styleUrls: ['./timelogs-form.component.scss'],
})
export class TimelogsFormComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public usersRepo: UsersRepository,
    private usersservice: UsersService,
    public service: TimelogsService,
    public repository: TimelogsRepository,
    private auth: AuthService,
    public repo: AuthRepository,
    public ui: UiRepository,
    private router: Router,
    private route: ActivatedRoute,
    public env: EnvState
  ) {}

  checkDuplicate: boolean = true;
  editForm: UntypedFormBuilder | any;
  @Input() editedProject?: IProject | null;
  @Input() editedTask?: ITask | null;
  @Input() id?: string | null;
  SubmitEntity: IProject | null = null;
  tasksForValidation: ITask[] = [];
  projectsForValidation: IProject[] = [];
  @Input() set users(value: User[] | null) {
    if (!value) {
      this.userOptions = null;
    } else {
      this.userOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
        imagePath: x.imagePath,
      }));
    }
  }

  @Input() set tasks(value: ITask[] | null) {
    if (!value) {
      this.taskOptions = null;
    } else {
      this.taskOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  userOptions: DatalistOption[] | null = null;
  taskOptions: DatalistOption[] | null = null;
  type: string | null = null;
  action: string | null = null;
  originalName?: string;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type');
        this.action = value.get('action');
        if (this.action == 'new') {
          if (this.type == 'project') {
            this.service.loadAll().subscribe((x) => {
              this.projectsForValidation = x;
            });
            this.service.loadTasks().subscribe((x) => {
              this.tasks = x;
            });
            this.usersservice.loadAll().subscribe((x) => {
              this.users = x;
            });
            this.editForm = this.formBuilder.group({
              name: [this.editedProject?.name, Validators.required],
              description: this.editedProject?.description,
              applicationUsersIds: [
                this.editedProject?.applicationUsers.map((x) => x.id),
                Validators.required,
              ],
              tasksIds: [
                this.editedProject?.tasks.map((x) => x.id),
                Validators.required,
              ],
              dateString: [this.editedProject?.dateString, Validators.required],
            });
          }
          if (this.type == 'task') {
            this.service.loadTasks().subscribe((x) => {
              this.tasksForValidation = x;
            });
            this.editForm = this.formBuilder.group({
              name: [this.editedTask?.name, Validators.required],
            });
          }
        } else if (this.action == 'edit') {
          if (this.type == 'project') {
            this.service.loadAll().subscribe((x) => {
              this.projectsForValidation = x;
            });
            this.originalName = this.editedProject?.name;
            this.id = value.get('id');
            this.service.loadTasks().subscribe((x) => {
              this.tasks = x;
            });
            this.usersservice.loadAll().subscribe((x) => {
              this.users = x;
            });
            this.editForm = this.formBuilder.group({
              name: [this.editedProject?.name, Validators.required],
              description: this.editedProject?.description,
              applicationUsersIds: [
                this.editedProject?.applicationUsers.map((x) => x.id),
                Validators.required,
              ],
              tasksIds: [
                this.editedProject?.tasks.map((x) => x.id),
                Validators.required,
              ],
              dateString: [
                this.editedProject?.dateString?.slice(0),
                Validators.required,
              ],
            });
          } else if (this.type == 'task') {
            this.originalName = this.editedProject?.name;
            this.id = value.get('id');
            this.editForm = this.formBuilder.group({
              name: [this.editedTask?.name, Validators.required],
            });
            this.service.loadTasks().subscribe((x) => {
              this.tasksForValidation = x;
            });
          }
        }
      },
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
  checkNameDuplicates(name: string) {
    if (name != null && name != undefined && name != '') {
      this.checkDuplicate = true;
      if (this.type == 'project') {
        if (this.originalName) {
          if (name == this.originalName) {
            return true;
          }
        }
        this.projectsForValidation?.forEach((x) => {
          if (x.name === name) {
            this.checkDuplicate = false;
          }
        });
      } else if (this.type == 'task') {
        if (this.originalName) {
          if (name == this.originalName) {
            return true;
          }
        }
        this.tasksForValidation?.forEach((x) => {
          if (x.name === name) {
            this.editForm.get('name').appValidateState = false;
            this.checkDuplicate = false;
          }
        });
      }
    } else {
      this.checkDuplicate = false;
    }
    return this.checkDuplicate;
  }
  onSubmit() {
    if (
      (this.editForm && !this.editForm.valid) ||
      !this.checkNameDuplicates(this.editForm.value.name)
    ) {
      this.editForm.markAllAsTouched();
      if (!this.checkDuplicate) {
        this.editForm.get('name').status = 'INVALID';
      } else {
        this.editForm.get('name').status = 'VALID';
      }
      return;
    }

    if (this.action == 'new') {
      if (this.type == 'task') {
        const name = this.editForm.value?.name.toString();
        this.service.addTask({ name }).subscribe((x) => history.back());
      } else if (this.type == 'project') {
        const name = this.editForm.value?.name.toString();
        const description = this.editForm.value?.description?.toString();
        const tasksIds = this.editForm.value?.tasksIds;
        const dateString = this.editForm.value?.dateString;
        const applicationUsersIds = this.editForm.value?.applicationUsersIds;
        this.service
          .addProject({
            name,
            description,
            applicationUsersIds,
            tasksIds,
            dateString,
          })
          .subscribe((x) => history.back());
      }
    } else if (this.action == 'edit') {
      if (this.type == 'task') {
        const name = this.editForm.value?.name.toString();
        const id = `${this.id}`;
        this.service.updateTask({ id, name }).subscribe((x) => history.back());
      } else if (this.type == 'project') {
        const id = `${this.id}`;
        const name = this.editForm.value?.name.toString();
        const description = this.editForm.value?.description?.toString();
        const tasksIds = this.editForm.value?.tasksIds;
        const dateString = this.editForm.value?.dateString;
        const applicationUsersIds = this.editForm.value?.applicationUsersIds;
        this.service
          .updateProject({
            id,
            name,
            description,
            applicationUsersIds,
            tasksIds,
            dateString,
          })
          .subscribe((x) => history.back());
      }
    }
  }
}
