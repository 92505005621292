import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';

@Component({
  selector: 'app-sort-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.scss'],
})
export class SortDropdownComponent implements OnInit {
  @Input() sortingOptions: SortOption[] = [];
  @Input() sorting: SortState | null = null;
  @Output() sortingChange = new EventEmitter<SortState>();

  constructor() {}

  ngOnInit(): void {}

  ChangeDirection() {
    this.sortingChange.emit({
      parameter: this.sorting?.parameter || this.sortingOptions[0],
      direction: this.sorting?.direction === 'asc' ? 'desc' : 'asc',
    });
  }

  ChangeParam(parameter: SortOption) {
    this.sortingChange.emit({
      direction: this.sorting?.direction || 'asc',
      parameter,
    });
  }
}
