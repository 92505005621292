import { Component, Input, OnInit } from '@angular/core';
import { QuatersEsRepository } from 'src/app/state/quaters.repository';

@Component({
  selector: 'app-ae-stats-sum-up',
  templateUrl: './ae-stats-sum-up.component.html',
  styleUrls: ['./ae-stats-sum-up.component.scss'],
})
export class AeStatsSumUpComponent {
  constructor(public repo: QuatersEsRepository) {}
}
