import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { CloseReason } from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';

@Component({
  selector: 'app-close-ticket-pop',
  templateUrl: './close-ticket-pop.component.html',
  styleUrls: ['./close-ticket-pop.component.scss'],
})
export class CloseTicketPopComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  @Input() isLoading = false;
  @Output() closeTicket = new EventEmitter<CloseReason>();
  @ViewChild('ticketCloseBbutton') ticketCloseBbutton: ElementRef | null = null;

  reasonOptions: DatalistOption[] = [
    { label: 'Ticket solved', value: 0 },
    { label: 'Ticket no longer relevant', value: 1 },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public service: TicketsService
  ) {}

  ngOnInit(): void {
    this.resetForm();
  }

  closeSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    this.closeTicket.emit(this.editForm.value?.closeReason);
    this.ticketCloseBbutton?.nativeElement.click();
  }

  resetForm() {
    this.editForm = this.formBuilder.group({
      closeReason: [null, Validators.required],
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
