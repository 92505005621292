import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { IEvent, EventsRepository } from 'src/app/state/event.repository';
import { EventService } from 'src/app/state/event.service';

@Component({
  selector: 'app-event-post',
  templateUrl: './event-post.component.html',
  styleUrls: ['./event-post.component.scss'],
})
export class EventPostComponent implements OnInit {
  post$!: Observable<IEvent>;
  submitErrors: string[] | null = null;

  constructor(
    private service: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: EventsRepository
  ) {}

  ngOnInit(): void {}

  post($event: Partial<IEvent>) {
    this.submitErrors = null;
    let updateResult: Observable<IEvent> | undefined;
    updateResult = this.service.add($event);
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/admin/events']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
