import { Component, OnInit } from '@angular/core';
import { IApv, ApvRepository } from 'src/app/state/apv.repository';
import { ApvService } from 'src/app/state/apv.service';
import { apvSortOptions } from 'src/app/state/apv.repository';
@Component({
  selector: 'app-apv',
  templateUrl: './apv.component.html',
  styleUrls: ['./apv.component.scss'],
})
export class ApvComponent implements OnInit {
  constructor(public repo: ApvRepository, public service: ApvService) {}

  sortOptions = apvSortOptions;

  Apvs: IApv[] = [];
  ngOnInit(): void {
    this.service.reloadPage().subscribe();
  }
}
