import { Component, Input, OnInit } from '@angular/core';
import { IActivityLog } from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import * as dayjs from 'dayjs';
@Component({
  selector: 'app-activity-log-modal',
  templateUrl: './activity-log-modal.component.html',
  styleUrls: ['./activity-log-modal.component.scss'],
})
export class ActivityLogModalComponent implements OnInit {
  constructor(public service: EventScheduleService) {}
  @Input() isLoading = false;
  _eventScheduleId?: string;
  @Input() actLogs: IActivityLog[] = [];
  ngOnInit(): void {}

  dateToFormat(date: Date) {
    return dayjs(date).format('DD-MMM HH:mm YYYY');
  }
}
