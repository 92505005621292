import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.scss'],
})
export class HeadermenuComponent implements OnInit {
  page: string | null = null;

  constructor(public repo: UiRepository, private router: Router) {}
  backnav() {
    //window.history.back();
    this.router.navigate(['/home']);
    return false;
  }

  ngOnInit(): void {}

  isRoute(route: string) {
    return this.router.url.includes(route);
  }
}
