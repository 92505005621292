import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { EventInfosRepository, IEventInfo } from './event-infos.repository';

const API = '/api/eventinfos';

@Injectable({ providedIn: 'root' })
export class EventInfosService extends BaseService<IEventInfo> {
  constructor(http: HttpClient, repo: EventInfosRepository) {
    super(API, http, repo);
  }
  loadOneEventInfo(id: string) {
    return this.http.get<IEventInfo>(API + '/loadOneEventInfo/' + id);
  }
  postEventInfo(entity: Partial<IEventInfo>) {
    return this.http.post<IEventInfo>(API + '/postEventInfo', entity);
  }
  updateEventInfo(entity: Partial<IEventInfo>) {
    return this.http.put<IEventInfo>(API + `/updateEventInfo`, entity);
  }
  loadGeneral() {
    return this.http.get<IEventInfo[]>(API + '/loadGeneral');
  }
}
