import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';

import {
  AssignCallsRepository,
  AssignCallsWithEventsDto,
  WeekWithAssignCalls,
  YearWithWeeks,
} from './assignCalls.repository';
import { tap } from 'rxjs';
import { AssignerInfoRepository } from './assignerInfo.repository';

const API = '/api/eventschedule';

@Injectable({ providedIn: 'root' })
export class AssignCallsService extends BaseService<WeekWithAssignCalls> {
  constructor(
    http: HttpClient,
    public repo: AssignCallsRepository,
    public asginerInfoRepo: AssignerInfoRepository
  ) {
    super(API, http, repo);
  }

  loadAssignCallsByYearWeek(year: string = '') {
    const query = [`year=${year}`];
    return this.http.get<WeekWithAssignCalls[]>(
      API + `/loadAssignCallsByYearWeek/?${query.join('&')}`
    );
  }
}
