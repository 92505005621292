import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ReferralSourcesRepository,
  IReferralSource,
} from 'src/app/state/referralSource.repository';
import { ReferralSourcesService } from 'src/app/state/referralSources.service';

@Component({
  selector: 'app-referral-sources-create-page',
  templateUrl: './referral-sources-create-page.component.html',
  styleUrls: ['./referral-sources-create-page.component.scss'],
})
export class ReferralSourcesCreatePageComponent {
  constructor(
    public service: ReferralSourcesService,
    public repo: ReferralSourcesRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  referralSource: IReferralSource | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service
            .loadOneReferralSource(`${value.get('id')}`)
            .subscribe((x) => {
              this.referralSource = x;
            });
        }
      },
    });
  }

  createReferralSource(event: Partial<IReferralSource>) {
    this.submitErrors = null;
    let updateResult$: Observable<IReferralSource> | undefined;
    if (this.id === 'new') {
      updateResult$ = this.service.postReferralSource(event);
    } else if (this.id) {
      updateResult$ = this.service.updateReferralSource(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.router.navigate(['/admin/referralSources']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
