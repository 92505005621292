import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AdsOptions,
  IEventSchedule,
  IEventScheduleLocation,
  IFunnel,
  IRoom,
  ITopic,
  PayStatsOptions,
  StatusesOptions,
  AtCostOptions,
  ReminderProcessOptions,
  EventScheduleRepository,
} from '../../../../state/event-schedule.repository';
import { DatePipe, Location } from '@angular/common';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { CompaniesService } from '../../../../state/companies.service';
import { LocationsService } from '../../../../state/locations.service';
import { FunnelsService } from '../../../../state/funnels.service';
import { RoomsService } from '../../../../state/rooms.service';
import { TopicsService } from '../../../../state/topics.service';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import * as dayjs from 'dayjs';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { EventInfosService } from '../../../../state/event-infos.service';
import { IEventInfo } from '../../../../state/event-infos.repository';
import { PopUpIframeComponent } from '../pop-up-iframe/pop-up-iframe.component';
import { AuthRepository } from 'src/app/state/auth.repository';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-client-event-schedule-edit-form',
  templateUrl: './client-event-schedule-edit-form.component.html',
  styleUrls: ['./client-event-schedule-edit-form.component.scss'],
})
export class ClientEventScheduleEditFormComponent {
  editForm: UntypedFormBuilder | any;
  date1formated?: string = '';
  date2formated?: string = '';
  emailBlast1stDate = '';
  emailBlast2ndDate = '';
  @Input() editedEvent?: IEventSchedule | null;
  Clients: ICompanyMarker[] | null = null;
  submitErrors: string[] | null = null;
  popUpTab: string | null = null;
  ownClient?: string;
  @Output() EventSubmit = new EventEmitter<Partial<IEventSchedule>>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private service: EventScheduleService,
    private repo: EventScheduleRepository,
    private ui: UiRepository,

    private clientService: CompaniesService,
    private clientLocationsService: LocationsService,
    private funnelsService: FunnelsService,
    private roomService: RoomsService,
    public authRepo: AuthRepository,

    public datepipe: DatePipe,
    private topicService: TopicsService,
    private eventInfosService: EventInfosService,

    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe({
      next: (value) => {
        let id = value.get('id');
        if (id && id !== 'new') {
          this.service.loadOneEventSchedule(`${id}`).subscribe((x) => {
            this.editedEvent = x;
            this.resetForm();
          });
        } else if (id === 'new') {
          this.editedEvent = null;

          this.resetForm();
        }
      },
    });
  }
  ngOnDestroy(): void {}
  @ViewChild(PopUpIframeComponent) popUpForm: PopUpIframeComponent | null =
    null;
  ClientsOptions: DatalistOption[] | null = null;
  FunnelsOptions: DatalistOption[] | null = null;
  TopicsOptions: DatalistOption[] | null = null;
  EventInfosOptions: DatalistOption[] | null = null;
  AdsOptions: DatalistOption[] = AdsOptions;
  StatusesOptions: DatalistOption[] = StatusesOptions;
  AtCostOptions: DatalistOption[] = AtCostOptions;
  scheduleLocationsOptions: DatalistOption[] | null = null;
  payStatsOptions: DatalistOption[] = PayStatsOptions;
  reminderProcessOptions: DatalistOption[] = ReminderProcessOptions;
  allRooms: IRoom[] = [];

  RoomsOptions: DatalistOption[] | null = null;
  SelectedClientId: string | null = null;
  SelectedroomId: string | null = null;
  SelectedroomD2Id: string | null = null;
  SelectedLocationId: string | null = null;
  SelectedClient: ICompanyMarker | null = null;
  SelectedLocation: IEventScheduleLocation | null = null;
  isDuplicated: boolean = false;
  @Input() set clients(value: ICompanyMarker[] | null) {
    if (!value) {
      this.ClientsOptions = null;
    } else {
      this.ClientsOptions = value.map((x) => ({
        value: x.id,
        label: x.clientCode ? x.clientCode + ' - ' + x.title : x.title,
      }));
    }
  }
  @Input() set topics(value: ITopic[] | null) {
    if (!value) {
      this.TopicsOptions = null;
    } else {
      this.TopicsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  @Input() set eventInfos(value: IEventInfo[] | null) {
    if (!value) {
      this.EventInfosOptions = null;
    } else {
      this.EventInfosOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set funnels(value: IFunnel[] | null) {
    if (!value) {
      this.FunnelsOptions = null;
    } else {
      this.FunnelsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set locations(value: IEventScheduleLocation[] | null) {
    if (!value) {
      this.scheduleLocationsOptions = null;
    } else {
      this.scheduleLocationsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
    // this.scheduleLocationsOptions?.push({
    //   value: 'New Venue',
    //   label: 'New Venue',
    // });
    // let locContoll = this.editForm?.get('eventScheduleLocationId');
    // if (this.editedEvent?.newVenue && locContoll) {
    //   locContoll.setValue('New Venue');
    // }
  }

  @Input() set rooms(value: IRoom[] | null) {
    if (!value) {
      this.RoomsOptions = null;
    } else {
      this.RoomsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  resetForm() {
    if (this.authRepo.isClientUser$) {
      this.ownClient = this.authRepo.getActiveClientId() ?? '';
      this.clientLocationsService
        .loadAllLocationsForClient(`${this.ownClient}`)
        .subscribe((x) => {
          this.locations = x;
        });
    }

    this.editForm = this.formBuilder.group({
      eventType: [this.editedEvent?.eventType ?? 'workshopsSeminarType'],

      status: [this.editedEvent?.status],
      statusD2: [this.editedEvent?.statusD2],
      clientId: [
        this.editedEvent?.clientId ?? this.ownClient,
        Validators.required,
      ],
      doubleEvent: [this.editedEvent?.doubleEvent],
      atCost: [this.editedEvent?.atCost ? this.editedEvent?.atCost : 'No'],
      atCostD2: [
        this.editedEvent?.atCostD2 ? this.editedEvent?.atCostD2 : 'No',
      ],

      date: [this.editedEvent?.date],
      time: [this.editedEvent?.time],
      prebuildTimeId: [this.editedEvent?.prebuildTimeId],
      prebuildTime2Id: [this.editedEvent?.prebuildTime2Id],

      landingPageUrl: [this.editedEvent?.landingPageUrl],
      wfrDinnerOrLunch: [this.editedEvent?.wfrDinnerOrLunch],
      twoPartSetup: [this.editedEvent?.twoPartSetup],

      eventScheduleLocationId: [
        this.editedEvent?.eventScheduleLocationId,
        Validators.required,
      ],
      roomId: [this.editedEvent?.roomId],
      roomD2Id: [this.editedEvent?.roomD2Id],

      parking: [this.editedEvent?.parking],
      topic: [this.editedEvent?.topic, Validators.required],

      newTopic: [
        !this.editedEvent?.prebuildTopicId && !this.editedEvent?.newTopic
          ? this.editedEvent?.topic
          : this.editedEvent?.newTopic,
      ],

      prebuildTopicId: [this.editedEvent?.prebuildTopicId],

      desiredBU: [this.editedEvent?.desiredBU],
      desiredBUD2: [this.editedEvent?.desiredBUD2],

      notes: [this.editedEvent?.notes],
      wfrReminder: [this.editedEvent?.wfrReminder],

      //todo table
      emailBlast1st: [this.editedEvent?.emailBlast1st],
      emailBlast2nd: [this.editedEvent?.emailBlast2nd],
      emailBlast1stD2: [this.editedEvent?.emailBlast1stD2],
      emailBlast2ndD2: [this.editedEvent?.emailBlast2ndD2],

      emailBlastScheduled: [this.editedEvent?.emailBlastScheduled],
      emailBlastD2Scheduled: [this.editedEvent?.emailBlastD2Scheduled],

      fewSpots: [this.editedEvent?.fewSpots],
      fewSpotsD2: [this.editedEvent?.fewSpotsD2],

      fewSpotsScheduled: [this.editedEvent?.fewSpotsScheduled],
      fewSpotsD2Scheduled: [this.editedEvent?.fewSpotsD2Scheduled],

      vmCode: [this.editedEvent?.vmCode],
      vmCodeD2: [this.editedEvent?.vmCodeD2],

      fiveDayWfrCallDate: [this.editedEvent?.fiveDayWfrCallDate],
      fiveDayWfrCallD2Date: [this.editedEvent?.fiveDayWfrCallD2Date],

      fiveDayWfrCall: [this.editedEvent?.fiveDayWfrCall],
      fiveDayWfrCallD2: [this.editedEvent?.fiveDayWfrCallD2],

      fiveDayWfrCallDone: [this.editedEvent?.fiveDayWfrCallDone],
      fiveDayWfrCallD2Done: [this.editedEvent?.fiveDayWfrCallD2Done],

      //calls table
      wfrCall1: [this.editedEvent?.wfrCall1],
      wfrCall1Date: [this.editedEvent?.wfrCall1Date],
      wfrCall1FunnelId: [this.editedEvent?.wfrCall1FunnelId],
      wfrCall1Done: [this.editedEvent?.wfrCall1Done],

      wfrCall2: [this.editedEvent?.wfrCall2],
      wfrCall2Date: [this.editedEvent?.wfrCall2Date],
      wfrCall2FunnelId: [this.editedEvent?.wfrCall2FunnelId],
      wfrCall2Done: [this.editedEvent?.wfrCall2Done],

      wfrCall1D2: [this.editedEvent?.wfrCall1D2],
      wfrCall1D2Date: [this.editedEvent?.wfrCall1D2Date],
      wfrCall1D2FunnelId: [this.editedEvent?.wfrCall1D2FunnelId],
      wfrCall1D2Done: [this.editedEvent?.wfrCall1D2Done],

      wfrCall2D2: [this.editedEvent?.wfrCall2D2],
      wfrCall2D2Date: [this.editedEvent?.wfrCall2D2Date],
      wfrCall2D2FunnelId: [this.editedEvent?.wfrCall2D2FunnelId],
      wfrCall2D2Done: [this.editedEvent?.wfrCall2D2Done],

      //set up table
      funnelId: [this.editedEvent?.funnelId],
      funnelSecondId: [this.editedEvent?.funnelSecondId],

      funnelDone: [this.editedEvent?.funnelDone],
      funnelSecondDone: [this.editedEvent?.funnelSecondDone],

      funnelQAbyId: [this.editedEvent?.funnelQAbyId],
      funnelSecondQAbyId: [this.editedEvent?.funnelSecondQAbyId],

      funnelQADone: [this.editedEvent?.funnelQADone],
      funnelSecondQADone: [this.editedEvent?.funnelSecondQADone],

      funnelAdsId: [this.editedEvent?.funnelAdsId],
      funnelSecondAdsId: [this.editedEvent?.funnelSecondAdsId],

      funnelAdsDone: [this.editedEvent?.funnelAdsDone],
      funnelSecondAdsDone: [this.editedEvent?.funnelSecondAdsDone],

      funnelAdsQAbyId: [this.editedEvent?.funnelAdsQAbyId],
      funnelSecondAdsQAbyId: [this.editedEvent?.funnelSecondAdsQAbyId],

      funnelAdsQADone: [this.editedEvent?.funnelAdsQADone],
      funnelSecondAdsQADone: [this.editedEvent?.funnelSecondAdsQADone],

      //end table
      adSpent: [this.editedEvent?.adSpent],
      adSpentNotes: [this.editedEvent?.adSpentNotes],
      payStats: [this.editedEvent?.payStats],
      reminder: [this.editedEvent?.reminder],
      reminderReady: [this.editedEvent?.reminderReady],
      roomsCapacity: [this.editedEvent?.roomsCapacity],
      maxAdSpend: [this.editedEvent?.maxAdSpend],

      //maybe we will need them at future
      attendees: [this.editedEvent?.attendees],
      attendeesD2: [this.editedEvent?.attendeesD2],
      finalRegistrants: [this.editedEvent?.finalRegistrants],
      finalRegistrantsD2: [this.editedEvent?.finalRegistrantsD2],

      totalAttendees: [this.editedEvent?.totalAttendees],
      googleSheet: [this.editedEvent?.googleSheet],
      googleSheetD2: [this.editedEvent?.googleSheetD2],

      clientTouch: [this.editedEvent?.clientTouch],
      clientTouchReady: [this.editedEvent?.clientTouchReady],
      emailBlast1Ready: [this.editedEvent?.emailBlast1Ready],
      emailBlast2Ready: [this.editedEvent?.emailBlast2Ready],
      wfrReminderReady: [this.editedEvent?.wfrReminderReady],
      date2: [this.editedEvent?.date2],
      time2: [this.editedEvent?.time2],

      landingPageUrl2: [this.editedEvent?.landingPageUrl2],
      fewSpotsReady: [this.editedEvent?.fewSpotsReady],
      clientUrl: [this.editedEvent?.clientUrl],
      locationUrl: [this.editedEvent?.locationUrl],
      funneladsQADone: [this.editedEvent?.funnelAdsQADone],
      adsD2QAby: [this.editedEvent?.adsD2QAby],
      adsD2QADone: [this.editedEvent?.adsD2QADone],
      formatedDate: [this.editedEvent?.formatedDate],
      formatedDate2: [this.editedEvent?.formatedDate2],
    });
  }
  refreshData() {
    this.topicService.loadGeneral().subscribe((x) => {
      this.topics = x;
    });
    this.eventInfosService.loadGeneral().subscribe((x) => {
      this.eventInfos = x;
    });
    this.funnelsService.loadAllFunnels().subscribe((x) => {
      this.funnels = x;
    });
    if (this.editedEvent?.clientId) {
      this.SelectedClientId = this.editedEvent?.clientId;
      this.clientService
        .loadOneCompany(this.editedEvent?.clientId)
        .subscribe((x) => (this.SelectedClient = x));
      this.clientLocationsService
        .loadAllLocationsForClient(this.editedEvent?.clientId)
        .subscribe((x) => {
          this.locations = x;
        });
    }
    if (this.editedEvent?.eventScheduleLocationId) {
      this.SelectedLocationId = this.editedEvent?.eventScheduleLocationId;
      this.clientLocationsService
        .loadOneLocation(this.SelectedLocationId)
        .subscribe((x) => (this.SelectedLocation = x));

      this.roomService
        .loadAllRoomsForLocation(this.SelectedLocationId)
        .subscribe((x) => {
          this.rooms = x;
          this.allRooms = x;
        });
    }
    this.clientService.loadAll().subscribe((x) => {
      this.clients = x;
    });

    this.editForm = this.formBuilder.group({
      eventType: [this.editedEvent?.eventType ?? 'workshopsSeminarType'],
      prebuildTimeId: [this.editedEvent?.prebuildTimeId],
      prebuildTime2Id: [this.editedEvent?.prebuildTime2Id],
      status: [this.editedEvent?.status],
      statusD2: [this.editedEvent?.statusD2],

      clientId: [this.editedEvent?.clientId],
      doubleEvent: [this.editedEvent?.doubleEvent],
      atCost: [this.editedEvent?.atCost ? this.editedEvent?.atCost : 'No'],
      atCostD2: [
        this.editedEvent?.atCostD2 ? this.editedEvent?.atCostD2 : 'No',
      ],

      date: [this.editedEvent?.date],
      time: [this.editedEvent?.time],
      landingPageUrl: [this.editedEvent?.landingPageUrl],
      wfrDinnerOrLunch: [this.editedEvent?.wfrDinnerOrLunch],
      twoPartSetup: [this.editedEvent?.twoPartSetup],

      eventScheduleLocationId: [
        this.editedEvent?.eventScheduleLocationId,
        Validators.required,
      ],
      roomId: [this.editedEvent?.roomId],
      roomD2Id: [this.editedEvent?.roomD2Id],

      parking: [this.editedEvent?.parking],
      topic: [this.editedEvent?.topic, Validators.required],
      newTopic: [
        !this.editedEvent?.prebuildTopicId && !this.editedEvent?.newTopic
          ? this.editedEvent?.topic
          : this.editedEvent?.newTopic,
      ],

      prebuildTopicId: [this.editedEvent?.prebuildTopicId],

      desiredBU: [this.editedEvent?.desiredBU],
      desiredBUD2: [this.editedEvent?.desiredBUD2],

      notes: [this.editedEvent?.notes],
      wfrReminder: [this.editedEvent?.wfrReminder],

      //todo table
      emailBlast1st: [this.editedEvent?.emailBlast1st],
      emailBlast2nd: [this.editedEvent?.emailBlast2nd],
      emailBlast1stD2: [this.editedEvent?.emailBlast1stD2],
      emailBlast2ndD2: [this.editedEvent?.emailBlast2ndD2],

      emailBlastScheduled: [this.editedEvent?.emailBlastScheduled],
      emailBlastD2Scheduled: [this.editedEvent?.emailBlastD2Scheduled],

      fewSpots: [this.editedEvent?.fewSpots],
      fewSpotsD2: [this.editedEvent?.fewSpotsD2],

      fewSpotsScheduled: [this.editedEvent?.fewSpotsScheduled],
      fewSpotsD2Scheduled: [this.editedEvent?.fewSpotsD2Scheduled],

      vmCode: [this.editedEvent?.vmCode],
      vmCodeD2: [this.editedEvent?.vmCodeD2],

      fiveDayWfrCallDate: [this.editedEvent?.fiveDayWfrCallDate],
      fiveDayWfrCallD2Date: [this.editedEvent?.fiveDayWfrCallD2Date],

      fiveDayWfrCall: [this.editedEvent?.fiveDayWfrCall],
      fiveDayWfrCallD2: [this.editedEvent?.fiveDayWfrCallD2],

      fiveDayWfrCallDone: [this.editedEvent?.fiveDayWfrCallDone],
      fiveDayWfrCallD2Done: [this.editedEvent?.fiveDayWfrCallD2Done],

      //calls table
      wfrCall1: [this.editedEvent?.wfrCall1],
      wfrCall1Date: [this.editedEvent?.wfrCall1Date],
      wfrCall1FunnelId: [this.editedEvent?.wfrCall1FunnelId],
      wfrCall1Done: [this.editedEvent?.wfrCall1Done],

      wfrCall2: [this.editedEvent?.wfrCall2],
      wfrCall2Date: [this.editedEvent?.wfrCall2Date],
      wfrCall2FunnelId: [this.editedEvent?.wfrCall2FunnelId],
      wfrCall2Done: [this.editedEvent?.wfrCall2Done],

      wfrCall1D2: [this.editedEvent?.wfrCall1D2],
      wfrCall1D2Date: [this.editedEvent?.wfrCall1D2Date],
      wfrCall1D2FunnelId: [this.editedEvent?.wfrCall1D2FunnelId],
      wfrCall1D2Done: [this.editedEvent?.wfrCall1D2Done],

      wfrCall2D2: [this.editedEvent?.wfrCall2D2],
      wfrCall2D2Date: [this.editedEvent?.wfrCall2D2Date],
      wfrCall2D2FunnelId: [this.editedEvent?.wfrCall2D2FunnelId],
      wfrCall2D2Done: [this.editedEvent?.wfrCall2D2Done],

      //set up table
      funnelId: [this.editedEvent?.funnelId],
      funnelSecondId: [this.editedEvent?.funnelSecondId],

      funnelDone: [this.editedEvent?.funnelDone],
      funnelSecondDone: [this.editedEvent?.funnelSecondDone],

      funnelQAbyId: [this.editedEvent?.funnelQAbyId],
      funnelSecondQAbyId: [this.editedEvent?.funnelSecondQAbyId],

      funnelQADone: [this.editedEvent?.funnelQADone],
      funnelSecondQADone: [this.editedEvent?.funnelSecondQADone],

      funnelAdsId: [this.editedEvent?.funnelAdsId],
      funnelSecondAdsId: [this.editedEvent?.funnelSecondAdsId],

      funnelAdsDone: [this.editedEvent?.funnelAdsDone],
      funnelSecondAdsDone: [this.editedEvent?.funnelSecondAdsDone],

      funnelAdsQAbyId: [this.editedEvent?.funnelAdsQAbyId],
      funnelSecondAdsQAbyId: [this.editedEvent?.funnelSecondAdsQAbyId],

      funnelAdsQADone: [this.editedEvent?.funnelAdsQADone],
      funnelSecondAdsQADone: [this.editedEvent?.funnelSecondAdsQADone],

      //end table
      adSpent: [this.editedEvent?.adSpent],
      adSpentNotes: [this.editedEvent?.adSpentNotes],
      payStats: [this.editedEvent?.payStats],
      reminder: [this.editedEvent?.reminder],
      reminderReady: [this.editedEvent?.reminderReady],
      roomsCapacity: [this.editedEvent?.roomsCapacity],
      maxAdSpend: [this.editedEvent?.maxAdSpend],
      //maybe we will need them at future
      attendees: [this.editedEvent?.attendees],
      attendeesD2: [this.editedEvent?.attendeesD2],
      finalRegistrants: [this.editedEvent?.finalRegistrants],
      finalRegistrantsD2: [this.editedEvent?.finalRegistrantsD2],

      totalAttendees: [this.editedEvent?.totalAttendees],
      googleSheet: [this.editedEvent?.googleSheet],
      googleSheetD2: [this.editedEvent?.googleSheetD2],

      clientTouch: [this.editedEvent?.clientTouch],
      clientTouchReady: [this.editedEvent?.clientTouchReady],
      emailBlast1Ready: [this.editedEvent?.emailBlast1Ready],
      emailBlast2Ready: [this.editedEvent?.emailBlast2Ready],
      wfrReminderReady: [this.editedEvent?.wfrReminderReady],
      date2: [this.editedEvent?.date2],
      time2: [this.editedEvent?.time2],

      landingPageUrl2: [this.editedEvent?.landingPageUrl2],
      fewSpotsReady: [this.editedEvent?.fewSpotsReady],
      clientUrl: [this.editedEvent?.clientUrl],
      locationUrl: [this.editedEvent?.locationUrl],
      funneladsQADone: [this.editedEvent?.funnelAdsQADone],
      adsD2QAby: [this.editedEvent?.adsD2QAby],
      adsD2QADone: [this.editedEvent?.adsD2QADone],
      formatedDate: [this.editedEvent?.formatedDate],
      formatedDate2: [this.editedEvent?.formatedDate2],
    });
  }
  ngOnInit(): void {
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;

      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
    if (this.authRepo.isClientUser$) {
      this.ownClient = this.authRepo.getActiveClientId() ?? '';
      this.clientLocationsService
        .loadAllLocationsForClient(`${this.ownClient}`)
        .subscribe((x) => {
          this.locations = x;
        });
    }
    this.refreshData();
  }
  duplicate() {
    if (this.editedEvent?.id) {
      this.service.duplicate(this.editedEvent?.id).subscribe((x) => {
        this.router.navigate(['/admin/eventSchedule/eventSchedule/', x.id]);
      });
    }
  }
  checkIfPastDate(): boolean {
    if (this.editForm.value?.date)
      this.isDate1Valid = this.validateDate(this.editForm.value?.date);

    if (this.editForm.value?.date2)
      this.isDate2Valid = this.validateDate(this.editForm.value?.setStartDate2);
    return this.isDate1Valid && this.isDate2Valid;
  }
  onSubmit(
    duplicate: boolean = false,
    review: boolean = false,
    draft: boolean = false
  ) {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      this.ui.openSnackBar('Please fill all required fields');

      return;
    }
    if (review && !this.checkIfPastDate()) {
      this.editForm.markAllAsTouched();
      this.ui.openSnackBar('Please set future date');
      return;
    }
    let newVenue = false;
    const id = this.editedEvent?.id?.toString();
    const registrants = this.editedEvent?.registrants;
    const registrantsD2 = this.editedEvent?.registrantsD2;
    const finalRegistrants = this.editForm.value?.finalRegistrants;
    const finalRegistrantsD2 = this.editForm.value?.finalRegistrantsD2;
    const tenantId = this.editedEvent?.tenantId?.toString();
    const topic = this.editForm.value?.topic?.toString();
    const prebuildTopicId = this.editForm.value?.prebuildTopicId?.toString();
    const clientId = this.ownClient
      ? this.ownClient
      : this.editForm.value?.clientId?.toString();
    const doubleEvent = this.editForm.value?.doubleEvent;
    const atCost = this.editForm.value?.atCost ?? 'No';
    const atCostD2 = this.editForm.value?.atCostD2 ?? 'No';

    const date = this.datepipe.transform(
      this.editForm.value?.date,
      'yyyy-MM-dd'
    ) as any;
    const date2 = this.datepipe.transform(
      this.editForm.value?.date2,
      'yyyy-MM-dd'
    ) as any;
    const status = this.editForm.value?.status?.toString();
    const statusD2 = this.editForm.value?.statusD2?.toString();

    const time = this.editForm.value?.time?.toString();
    const time2 = this.editForm.value?.time2?.toString();
    const landingPageUrl = this.editForm.value?.landingPageUrl?.toString();
    const landingPageUrl2 = this.editForm.value?.landingPageUrl2?.toString();
    const wfrDinnerOrLunch = this.editForm.value?.wfrDinnerOrLunch;
    const twoPartSetup = this.editForm.value?.twoPartSetup;
    let eventScheduleLocationId =
      this.editForm.value?.eventScheduleLocationId?.toString();

    if (eventScheduleLocationId == this.repo.Default_LocationId) {
      this.ui.openSnackBar(this.repo.Default_Location_Error);
    }
    // if (eventScheduleLocationId === 'New Venue') {
    //   eventScheduleLocationId = null;
    //   newVenue = true;
    // }

    const roomId = this.editForm.value?.roomId?.toString();
    const roomD2Id = this.editForm.value?.roomD2Id?.toString();
    const desiredBU = this.editForm.value?.desiredBU?.toString();
    const desiredBUD2 = this.editForm.value?.desiredBUD2?.toString();

    const notes = this.editForm.value?.notes?.toString();
    const wfrReminder = this.editForm.value?.wfrReminder?.toString();

    const emailBlast1st = this.editForm.value?.emailBlast1st?.toString();
    const emailBlast2nd = this.editForm.value?.emailBlast2nd?.toString();
    const emailBlast1stD2 = this.editForm.value?.emailBlast1stD2?.toString();
    const emailBlast2ndD2 = this.editForm.value?.emailBlast2ndD2?.toString();
    const emailBlastScheduled = this.editForm.value?.emailBlastScheduled;
    const emailBlastD2Scheduled = this.editForm.value?.emailBlastD2Scheduled;

    const fewSpots = this.editForm.value?.fewSpots?.toString();
    const fewSpotsD2 = this.editForm.value?.fewSpotsD2?.toString();
    const fewSpotsScheduled = this.editForm.value?.fewSpotsScheduled;
    const fewSpotsD2Scheduled = this.editForm.value?.fewSpotsD2Scheduled;
    const vmCode = this.editForm.value?.vmCode?.toString();
    const vmCodeD2 = this.editForm.value?.vmCodeD2?.toString();

    const fiveDayWfrCallDate = this.datepipe.transform(
      this.editForm.value?.fiveDayWfrCallDate,
      'yyyy-MM-dd'
    ) as any;
    const fiveDayWfrCallD2Date = this.datepipe.transform(
      this.editForm.value?.fiveDayWfrCallD2Date,
      'yyyy-MM-dd'
    ) as any;

    const fiveDayWfrCall = this.editForm.value?.fiveDayWfrCall?.toString();
    const fiveDayWfrCallD2 = this.editForm.value?.fiveDayWfrCallD2?.toString();

    const fiveDayWfrCallDone = this.editForm.value?.fiveDayWfrCallDone;
    const fiveDayWfrCallD2Done = this.editForm.value?.fiveDayWfrCallD2Done;

    const funnelId = this.editForm.value?.funnelId?.toString();
    const funnelSecondId = this.editForm.value?.funnelSecondId?.toString();

    const funnelDone = this.editForm.value?.funnelDone;
    const funnelSecondDone = this.editForm.value?.funnelSecondDone;

    const funnelQAbyId = this.editForm.value?.funnelQAbyId?.toString();
    const funnelSecondQAbyId =
      this.editForm.value?.funnelSecondQAbyId?.toString();

    const funnelQADone = this.editForm.value?.funnelQADone;
    const funnelSecondQADone = this.editForm.value?.funnelSecondQADone;

    const funnelAdsId = this.editForm.value?.funnelAdsId?.toString();
    const funnelSecondAdsId =
      this.editForm.value?.funnelSecondAdsId?.toString();

    const funnelAdsDone = this.editForm.value?.funnelAdsDone;
    const funnelSecondAdsDone = this.editForm.value?.funnelSecondAdsDone;

    const funnelAdsQAbyId = this.editForm.value?.funnelAdsQAbyId?.toString();
    const funnelSecondAdsQAbyId =
      this.editForm.value?.funnelSecondAdsQAbyId?.toString();

    const funnelAdsQADone = this.editForm.value?.funnelAdsQADone;
    const funnelSecondAdsQADone = this.editForm.value?.funnelSecondAdsQADone;

    const adSpent = this.editForm.value?.adSpent?.toString();
    const parking = this.editForm.value?.parking;
    const reminder = this.editForm.value?.reminder?.toString();
    const reminderReady = this.editForm.value?.reminderReady ?? false;

    const payStats = this.editForm.value?.payStats?.toString();
    const adSpentNotes = this.editForm.value?.adSpentNotes?.toString();
    const attendees = this.editForm.value?.attendees;
    const attendeesD2 = this.editForm.value?.attendeesD2;

    const totalAttendees = this.editForm.value?.totalAttendees;
    const googleSheet = this.editForm.value?.googleSheet;
    const googleSheetD2 = this.editForm.value?.googleSheetD2;
    const sheetError = this.editForm.value?.sheetError;
    const sheetErrorD2 = this.editForm.value?.sheetErrorD2;

    const wfrCall1Date = this.datepipe.transform(
      this.editForm.value?.wfrCall1Date,
      'yyyy-MM-dd'
    ) as any;
    const wfrCall1FunnelId = this.editForm.value?.wfrCall1FunnelId;
    const wfrCall1Done = this.editForm.value?.wfrCall1Done ?? false;

    const wfrCall2Date = this.datepipe.transform(
      this.editForm.value?.wfrCall2Date,
      'yyyy-MM-dd'
    ) as any;

    const wfrCall2FunnelId = this.editForm.value?.wfrCall2FunnelId;
    const wfrCall2Done = this.editForm.value?.wfrCall2Done ?? false;

    const wfrCall1D2Date = this.datepipe.transform(
      this.editForm.value?.wfrCall1D2Date,
      'yyyy-MM-dd'
    ) as any;

    const wfrCall1D2FunnelId = this.editForm.value?.wfrCall1D2FunnelId;
    const wfrCall1D2Done = this.editForm.value?.wfrCall1D2Done ?? false;

    const wfrCall2D2Date = this.datepipe.transform(
      this.editForm.value?.wfrCall2D2Date,
      'yyyy-MM-dd'
    ) as any;
    const wfrCall2D2FunnelId = this.editForm.value?.wfrCall2D2FunnelId;
    const wfrCall2D2Done = this.editForm.value?.wfrCall2D2Done ?? false;

    let submitEventSchedule = null as Partial<IEventSchedule> | null;

    if (doubleEvent) {
      submitEventSchedule = {
        newTopic: this.editForm.value?.newTopic?.toString(),
        prebuildTime2Id: this.editForm.value?.prebuildTime2Id,
        prebuildTimeId: this.editForm.value?.prebuildTimeId,
        desiredBUD2,
        newVenue,
        id,
        registrants,
        registrantsD2,
        finalRegistrants,
        finalRegistrantsD2,
        tenantId,
        clientId,
        topic,
        prebuildTopicId,
        doubleEvent,
        atCost,
        atCostD2,
        date,
        date2,
        status,
        statusD2,
        time,
        time2,
        landingPageUrl,
        landingPageUrl2,
        wfrDinnerOrLunch,
        twoPartSetup,
        eventScheduleLocationId,
        roomId,
        roomD2Id,
        parking,
        desiredBU,
        notes,
        wfrReminder,
        emailBlast1st,
        emailBlast2nd,
        emailBlast1stD2,
        emailBlast2ndD2,
        emailBlastD2Scheduled,
        emailBlastScheduled,
        fewSpots,
        fewSpotsD2,
        fewSpotsScheduled,
        fewSpotsD2Scheduled,
        vmCode,
        vmCodeD2,
        fiveDayWfrCall,
        fiveDayWfrCallDate,
        fiveDayWfrCallD2Date,
        fiveDayWfrCallD2,
        fiveDayWfrCallDone,
        fiveDayWfrCallD2Done,
        funnelId,
        funnelDone,
        funnelQAbyId,
        funnelQADone,
        funnelSecondId,
        funnelSecondDone,
        funnelSecondQAbyId,
        funnelSecondQADone,
        adSpent,
        payStats,
        reminder,
        reminderReady,
        adSpentNotes,
        funnelAdsId,
        funnelAdsDone,
        funnelAdsQAbyId,
        funnelAdsQADone,
        funnelSecondAdsId,
        funnelSecondAdsDone,
        funnelSecondAdsQAbyId,
        funnelSecondAdsQADone,
        attendees,
        attendeesD2,

        totalAttendees,
        googleSheet,
        googleSheetD2,
        sheetErrorD2,
        sheetError,
        orangeDot: this.editedEvent?.orangeDot,
        pinkDot: this.editedEvent?.pinkDot,
        orangeDotD2: this.editedEvent?.orangeDotD2,
        pinkDotD2: this.editedEvent?.pinkDotD2,

        wfrCall1Date: wfrCall1Date,
        wfrCall1FunnelId: wfrCall1FunnelId,
        wfrCall1Done: wfrCall1Done,

        wfrCall2Date: wfrCall2Date,
        wfrCall2FunnelId: wfrCall2FunnelId,
        wfrCall2Done: wfrCall2Done,

        wfrCall1D2Date: wfrCall1D2Date,
        wfrCall1D2FunnelId: wfrCall1D2FunnelId,
        wfrCall1D2Done: wfrCall1D2Done,

        wfrCall2D2Date: wfrCall2D2Date,
        wfrCall2D2FunnelId: wfrCall2D2FunnelId,
        wfrCall2D2Done: wfrCall2D2Done,
        isDraft: draft,
        isInReview: review,
        eventType: this.editForm.value?.eventType ?? 'workshopsSeminarType',
      };
    } else {
      submitEventSchedule = {
        newTopic: this.editForm.value?.newTopic?.toString(),
        prebuildTime2Id: this.editForm.value?.prebuildTime2Id,
        prebuildTimeId: this.editForm.value?.prebuildTimeId,
        desiredBUD2,
        newVenue,
        id,
        registrants,
        registrantsD2,
        finalRegistrants,
        tenantId,
        clientId,
        topic,
        prebuildTopicId,
        doubleEvent,
        atCost,
        date,
        status,
        time,
        landingPageUrl,
        wfrDinnerOrLunch,
        twoPartSetup,
        eventScheduleLocationId,
        roomId,
        parking,
        desiredBU,
        notes,
        wfrReminder,
        emailBlast1st,
        emailBlast2nd,
        emailBlastScheduled,
        fewSpots,
        fewSpotsScheduled,
        vmCode,
        fiveDayWfrCallDate,
        fiveDayWfrCall,
        fiveDayWfrCallDone,
        funnelId,
        funnelDone,
        funnelQAbyId,
        funnelQADone,
        adSpent,
        payStats,
        reminder,
        reminderReady,

        adSpentNotes,
        funnelAdsId,
        funnelAdsDone,
        funnelAdsQAbyId,
        funnelAdsQADone,
        attendees,
        totalAttendees,
        googleSheet,
        sheetError,
        orangeDot: this.editedEvent?.orangeDot,
        pinkDot: this.editedEvent?.pinkDot,

        wfrCall1Date: wfrCall1Date,
        wfrCall1FunnelId: wfrCall1FunnelId,
        wfrCall1Done: wfrCall1Done,

        wfrCall2Date: wfrCall2Date,
        wfrCall2FunnelId: wfrCall2FunnelId,
        wfrCall2Done: wfrCall2Done,
        isDraft: draft,
        isInReview: review,
        eventType: this.editForm.value?.eventType ?? 'workshopsSeminarType',
      };
    }
    if (!duplicate && submitEventSchedule) {
      this.EventSubmit.emit(submitEventSchedule);
      this.isDuplicated = false;
    } else if (duplicate && submitEventSchedule) {
      this.isDuplicated = true;

      this.service
        .updateEventSchedule(submitEventSchedule)
        .subscribe({ complete: () => this.duplicate() });
    }
  }
  prebuiltTopic(value: any) {
    const controlObject = this.editForm?.get('topic');
    controlObject?.setValue(null);
    controlObject?.markAsTouched();

    let label = this.TopicsOptions?.find((x) => x.value === value)?.label;
    if (label) {
      controlObject?.setValue(label);
      controlObject?.markAsTouched();
    }

    const prebuildTopicIdObject = this.editForm?.get('prebuildTopicId');
    if (prebuildTopicIdObject) {
      prebuildTopicIdObject?.setValue(value);
      prebuildTopicIdObject?.markAsTouched();
    }

    const newTopicObject = this.editForm?.get('newTopic');
    if (newTopicObject) {
      newTopicObject?.setValue(null);
      newTopicObject?.markAsTouched();
    }
  }

  prebuiltEventInfo(value: any, name: string) {
    let label = this.EventInfosOptions?.find((x) => x.value === value)?.label;
    if (label) {
      const controlObject = this.editForm?.get(name);
      controlObject?.setValue(label);
      controlObject?.markAsTouched();
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  changeClient(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if (value) {
      const eventScheduleLocationController = this.editForm?.get(
        'eventScheduleLocationId'
      );
      eventScheduleLocationController?.setValue(null);
      const roomController = this.editForm?.get('roomId');
      roomController?.setValue(null);
      const roomD2Controller = this.editForm?.get('roomD2Id');
      roomD2Controller?.setValue(null);
      eventScheduleLocationController?.markAsTouched();
      this.SelectedClientId = value;
      this.clientService
        .loadOneCompany(value)
        .subscribe((x) => (this.SelectedClient = x));
      this.clientLocationsService
        .loadAllLocationsForClient(value)
        .subscribe((x) => {
          this.locations = x;
        });
      this.topicService.loadByClientId(value).subscribe((x) => {
        this.topics = x;
      });
    } else {
      this.SelectedClient = null;
      this.SelectedClientId = null;
      this.scheduleLocationsOptions = null;
      this.RoomsOptions = null;
      this.SelectedLocation = null;
      this.SelectedLocationId = null;
      this.editForm.get('parking').setValue(undefined);
      this.editForm.get('roomId').setValue(undefined);
      this.editForm.get('roomD2Id').setValue(undefined);
      this.editForm.get('locationId').setValue(undefined);
    }
  }

  getDateStr(event: any) {
    var datestr = null;
    if (
      event &&
      event.value &&
      event.value.month() &&
      event.value.year() &&
      event.value.date()
    ) {
      datestr =
        event.value.month() +
        1 +
        '/' +
        event.value.date() +
        '/' +
        event.value.year();
    } else {
      datestr = null;
    }
    return datestr;
  }
  handleTimeChange(toChange: string, toClear: string, value: any) {
    this.updateValue(toChange, value);
    this.updateValue(toClear, null);
  }

  setFiveDay(event: any, dateIndx: number) {
    var datestr = this.getDateStr(event);

    switch (dateIndx) {
      case 1:
        this.editForm.controls.fiveDayWfrCallDate.setValue(event.value);
        this.editForm.controls.fiveDayWfrCall.setValue(datestr);
        break;
      case 2:
        this.editForm.controls.fiveDayWfrCallD2Date.setValue(event.value);
        this.editForm.controls.fiveDayWfrCallD2.setValue(datestr);
        break;
      default:
        break;
    }
  }
  isDate1Valid = true;
  isDate2Valid = true;
  validateDate(incomingDate: any): boolean {
    return dayjs().isBefore(dayjs(incomingDate).add(1, 'day'), 'day');
  }
  setStartDate(event: any) {
    this.isDate1Valid = this.validateDate(event.value);
    if (!this.isDate1Valid) {
      this.editForm.controls.emailBlast1st.setValue(null);
      this.editForm.controls.emailBlast2nd.setValue(null);
      this.editForm.controls.fewSpots.setValue(null);
      this.editForm.controls.date.setValue(null);
      this.editForm.controls.formatedDate.setValue(null);
      return;
    }
    this.editForm.controls.date.setValue(event.value);
    var emailBlast1stdate = dayjs(event.value).add(11, 'hour').add(-14, 'day');
    var emailBlast2nddate = dayjs(event.value).add(11, 'hour').add(-7, 'day');
    var fewSpotsdate = dayjs(event.value).add(11, 'hour').add(-9, 'day');
    var gendate = dayjs(event.value).add(11, 'hour');
    this.editForm.controls.formatedDate.setValue(
      moment(gendate.toDate()).format('M/DD/YYYY')
    );
    //this.editForm.controls.formatedDate.setValue(
    //  gendate.month() + 1 + '/' + gendate.date() + '/' + gendate.year()
    //);

    this.editForm.controls.emailBlast1st.setValue(
      emailBlast1stdate.month() +
        1 +
        '/' +
        emailBlast1stdate.date() +
        '/' +
        emailBlast1stdate.year()
    );
    this.editForm.controls.emailBlast2nd.setValue(
      emailBlast2nddate.month() +
        1 +
        '/' +
        emailBlast2nddate.date() +
        '/' +
        emailBlast2nddate.year()
    );

    this.editForm.controls.fewSpots.setValue(
      fewSpotsdate.month() +
        1 +
        '/' +
        fewSpotsdate.date() +
        '/' +
        fewSpotsdate.year()
    );
  }

  setStartDate2(event: any) {
    this.isDate2Valid = this.validateDate(event.value);
    if (!this.isDate2Valid) {
      this.editForm.controls.emailBlast1stD2.setValue(null);
      this.editForm.controls.date2.setValue(null);
      this.editForm.controls.emailBlast2ndD2.setValue(null);
      this.editForm.controls.fewSpotsD2.setValue(null);
      this.editForm.controls.formatedDate2.setValue(null);
      return;
    }
    this.editForm.controls.date2.setValue(event.value);
    var emailBlast1stdate = dayjs(event.value).add(11, 'hour').add(-14, 'day');
    var emailBlast2nddate = dayjs(event.value).add(11, 'hour').add(-7, 'day');
    var fewSpotsdate = dayjs(event.value).add(11, 'hour').add(-9, 'day');
    var gendate = dayjs(event.value).add(11, 'hour');
    this.editForm.controls.formatedDate2.setValue(
      moment(gendate.toDate()).format('M/DD/YYYY')
    );

    //this.editForm.controls.formatedDate2.setValue(
    //  gendate.month() + 1 + '/' + gendate.date() + '/' + gendate.year()
    //);

    this.editForm.controls.emailBlast1stD2.setValue(
      emailBlast1stdate.month() +
        1 +
        '/' +
        emailBlast1stdate.date() +
        '/' +
        emailBlast1stdate.year()
    );
    this.editForm.controls.emailBlast2ndD2.setValue(
      emailBlast2nddate.month() +
        1 +
        '/' +
        emailBlast2nddate.date() +
        '/' +
        emailBlast2nddate.year()
    );
    this.editForm.controls.fewSpotsD2.setValue(
      fewSpotsdate.month() +
        1 +
        '/' +
        fewSpotsdate.date() +
        '/' +
        fewSpotsdate.year()
    );
  }

  changeLocation(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if (value) {
      const eventScheduleLocationController = this.editForm?.get('roomId');
      eventScheduleLocationController?.setValue(null);
      eventScheduleLocationController?.markAsTouched();
      this.SelectedLocationId = value;
      this.clientLocationsService.loadOneLocation(value).subscribe((x) => {
        this.SelectedLocation = x;
        this.editForm.get('parking').setValue(x.parking);
      });

      this.roomService.loadAllRoomsForLocation(value).subscribe((x) => {
        this.rooms = x;
        this.allRooms = x;
        if (x.length) {
          this.editForm.get('roomId').setValue(x[0].id);
          this.editForm.get('roomD2Id').setValue(x[0].id);
        }
      });
    } else {
      this.RoomsOptions = null;
      this.SelectedLocation = null;
      this.SelectedLocationId = null;
      this.editForm.get('parking').setValue(undefined);
      this.editForm.get('roomId').setValue(undefined);
      this.editForm.get('roomD2Id').setValue(undefined);
    }
  }
  changeWFRor2part(check: string, value: boolean) {
    if (value)
      if (check == 'WFR') {
        this.editForm.get('twoPartSetup').setValue(false);
      } else if (check == 'twoPartSetup') {
        this.editForm.get('wfrDinnerOrLunch').setValue(false);
      }
  }

  locationBack() {
    this.location.back();
  }
  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) {
      modal.style.display = 'none';
      this.popUpForm?.clear();
    }
  }

  showModal(tab: string) {
    this.popUpTab = tab;
    var modal = document.getElementById('myModal') as HTMLModElement;
    modal.style.display = 'block';
  }

  reloadRooms() {
    var location = this.editForm.get('eventScheduleLocationId')?.value;
    if (location)
      this.roomService.loadAllRoomsForLocation(location).subscribe((x) => {
        this.rooms = x;
        this.allRooms = x;
        this.popUpForm!.clear();
        this.popUpForm!.isLoading = false;
        this.hidemodal();
      });
  }
  reloadlocations() {
    var client = this.editForm.get('clientId')?.value;
    if (client)
      this.clientLocationsService
        .loadAllLocationsForClient(client)
        .subscribe((x) => {
          this.locations = x;
          this.popUpForm!.clear();
          this.popUpForm!.isLoading = false;
          this.popUpForm!.locations = x;
          this.hidemodal();
        });
  }

  saveDraft() {
    if (this.ownClient) {
      this.onSubmit();
    } else this.submitErrors = ['Can not read client for logged user'];
  }

  changeRoom(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();

    let parking_control_string = '';
    let desiredBU_control_string = '';
    if (control == 'roomId') {
      parking_control_string = 'parking';
      desiredBU_control_string = 'desiredBU';
    } else {
      parking_control_string = 'parkingD2';
      desiredBU_control_string = 'desiredBUD2';
    }

    if (value) {
      let roomselected = this.allRooms?.find((x) => x.id == value);
      if (roomselected) {
        if (roomselected.parking)
          this.editForm
            .get(parking_control_string)
            .setValue(roomselected.parking);
        if (roomselected.desiredBUCapasity)
          this.editForm
            .get(desiredBU_control_string)
            .setValue(roomselected.desiredBUCapasity);
      }
    } else {
      this.editForm.get(parking_control_string).setValue(undefined);
      this.editForm.get(desiredBU_control_string).setValue(undefined);
    }
  }

  manageEventType(control: string) {
    this.editForm.get('eventType').setValue(control);
  }
  changeTopic() {
    let val = this.editForm?.get('newTopic');
    if (val) {
      const controlObject = this.editForm?.get('topic');
      const prebuildTopicIdObject = this.editForm?.get('prebuildTopicId');

      controlObject?.setValue(val?.value);
      controlObject?.markAsTouched();

      prebuildTopicIdObject?.setValue(null);
      prebuildTopicIdObject?.markAsTouched();
    }
  }
}
