<div class="list-section">
  <div class="responsiveTop">
    <div class="d-flex justify-content-between align-items-center p-2">
      <h3 class="m-0">
        {{ displayName }}
      </h3>
      <div class="align-items-center d-flex">
        <button
          class="accordion-button btn collapsed p-1 me-2 filterBtn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#filterCollapse"
          aria-controls="filterCollapse"
        >
          Filter
        </button>

        <button
          class="btn btn-wfrBlue shadow-sm btn-sm"
          [routerLink]="['/ticket/new']"
        >
          New
        </button>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center px-2 pt-0 pb-1 mb-2 w-100"
    >
      <div class="col p-0 m-0 p-relative">
        <input
          [formControl]="searchControl"
          type="text"
          class="inpt p-1"
          placeholder="Search .."
          (change)="search()"
        />
        <button
          class="close-icon closeRelative btn-sm p-0"
          type="reset"
          (click)="clearSearch()"
        ></button>
      </div>
      <div>
        <button class="btn col btn-search ms-1 p-1" (click)="search()">
          <img src="../../../../../assets/img/search/seacrch-sm.svg" />
        </button>
      </div>
    </div>

    <div
      #filterCollapse
      id="filterCollapse"
      class="accordion-collapse collapse row w-100"
      aria-labelledby="headingOne"
    >
      <div class="col-6 ps-4">
        <app-datalist-select
          [options]="clientsOptions"
          [(activeValue)]="filterClient"
          [label]="'Client'"
          (activeValueChange)="search()"
        ></app-datalist-select>
      </div>
      <div class="col-6 ps-2">
        <app-datalist-select
          [options]="locationsOptions"
          [(activeValue)]="filterLocation"
          [label]="'Location'"
          (activeValueChange)="search()"
        ></app-datalist-select>
      </div>
      <div class="col-6 ps-4">
        <app-datalist-select
          [options]="creatorOptions"
          [(activeValue)]="filterCreator"
          [label]="'Creator'"
          (activeValueChange)="search()"
        ></app-datalist-select>
      </div>
      <div class="col-6 ps-2">
        <app-datalist-select
          [options]="assigneeOptions"
          [(activeValue)]="filterAssignee"
          [label]="'Assignee'"
          (activeValueChange)="search()"
        ></app-datalist-select>
      </div>
    </div>

    <div class="d-flex flex-column scroll-y b-top emojiFonts">
      <div
        *ngFor="let ticket of filteredTickets"
        class="d-flex ticket b-bot"
        (click)="select(ticket.id)"
        [class.selected]="ticket.id == selectedTicketId"
      >
        <div class="icon">
          <img
            class="imgSizing"
            src="../../../../../assets/img/tickets/ticket.svg"
            loading="lazy"
          />
        </div>
        <div class="p-1 ticket-text-width">
          <div class="w-100 ticket-text txt-bold">
            {{ ticket.formatedTopic }}
          </div>
          <div *ngIf="ticket.comments?.length" class="w-100 ticket-text">
            <ng-container *ngVar="ticket.comments[0] as comment">
              <span *ngIf="comment.userName"> {{ comment.userName }} : </span>
              <span class="emojiFonts">{{ comment.content }}</span>
            </ng-container>
          </div>
        </div>

        <div class="p-1 ticket-date text-muted">
          <span class="me-1"> {{ ticket.formatedCreatedAt }} </span>
          <a class="btn btn-menu" [href]="'/ticket/' + ticket.id">
            <img src="assets/img/menu/dots-vertical.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
