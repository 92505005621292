<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-12 col-lg-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title oneLineTextHeader" i18n="Label users">
        Clients list
        <span class="ps-3">({{ repo.getPaginationData().total }}) </span>
      </h2>
    </div>
    <div
      *ngIf="innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <div class="d-flex">
        <a
          routerLink="/admin/clients/new"
          class="d-flex justify-content-center btn btn-lg dark-wfrBlue text-white"
          style="justify-content: end; width: 140px !important"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99996 1.16699V12.8337M1.16663 7.00033H12.8333"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="text-btn-add" i18n="@@addClient">Add Client</span>
        </a>
      </div>
    </div>
    <div class="ps-1 p-sm-0 d-flex align-items-center row p-0 m-0">
      <app-search
        class="col m-0 p-0 pe-2"
        (searchChange)="setFilter($event)"
      ></app-search>
      <div class="col p-0 m-0" style="max-width: 150px">
        <label class="inpt-title">Search for Name</label>
        <input
          type="checkbox"
          (change)="changeCheckboxes(1); refreshData()"
          [(ngModel)]="searchForName"
          class="ms-2 form-check-input"
        />
        <label class="inpt-title">Search for CC</label>
        <input
          type="checkbox"
          (change)="changeCheckboxes(2); refreshData()"
          [(ngModel)]="searchForCC"
          class="ms-2 form-check-input"
        />
      </div>

      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="
          service.sort($event, searchFilter, searchForCC).subscribe()
        "
      ></app-sort-dropdown>
    </div>
    <div
      class="pe-1 mt-4 ps-1 p-sm-0 d-flex justify-content-between align-items-center"
      *ngIf="deleteError"
    >
      <app-error-alert
        class="me-1 w-100"
        title="Could not delete Entity "
        [errors]="deleteError"
        [enableCancel]="true"
        (cancel)="clearError()"
      ></app-error-alert>
    </div>

    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="box mb-2 mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header p-0" style="width: 100%">
        <div class="mt-1 ms-1 d-lex row pt-1">
          <div class="col-1 d-flex p-0 m-0" style="width: 45px">
            <span class="txt mt-1">Status</span>
          </div>
          <div class="col-sm-1 col-2 d-flex p-0 m-0">
            <span class="txt mt-1">Client Code</span>
          </div>
          <div class="col-sm-6 col-6 d-flex p-0 m-0">
            <span class="txt mt-1 ms-1">Client Name</span>
          </div>
        </div>
      </div>
      <br />
      <ng-container *ngVar="repo.page$ | async as companies">
        <div
          *ngFor="let company of companies; let curentMonthIndex = index"
          class="row brd d-flex"
          style="margin-left: 0.1rem"
        >
          <div class="pt-3 pb-3 align-items-center p-0 m-0 w-40px">
            <div
              class="txt-name d-flex align-items-center"
              (click)="mouseEnter(curentMonthIndex, $event)"
              (mouseleave)="mouseLeave()"
            >
              <div class="d-flex align-items-center">
                <div
                  *ngIf="company.status === 'Active'"
                  class="rounded-circle size15x15 bg-green"
                ></div>

                <div
                  *ngIf="company.status === 'Disabled'"
                  class="rounded-circle size15x15 bg-gray"
                ></div>
              </div>
              <app-companies-status-pop-up
                [left]="statusPopLeft"
                [top]="statusPopTop"
                [companyOnPopUp]="company"
                (submit)="changeStatus($event); mouseLeave()"
                [hidden]="clickedIndex != curentMonthIndex"
              ></app-companies-status-pop-up>
            </div>
          </div>
          <div
            class="col-sm-1 col-2 pt-3 pb-3 d-flex align-items-center p-0 m-0"
          >
            <div class="txt-name d-flex align-items-center">
              <div
                class="text-break"
                [routerLink]="['/admin/clients/', company.id]"
              >
                <span class="oneLineText"> {{ company.clientCode }} </span>
              </div>
            </div>
          </div>
          <div
            class="col-sm-6 col-8 pt-3 pb-3 d-flex align-items-center p-0 m-0"
          >
            <div class="txt-name d-flex align-items-center">
              <div
                class="text-break"
                [routerLink]="['/admin/clients/', company.id]"
              >
                <span class="oneLineText"> {{ company.title }} </span>
              </div>
            </div>
          </div>
          <div
            *ngIf="innerWidth > 575"
            class="col-4 d-flex align-items-center justify-content-end p-0"
          >
            <div>
              <a
                class="btn btn-md btn-link w-100"
                [routerLink]="['/admin/clients/clientUsers/', company.id]"
                title="Client Users"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                class="btn btn-md btn-link w-100"
                [routerLink]="['/admin/clients/locations/', company.id]"
                title="Locations"
                i18n-title="Locations button"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 11.3744C18.5318 12.0688 21 13.6547 21 15.5C21 17.9853 16.5228 20 11 20C5.47715 20 1 17.9853 1 15.5C1 13.6547 3.46819 12.0688 7 11.3744M11 15V1L16.3177 4.27244C16.7056 4.51114 16.8995 4.63049 16.9614 4.78085C17.0154 4.912 17.0111 5.0599 16.9497 5.18771C16.8792 5.33426 16.6787 5.44222 16.2777 5.65815L11 8.5"
                    stroke="#475467"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                class="btn btn-md btn-link w-100"
                [routerLink]="['/admin/clients/', company.id]"
                title="Edit"
                i18n-title="Edit button"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
                    stroke="#475467"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-md btn-link w-100 text-danger"
                data-bs-target="#deleteusermodal"
                (click)="deleteConfirmation = company"
                title="Delete"
                i18n-title="Delete button"
              >
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3333 5.00033V4.33366C12.3333 3.40024 12.3333 2.93353 12.1517 2.57701C11.9919 2.2634 11.7369 2.00844 11.4233 1.84865C11.0668 1.66699 10.6001 1.66699 9.66667 1.66699H8.33333C7.39991 1.66699 6.9332 1.66699 6.57668 1.84865C6.26308 2.00844 6.00811 2.2634 5.84832 2.57701C5.66667 2.93353 5.66667 3.40024 5.66667 4.33366V5.00033M7.33333 9.58366V13.7503M10.6667 9.58366V13.7503M1.5 5.00033H16.5M14.8333 5.00033V14.3337C14.8333 15.7338 14.8333 16.4339 14.5608 16.9686C14.3212 17.439 13.9387 17.8215 13.4683 18.0612C12.9335 18.3337 12.2335 18.3337 10.8333 18.3337H7.16667C5.76654 18.3337 5.06647 18.3337 4.53169 18.0612C4.06129 17.8215 3.67883 17.439 3.43915 16.9686C3.16667 16.4339 3.16667 15.7338 3.16667 14.3337V5.00033"
                    stroke="#475467"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <br />
      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service.loadPage($event, 25, searchFilter, searchForCC).subscribe()
          "
        ></app-custom-pagination>
        <div
          class="w-100 d-flex justify-content-end add"
          *ngIf="innerWidth < 1050"
        >
          <a
            routerLink="/admin/clients/new"
            class="d-flex justify-content-center btn btn-lg dark-wfrBlue text-white"
            style="justify-content: end; width: 140px !important"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99996 1.16699V12.8337M1.16663 7.00033H12.8333"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="text-btn-add" i18n="@@addCompany">Add Client</span>
          </a>
        </div>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
  </div>
</div>
