<div *ngFor="let month of months; let curentMonthIndex = index" class="p-0 m-0">
  <div class="d-flex align-items-center">
    <button
      class="ms-0 btn btn-wfrBlue w-100 mt-2"
      style="padding-top: 3px; padding-bottom: 3px"
      (click)="expand.emit(month.id)"
      id="ads-view-button-{{ curentMonthIndex }}"
    >
      <span class="d-flex justify-content-start">
        <span>
          {{ month.name }} | Events: {{ month.eventsCount }} | AE:
          {{ month.aeCount }} | Rescheduled: {{ month.rescheduledCount }} |
          Cancelled: {{ month.cancelledCount }} | Net-Events:
          {{ month.netEventsCount }}
        </span>
      </span>
    </button>
  </div>
  <div
    class="box my-2 hide-scrollbar"
    style="overflow-x: scroll; overflow-y: hidden; padding-bottom: 10px"
    *ngIf="month.expanded"
  >
    <div class="box-name-header p-0 m-0">
      <div class="d-flex justify-content-between p-2">
        <div class="  " style="width: 200px">
          <span class="txt">Status</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">CC</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">Client</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">Date</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">Location</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">Time</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">Topic</span>
        </div>
        <div style="width: 500px">
          <span class="txt mt-1">Reminder</span>
        </div>
        <div style="min-width: 50px">
          <span class="txt mt-1"></span>
        </div>
      </div>
    </div>
    <ng-container *ngVar="month.showEventSchedules as events">
      <div infiniteScroll (scrolled)="onScrollDown(month)">
        <div *ngFor="let event of events; let curentEventIndx = index">
          <div
            class="d-flex justify-content-between px-2 h60"
            [class.b-top-gray]="
              events.length &&
              curentEventIndx !== 0 &&
              curentEventIndx !== events.length
            "
          >
            <div
              class="d-flex align-items-center"
              style="width: 200px"
              type="button"
              (click)="
                mouseEnter(
                  curentMonthIndex,
                  curentEventIndx,
                  'changeStatus-',
                  $event,
                  1
                )
              "
            >
              <app-event-status
                [status]="event.status"
                [statusNum]="+event.statusNum"
              >
              </app-event-status>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.client.clientCode }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.client.title }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.formatedDateAdmin }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.eventScheduleLocation?.title }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.time ?? event.prebuildTime?.name }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.topic }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 500px">
              <div class="text-break oneLineText">
                {{ event.wfrReminder }}
              </div>
            </div>

            <div
              class="d-flex align-items-center justify-content-end"
              style="min-width: 50px; right: 0"
            >
              <div
                *ngIf="event.isInReview || event.isDraft"
                class="d-flex align-items-center justify-content-end"
              >
                <div>
                  <a
                    class="btn btn-link w-100 p-0 m-0"
                    [routerLink]="[
                      '/admin/eventSchedule/eventSchedule/',
                      event.id
                    ]"
                    title="Review"
                  >
                    <img src="assets/img/edit19x19.svg" />
                  </a>
                </div>
                <!-- <div>
                  <button
                    type="button"
                    class="btn btn-link w-100 p-0 px-1 m-0"
                    data-bs-target="#deleteusermodal"
                    title="Delete"
                    i18n-title="Delete button"
                    (click)="deleteConfirmation = event"
                  >
                    <img src="assets/img/delete18x20.svg" />
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div *ngIf="event.doubleEvent">
            <div class="d-flex justify-content-between px-2 h60">
              <div
                class="d-flex align-items-center"
                style="width: 200px"
                type="button"
                (click)="
                  mouseEnter(
                    curentMonthIndex,
                    curentEventIndx,
                    'changeStatusD2-',
                    $event,
                    2
                  )
                "
              >
                <app-event-status
                  [status]="event.statusD2"
                  [statusNum]="+event.statusNumD2"
                >
                </app-event-status>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.client.clientCode }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.client.title }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.formatedDate2Admin }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.time2 ?? event.prebuildTime2?.name }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.eventScheduleLocation?.title }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.topic }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 500px">
                <div class="text-break oneLineText">
                  {{ event.wfrReminder }}
                </div>
              </div>

              <div
                class="d-flex align-items-center justify-content-end"
                style="min-width: 50px; right: 0"
              >
                <div
                  *ngIf="event.isInReview || event.isDraft"
                  class="d-flex align-items-center justify-content-end"
                >
                  <div>
                    <a
                      class="btn btn-link w-100 p-0 m-0"
                      [routerLink]="[
                        '/admin/eventSchedule/eventSchedule/',
                        event.id
                      ]"
                      title="Review"
                    >
                      <img src="assets/img/edit19x19.svg" />
                    </a>
                  </div>
                  <!-- <div>
                    <button
                      type="button"
                      class="btn btn-link w-100 p-0 px-1 m-0"
                      data-bs-target="#deleteusermodal"
                      title="Delete"
                      i18n-title="Delete button"
                      (click)="secondDeleteConfirmation = event"
                    >
                      <img src="assets/img/delete18x20.svg" />
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- <app-confirm-delete
  id="deleteusermodal"
  *ngIf="deleteConfirmation"
  [eventSchedule]="true"
  [clientCode]="deleteConfirmation.client.clientCode"
  [clientName]="deleteConfirmation.client.title"
  [date]="deleteConfirmation.formatedDateAdmin"
  [location]="deleteConfirmation.eventScheduleLocation?.title"
  [deleteCandidate]="''"
  (confirm)="
    deleteConfirmation.doubleEvent
      ? setSecondDateAsPrimary(deleteConfirmation)
      : handleDeleteClick(deleteConfirmation);
    deleteConfirmation = null
  "
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
<app-confirm-delete
  id="esDeleteConfirmModal"
  *ngIf="secondDeleteConfirmation"
  [eventSchedule]="true"
  [clientCode]="secondDeleteConfirmation.client.clientCode"
  [clientName]="secondDeleteConfirmation.client.title"
  [date]="secondDeleteConfirmation.formatedDate2Admin"
  [location]="secondDeleteConfirmation.eventScheduleLocation?.title"
  [deleteCandidate]="''"
  (confirm)="
    secondHandleDeleteClick(secondDeleteConfirmation);
    secondDeleteConfirmation = null
  "
  (cancel)="secondDeleteConfirmation = null"
></app-confirm-delete> -->
<!-- <app-change-status-modal
[left]="statusLeft"
[top]="statusTop"
[eventScheduleOnPopUp]="event"
[statusIndex]="1"
(submit)="refreshData.emit(); mouseLeave()"
id="changeStatus-{{ curentMonthIndex }}-{{ curentEventIndx }}"
[hidden]="
  clickedMonthIndex != curentMonthIndex ||
  clickedEventIndex != curentEventIndx ||
  clickedDateIndex != 'changeStatus-'
"
></app-change-status-modal> -->
<app-change-status-modal
  [top]="statusTop"
  [left]="statusLeft"
  [eventId]="editedEventId!"
  [statusIndex]="dateIndex"
  (mouseLeave)="mouseLeave()"
  (submit)="refreshData.emit(); mouseLeave()"
  [hidden]="
    clickedMonthIndex == null ||
    clickedEventIndex == null ||
    dateIndex == null ||
    editedEventId == null
  "
></app-change-status-modal>
