import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  EventScheduleRepository,
  IEventSchedule,
  IMonthWithEvents,
  IPageProperties,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-client-month-table',
  templateUrl: './client-month-table.component.html',
  styleUrls: ['./client-month-table.component.scss'],
})
export class ClientMonthTableComponent {
  constructor(
    public repo: EventScheduleRepository,
    public service: EventScheduleService
  ) {}

  @Input() mappedMonths: IMonthWithEvents[] = [];
  @Input() pageProperties?: IPageProperties;
  @Output() showModal = new EventEmitter<string>();
  @Output() expand = new EventEmitter<string>();
  @Output() refreshData = new EventEmitter<void>();
  deleteConfirmation: IEventSchedule | null = null;
  secondDeleteConfirmation: IEventSchedule | null = null;
  clickedMonthIndex: number | null = null;
  clickedEventIndex: number | null = null;
  clickedDateIndex: string | null = null;
  statusTop: number = 0;
  statusLeft: number = 0;
  isMonthClicked: boolean = false;
  @Output() refreshMonth = new EventEmitter<IMonthWithEvents>();

  sortmonthStatus(curentMonthIndex: number, $event: any) {
    let filtered = this.mappedMonths[curentMonthIndex];
    if (!$event && filtered) {
      filtered.sortStatus = undefined;
      filtered.sortStatusNum = undefined;
      this.refreshMonth.emit(filtered);
    } else if (filtered) {
      filtered.sortStatus = $event.label;
      filtered.sortStatusNum = $event.value;
      this.refreshMonth.emit(filtered);
    }
  }
  mouseEnter(mon: number, eve: number, name: string, event: any) {
    this.clickedMonthIndex = mon;
    this.clickedEventIndex = eve;
    this.clickedDateIndex = name;
    this.statusLeft = event.pageX - 20;
    this.statusTop = event.pageY;
  }
  mouseLeave() {
    this.clickedMonthIndex = null;
    this.clickedEventIndex = null;
    this.statusLeft = 0;
    this.statusTop = 0;
  }
  showSortStatus(mon: number, event: any, name: string) {
    this.clickedDateIndex = name;
    this.isMonthClicked = true;
    this.clickedMonthIndex = mon;
    this.statusLeft = event.pageX - 20;
    this.statusTop = event.pageY;
  }
  onScrollDown(ev: any) {
    var curMonth = this.mappedMonths.find((x) => x.id == ev.id);

    if (
      curMonth &&
      curMonth.eventSchedules.length &&
      curMonth.showEventsCount! < curMonth.eventSchedules.length
    ) {
      this.appendItems(ev);
      curMonth.showEventsCount! += 20;
    }
  }
  appendItems(ev: IMonthWithEvents) {
    var curMonth = this.mappedMonths.find((x) => x.id == ev.id);
    if (curMonth) {
      let temp = this.repo.setLastStatusEvents(ev, this.pageProperties);
      for (let i = ev.showEventsCount!; i < ev.showEventsCount! + 20; ++i) {
        if (temp[i]) {
          curMonth.showEventSchedules?.push(temp[i]);
        }
      }
    }
  }
  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }

  setSecondDateAsPrimary(post: IEventSchedule) {
    this.service.setSecondDateAsPrimary(post.id).subscribe({
      complete: () => {
        this.refreshData.emit();
      },
    });
  }

  handleDeleteClick(post: IEventSchedule) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.refreshData.emit();
      },
    });
  }
  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }
  secondHandleDeleteClick(post: IEventSchedule) {
    this.service.deleteSecond(post.id).subscribe({
      complete: () => {
        this.refreshData.emit();
      },
    });
  }
}
