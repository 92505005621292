<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-12 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">Document list</h2>
    </div>
    <div class="box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-12 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">Name</span>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let document of documents"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-12 pt-3 pb-3 d-flex align-items-center psm0">
          <input class="check form-check-input me-2" type="checkbox" />
          <div class="txt-name">
            <div class="text-break">
              <a
                class="href"
                href="{{ document.documentPath }}"
                target="_blank"
              >
                {{ document.title | slice : 0 : 80 }}
                <span *ngIf="document.title && document.title.length > 50"
                  >...</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />

      <!--<div class="box-pagination-footer">
        <app-custom-pagination [innerWidth]="innerWidth"
                               [page]="(repo.pageNumber$ | async) || 1"
                               [total]="(repo.paginationData$ | async)?.lastPage || 0"
                               (pageChange)="service.loadPage($event).subscribe()"></app-custom-pagination>

        <div class="w-100  d-flex justify-content-end add" *ngIf=" innerWidth < 1050">
         
        </div>
      </div>-->
    </div>
  </div>
</div>
