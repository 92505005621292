import { Component, Input } from '@angular/core';
import { IEventSchedule } from 'src/app/state/event-schedule.repository';

@Component({
  selector: 'app-event-snapshot-modal',
  templateUrl: './event-snapshot-modal.component.html',
  styleUrls: ['./event-snapshot-modal.component.scss'],
})
export class EventSnapshotModalComponent {
  @Input() isLoading = false;
  @Input() eventScheduleOnPopUp: IEventSchedule | null = null;
}
