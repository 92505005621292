import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export const ReferralSourcesSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];

export interface IReferralSource {
  id: string;
  tenantId: string;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class ReferralSourcesRepository extends BaseRepository<IReferralSource> {
  constructor() {
    super('ReferralSourcesSortOptions', ReferralSourcesSortOptions);
  }
}
