import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

import {
  CompaniesRepository,
  IClientOption,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
} from './companies.repository';
import { SortState } from '../modules/shared/pipes/sort.pipe';

const API = '/api/companies';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseService<ICompanyMarker> {
  constructor(http: HttpClient, repo: CompaniesRepository) {
    super(API, http, repo);
  }
  changeCompanyStatus(status: string, id: string) {
    return this.http.get<ICompanyMarker>(
      API + `/changeCompanyStatus/${status}/${id}`
    );
  }
  postCompany(entity: Partial<ICompanyMarker>) {
    return this.http.post<ICompanyMarker>(API + '/postCompany', entity);
  }
  override reloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    clientCode: boolean = false,
    contacts: boolean = false
  ): Observable<(PaginationData & { data: ICompanyMarker[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(
        data.currentPage,
        data.perPage,
        search,
        clientCode,
        contacts
      );
    }
    return this.loadPage(1, defaultTake, search, clientCode, contacts);
  }

  override loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    clientCode: boolean = false,
    contacts: boolean = false
  ): Observable<PaginationData & { data: ICompanyMarker[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `clientCode=${clientCode}`,
      `contacts=${contacts}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: ICompanyMarker[] }>(
        `${API}/CC?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  search(searchString: string) {
    return this.http.get<ICompanyMarker[]>(API + '/search/' + searchString);
  }

  override sort(
    sortBy: SortState,
    search?: string,
    clientCode: boolean = false,
    contacts: boolean = false
  ) {
    this.repo.setSort(sortBy);
    return this.reloadPage(
      search,
      DEFAULT_ENTITIES_PER_PAGE,
      clientCode,
      contacts
    );
  }
  override searchReloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    clientCode: boolean = false,
    contacts: boolean = false
  ): Observable<(PaginationData & { data: ICompanyMarker[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(1, data.perPage, search, clientCode, contacts);
    }
    return this.loadPage(1, defaultTake, search, clientCode, contacts);
  }

  loadAll() {
    return this.http.get<ICompanyMarker[]>(API + '/loadAll').pipe(
      tap((companies) => {
        this.repo.clear();
        this.repo.set(companies);
      })
    );
  }

  loadOptions() {
    return this.http.get<IClientOption[]>(API + '/loadOptions');
  }
  loaOptFromTickets() {
    return this.http.get<IClientOption[]>(API + '/loaOptFromTickets');
  }

  clientUserActive() {
    return this.http.get<ICompanyMarker[]>(API + '/getClientUserActive').pipe();
  }
  loadAllActive() {
    return this.http.get<ICompanyMarker[]>(API + '/loadAllActive').pipe(
      tap((companies) => {
        this.repo.clear();
        this.repo.set(companies);
      })
    );
  }

  loadMapInfo() {
    return this.http.get<IMapInfo>(API + '/loadMapInfo');
  }

  loadOneCompany(id: string) {
    return this.http.get<ICompanyMarker>(API + '/loadOneCompany/' + id);
  }

  updateCompany(entity: Partial<ICompanyMarker>) {
    return this.http.patch<any>(API + '/updateCompany', entity);
  }

  updateLocaion(entity: Partial<ICompanyLocation>) {
    return this.http.patch<any>(API + '/UpdateLocationRadius', entity);
  }
}
