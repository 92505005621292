import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  HostListener,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Post } from 'src/app/state/posts.repository';
import {
  DocumentsRepository,
  IDocument,
} from '../../../../state/document.repository';
import { DocumentsService } from '../../../../state/document.service';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import {
  FoldersRepository,
  IFolder,
} from '../../../../state/folders.repository';
import { FoldersService } from '../../../../state/folders.service';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { PostsService } from '../../../../state/posts.service';

declare var ClassicEditor: any;

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss'],
})
export class NewsFormComponent implements OnInit, AfterViewInit {
  editForm: UntypedFormBuilder | any;
  editor: any;
  file: File | null = null;
  submitErrors: string[] | null = null;
  documentTypeId: string = '';
  @Input() editedPost: Partial<Post> | null = null;
  @Output() newsSubmit = new EventEmitter<Partial<Post>>();
  ParentFolderOptions: DatalistOption[] | null = null;
  DocumentTypeOptions: DatalistOption[] | null = null;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.editForm) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.editForm.value.document = this.file;
      }
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    public foldersRepo: FoldersRepository,
    public foldersService: FoldersService,
    public documentypesRepo: DocumentTypesRepository,
    public documenttypesService: DocumentTypesService,
    public DocumentService: DocumentsService,
    private postService: PostsService,
    private location: Location
  ) {}

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      title: [this.editedPost?.title, Validators.required],
      editor: this.editedPost?.content,
      documentTitle: [this.editedPost?.documentTitle],
      //folderId: [this.editedPost?.folderId, Validators.required],
      documentInserted: [
        this.editedPost?.documentInserted,
        Validators.required,
      ],
    });

    this.documenttypesService.loadDefaultDoctype('News').subscribe((x) => {
      this.documentTypeId = x.id;
    });
  }

  ngAfterViewInit() {
    if (typeof ClassicEditor !== 'function') {
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/assets/js/ckeditor.js';
      this.document.body.appendChild(script);
      script.onload = () => this.initEditor();
    } else {
      this.initEditor();
    }
  }

  private initEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      if (this.editedPost?.content) {
        newEditor.setData(this.editedPost?.content);
      }
      this.editor = newEditor;
    });
  }

  onSubmit() {
    const title = this.editForm.value?.title.toString();
    const content = this.editor.getData();
    const documentTitle = this.editForm.value?.documentTitle.toString();

    //const folderId = this.editForm.value?.folderId;
    this.submitErrors = null;

    //if editing but document didnt change
    if (
      !this.file &&
      this.editedPost?.documentPath &&
      this.editedPost?.documentId
    ) {
      if (
        title &&
        content &&
        documentTitle &&
        this.documentTypeId /*&& folderId*/
      ) {
        this.newsSubmit.emit({
          title,
          content,
          documentId: this.editedPost?.documentId,
          documentTitle,
          documentTypeId: this.documentTypeId,
          documentPath: this.editedPost?.documentPath,
          /* folderId,*/ documentInserted: this.file,
        });
        this.location.back();
        return;
      }
    }
    //if editing and document changed
    if (
      this.file &&
      this.editedPost?.documentPath &&
      this.editedPost?.documentId
    ) {
      if (
        title &&
        content &&
        documentTitle &&
        this.documentTypeId /*&& folderId*/
      ) {
        this.newsSubmit.emit({
          title,
          content,
          documentId: this.editedPost?.documentId,
          documentTitle,
          documentTypeId: this.documentTypeId,
          documentPath: this.editedPost?.documentPath /*, folderId*/,
          documentInserted: this.file,
        });
        this.postService
          .update(`${this.editedPost?.id}`, {
            title,
            content,
            documentId: this.editedPost?.documentId,
            documentTitle,
            documentTypeId: this.documentTypeId,
            documentPath: this.editedPost?.documentPath /*, folderId*/,
            documentInserted: this.file,
          })
          .subscribe((x) => {
            this.DocumentService.updateDocument({
              title: documentTitle,
              id: this.editedPost?.documentId,
              documentTypeId: this.documentTypeId,
              documentPath: this.editedPost?.documentPath /*, folderId */,
            }).subscribe((y) => {
              this.DocumentService.patch({
                id: this.editedPost?.documentId,
                document: this.file,
              }).subscribe();
            });
          });
        this.location.back();
        return;
      }
    }
    if (
      !this.file &&
      this.editedPost?.documentPath &&
      this.editedPost?.documentId
    ) {
      if (
        title &&
        content &&
        documentTitle &&
        this.documentTypeId /*&& folderId*/
      ) {
        this.newsSubmit.emit({
          title,
          content,
          documentId: this.editedPost?.documentId,
          documentTitle,
          documentTypeId: this.documentTypeId,
          documentPath: this.editedPost?.documentPath /*, folderId*/,
          documentInserted: this.file,
        });
      }
    }

    let updateResult: Observable<IDocument> | undefined;

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.DocumentService.postDocumentSystem({
      title,
      documentTypeId: this.documentTypeId /*, folderId */,
    }).subscribe((x) => {
      const id = x.id;
      updateResult = this.DocumentService.add({
        id,
        title,
        documentTypeId: this.documentTypeId /*, folderId*/,
        document: this.file,
      });
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            const documentId = x.id;
            this.newsSubmit.emit({
              title,
              content,
              documentId,
              documentTitle,
              documentTypeId: this.documentTypeId /*, folderId*/,
              documentInserted: this.file,
            });
            this.location.back();
          },
          error: (data) => (this.submitErrors = data),
        });
      }
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
