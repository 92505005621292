import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { AuthRepository } from 'src/app/state/auth.repository';
import { EventService } from '../../../state/event.service';
import {
  IEvent,
  EventsRepository,
  calendarSortOptions,
} from 'src/app/state/event.repository';
import { Router } from '@angular/router';
import { EventInfoComponent } from '../event-info/event-info.component';

@Component({
  selector: 'app-event-admin',
  templateUrl: './event-admin.component.html',
  styleUrls: ['./event-admin.component.scss'],
})
export class EventAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = calendarSortOptions;
  searchFilter: string = '';

  constructor(
    private router: Router,
    public authRepo: AuthRepository,
    public repo: EventsRepository,
    public service: EventService
  ) {}

  events: IEvent[] = [];
  deleteConfirmation: IEvent | null = null;
  EventInformation: IEvent | null = null;

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(event: IEvent) {
    this.service.delete(event.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
