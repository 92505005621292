import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-documenttype-editform',
  templateUrl: './documenttype-editform.component.html',
  styleUrls: ['./documenttype-editform.component.scss'],
})
export class DocumenttypeEditformComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private repo: DocumentTypesRepository,
    private service: DocumentTypesService,
    private location: Location
  ) {}
  editForm: UntypedFormBuilder | any;
  @Input() editedDocumenttype: Partial<IDocumentType> | null = null;
  submitErrors: string[] | null = null;

  moreThanfiveError: boolean = false;
  //editedDocumenttype
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedDocumenttype?.name, Validators.required],
      isShownOnSideMenu: [this.editedDocumenttype?.isShownOnSideMenu],
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const isShownOnSideMenu = this.editForm.value?.isShownOnSideMenu;
    const name = this.editForm.value?.name.toString();

    const id = this.editedDocumenttype?.id;
    this.submitErrors = null;
    let updateResult: Observable<IDocumentType> | undefined;
    updateResult = this.service.updateDocumenttype({
      id,
      name,
      isShownOnSideMenu,
    });
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
