<div class="reminderHover" [style.top.px]="top" [style.left.px]="left">
  <div
    (click)="updateValue(caller)"
    *ngFor="let caller of callersOptions; let i = index"
    class="min-w-100px option"
  >
    <div class="d-flex align-items-center px-2 w-100">
      {{ caller.label }}
    </div>
  </div>
</div>
