import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ICompanyMarker } from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import {
  ISubscriber,
  ConfirmedOptions,
} from '../../../../state/subscribers.repository';
import { SubscribersService } from '../../../../state/subscribers.service';
import { Location } from '@angular/common';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';

@Component({
  selector: 'app-subscriber-editform',
  templateUrl: './subscriber-editform.component.html',
  styleUrls: ['./subscriber-editform.component.scss'],
})
export class SubscriberEditformComponent {
  editForm: UntypedFormBuilder | any;
  @Input() editedSubscriber?: ISubscriber | null;
  client: ICompanyMarker[] | null = null;
  submitErrors: string[] | null = null;
  @Output() subscriberSubmit = new EventEmitter<Partial<ISubscriber>>();
  ConfirmedOptions: DatalistOption[] = ConfirmedOptions;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private service: SubscribersService,
    private clientService: CompaniesService
  ) {}
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      firstName: [this.editedSubscriber?.firstName, Validators.required],
      lastName: [this.editedSubscriber?.lastName],
      email: [this.editedSubscriber?.email],
      timeStamp: [this.editedSubscriber?.timeStamp],
      phoneNumber: [this.editedSubscriber?.phoneNumber],
      spouceGuest: [this.editedSubscriber?.spouceGuest],
      show: [this.editedSubscriber?.show],
      confirmed: [this.editedSubscriber?.confirmed],
      callStatus: [this.editedSubscriber?.callStatus],
      callStatus2: [this.editedSubscriber?.callStatus2],
      callStatus3: [this.editedSubscriber?.callStatus3],
      callStatus4: [this.editedSubscriber?.callStatus4],
    });
  }
  onSubmit() {
    const id = this.editedSubscriber?.id?.toString();
    const tenantId = this.editedSubscriber?.tenantId?.toString();
    const eventScheduleId = this.editedSubscriber?.eventScheduleId?.toString();

    const firstName = this.editForm.value?.firstName?.toString();
    const lastName = this.editForm.value?.lastName?.toString();
    const email = this.editForm.value?.email?.toString();
    const timeStamp = this.editForm.value?.timeStamp?.toString();
    const phoneNumber = this.editForm.value?.phoneNumber?.toString();
    const spouceGuest = this.editForm.value?.spouceGuest;
    const show = this.editForm.value?.show;
    const confirmed = this.editForm.value?.confirmed?.toString();
    const callStatus = this.editForm.value?.callStatus?.toString();
    const callStatus2 = this.editForm.value?.callStatus2?.toString();
    const callStatus3 = this.editForm.value?.callStatus3?.toString();
    const callStatus4 = this.editForm.value?.callStatus4?.toString();

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    this.subscriberSubmit.emit({
      id,
      tenantId,
      firstName,
      eventScheduleId,
      lastName,
      email,
      timeStamp,
      phoneNumber,
      spouceGuest,
      show,
      confirmed,
      callStatus,
      callStatus2,
      callStatus3,
      callStatus4,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
