import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-unsaved-data',
  templateUrl: './confirm-unsaved-data.component.html',
  styleUrls: ['./confirm-unsaved-data.component.scss'],
})
export class ConfirmUnsavedDataComponent {
  @Output() confirmSubmit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
}
