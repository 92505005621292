import { Component } from '@angular/core';
import { CompaniesService } from '../../../../state/companies.service';
import { LocationsRepository } from '../../../../state/locations.repository';
import { LocationsService } from '../../../../state/locations.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ICompanyLocation,
  ICompanyMarker,
} from 'src/app/state/companies.repository';

@Component({
  selector: 'app-locations-create-page',
  templateUrl: './locations-create-page.component.html',
  styleUrls: ['./locations-create-page.component.scss'],
})
export class LocationsCreatePageComponent {
  constructor(
    public service: LocationsService,
    public clientService: CompaniesService,
    public repo: LocationsRepository,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  isLoading = false;
  companyLocation: ICompanyLocation | null = null;
  company: ICompanyMarker | null = null;
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type')!;
        this.id = value.get('id')!;
        if (this.id && this.type != 'new') {
          this.service.loadOneLocation(`${value.get('id')}`).subscribe((y) => {
            this.companyLocation = y;
          });
        }
      },
    });
  }

  createLocation(location: Partial<ICompanyLocation>) {
    this.isLoading = true;
    this.submitErrors = null;
    let updateResult$: Observable<ICompanyLocation> | undefined;
    if (this.type === 'new') {
      location.companyId = this.id;

      updateResult$ = this.service.postLocation(location);
    } else if (this.id) {
      location.id = this.id;

      updateResult$ = this.service.updateLocation(location);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.isLoading = false;

          this.location.back();
        },
        error: (data) => {
          this.submitErrors = data;
          this.isLoading = false;
        },
      });
    }
  }
}
