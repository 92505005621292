<div class="hidenOnBigScreen row">
  <button
    *ngIf="!isRoute('/home')"
    type="button"
    class="align-self-center pe-4 col-3 pr-0 btn btn-link back-btn text-white"
    (click)="backnav()"
  >
    <svg
      width="16"
      height="12"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7H1M1 7L7 13M1 7L7 1"
        stroke="#98A2B3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
  <button
    *ngIf="isRoute('/home')"
    type="button"
    class="align-self-center pe-4 col-3 pr-0 btn btn-link back-btn text-white"
  ></button>

  <div class="col-6 text-center align-self-center px-0">
    <label
      *ngIf="isRoute('/home')"
      class="title d-flex align-items-center justify-content-center"
    >
      <svg
        width="19"
        height="17"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 19.9125V14.5125C13 13.9524 13 13.6724 12.891 13.4585C12.7951 13.2703 12.6422 13.1173 12.454 13.0215C12.2401 12.9125 11.9601 12.9125 11.4 12.9125H8.6C8.03995 12.9125 7.75992 12.9125 7.54601 13.0215C7.35785 13.1173 7.20487 13.2703 7.10899 13.4585C7 13.6724 7 13.9524 7 14.5125V19.9125M1 5.91248C1 7.56933 2.34315 8.91248 4 8.91248C5.65685 8.91248 7 7.56933 7 5.91248C7 7.56933 8.34315 8.91248 10 8.91248C11.6569 8.91248 13 7.56933 13 5.91248C13 7.56933 14.3431 8.91248 16 8.91248C17.6569 8.91248 19 7.56933 19 5.91248M4.2 19.9125H15.8C16.9201 19.9125 17.4802 19.9125 17.908 19.6945C18.2843 19.5027 18.5903 19.1968 18.782 18.8205C19 18.3926 19 17.8326 19 16.7125V5.11248C19 3.99237 19 3.43232 18.782 3.00449C18.5903 2.62817 18.2843 2.32221 17.908 2.13046C17.4802 1.91248 16.9201 1.91248 15.8 1.91248H4.2C3.0799 1.91248 2.51984 1.91248 2.09202 2.13046C1.71569 2.32221 1.40973 2.62817 1.21799 3.00449C1 3.43232 1 3.99237 1 5.11248V16.7125C1 17.8326 1 18.3926 1.21799 18.8205C1.40973 19.1968 1.71569 19.5027 2.09202 19.6945C2.51984 19.9125 3.07989 19.9125 4.2 19.9125Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@home">Home</span>
    </label>
    <label
      *ngIf="isRoute('/posts')"
      class="title d-flex align-items-center justify-content-center"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.60802 8.4375H10.608M6.60802 11.9375H13.608M11.108 18.9375C15.8024 18.9375 19.608 15.1319 19.608 10.4375C19.608 5.74308 15.8024 1.9375 11.108 1.9375C6.4136 1.9375 2.60802 5.74308 2.60802 10.4375C2.60802 11.3875 2.76387 12.3011 3.05139 13.1541C3.15959 13.4751 3.21369 13.6356 3.22345 13.7589C3.23309 13.8807 3.2258 13.9661 3.19568 14.0844C3.16517 14.2043 3.09782 14.329 2.96312 14.5783L1.32746 17.6059C1.09415 18.0377 0.977493 18.2536 1.0036 18.4203C1.02634 18.5654 1.11177 18.6932 1.2372 18.7697C1.3812 18.8576 1.62532 18.8323 2.11356 18.7819L7.23459 18.2525C7.38967 18.2365 7.46721 18.2285 7.53788 18.2312C7.6074 18.2338 7.65647 18.2404 7.72426 18.256C7.79318 18.2719 7.87984 18.3053 8.05318 18.372C9.00123 18.7373 10.0312 18.9375 11.108 18.9375Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@news"> News</span>
    </label>
    <label *ngIf="isRoute('/events')" class="title">
      <svg
        width="19"
        height="18"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@calendar">Calendar</span>
    </label>
    <label *ngIf="isRoute('/maps')" class="title">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        fill="currentColor"
        class="bi bi-globe-central-south-asia"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM4.882 1.731a.482.482 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.721.721 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a.996.996 0 0 0-.462-.04 7.03 7.03 0 0 1 2.45-2.027Zm-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.78.78 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.454.454 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282a.61.61 0 0 0 .04-.001 7.003 7.003 0 0 1-12.658.905Z"
        />
      </svg>
      <span class="ps-2" i18n="@maps">Maps</span>
    </label>
    <label *ngIf="isRoute('/timelogs')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 8.8125V12.8125L12.5 14.3125M10 4.3125C5.30558 4.3125 1.5 8.11808 1.5 12.8125C1.5 17.5069 5.30558 21.3125 10 21.3125C14.6944 21.3125 18.5 17.5069 18.5 12.8125C18.5 8.11808 14.6944 4.3125 10 4.3125ZM10 4.3125V1.3125M8 1.3125H12M18.329 4.90454L16.829 3.40454L17.579 4.15454M1.67102 4.90454L3.17102 3.40454L2.42102 4.15454"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@timelogs">Timelogs</span>
    </label>
    <label *ngIf="isRoute('/mwevents') || isRoute('ideasrating')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1.5625L2.09344 12.2504C1.74463 12.6689 1.57023 12.8782 1.56756 13.055C1.56524 13.2086 1.63372 13.3548 1.75324 13.4514C1.89073 13.5625 2.16316 13.5625 2.70802 13.5625H9.99998L8.99998 21.5625L17.9065 10.8746C18.2553 10.4561 18.4297 10.2468 18.4324 10.07C18.4347 9.91638 18.3663 9.7702 18.2467 9.67361C18.1092 9.5625 17.8368 9.5625 17.292 9.5625H9.99998L11 1.5625Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@colaborations">Collaborations</span>
    </label>
    <label *ngIf="isRoute('/whistleblow')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 7.02084V11.0208M10 15.0208H10.01M1 6.96228V15.0794C1 15.422 1 15.5934 1.05048 15.7462C1.09515 15.8813 1.16816 16.0054 1.26463 16.1101C1.37369 16.2284 1.52345 16.3116 1.82297 16.478L9.22297 20.5892C9.50658 20.7467 9.64838 20.8255 9.79855 20.8564C9.93146 20.8837 10.0685 20.8837 10.2015 20.8564C10.3516 20.8255 10.4934 20.7467 10.777 20.5892L18.177 16.478C18.4766 16.3116 18.6263 16.2284 18.7354 16.1101C18.8318 16.0054 18.9049 15.8813 18.9495 15.7462C19 15.5934 19 15.422 19 15.0794V6.96228C19 6.61964 19 6.44832 18.9495 6.29552C18.9049 6.16034 18.8318 6.03626 18.7354 5.93157C18.6263 5.81323 18.4766 5.73003 18.177 5.56363L10.777 1.45252C10.4934 1.29496 10.3516 1.21618 10.2015 1.1853C10.0685 1.15796 9.93146 1.15796 9.79855 1.1853C9.64838 1.21618 9.50658 1.29496 9.22297 1.45252L1.82297 5.56363C1.52345 5.73003 1.37369 5.81323 1.26463 5.93157C1.16816 6.03626 1.09515 6.16034 1.05048 6.29552C1 6.44832 1 6.61964 1 6.96228Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@whistleblow">Whistleblow</span>
    </label>
    <label *ngIf="isRoute('/messages')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 9H4.88197C5.56717 9 6.19357 9.38713 6.5 10C6.80643 10.6129 7.43283 11 8.11803 11H13.882C14.5672 11 15.1936 10.6129 15.5 10C15.8064 9.38713 16.4328 9 17.118 9H20.5M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@messages"> Messages</span>
    </label>
    <label *ngIf="isRoute('/apv')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 11L10 13L14.5 8.5M16.9012 3.99851C17.1071 4.49653 17.5024 4.8924 18.0001 5.09907L19.7452 5.82198C20.2433 6.02828 20.639 6.42399 20.8453 6.92206C21.0516 7.42012 21.0516 7.97974 20.8453 8.47781L20.1229 10.2218C19.9165 10.7201 19.9162 11.2803 20.1236 11.7783L20.8447 13.5218C20.9469 13.7685 20.9996 14.0329 20.9996 14.2999C20.9997 14.567 20.9471 14.8314 20.8449 15.0781C20.7427 15.3249 20.5929 15.549 20.4041 15.7378C20.2152 15.9266 19.991 16.0764 19.7443 16.1785L18.0004 16.9009C17.5023 17.1068 17.1065 17.5021 16.8998 17.9998L16.1769 19.745C15.9706 20.2431 15.575 20.6388 15.0769 20.8451C14.5789 21.0514 14.0193 21.0514 13.5212 20.8451L11.7773 20.1227C11.2792 19.9169 10.7198 19.9173 10.2221 20.1239L8.47689 20.8458C7.97912 21.0516 7.42001 21.0514 6.92237 20.8453C6.42473 20.6391 6.02925 20.2439 5.82281 19.7464L5.09972 18.0006C4.8938 17.5026 4.49854 17.1067 4.00085 16.9L2.25566 16.1771C1.75783 15.9709 1.36226 15.5754 1.15588 15.0777C0.949508 14.5799 0.949228 14.0205 1.1551 13.5225L1.87746 11.7786C2.08325 11.2805 2.08283 10.7211 1.8763 10.2233L1.15497 8.47678C1.0527 8.2301 1.00004 7.96568 1 7.69863C0.999957 7.43159 1.05253 7.16715 1.15472 6.92043C1.25691 6.67372 1.40671 6.44955 1.59557 6.26075C1.78442 6.07195 2.00862 5.92222 2.25537 5.8201L3.9993 5.09772C4.49687 4.89197 4.89248 4.4972 5.0993 4.00006L5.82218 2.25481C6.02848 1.75674 6.42418 1.36103 6.92222 1.15473C7.42027 0.948424 7.97987 0.948424 8.47792 1.15473L10.2218 1.87712C10.7199 2.08291 11.2793 2.08249 11.7771 1.87595L13.523 1.15585C14.021 0.94966 14.5804 0.949702 15.0784 1.15597C15.5763 1.36223 15.972 1.75783 16.1783 2.25576L16.9014 4.00153L16.9012 3.99851Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@apv">Apv</span>
    </label>
    <label
      *ngIf="
        isRoute('/clients') &&
        !isRoute('/contacts') &&
        !isRoute('/locations') &&
        !isRoute('/rooms')
      "
      class="title"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        fill="currentColor"
        class="bi bi-globe-central-south-asia"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM4.882 1.731a.482.482 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.721.721 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a.996.996 0 0 0-.462-.04 7.03 7.03 0 0 1 2.45-2.027Zm-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.78.78 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.454.454 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282a.61.61 0 0 0 .04-.001 7.003 7.003 0 0 1-12.658.905Z"
        />
      </svg>
      <span class="ps-2" i18n="@clients">Clients</span>
    </label>
    <label *ngIf="isRoute('/documents')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 6V5.8C17 4.11984 17 3.27976 16.673 2.63803C16.3854 2.07354 15.9265 1.6146 15.362 1.32698C14.7202 1 13.8802 1 12.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H9.5M9.5 10H5M8.5 14H5M13 6H5M15 17V11.5C15 10.6716 15.6716 10 16.5 10C17.3284 10 18 10.6716 18 11.5V17C18 18.6569 16.6569 20 15 20C13.3431 20 12 18.6569 12 17V13"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@documents">Documents</span>
    </label>
    <label *ngIf="isRoute('/users')" class="title">
      <i class="bi bi-people"></i>
      <span class="ps-2" i18n="@user">Users</span>
    </label>
    <label *ngIf="isRoute('/tenants')" class="title">
      <i class="bi bi-building"></i>
      <span class="ps-2" i18n="@user">Tenats</span>
    </label>
    <label *ngIf="isRoute('/contacts')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.38028 7.85323C8.07627 9.30285 9.02506 10.6615 10.2266 11.8631C11.4282 13.0646 12.7869 14.0134 14.2365 14.7094C14.3612 14.7693 14.4235 14.7992 14.5024 14.8222C14.7828 14.904 15.127 14.8453 15.3644 14.6752C15.4313 14.6274 15.4884 14.5702 15.6027 14.4559C15.9523 14.1063 16.1271 13.9315 16.3029 13.8172C16.9658 13.3862 17.8204 13.3862 18.4833 13.8172C18.6591 13.9315 18.8339 14.1063 19.1835 14.4559L19.3783 14.6508C19.9098 15.1822 20.1755 15.448 20.3198 15.7333C20.6069 16.3009 20.6069 16.9712 20.3198 17.5387C20.1755 17.8241 19.9098 18.0898 19.3783 18.6213L19.2207 18.7789C18.6911 19.3085 18.4263 19.5733 18.0662 19.7756C17.6667 20 17.0462 20.1614 16.588 20.16C16.1751 20.1588 15.8928 20.0787 15.3284 19.9185C12.295 19.0575 9.43264 17.433 7.04466 15.045C4.65668 12.6571 3.03221 9.79471 2.17124 6.76131C2.01103 6.19687 1.93092 5.91464 1.9297 5.5017C1.92833 5.04347 2.08969 4.42298 2.31411 4.02348C2.51636 3.66345 2.78117 3.39863 3.3108 2.86901L3.46843 2.71138C3.99987 2.17993 4.2656 1.91421 4.55098 1.76987C5.11854 1.4828 5.7888 1.4828 6.35636 1.76987C6.64174 1.91421 6.90747 2.17993 7.43891 2.71138L7.63378 2.90625C7.98338 3.25585 8.15819 3.43065 8.27247 3.60643C8.70347 4.26932 8.70347 5.1239 8.27247 5.78679C8.15819 5.96257 7.98338 6.13738 7.63378 6.48698C7.51947 6.60129 7.46231 6.65845 7.41447 6.72526C7.24446 6.96269 7.18576 7.30695 7.26748 7.5873C7.29048 7.6662 7.32041 7.72854 7.38028 7.85323Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@Contacts">Contacts</span>
    </label>
    <label *ngIf="isRoute('/teams')" class="title">
      <img src="assets/img/users-01_18x19_white.svg" />
      <span class="ps-2" i18n="@teams">Teams</span>
    </label>

    <label
      *ngIf="isRoute('/eventSchedule') && !isRoute('/registrants')!"
      class="title"
    >
      <svg
        width="18"
        height="19"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@eventschedule">Event Schedule</span>
    </label>
    <label *ngIf="isRoute('/locations')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 11.3744C18.5318 12.0688 21 13.6547 21 15.5C21 17.9853 16.5228 20 11 20C5.47715 20 1 17.9853 1 15.5C1 13.6547 3.46819 12.0688 7 11.3744M11 15V1L16.3177 4.27244C16.7056 4.51114 16.8995 4.63049 16.9614 4.78085C17.0154 4.912 17.0111 5.0599 16.9497 5.18771C16.8792 5.33426 16.6787 5.44222 16.2777 5.65815L11 8.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@locations">Locations</span>
    </label>

    <label *ngIf="isRoute('/rooms')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@rooms">Rooms</span>
    </label>
    <label *ngIf="isRoute('/topics')" class="title">
      <svg
        width="18"
        height="19"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 5V15M14 5V15M14 10L6 10M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@topics">Topics</span>
    </label>
    <label *ngIf="isRoute('/registrants')" class="title">
      <svg
        width="19"
        height="19"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 19V17C21 15.1362 19.7252 13.5701 18 13.126M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5C17 6.67869 15.9659 8.11585 14.5 8.70924M16 19C16 17.1362 16 16.2044 15.6955 15.4693C15.2895 14.4892 14.5108 13.7105 13.5307 13.3045C12.7956 13 11.8638 13 10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="ps-2" i18n="@registrants">Regisrants</span>
    </label>
  </div>

  <button
    type="button"
    class="ps-4 align-self-center col-3 btn btn-link menu-btn open-btn"
    aria-label="Toggle menu"
    i18n-aria-label="Header's menu icon"
    (click)="repo.toggleMenuOpened()"
  >
    <svg
      width="16"
      height="12"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7H13M1 1H19M1 13H19"
        stroke="#98A2B3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>
