import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { StatisticElement } from 'src/app/state/event.repository';
import {
  IEventsWithStats,
  QuaterEs,
  QuatersEsRepository,
  quatersOptions,
} from 'src/app/state/quaters.repository';
import { QuatersEsService } from 'src/app/state/quaters.service';

@Component({
  selector: 'app-ae-stats-page',
  templateUrl: './ae-stats-page.component.html',
  styleUrls: ['./ae-stats-page.component.scss'],
})
export class AeStatsPageComponent {
  constructor(
    public quatersService: QuatersEsService,
    public quatersRepo: QuatersEsRepository,
    public service: EventScheduleService
  ) {
    this.selectedYear = this.Date?.year();
    this.search(true);
  }

  quatersOptions: DatalistOption[] = quatersOptions;
  selectedQuater: number | null = null;
  selectedYear: number | null = null;
  selectedSearchType: number | null = null;
  selectedMonth: number | null = null;
  activeSearch: string | null = null;
  activeSearchType: string = '0';
  getAeStatisticsSubscription: any;
  handleSearchType(type: number) {
    this.activeSearchType = type.toString();
  }
  search(getYearsOpt = false) {
    if (this.getAeStatisticsSubscription) {
      this.getAeStatisticsSubscription.unsubscribe();
    }
    this.getAeStatisticsSubscription = this.quatersService
      .getAeStatistics({
        year: this.selectedYear ?? undefined,
        quater: this.quatersRepo.activeQuaterES?.value ?? undefined,
        month: this.quatersRepo.activeMonthEs?.value ?? undefined,
        search: this.activeSearch ?? undefined,
        searchType: +this.activeSearchType ?? undefined,
        getYearsOpt: getYearsOpt,
      })
      .subscribe((x) => {
        if (getYearsOpt) {
          this.yearsOptions = x.years.map(
            (x) =>
              ({ value: x.toString(), label: x.toString() } as DatalistOption)
          );
        }
      });
  }
  setFilter(event: string | null) {
    this.activeSearch = event;
    this.search();
  }

  monStats: StatisticElement[] = [];

  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');

  yearsOptions: DatalistOption[] = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];
  setYear(event: string) {
    this.selectedYear = +event;
    this.search();
  }
}
