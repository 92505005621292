import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTicketsPageComponent } from './pages/edit-tickets-page/edit-tickets-page.component';
import { EditTicketFormComponent } from './components/edit-ticket-form/edit-ticket-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../shared/shared.module';
import { TicketsListComponent } from './components/tickets-list/tickets-list.component';
import { TicketChatComponent } from './components/ticket-chat/ticket-chat.component';

import { CloseTicketPopComponent } from './components/popups/close-ticket-pop/close-ticket-pop.component';
import { SignalrStatusComponent } from './components/popups/signalr-status/signalr-status.component';
import { CommentActionsPopComponent } from './components/popups/comment-actions-pop/comment-actions-pop.component';
import { TicketsPageComponent } from './pages/tickets-page/tickets-page.component';
import { EmojiMartPopupComponent } from './components/popups/emoji-mart-popup/emoji-mart-popup.component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { AttachFilePopupComponent } from './components/popups/attach-file-popup/attach-file-popup.component';
import { PrefillTicketEsComponent } from './pages/prefill-ticket-es/prefill-ticket-es.component';
import { ConfirmUnsavedDataComponent } from './components/popups/confirm-unsaved-data/confirm-unsaved-data.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    EditTicketsPageComponent,
    EditTicketFormComponent,
    TicketsListComponent,
    TicketChatComponent,
    CloseTicketPopComponent,
    SignalrStatusComponent,
    CommentActionsPopComponent,
    TicketsPageComponent,
    EmojiMartPopupComponent,
    AttachFilePopupComponent,
    PrefillTicketEsComponent,
    ConfirmUnsavedDataComponent,
  ],
  imports: [
    PickerComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    InfiniteScrollModule,
    MatFormFieldModule,
    MatInputModule,
    ModalModule.forRoot(),
  ],
})
export class TicketSystemModule {}
