<button class="dnone" id="initloader" (click)="initLoad()"></button>
<button
  class="dnone"
  #editclick
  id="editclick"
  (click)="editClickInfo($event)"
></button>

<div class="row d-flex justify-content-center minHeight50px" id="topMenu">
  <div class="row d-flex justify-content-center" id="topMenu">
    <div class="col-2 d-flex flex-column text-break oneLineText">
      <label>Name:</label>
      <label>
        {{ selectedCompany?.title }}
      </label>
    </div>
    <div class="col-2 d-flex flex-column text-break oneLineText">
      <label>AE Advisor:</label>
      <label>
        {{ selectedCompany?.aeAdvisor == true ? "Yes" : "No" }}
      </label>
    </div>
    <div class="col-4 d-flex flex-column text-break oneLineText">
      <label>Address:</label>
      <label>
        {{ selectedCompany?.address }}
      </label>
    </div>
    <div class="col-2 d-flex flex-column text-break oneLineText">
      <label>First Event:</label>
      <label>
        {{
          datepipe.transform(selectedCompany?.formatedClientSince, "MM-dd-yyyy")
        }}
      </label>
    </div>
    <div class="col-2 d-flex flex-column text-break oneLineText">
      <label>Contacts:</label>
      <label>
        {{ selectedCompany?.acquiredBy }}
      </label>
    </div>
  </div>
</div>

<div class="posRelative">
  <app-search-modal-absolute
    (ChangeCenterSubmit)="ChangeCenterSubmit($event)"
    (clear)="deleteSearchMarker()"
  ></app-search-modal-absolute>

  <div id="map"></div>
  <div class="mapsinpt">
    <div class="menuTitle p-2" [class.bordRad12]="!clientMenuShown">
      <span class="w-90per">Clients</span>
      <button
        class="btn p-0 m-0 d-flex align-items-center wh-24px"
        (click)="clientMenuShown = !clientMenuShown"
      >
        <img
          *ngIf="clientMenuShown"
          src="../../../../../assets/img/arrows/crevron-down.svg"
        />

        <img *ngIf="!clientMenuShown" src="assets/img/arrows/crevron-up.svg" />
      </button>
    </div>

    <div
      class="h-0"
      [class.bordRadBott12]="clientMenuShown"
      [class.smoothHeight]="clientMenuShown"
    >
      <div
        class="checkAllBox p-1 ps-2 d-flex align-items-center"
        [class.bordRad12]="!clientMenuShown"
      >
        <input
          class="form-check-input me-2"
          type="checkbox"
          [disabled]="showAllDisabled == true"
          [(ngModel)]="showAll"
          (change)="handleShowAll()"
        />
        <div
          *ngIf="showAllDisabled"
          class="spinner-border wh-24 me-2"
          role="status"
        >
          <span class="sr-only"></span>
        </div>
        <span class="disableSelect">Select</span>
      </div>
      <div class="mapsContainer p-2 pt-0 pe-1">
        <div *ngFor="let comp of companies; let comi = index" class="clientRow">
          <div class="d-flex">
            <input
              class="pointer form-check-input me-2"
              type="checkbox"
              [(ngModel)]="comp.allCompaniesSelected"
              (change)="handleShowLocations('company', comp.id)"
              [disabled]="comp.checkboxDisabled == true"
            />
            <div
              title="{{ comp.title }}"
              class="clientTitle pointer"
              (click)="
                selectedCompany = comp;
                moveToLoc(10, comp.latitude, comp.longitude)
              "
            >
              <div
                *ngIf="comp.checkboxDisabled == true"
                class="spinner-border wh-24"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
              {{ comp.title }}
            </div>
            <div title="Expand" class="me-1 d-flex align-items-center">
              <button
                *ngIf="comp.companyLocations.length"
                class="btn p-0 m-0 d-flex align-items-center accordion-button wh-24px"
                type="button"
                data-bs-toggle="collapse"
                attr.aria-expanded="{{ comp.locationsHiden == false }}"
                attr.aria-controls="locations-{{ comi }}"
                (click)="comp.locationsHiden = !comp.locationsHiden"
                attr.data-bs-target="#locations-{{ comi }}"
              >
                <img
                  style="rotate: 0deg"
                  [class.rotate180]="comp.locationsHiden"
                  src="../../../../../assets/img/arrows/crevron-down.svg"
                />
              </button>
            </div>
          </div>
          <div
            id="locations-{{ comi }}"
            class="locationsSection ps-3 accordion-collapse collapse"
            [class.collapsed]="comp.locationsHiden"
            [class.show]="!comp.locationsHiden"
          >
            <div
              *ngFor="let location of comp.companyLocations; let loci = index"
            >
              <div class="d-flex align-items-center">
                <div class="me-1 d-flex align-items-center">
                  <input
                    class="form-check-input"
                    [(ngModel)]="location.checked"
                    type="checkbox"
                    (change)="
                      handleShowLocations(
                        'location',
                        location.id,
                        location.checked
                      )
                    "
                    [disabled]="comp.checkboxDisabled == true"
                  />
                </div>
                <div
                  class="me-1 locationTitle pointer"
                  title="{{ location.title }} "
                  (click)="
                    selectedCompany = comp;
                    moveToLoc(10, location.latitude, location.longitude)
                  "
                >
                  {{ location.title }}
                </div>

                <button
                  class="btn p-0 m-0 d-flex align-items-center justify-content-center"
                  (click)="clickchange($event, location)"
                >
                  <img src="assets/img/edit19x19.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clientsSearchDiv p-2 mt-1">
        <input
          id="searchInput"
          class="clientsSearch"
          [(ngModel)]="clientSearch"
          (input)="filterCompanies()"
          placeholder="Search .."
          autocomplete="off"
        />
      </div>
    </div>
  </div>
</div>

<app-edit-location-popover
  (mouseleave)="showEditLocationPopover = false"
  [shown]="showEditLocationPopover"
  id="editLocationPopOver"
  [posLeft]="editLeft"
  [posTop]="editTop"
  [location]="editLocation"
  [useKmAsValues]="useKmAsValues"
  (updateLocation)="updateLocationCircle($event.id!, $event.radius!)"
></app-edit-location-popover>
<app-edit-location-popover
  (mouseleave)="mouseInfoLeave()"
  [shown]="showEditLocationInfo"
  id="editLocationInfo"
  [posLeft]="editInfoLeft"
  [posTop]="editInfoTop"
  [location]="editInfoLocation"
  [useKmAsValues]="useKmAsValues"
  (updateLocation)="updateLocationCircle($event.id!, $event.radius!)"
></app-edit-location-popover>
<!-- (mouseleave)="showEditLocationInfo = false" -->
