import { Component, OnInit } from '@angular/core';
import {
  AuthProps,
  AuthRepository,
  IdentityError,
} from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-office365auth',
  templateUrl: './office365auth.component.html',
  styleUrls: ['./office365auth.component.scss'],
})
export class Office365authComponent implements OnInit {
  submitErrors: IdentityError[] | null = null;
  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    var code = this.route.snapshot.queryParams['code'];
    this.auth.loginOffice365Auth(code).subscribe({
      complete: () => {
        this.router.navigate(['/home']);
      },
      error: (data) => {
        this.submitErrors = data;
        this.router.navigate(['signin/:error']);
      },
    });
  }

  getErrors(errors: IdentityError[]): string[] | undefined {
    if (errors && errors.length > 0) {
      let simpleErrors = errors.map((x) => {
        return x.description;
      });
      return simpleErrors;
    }
    return;
  }
}
