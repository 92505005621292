import { Component, OnInit } from '@angular/core';
import { IMWEvent, SortIdeas } from 'src/app/state/mwevent.repository';
import { MWEventService } from 'src/app/state/mwevent.service';
import { ActivatedRoute } from '@angular/router';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-mweventslist',
  templateUrl: './mweventslist.component.html',
  styleUrls: ['./mweventslist.component.scss'],
})
export class MweventslistComponent implements OnInit {
  ideas: SortIdeas[] | null = null;
  id: string | null = null;
  options: EChartsOption | any = {};
  ideaindex: number | undefined = -1;

  constructor(
    private service: MWEventService,
    private activatedRoute: ActivatedRoute
  ) {}

  mwevents: IMWEvent[] = [];
  deleteConfirmation: IMWEvent | null = null;
  colors: Array<string> = ['#E36A48', '#FDBE54', '#62CBE6', '#03ff8a'];

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        this.service.loadIdeasRating(`${this.id}`).subscribe((x) => {
          this.ideas = x;
          this.setColor();
          this.setGraph();
          this.setpoints();
        });
      },
    });
  }

  scroll(id?: string) {
    if (id) {
      let el = document.getElementById(id);
      if (el) {
        el.scrollIntoView();
      }
    }
  }

  setColor() {
    this.ideas?.forEach((value) => {
      var colind = 0;
      if (value.impactAvg < 2.5 && value.effortAvg > 2.5) {
        colind = 0;
      }
      if (value.impactAvg >= 2.5 && value.effortAvg > 2.5) {
        colind = 1;
      }
      if (value.impactAvg < 2.5 && value.effortAvg <= 2.5) {
        colind = 2;
      }
      if (value.impactAvg >= 2.5 && value.effortAvg <= 2.5) {
        colind = 3;
      }
      value.color = this.colors[colind];
    });
  }

  setpoints(
    impact?: number,
    effort?: number,
    chosenTitle?: string,
    chosenColor?: string
  ) {
    this.ideas?.forEach((value: SortIdeas) => {
      this.options.series?.push({
        type: 'scatter',
        name: value.idea.title,
        encode: { tooltip: [0, 1] },
        data: [[value.impactAvg, value.effortAvg]],
        color: value.color,
        symbolSize: 10 + (value.impactAvg + value.effortAvg) * 1.5,
      });
    });
    if (impact && effort) {
      this.options.series?.push({
        type: 'effectScatter',
        name: chosenTitle,
        symbolSize: 10 + (impact + effort) * 1.5,
        encode: { tooltip: [0, 1] },
        data: [[impact, effort]],
        color: chosenColor,
      });
    }
  }

  onChartEvent(event: any) {
    if (event.seriesId == 'chosen') {
      return;
    }
    var indx = this.ideas?.indexOf(
      this.ideas?.filter((x) => x.idea.title == event.seriesName)[0]
    );
    if (indx != null) {
      this.click(indx, event.data[0], event.data[1], true);
    }
  }

  setGraph() {
    this.options = {
      tooltip: {
        trigger: 'item',
      },
      xAxis: {
        name: 'Impact',
        min: 0,
        max: 5,
        nameLocation: 'middle',
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      yAxis: {
        name: 'Effort',
        min: 0,
        max: 5,
        nameLocation: 'middle',
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [
        {
          type: 'line',
          data: [
            [0, 2.5],
            [5, 2.5],
          ],
          symbolSize: 0,
          encode: { tooltip: [1] },
          lineStyle: {
            type: 'dashed',
            opacity: 0.2,
          },
          color: 'black',
        },
        {
          type: 'line',
          data: [
            [2.5, 0],
            [2.5, 5],
          ],
          symbolSize: 0,
          encode: { tooltip: [0] },
          lineStyle: {
            type: 'dashed',
            opacity: 0.2,
          },
          color: 'black',
        },
      ],
    };
  }

  click(
    ideai: number,
    impact?: number,
    effort?: number,
    isPoint: boolean = false
  ) {
    this.ideaindex = ideai;
    if (impact && effort) {
      this.options = {
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          name: 'Impact',
          min: 0,
          max: 5,
          nameLocation: 'middle',
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        yAxis: [
          {
            name: 'Effort',
            min: 0,
            max: 5,
            nameLocation: 'middle',
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            type: 'line',
            data: [
              [0, 2.5],
              [5, 2.5],
            ],
            symbolSize: 0,
            encode: { tooltip: [1] },
            lineStyle: {
              type: 'dashed',
              opacity: 0.2,
            },
            color: 'black',
          },
          {
            type: 'line',
            data: [
              [2.5, 0],
              [2.5, 5],
            ],
            symbolSize: 0,
            encode: { tooltip: [0] },
            lineStyle: {
              type: 'dashed',
              opacity: 0.2,
            },
            color: 'black',
          },
        ],
      };
      if (this.ideas) {
        if (isPoint) {
          this.scroll(this.ideas[ideai].idea.id);
        }
        this.setpoints(
          impact,
          effort,
          this.ideas[ideai].idea.title,
          this.ideas[ideai].color
        );
      }
    }
  }
}
