<div><h2 class="col col-xs-2">Subscribed Events</h2></div>

<div *ngFor="let item of EventsList" class="project-box mt-3">
  <div class="col-5" id="cardcontent">
    <h5 class="card-title text-center text-break">
      {{ item.title | slice : 0 : 50 }}
    </h5>
  </div>
  <div class="col row">
    <div class="col-sm-4 pl-0 d-flex align-items-center">
      <button
        type="button"
        class="btn btn-md btn-link w-100 text-info"
        data-bs-target="#showinfo"
        [routerLink]="['/events', item.id]"
        title="Show Information"
      >
        <i class="bi bi-info-square"></i>
      </button>
    </div>

    <div class="project-box-footer">
      <small class="">{{ item.eventDate | date : "EEEE, MMM d, y " }}</small>
    </div>
  </div>
</div>
