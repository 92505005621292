<!-- <button (click)="openSnackBar()">123</button> -->
<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="max-900 row">
    <div
      class="col-12 col-lg-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2 d-flex align-items-center"
    >
      <h2 class="page-title oneLineTextHeader">Event Schedule</h2>
      <app-datalist-select
        style="min-width: 200px"
        id="yearsSelect"
        [options]="yearsOptions"
        [multiple]="false"
        class="ms-5"
        [(activeValue)]="selectedYear"
        (activeValueChange)="setYear($event)"
        [clearable]="false"
      >
      </app-datalist-select>
      <div
        *ngIf="tabSelected === 'overview'"
        class="d-flex flex-column ps-2"
        style="height: 50px !important"
      >
        <div class="d-flex align-items-center" *ngIf="markingDotsOrange">
          <div class="rounded-circle size15x15 markingColorOrange"></div>
          <div class="px-1">
            <input [formControl]="orangeControl" class="markingDotInpt" />
          </div>
        </div>
        <div class="d-flex align-items-center" *ngIf="markingDotsPink">
          <div class="rounded-circle size15x15 markingColorPink"></div>
          <div class="px-1 pt-1">
            <input [formControl]="pinkControl" class="markingDotInpt" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end">
      <div class="d-flex">
        <a
          routerLink="/admin/eventSchedule/eventSchedule/new"
          class="d-flex justify-content-center btn btn-lg dark-wfrBlue text-white"
          style="justify-content: end; width: 140px !important"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99996 1.16699V12.8337M1.16663 7.00033H12.8333"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="text-btn-add">Add Event</span>
        </a>
      </div>
    </div>
    <div class="ps-1 p-sm-0 d-flex align-items-center row p-0 m-0">
      <app-search
        class="col m-0 p-0 pe-2"
        (searchChange)="setFilter($event)"
        [enableClearButon]="true"
      ></app-search>
      <div class="col p-0 m-0" style="max-width: 200px">
        <div>
          <label class="w170px inpt-title">Search for CC</label>
          <input
            type="checkbox"
            (change)="handleSearch('client')"
            [(ngModel)]="searchForCC"
            class="ms-2 form-check-input"
          />
        </div>
        <div>
          <label class="w170px inpt-title">Search for Location</label>
          <input
            type="checkbox"
            (change)="handleSearch('location')"
            [(ngModel)]="searchForLocation"
            class="ms-2 form-check-input"
          />
        </div>
        <div>
          <label class="w170px inpt-title">Search for Topic</label>
          <input
            type="checkbox"
            (change)="handleSearch('topic')"
            [(ngModel)]="searchForTopic"
            class="ms-2 form-check-input"
          />
        </div>
      </div>

      <div
        class="btn-group btn-group-lg p-0 m-0 col"
        style="max-width: 400px"
        role="group"
      >
        <app-event-admin-tabs
          (tabSelectedEmit)="changeTab($event)"
          (tabSelected)="(pageProperties.tabSelected)"
          class="w-100"
          (exportEmit)="exortAllChecked()"
        >
        </app-event-admin-tabs>
      </div>
    </div>

    <div id="monthSection" class="monthSection p-0"></div>
  </div>
</div>

<app-event-snapshot-modal
  [isLoading]="eventSnapshotIsLoading"
  [eventScheduleOnPopUp]="eventScheduleOnPopUp"
></app-event-snapshot-modal>
<app-change-status-modal
  [top]="statusTop"
  [left]="statusLeft"
  [eventId]="editedEventId!"
  (mouseLeave)="mouseLeave()"
  [statusIndex]="dateIndex"
  (submit)="changeStatus(editedEventId!); mouseLeave()"
  (errorAlert)="openSnackBar($event); mouseLeave()"
  [hidden]="dateIndex == null || editedEventId == null"
></app-change-status-modal>
<app-action-button-modal
  (openActivityLogs)="manageActivityLogs(actionEvent.id)"
  (mouseleave)="mouseLeave()"
  [actionDate]="actionDate"
  [event]="actionEvent"
  [tabSelected]="tabSelected"
  [top]="actionTop ?? 0"
  [left]="actionLeft ?? 200"
  *ngIf="actionEvent"
  (deleteConfirmation)="
    deleteConfirmation = $event.entity; deleteConfirmationDate = $event.date
  "
  (consolidateConfirmation)="consolidationConfirm = $event"
>
</app-action-button-modal>
<app-change-pay-stats-pop-up
  (mouseleave)="mouseLeave()"
  [top]="payStatsTop ?? 0"
  [left]="payStatsLeft ?? 0"
  [esIdOnPopUp]="esIdOnPopUp"
  (submit)="changePayStats(esIdOnPopUp, $event, 1); mouseLeave()"
  (errorAlert)="openSnackBar($event); mouseLeave()"
  [hidden]="payStatsTop == null || payStatsLeft == null || !esIdOnPopUp"
></app-change-pay-stats-pop-up>

<app-activity-log-modal
  [isLoading]="activityLogsLoading"
  [actLogs]="actLogs"
></app-activity-log-modal>

<app-confirm-delete
  id="esDeleteConfirmModal"
  *ngIf="deleteConfirmation"
  [eventSchedule]="true"
  [clientCode]="deleteConfirmation.client.clientCode"
  [clientName]="deleteConfirmation.client.title"
  [date]="deleteConfirmation.formatedDateAdmin"
  [location]="deleteConfirmation.eventScheduleLocation?.title"
  [deleteCandidate]="''"
  (confirm)="manageDeleteConfirmation()"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>

<app-confirm-event-cancel
  *ngIf="consolidationConfirm"
  (confirm)="updateStatus(consolidationConfirm)"
  (cancel)="consolidationConfirm = null"
  [forConsolidation]="true"
/>
