import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { AdminAdsViewEventScheduleComponent } from '../modules/event-schedule/pages/admin-ads-view-event-schedule/admin-ads-view-event-schedule.component';
import { AdminEventInfosPageComponent } from '../modules/event-schedule/pages/admin-event-infos-page/admin-event-infos-page.component';
import { AdminEventSchedulePageComponent } from '../modules/event-schedule/pages/admin-event-schedule-page/admin-event-schedule-page.component';
import { AdminFunnelPageComponent } from '../modules/event-schedule/pages/admin-funnel-page/admin-funnel-page.component';
import { AdminPayStatsEventScheduleComponent } from '../modules/event-schedule/pages/admin-pay-stats-event-schedule/admin-pay-stats-event-schedule.component';
import { AdminSubscribersPageComponent } from '../modules/event-schedule/pages/admin-subscribers-page/admin-subscribers-page.component';
import { AdminToDoEventScheduleComponent } from '../modules/event-schedule/pages/admin-to-do-event-schedule/admin-to-do-event-schedule.component';
import { AdminTopicsPageComponent } from '../modules/event-schedule/pages/admin-topics-page/admin-topics-page.component';
import { EventInfosCreatePageComponent } from '../modules/event-schedule/pages/event-infos-create-page/event-infos-create-page.component';
import { EventScheduleCreatePageComponent } from '../modules/event-schedule/pages/event-schedule-create-page/event-schedule-create-page.component';
import { EventSchedulesPageComponent } from '../modules/event-schedule/pages/event-schedules-page/event-schedules-page.component';
import { FunnelCreatePageComponent } from '../modules/event-schedule/pages/funnel-create-page/funnel-create-page.component';
import { SubscriberCreatePageComponent } from '../modules/event-schedule/pages/subscriber-create-page/subscriber-create-page.component';
import { TopicsCreatePageComponent } from '../modules/event-schedule/pages/topics-create-page/topics-create-page.component';
import { NewsEditComponent } from '../modules/news/pages/news-edit/news-edit.component';
import { UserRoles } from '../state/auth.repository';

import { TenantFeatures } from '../state/feature.repository';
import { StatisticsPageComponent } from '../modules/event-schedule/pages/statistics-page/statistics-page.component';
import { ClientEventSchedulesComponent } from '../modules/event-schedule/pages/client-event-schedules/client-event-schedules.component';
import { EventScheduleClientCreatePageComponent } from '../modules/event-schedule/pages/event-schedule-client-create-page/event-schedule-client-create-page.component';
import { DraftsPageComponent } from '../modules/event-schedule/pages/drafts-page/drafts-page.component';
import { SubmitsMonthTableComponent } from '../modules/event-schedule/components/submits-month-table/submits-month-table.component';
import { AdminSubmitsPageComponent } from '../modules/event-schedule/pages/admin-submits-page/admin-submits-page.component';
import { BackendPageComponent } from '../modules/event-schedule/pages/backend-page/backend-page.component';
import { AeStatsPageComponent } from '../modules/event-schedule/pages/ae-stats-page/ae-stats-page.component';
import { AssignCallsPageComponent } from '../modules/event-schedule/pages/assign-calls-page/assign-calls-page.component';
import { ReferralSourcesPageComponent } from '../modules/event-schedule/pages/referral-sources-page/referral-sources-page.component';
import { ReferralSourcesCreatePageComponent } from '../modules/event-schedule/pages/referral-sources-create-page/referral-sources-create-page.component';
import { CallerStatsPageComponent } from '../modules/event-schedule/pages/caller-stats-page/caller-stats-page.component';
import { CustomTemplatePageComponent } from '../modules/event-schedule/pages/custom-template-page/custom-template-page.component';
import { TopicDuplicatePageComponent } from '../modules/event-schedule/pages/topic-duplicate-page/topic-duplicate-page.component';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'backend',
        component: BackendPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'assigncalls',
        component: AssignCallsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'aestatistics',
        component: AeStatsPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [
            UserRoles.Superamin,
            UserRoles.TenantAdmin,
            UserRoles.AdvisorsExcel,
          ],
        },
      },

      {
        path: 'eventSchedules',
        component: EventSchedulesPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'client/eventSchedules',
        component: ClientEventSchedulesComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.ClientUser] },
      },
      {
        path: 'client/drafts',
        component: DraftsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.ClientUser] },
      },
      {
        path: 'client/pending',
        component: AdminSubmitsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'client/eventSchedules/:id',
        component: EventScheduleClientCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.ClientUser] },
      },
      {
        path: 'statistics',
        component: StatisticsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'callerstatistics',
        component: CallerStatsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule',
        component: AdminEventSchedulePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/adsVeiew',
        component: AdminAdsViewEventScheduleComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/payStats',
        component: AdminPayStatsEventScheduleComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/toDo',
        component: AdminToDoEventScheduleComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/registrants/:id',
        component: AdminSubscribersPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/event-infos',
        component: AdminEventInfosPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [
            UserRoles.Superamin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
        },
      },
      {
        path: 'admin/event-infos/:id',
        component: EventInfosCreatePageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [
            UserRoles.Superamin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
        },
      },
      {
        path: 'admin/teams',
        component: AdminFunnelPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/referralSources',
        component: ReferralSourcesPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/referralSources/:id',
        component: ReferralSourcesCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/teams/:id',
        component: FunnelCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/topics',
        component: AdminTopicsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/topics/:id',
        component: TopicsCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/topics/:id/duplicate',
        component: TopicDuplicatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/registrant/:type/:id',
        component: SubscriberCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/eventSchedule/:id',
        component: EventScheduleCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.Superamin, UserRoles.TenantAdmin] },
      },
      {
        path: 'admin/eventSchedule/custom-template/:id',
        component: CustomTemplatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.Superamin] },
      },
    ],
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    canActivateChild: [AuthGuard],

    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class EventScheduleRoutingModule {}
