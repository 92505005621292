import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-event-admin-tabs',
  templateUrl: './event-admin-tabs.component.html',
  styleUrls: ['./event-admin-tabs.component.scss'],
})
export class EventAdminTabsComponent implements OnInit {
  @Output() tabSelectedEmit = new EventEmitter<string>();
  @Output() exportEmit = new EventEmitter();

  @Input()
  set tabSelected(name: string) {
    this.tabSelected2 = name;
    this.tabSelectedEmit.emit(this.tabSelected2);
  }

  constructor(public authRepo: AuthRepository) {}

  tabSelected2: string = 'overview';
  ngOnInit(): void {}

  setTab(curTab: string) {
    this.tabSelected = curTab;
  }
}
