import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository } from 'src/app/state/auth.repository';
import { EventService } from '../../../state/event.service';
import { IEvent, EventsRepository } from 'src/app/state/event.repository';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CalendarOptions } from '@fullcalendar/core';
import { Calendar } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EventPopupComponent } from '../event-popup/event-popup.component';
import { style } from '@angular/animations';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  dateCalendar: string = '';
  AllEventsList: any = [];
  PersonalEvents: any = [];
  submitErrors: string[] | null = null;
  EventInformation: IEvent | null = null;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    eventChange: this.refresh.bind(this),
    height: 'auto',
    contentHeight: 'auto',
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'prev', // will normally be on the left. if RTL, will be on the right
      center: 'title',
      end: 'next', // will normally be on the right. if RTL, will be on the left
    },
    selectable: true,
    dateClick: this.choosedate.bind(this),

    eventMouseEnter: this.EventHover.bind(this),
    eventMouseLeave: this.EventHoverEnd.bind(this),
    loading: this.Loading.bind(this),
    eventColor: '#7297f8',
    events: this.AllEventsList + this.PersonalEvents,
  };

  constructor(
    private router: Router,
    public authRepo: AuthRepository,
    private repo: EventsRepository,
    private service: EventService,
    private dialog: MatDialog
  ) {}

  SelectedDate: string = new Date().toJSON();
  EventsList: any = [];
  deleteConfirmation: IEvent | null = null;
  isAdmin: boolean = false;
  ngOnInit(): void {
    this.refreshCalendar();
  }
  Loading(isLoading: any) {
    if (isLoading == true) {
    } else {
      this.refresh(null);
    }
  }
  refresh(an: any) {
    var todayTD = document.getElementsByClassName(
      'fc-day-today'
    )[0] as HTMLDivElement;
    var div = document.createElement('div');
    div.style.backgroundColor = '#F2F4F7';
    div.style.margin = '8px';
    div.style.zIndex = '9999!important';
    div.style.position = 'absolute';
    div.style.top = '0';
    div.style.left = '0';
    div.style.right = '0';
    div.style.borderRadius = '20px';
    div.style.bottom = '0';
    todayTD.lastChild?.lastChild?.appendChild(div);
  }
  EventHover(an: any) {}
  EventHoverEnd(an: any) {
    this.refreshCalendar();
  }

  refreshCalendar(date: string = new Date().toJSON()) {
    this.service.getEventSharing().subscribe((x) => {
      this.PersonalEvents = x.map((y) => ({
        title: '',
        start: y.eventDate.toJSON().slice(0, 10),
        color: '#0B5087',
        id: y.id,
      }));

      this.service.GetEventsWithoutPersonal().subscribe((x) => {
        this.AllEventsList = x.map((y) => ({
          title: '',
          start: y.eventDate.toJSON().slice(0, 10),
          id: y.id,
        }));
        this.calendarOptions.events = this.AllEventsList.concat(
          this.PersonalEvents
        );
      });

      this.refreshEvents(date);
    });
  }

  ShowInfo(id: string) {
    this.loadevent(id);
  }

  loadevent(id: string) {
    this.service.loadOne(id).subscribe((x) => {
      this.EventInformation = x;
    });
  }

  refreshEvents(date: string) {
    this.service.getByDate(date).subscribe((data) => {
      this.EventsList = data;
    });
  }

  choosedate(info: DateClickArg) {
    this.service.getByDate(info.dateStr).subscribe((data) => {
      if (data.length) {
        this.EventsList = data;
        this.SelectedDate = info.dateStr;
        this.refreshEvents(info.dateStr);
        const dialogConfig = new MatDialogConfig();
        (dialogConfig.id = 'dialog'), (dialogConfig.backdropClass = 'b1');
        this.dialog.open(EventPopupComponent, {
          width: '450px',
          maxWidth: '90%',
          minHeight: '288px',
          data: {
            date: info.dateStr,
            events: data,
          },
          backdropClass: 'b1',
        });
        this.dialog.afterAllClosed.subscribe((x) => this.refreshCalendar());
      }
    });
  }

  EventClick(an: any) {
    const dialogConfig = new MatDialogConfig();
    (dialogConfig.id = 'dialog'), (dialogConfig.backdropClass = 'b1');
    this.dialog.open(EventPopupComponent, {
      width: '400px',
      maxWidth: '90%',
      minHeight: '288px',
      data: {
        date: an.event.date,
        title: an.event.title,
        description: an.event.description,
        id: an.event.id,
      },
      backdropClass: 'b1',
    });
    this.dialog.afterAllClosed.subscribe((x) => this.refreshCalendar());
  }

  subscribe(event: IEvent, value: boolean) {
    if (value) {
      this.service
        .deleteFromAppList(event.id)
        .subscribe((x) => this.refreshCalendar(event.eventDate.toJSON()));
    } else {
      this.service
        .subscribe(event.id)
        .subscribe((x) => this.refreshCalendar(event.eventDate.toJSON()));
    }
    event.subscribe = !event.subscribe;
  }
}
