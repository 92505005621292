<div
  *ngFor="let month of repo.months$ | async; let curentMonthIndex = index"
  class="p-0 m-0"
>
  <div class="d-flex align-items-center">
    <button
      class="ms-0 btn btn-wfrBlue w-100 mt-2"
      style="padding-top: 3px; padding-bottom: 3px"
      (click)="expand.emit(month.id)"
      id="ads-view-button-{{ curentMonthIndex }}"
    >
      <span class="d-flex justify-content-start">
        <span> {{ month.name }} | Events: {{ month.eventsCount }} </span>
      </span>
    </button>
  </div>
  <div
    class="box my-2 hide-scrollbar"
    style="overflow-x: scroll; overflow-y: hidden; padding-bottom: 10px"
    *ngIf="month.expanded"
  >
    <div class="box-name-header p-0 m-0">
      <div class="d-flex justify-content-between p-2">
        <div class="  " style="width: 300px">
          <span class="txt">Status</span>
        </div>

        <div class="" style="width: 300px">
          <span class="txt mt-1">Date</span>
        </div>

        <div class="" style="width: 300px">
          <span class="txt mt-1">Time</span>
        </div>

        <div class="" style="width: 800px">
          <span class="txt mt-1">Location</span>
        </div>
        <div class="" style="width: 800px">
          <span class="txt mt-1">Topic</span>
        </div>
        <div class="" style="width: 800px">
          <span class="txt mt-1">Reminder</span>
        </div>
        <div class="" style="width: 500px">
          <span class="txt mt-1">
            <img src="assets/img/users19x19.svg" />
          </span>
        </div>

        <div class="" style="min-width: 50px">
          <span class="txt mt-1"></span>
        </div>
      </div>
    </div>
    <ng-container *ngVar="month.showEventSchedules as events" class="">
      <div infiniteScroll (scrolled)="onScrollDown(month)">
        <div
          *ngFor="let event of events; let curentEventIndex = index"
          class=""
        >
          <div
            class="d-flex justify-content-between px-2 h60"
            [class.b-top-gray]="
              events.length &&
              curentEventIndex !== 0 &&
              curentEventIndex !== events.length
            "
          >
            <div class="d-flex align-items-center" style="width: 300px">
              <div class="text-break oneLineText">
                <span>{{ event.confirmationStatus }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 300px">
              <div class="text-break oneLineText">
                {{ event.formatedDateAdmin }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 300px">
              <div class="text-break oneLineText">
                {{ event.time ?? event.prebuildTime?.name }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 800px">
              <div class="text-break oneLineText">
                {{ event.eventScheduleLocation?.title }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 800px">
              <div class="text-break oneLineText">
                {{ event.topic }}
              </div>
            </div>
            <div class="d-flex align-items-center" style="width: 800px">
              <div class="text-break oneLineText">
                {{ event.wfrReminder }}
              </div>
            </div>
            <div class="d-flex align-items-center pointer" style="width: 500px">
              <div
                class="d-flex align-items-center justify-content-end flex-column py-2"
                title="Registrants"
              >
                <div class="d-flex align-items-center">
                  <a
                    class="btn btn-link d-flex p-0 m-0"
                    (click)="goToLink(event.googleSheet)"
                    title="Registrants"
                    i18n-title="Registrants button"
                  >
                    <span class="me-1 p-0 m-0" style="color: black">
                      <sup class="p-0 m-0"> {{ +event.attendees }} </sup>/<sub
                        class="me-1 p-0 m-0"
                      >
                        {{
                          +(event.finalRegistrants
                            ? event.finalRegistrants
                            : event.registrants)
                        }}
                      </sub>
                      <span>
                        ({{
                          setMax100(
                            event.attendees,
                            +(event.finalRegistrants
                              ? event.finalRegistrants
                              : event.registrants)
                          )
                        }}%)
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <br />
            </div>
            <div
              class="d-flex align-items-center justify-content-end"
              style="min-width: 50px; right: 0"
            >
              <div
                *ngIf="event.isInReview || event.isDraft"
                class="d-flex align-items-center justify-content-end"
              >
                <div>
                  <a
                    class="btn btn-link w-100 p-0 m-0"
                    [routerLink]="['/client/eventSchedules/', event.id]"
                    title="Edit"
                    i18n-title="Edit button"
                  >
                    <img src="assets/img/edit19x19.svg" />
                  </a>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-link w-100 p-0 px-1 m-0"
                    data-bs-target="#deleteusermodal"
                    title="Delete"
                    i18n-title="Delete button"
                    (click)="deleteConfirmation = event"
                  >
                    <img src="assets/img/delete18x20.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="event.doubleEvent">
            <div class="d-flex justify-content-between px-2 h60">
              <div class="d-flex align-items-center" style="width: 300px">
                <div class="text-break oneLineText">
                  <span>{{ event.confirmationStatusD2 }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 300px">
                <div class="text-break oneLineText">
                  {{ event.formatedDate2Admin }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 300px">
                <div class="text-break oneLineText">
                  {{ event.time2 ?? event.prebuildTime2?.name }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 800px">
                <div class="text-break oneLineText">
                  {{ event.eventScheduleLocation?.title }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 800px">
                <div class="text-break oneLineText">
                  {{ event.topic }}
                </div>
              </div>
              <div class="d-flex align-items-center" style="width: 800px">
                <div class="text-break oneLineText">
                  {{ event.wfrReminder }}
                </div>
              </div>
              <div
                class="d-flex align-items-center pointer"
                style="width: 500px"
              >
                <div
                  class="d-flex align-items-center justify-content-end flex-column py-2"
                  title="Registrants"
                >
                  <div class="d-flex align-items-center">
                    <a
                      class="btn btn-link d-flex p-0 m-0"
                      (click)="goToLink(event.googleSheetD2)"
                      title="Registrants"
                      i18n-title="Registrants button"
                    >
                      <span class="me-1 p-0 m-0" style="color: black">
                        <sup class="p-0 m-0"> {{ +event.attendeesD2 }} </sup
                        >/<sub class="me-1 p-0 m-0">
                          {{
                            +(event.finalRegistrantsD2
                              ? event.finalRegistrantsD2
                              : event.registrantsD2)
                          }}
                        </sub>

                        <span>
                          ({{
                            setMax100(
                              event.attendeesD2,
                              +(event.finalRegistrantsD2
                                ? event.finalRegistrantsD2
                                : event.registrantsD2)
                            )
                          }}%)
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <br />
              </div>

              <div
                class="d-flex align-items-center justify-content-end"
                style="min-width: 50px; right: 0"
              >
                <div
                  *ngIf="event.isInReview || event.isDraft"
                  class="d-flex align-items-center justify-content-end"
                >
                  <div>
                    <a
                      class="btn btn-link w-100 p-0 m-0"
                      [routerLink]="['/client/eventSchedules/', event.id]"
                      title="Edit"
                      i18n-title="Edit button"
                    >
                      <img src="assets/img/edit19x19.svg" />
                    </a>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link w-100 p-0 px-1 m-0"
                      data-bs-target="#deleteusermodal"
                      title="Delete"
                      i18n-title="Delete button"
                      (click)="secondDeleteConfirmation = event"
                    >
                      <img src="assets/img/delete18x20.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-confirm-delete
  id="deleteusermodal"
  *ngIf="deleteConfirmation"
  [eventSchedule]="true"
  [clientCode]="deleteConfirmation.client.clientCode"
  [clientName]="deleteConfirmation.client.title"
  [date]="deleteConfirmation.formatedDateAdmin"
  [location]="deleteConfirmation.eventScheduleLocation?.title"
  [deleteCandidate]="''"
  (confirm)="
    deleteConfirmation.doubleEvent
      ? setSecondDateAsPrimary(deleteConfirmation)
      : handleDeleteClick(deleteConfirmation);
    deleteConfirmation = null
  "
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
<app-confirm-delete
  id="esDeleteConfirmModal"
  *ngIf="secondDeleteConfirmation"
  [eventSchedule]="true"
  [clientCode]="secondDeleteConfirmation.client.clientCode"
  [clientName]="secondDeleteConfirmation.client.title"
  [date]="secondDeleteConfirmation.formatedDate2Admin"
  [location]="secondDeleteConfirmation.eventScheduleLocation?.title"
  [deleteCandidate]="''"
  (confirm)="
    secondHandleDeleteClick(secondDeleteConfirmation);
    secondDeleteConfirmation = null
  "
  (cancel)="secondDeleteConfirmation = null"
></app-confirm-delete>
