import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';

import { CalendarComponent } from './calendar/calendar.component';
import { EventFormComponent } from './event-form/event-form.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventPostComponent } from './event-post/event-post.component';
import { FullCalendarModule } from '@fullcalendar/angular';

import { EventSharingComponent } from './event-sharing/event-sharing.component';
import { EventInfoComponent } from './event-info/event-info.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EventAdminComponent } from './event-admin/event-admin.component';
import { EventPopupComponent } from './event-popup/event-popup.component';

@NgModule({
  declarations: [
    CalendarComponent,
    EventFormComponent,
    EventEditComponent,
    EventPostComponent,
    EventSharingComponent,
    EventInfoComponent,
    EventAdminComponent,
    EventPopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    FullCalendarModule,
    MatDialogModule,
  ],
  exports: [
    CalendarComponent,
    EventFormComponent,
    EventEditComponent,
    EventPostComponent,
    EventSharingComponent,
  ],
})
export class CalendarModule {}
