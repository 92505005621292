<!--<div class="dropdown">
  <div class="btn-group" role="group">
    <button class="btn btn-outline-secondary" data-bs-toggle="dropdown">
      <span i18n="Sort by label">Sort by {{ sorting?.parameter?.label }}</span>
      <i class="ms-2 bi bi-caret-down-fill"></i>
    </button>
    <ul class="dropdown-menu">
      <li *ngFor="let option of sortingOptions" class="dropdown-item"
        (click)="ChangeParam(option)">{{ option.label }}</li>
    </ul>
    <button (click)="ChangeDirection()" class="btn btn-outline-secondary">
      <i class="bi" 
        [class.bi-sort-down]="sorting?.direction === 'desc'"
        [class.bi-sort-up]="sorting?.direction === 'asc'"></i>
    </button>
  </div>
</div>-->

<div class="dropdown">
  <div class="sortbody btn-group" role="group">
    <button class="btn" data-bs-toggle="dropdown">
      <span class="sorttext mainfont" i18n="Sort by label"
        >Sort by {{ sorting?.parameter?.label }}</span
      >
    </button>
    <ul class="dropdown-menu sortul">
      <li
        *ngFor="let option of sortingOptions"
        class="sorttext mainfont dropdown-item"
        (click)="ChangeParam(option)"
      >
        {{ option.label }}
      </li>
    </ul>
    <button (click)="ChangeDirection()" class="btn p-0 pe-3">
      <svg
        *ngIf="sorting?.direction === 'desc'"
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.5L6 6.5L11 1.5"
          stroke="#344054"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        *ngIf="sorting?.direction === 'asc'"
        width="12"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 7L7 1L1 7"
          stroke="#344054"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</div>
