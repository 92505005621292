import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { getStoredToken } from 'src/app/state/auth.repository';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { NewsModule } from './modules/news/news.module';
import { UnauthorizedModule } from './modules/unauthorized/unauthorized.module';
import { WhistleblowModule } from './modules/whistleblows/whistleblow.module';
import { CalendarModule } from './modules/calendar/calendar.module';
import { TimelogsModule } from './modules/timelogs/timelogs.module';
import { ApvModule } from './modules/apv/apv.module';
import { MailModule } from './modules/mail/mail.module';
import { MweventsModule } from './modules/mwevents/mwevents.module';
import { SessionInterceptor } from './modules/core/interceptors/session.interceptor';
import { DateInterceptor } from './modules/core/interceptors/date.interceptor';
import { ImageInterceptor } from './modules/core/interceptors/image.interceptor';
import { BaseUrlInterceptor } from './modules/core/interceptors/base-url.interceptor';
import { CoreModule } from './modules/core/core.module';
import { CoreRoutingModule } from './routing/core-routing.module';
import { UnauthorizedRoutingModule } from './routing/unauthorized-routing.module';
import { ApvRoutingModule } from './routing/apv-routing.module';
import { NewsRoutingModule } from './routing/news-routing.module';
import { WhistleblowRoutingModule } from './routing/whistleblow-routing.module';
import { CalendarRoutingModule } from './routing/calendar-routing.module';
import { TimelogsRoutingModule } from './routing/timelogs-routing.module';
import { MailRoutingModule } from './routing/mail-routing.module';
import { MWEventsRoutingModule } from './routing/mwevents-routing.module';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './modules/core/pages/pagenotfound/pagenotfound.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RetryInterceptor } from './modules/core/interceptors/retry.interceptor';
import { REQUESTS_RESOLVER } from './modules/shared/services/requests-resolver';
import { EnvState } from './modules/shared/helpers/env-state';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { EventScheduleModule } from './modules/event-schedule/event-schedule.module';
import { EventScheduleRoutingModule } from './routing/eventSchedule-routing.module';
import { DatePipe } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
const optionalModules = [];
const optionalProviders = [];
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
if (environment.features.whistleblow) {
  optionalModules.push(WhistleblowModule);
  optionalModules.push(WhistleblowRoutingModule);
}
if (environment.features.apv) {
  optionalModules.push(ApvModule);
  optionalModules.push(ApvRoutingModule);
}
if (environment.features.calendar) {
  optionalModules.push(CalendarModule);
  optionalModules.push(CalendarRoutingModule);
}
if (environment.features.timelogs) {
  optionalModules.push(TimelogsModule);
  optionalModules.push(TimelogsRoutingModule);
}
if (environment.features.mail) {
  optionalModules.push(MailModule);
  optionalModules.push(MailRoutingModule);
}
if (environment.features.news) {
  optionalModules.push(NewsModule);
  optionalModules.push(NewsRoutingModule);
}
if (environment.features.maps) {
  optionalModules.push(UnauthorizedModule);
  optionalModules.push(UnauthorizedRoutingModule);
}
if (environment.features.mwevents) {
  optionalModules.push(MweventsModule);
  optionalModules.push(MWEventsRoutingModule);
}
if (environment.features.eventSchedule) {
  optionalModules.push(EventScheduleModule);
  optionalModules.push(EventScheduleRoutingModule);
}
//#tickets_system
if (environment.features.tickets) {
  optionalModules.push(TicketSystemModule);
  optionalModules.push(TicketsRoutingModule);
}

if (environment.features.offlineChanges) {
  optionalProviders.push({
    provide: HTTP_INTERCEPTORS,
    useClass: RetryInterceptor,
    multi: true,
  });
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'M/DD/YYYY',
  },
  display: {
    dateInput: 'M/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { TicketSystemModule } from './modules/ticket-system/ticket-system.module';
import { TicketsRoutingModule } from './routing/tickets-routing.module';

const moment = _rollupMoment || _moment;

@NgModule({
  declarations: [AppComponent],
  imports: [
    QRCodeModule,
    GooglePlaceModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyArK0PM_qJ07Bp3pBZ1ER2wlUNRInZ_eQc',
    }),
    MatDialogModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 2 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:2000',
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => getStoredToken(),
        allowedDomains: [window.location.origin],
      },
    }),
    CoreModule,
    CoreRoutingModule,
    ...optionalModules,
    // 404 redirect must be the last import!
    RouterModule.forRoot([{ path: '**', component: PagenotfoundComponent }]),
    NoopAnimationsModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'us-us' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: EnvState,
      useValue: environment,
    },
    ...optionalProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
