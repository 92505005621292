<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create tenant label">
  Create client
</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit tenant label">Edit client</h2>
<div *ngIf="id === 'new' || company" class="row mb-4">
  <div class="col-12">
    <app-companies-editform [isLoading]="isLoading" [referralSources]="referralSources" [editedCompany]="company"
      (ClientSubmit)="createClient($event)"></app-companies-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert title="Could not submit the tenant profile" i18n-title="Tenant profile submit error alert"
      [errors]="submitErrors"></app-error-alert>
  </div>
</div>