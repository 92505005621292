import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { Post, PostsRepository } from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';

@Component({
  selector: 'app-news-post',
  templateUrl: './news-post.component.html',
  styleUrls: ['./news-post.component.scss'],
})
export class NewsPostComponent implements OnInit {
  post$!: Observable<Post>;
  image = 'assets/img/product1.jpg';
  submitErrors: string[] | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private repo: PostsRepository,
    private service: PostsService
  ) {}

  ngOnInit(): void {}

  post($news: Partial<Post>) {
    this.submitErrors = null;
    let updateResult: Observable<Post> | undefined;
    updateResult = this.service.add($news);
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/admin/posts']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
