import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { environment } from 'src/environments/environment';
import { TicketCommentAction } from '../tickets/tickets.repository';

@Injectable({
  providedIn: 'root',
})
export class TicketClientService {
  private hubConnection?: signalR.HubConnection;
  ticketSubject = new Subject<TicketCommentAction>();
  ticketCommentSubject = new Subject<TicketCommentAction>();

  commentsAction$: Observable<TicketCommentAction> =
    this.ticketCommentSubject.asObservable();
  ticketsAction$: Observable<TicketCommentAction> =
    this.ticketSubject.asObservable();
  public status = 'Closed';
  constructor(public http: HttpClient, private env: EnvState) { }

  closeConn() {
    this.hubConnection?.stop();
    this.hubConnection?.off('TicketCommentAction', (ticketCommentAction) => { });
    this.hubConnection?.off('TicketAction', (ticketCommentAction) => { });
    this.hubConnection = undefined;
    // this.ticketSubject = new Subject<TicketCommentAction>();
    // this.ticketCommentSubject = new Subject<TicketCommentAction>();
  }
  openConn() {
    var url = new URL('/core/TicketsHub', new URL(window.location.href).origin)
      .href;

    if (!environment.production) {
      url = 'https://localhost:7260/TicketsHub';
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Warning)
      .build();
    this.hubConnection
      .start()
      .then(() => {
        this.status = 'Connected';
        this.hubConnection?.on('TicketAction', (ticketCommentAction) => {
          this.ticketSubject.next(ticketCommentAction);
        });
        this.hubConnection?.on('TicketCommentAction', (ticketCommentAction) => {
          this.ticketCommentSubject.next(ticketCommentAction);
        });
      })
      .catch((err) => console.error('Error connecting to SignalR hub:', err));

    this.hubConnection.onreconnecting((x) => {
      this.status = 'Reconecting';
    });
    this.hubConnection.onreconnected((x) => {
      this.status = 'Connected';
    });
    this.hubConnection.onclose((x) => {
      this.status = 'Closed';
    });
  }
}
