<div class="row">
  <div class="col-2 col-sm-1 topmenu pb-3">
    <div class="icosection d-flex justify-content-center">
      <div class="mt-lg-0 mt-5">
        <div class="icons pt-4 row p-0">
          <div class="col-10">
            <div
              *ngIf="index !== -1"
              (click)="navigateByIcons(-1)"
              class="icon d-flex justify-content-center align-items-center"
            >
              <svg
                width="16"
                height="20"
                viewBox="0 0 20 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1.25L2.09344 11.9379C1.74463 12.3564 1.57023 12.5657 1.56756 12.7425C1.56524 12.8961 1.63372 13.0423 1.75324 13.1389C1.89073 13.25 2.16316 13.25 2.70802 13.25H9.99998L8.99998 21.25L17.9065 10.5621C18.2553 10.1436 18.4297 9.93429 18.4324 9.75754C18.4347 9.60388 18.3663 9.4577 18.2467 9.36111C18.1092 9.25 17.8368 9.25 17.292 9.25H9.99998L11 1.25Z"
                  stroke="#0B5087"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              *ngIf="index === -1"
              class="icon chosenicon d-flex justify-content-center align-items-center"
            >
              <svg
                width="16"
                height="20"
                viewBox="0 0 20 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1.25L2.09344 11.9379C1.74463 12.3564 1.57023 12.5657 1.56756 12.7425C1.56524 12.8961 1.63372 13.0423 1.75324 13.1389C1.89073 13.25 2.16316 13.25 2.70802 13.25H9.99998L8.99998 21.25L17.9065 10.5621C18.2553 10.1436 18.4297 9.93429 18.4324 9.75754C18.4347 9.60388 18.3663 9.4577 18.2467 9.36111C18.1092 9.25 17.8368 9.25 17.292 9.25H9.99998L11 1.25Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div *ngFor="let subevent of getControls(); let i = index">
          <div class="d-flex justify-content-center col-10">
            <hr *ngIf="index === i" class="choosenvl vl" style="0 auto" />
            <hr *ngIf="index < i" class="vl" style="0 auto" />
            <hr *ngIf="index > i" class="passedvl vl" style="0 auto" />
          </div>

          <div
            *ngIf="index !== i && index < i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              (click)="navigateByIcons(i)"
              class="icon d-flex justify-content-center align-items-center"
            >
              <div>{{ i + 1 }}</div>
            </div>
            <div class="ms-1 d-flex flex-column">
              <svg
                (click)="moveSubevent(i, 1)"
                class="arrow mb-1"
                *ngIf="i !== 0"
                width="10"
                height="10"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 17V1M7 1L1 7M7 1L13 7"
                  stroke="#1D2939"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                (click)="moveSubevent(i, -1)"
                class="arrow"
                *ngIf="i !== getControls().length - 1"
                width="10"
                height="10"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1V17M7 17L13 11M7 17L1 11"
                  stroke="#1D2939"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div
            *ngIf="index === i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="icon chosenicon d-flex justify-content-center align-items-center"
            >
              <div>{{ i + 1 }}</div>
            </div>
            <div class="ms-1 d-flex flex-column">
              <svg
                (click)="moveSubevent(i, 1)"
                class="arrow mb-1"
                *ngIf="i !== 0"
                width="10"
                height="10"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 17V1M7 1L1 7M7 1L13 7"
                  stroke="#1D2939"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                (click)="moveSubevent(i, -1)"
                class="arrow"
                *ngIf="i !== getControls().length - 1"
                width="10"
                height="10"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1V17M7 17L13 11M7 17L1 11"
                  stroke="#1D2939"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            *ngIf="index > i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              (click)="navigateByIcons(i)"
              class="passedicon icon d-flex justify-content-center align-items-center"
            >
              <div>{{ i + 1 }}</div>
            </div>
            <div class="ms-1 d-flex flex-column">
              <svg
                (click)="moveSubevent(i, 1)"
                class="arrow mb-1"
                *ngIf="i !== 0"
                width="10"
                height="10"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 17V1M7 1L1 7M7 1L13 7"
                  stroke="#1D2939"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                (click)="moveSubevent(i, -1)"
                class="arrow"
                *ngIf="i !== getControls().length - 1"
                width="10"
                height="10"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1V17M7 17L13 11M7 17L1 11"
                  stroke="#1D2939"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="Form" class="col-10 col-sm-11">
    <form
      [formGroup]="Form"
      (ngSubmit)="onSubmit()"
      enctype="multipart/form-data"
    >
      <div [hidden]="index !== -1">
        <div
          class="form-group form-floating mb-3"
          [appValidateState]="Form.get('title')"
        >
          <div class="inpt-title">Title</div>
          <input
            id="title"
            formControlName="title"
            type="text"
            class="inpt"
            placeholder="Title"
            i18n-placeholder="@@title"
            autocomplete="off"
          />
        </div>
        <div
          class="form-group form-floating mb-3"
          [appValidateState]="Form.get('description')"
        >
          <div class="inpt-title">Description</div>

          <textarea
            id="editor"
            formControlName="description"
            type="text"
            class="form-control"
            placeholder="Description"
            i18n-placeholder="Label description"
            autocomplete="off"
          >
          </textarea>
        </div>

        <div class="col-12 row mb-3 p-0 m-0">
          <div class="d-flex p-0">
            <div
              class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.6667 2.99984H7.4C5.15979 2.99984 4.03969 2.99984 3.18404 3.43581C2.43139 3.8193 1.81947 4.43123 1.43597 5.18388C1 6.03952 1 7.15963 1 9.39984V20.5998C1 22.84 1 23.9602 1.43597 24.8158C1.81947 25.5685 2.43139 26.1804 3.18404 26.5639C4.03969 26.9998 5.15979 26.9998 7.4 26.9998H19.6667C20.9066 26.9998 21.5266 26.9998 22.0353 26.8635C23.4156 26.4937 24.4938 25.4155 24.8637 24.0351C25 23.5264 25 22.9065 25 21.6665M22.3333 9.6665V1.6665M18.3333 5.6665H26.3333M11 10.3332C11 11.8059 9.80609 12.9998 8.33333 12.9998C6.86057 12.9998 5.66667 11.8059 5.66667 10.3332C5.66667 8.86041 6.86057 7.6665 8.33333 7.6665C9.80609 7.6665 11 8.86041 11 10.3332ZM16.9867 14.8907L5.7082 25.1439C5.07382 25.7206 4.75663 26.009 4.72857 26.2588C4.70425 26.4753 4.78727 26.69 4.95091 26.8339C5.13971 26.9998 5.56837 26.9998 6.42571 26.9998H18.9413C20.8602 26.9998 21.8196 26.9998 22.5732 26.6775C23.5193 26.2728 24.2729 25.5191 24.6776 24.5731C25 23.8195 25 22.86 25 20.9411C25 20.2955 25 19.9727 24.9294 19.672C24.8407 19.2942 24.6706 18.9403 24.431 18.635C24.2403 18.3921 23.9883 18.1904 23.4841 17.7871L19.7544 14.8034C19.2499 14.3997 18.9976 14.1979 18.7197 14.1267C18.4748 14.0639 18.2172 14.072 17.9767 14.1501C17.7039 14.2387 17.4648 14.456 16.9867 14.8907Z"
                  stroke="#475467"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="d-flex form-floating col file-inpt displayNoneOnSmallScreens"
            >
              <input
                id="custom-file-input"
                formControlName="file"
                type="file"
                (change)="uploadFile($event, -1)"
                class="form-control mt-3"
              />
              <label for="file" i18n="@@VideoFile"
                >Click to upload or drag and drop video file
              </label>
            </div>
            <div class="pt-2 displayNoneOnBigScreens">
              <div
                class="form-floating file-inpt-sm d-flex align-items-center ps-2"
                style=""
              >
                <input
                  id="custom-file-input"
                  formControlName="file"
                  type="file"
                  (change)="uploadFile($event, -1)"
                  class="custom-file-input"
                  style="width: 100%"
                />
                <label class="label" for="custom-file-input">
                  <svg
                    style="flex: none"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613 "
                      stroke="#344054"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Upload File
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-12 form-group form-floating mb-md-0 mb-3 d-flex align-items-center"
          >
            <input
              type="checkbox"
              formControlName="isActive"
              placeholder="Active"
              class="form-check-input m-0 me-1"
            />
            Active
          </div>
          <div class="col-md-6 col-12">
            <app-users-datalist-select
              [options]="viewerOptions"
              [multiple]="true"
              class="form-floating"
              label="Viewer:"
              [(activeValue)]="Form?.value.viewersIds"
              (activeValueChange)="updateValue('viewersIds', $event)"
            >
            </app-users-datalist-select>
          </div>
          <div class="col-md-6 col-12">
            <app-users-datalist-select
              [options]="respOptions"
              [multiple]="true"
              class="form-floating"
              label="Responsible:"
              [(activeValue)]="Form?.value.respIds"
              (activeValueChange)="updateValue('respIds', $event)"
            >
            </app-users-datalist-select>
          </div>

          <div class="col-12">
            <app-users-datalist-select
              [options]="userOptions"
              [multiple]="true"
              class="form-floating"
              label="Users:"
              [(activeValue)]="Form?.value.usersIds"
              (activeValueChange)="updateValue('usersIds', $event)"
            >
            </app-users-datalist-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-6">
            <select class="form-select" formControlName="subType">
              <option value="Text">Text</option>
              <option value="VideoUrl">VideoUrl</option>
              <option value="VideoFile">VideoFile</option>
              <option value="Questions">Questions</option>
            </select>
          </div>
          <div class="col-6 p-0">
            <div class="form-group form-floating pb-3 d-grid mr-3">
              <button
                type="button"
                (click)="addNewSubevents()"
                class="btn btn-md-lg btn-wfrBlue"
                i18n="@@AddSubevent"
                [disabled]="Form.value.subType == null"
              >
                <i class="bi bi-plus"></i> Add Subevent
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3" formArrayName="subevents">
        <div class="" *ngFor="let item of getControls(); let i = index">
          <div [hidden]="index != i">
            <div class="row mb-2">
              <div class="col">
                <h6 class="my-1" i18n="Basic info label">
                  Subevent {{ i + 1 }}
                </h6>
              </div>
            </div>
            <div class="row mb-2">
              <div [formGroupName]="i" class="col-12 col-md-10 col-lg-10">
                <div
                  class="form-group form-floating mb-2"
                  [class.is-invalid]="
                    item.get('title')?.hasError('required') &&
                    item.get('title')?.touched
                  "
                >
                  <div class="inpt-title">Title</div>
                  <input
                    formControlName="title"
                    type="text"
                    class="inpt"
                    placeholder="Title"
                    i18n-placeholder="Title placeholder @@title"
                  />
                </div>
                <div
                  class="form-group form-floating mb-2"
                  [class.is-invalid]="
                    item.get('description')?.hasError('required') &&
                    item.get('description')?.touched
                  "
                >
                  <div class="inpt-title">Description</div>

                  <textarea
                    id="ck{{ i }}"
                    formControlName="description"
                    type="text"
                    class="form-control"
                    placeholder="Description"
                    i18n-placeholder="Label description"
                    autocomplete="off"
                  >
                  </textarea>
                </div>

                <div
                  *ngIf="item.value.subeventType === 'VideoUrl'"
                  class="form-group form-floating mb-2"
                >
                  <div class="form-group form-floating">
                    <div class="inpt-title">VideoUrl</div>
                    <input
                      formControlName="url"
                      type="text"
                      class="inpt"
                      placeholder="Url"
                      i18n-placeholder="Url placeholder @@url"
                    />
                  </div>
                </div>

                <div
                  [hidden]="item.value.subeventType !== 'Text'"
                  class="form-group form-floating mb-2"
                >
                  <div class="inpt-title">Text</div>
                  <textarea
                    id="ckt{{ i }}"
                    formControlName="text"
                    type="text"
                    class="inpt inpt-lg"
                    placeholder="Text for subevent"
                    i18n-placeholder="Text for subevent @@text"
                  >
                  </textarea>
                </div>

                <div
                  *ngIf="item.value.subeventType === 'VideoFile'"
                  class="form-group form-floating mb-2"
                >
                  <div class="col-12 row mb-2 p-0 m-0">
                    <div class="d-flex p-0">
                      <div
                        class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.6667 2.99984H7.4C5.15979 2.99984 4.03969 2.99984 3.18404 3.43581C2.43139 3.8193 1.81947 4.43123 1.43597 5.18388C1 6.03952 1 7.15963 1 9.39984V20.5998C1 22.84 1 23.9602 1.43597 24.8158C1.81947 25.5685 2.43139 26.1804 3.18404 26.5639C4.03969 26.9998 5.15979 26.9998 7.4 26.9998H19.6667C20.9066 26.9998 21.5266 26.9998 22.0353 26.8635C23.4156 26.4937 24.4938 25.4155 24.8637 24.0351C25 23.5264 25 22.9065 25 21.6665M22.3333 9.6665V1.6665M18.3333 5.6665H26.3333M11 10.3332C11 11.8059 9.80609 12.9998 8.33333 12.9998C6.86057 12.9998 5.66667 11.8059 5.66667 10.3332C5.66667 8.86041 6.86057 7.6665 8.33333 7.6665C9.80609 7.6665 11 8.86041 11 10.3332ZM16.9867 14.8907L5.7082 25.1439C5.07382 25.7206 4.75663 26.009 4.72857 26.2588C4.70425 26.4753 4.78727 26.69 4.95091 26.8339C5.13971 26.9998 5.56837 26.9998 6.42571 26.9998H18.9413C20.8602 26.9998 21.8196 26.9998 22.5732 26.6775C23.5193 26.2728 24.2729 25.5191 24.6776 24.5731C25 23.8195 25 22.86 25 20.9411C25 20.2955 25 19.9727 24.9294 19.672C24.8407 19.2942 24.6706 18.9403 24.431 18.635C24.2403 18.3921 23.9883 18.1904 23.4841 17.7871L19.7544 14.8034C19.2499 14.3997 18.9976 14.1979 18.7197 14.1267C18.4748 14.0639 18.2172 14.072 17.9767 14.1501C17.7039 14.2387 17.4648 14.456 16.9867 14.8907Z"
                            stroke="#475467"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div
                        class="d-flex form-floating col file-inpt displayNoneOnSmallScreens"
                      >
                        <input
                          id="custom-file-input"
                          formControlName="file"
                          type="file"
                          (change)="uploadFile($event, i)"
                          class="form-control mt-3"
                        />
                        <label for="file" i18n="@@VideoFile"
                          >Click to upload or drag and drop video file
                        </label>
                      </div>
                      <div class="pt-2 displayNoneOnBigScreens">
                        <div
                          class="form-floating file-inpt-sm d-flex align-items-center ps-2"
                          style=""
                        >
                          <input
                            id="custom-file-input"
                            formControlName="file"
                            type="file"
                            (change)="uploadFile($event, i)"
                            class="custom-file-input"
                            style="width: 100%"
                          />
                          <label class="label" for="custom-file-input">
                            <svg
                              style="flex: none"
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613 "
                                stroke="#344054"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Upload File
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="item.value.subeventType === 'Questions'"
                  class="form-group form-floating mb-2"
                >
                  <div class="form-group form-floating mb-3">
                    <div class="inpt-title">Headline</div>
                    <input
                      formControlName="headline"
                      type="text"
                      class="inpt"
                      placeholder="Headline"
                      i18n-placeholder="Headline placeholder @@headline"
                    />
                  </div>
                  <div class="form-group form-floating pb-3 d-grid mr-3">
                    <button
                      type="button"
                      (click)="addNewSubeventQuest()"
                      class="btn btn-md-lg btn-wfrBlue"
                      i18n="Add button @@add"
                    >
                      <i class="bi bi-plus"></i> Add Question
                    </button>
                  </div>
                  <div class="mt-3" formArrayName="questions">
                    <div
                      class=""
                      *ngFor="
                        let question of getQuestControls(i);
                        let k = index
                      "
                    >
                      <div [formGroupName]="k" class=" ">
                        <div>Question {{ k + 1 }}</div>
                        <div
                          class="ps-2 pe-2 row form-group form-floating"
                          [class.is-invalid]="
                            question.get('question')?.hasError('required') &&
                            question.get('question')?.touched
                          "
                        >
                          <input
                            formControlName="question"
                            type="text"
                            class="inpt mb-2 col-sm-10 col-12"
                            placeholder="Question"
                            i18n-placeholder="Question placeholder @@question"
                          />

                          <div
                            class="col-2 d-flex align-items-end mb-2 ps-sm-3 ps-0"
                          >
                            <i
                              class="btn btn-danger"
                              (click)="deleteQuestion(k)"
                              >Delete</i
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-2 col-lg-2">
                <div class="row">
                  <div class="col"></div>
                  <div class="col-auto d-flex align-items-end mb-2">
                    <i class="btn btn-danger" (click)="deleteSubevent(i)"
                      >Delete</i
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
