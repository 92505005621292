<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="inpt-title">Tenant name</div>
      <div
        [appValidateState]="form.get('name')"
        class="form-group form-floating mb-3"
      >
        <input
          formControlName="name"
          type="text"
          class="inpt"
          placeholder="Tenant name"
          i18n-placeholder="Tenant name placeholder @@tenantName"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">CVR number</div>
        <input
          formControlName="cvr"
          type="text"
          class="inpt"
          placeholder="CVR number"
          i18n-placeholder="Tenant CVR number placeholder @@cvr"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Address</div>
        <input
          formControlName="address"
          type="text"
          class="inpt"
          placeholder="Tenant address"
          i18n-placeholder="Tenant address placeholder @@address"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Main mail</div>
        <input
          formControlName="mainMail"
          type="text"
          class="inpt"
          placeholder="Main mail"
          i18n-placeholder="Main mail placeholder @@mainMail"
        />
      </div>
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Phone number</div>
        <input
          formControlName="phoneNumber"
          type="text"
          class="inpt"
          placeholder="Phone number"
          i18n-placeholder="Tenant phone number placeholder @@phoneNumber"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          class="app-datalist-select-double-height"
          [options]="featureOptions"
          [multiple]="true"
          [activeValue]="form.value.featureIds"
          (activeValueChange)="updateValue('featureIds', $event)"
          label="Features"
          i18n-label="Features label"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mt-md-0 mt-5">
      <div class="form-group form-floating">
        <div class="inpt-title">Tenant Remarks</div>
        <textarea
          rows="4"
          style="height: 100%"
          formControlName="remarks"
          type="text"
          class="inpt inpt-lg"
          placeholder="Tenant Remarks"
          i18n-placeholder="Tenant remarks placeholder @@remarks"
        ></textarea>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating">
        <div class="d-flex p-0">
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6667 2.99984H7.4C5.15979 2.99984 4.03969 2.99984 3.18404 3.43581C2.43139 3.8193 1.81947 4.43123 1.43597 5.18388C1 6.03952 1 7.15963 1 9.39984V20.5998C1 22.84 1 23.9602 1.43597 24.8158C1.81947 25.5685 2.43139 26.1804 3.18404 26.5639C4.03969 26.9998 5.15979 26.9998 7.4 26.9998H19.6667C20.9066 26.9998 21.5266 26.9998 22.0353 26.8635C23.4156 26.4937 24.4938 25.4155 24.8637 24.0351C25 23.5264 25 22.9065 25 21.6665M22.3333 9.6665V1.6665M18.3333 5.6665H26.3333M11 10.3332C11 11.8059 9.80609 12.9998 8.33333 12.9998C6.86057 12.9998 5.66667 11.8059 5.66667 10.3332C5.66667 8.86041 6.86057 7.6665 8.33333 7.6665C9.80609 7.6665 11 8.86041 11 10.3332ZM16.9867 14.8907L5.7082 25.1439C5.07382 25.7206 4.75663 26.009 4.72857 26.2588C4.70425 26.4753 4.78727 26.69 4.95091 26.8339C5.13971 26.9998 5.56837 26.9998 6.42571 26.9998H18.9413C20.8602 26.9998 21.8196 26.9998 22.5732 26.6775C23.5193 26.2728 24.2729 25.5191 24.6776 24.5731C25 23.8195 25 22.86 25 20.9411C25 20.2955 25 19.9727 24.9294 19.672C24.8407 19.2942 24.6706 18.9403 24.431 18.635C24.2403 18.3921 23.9883 18.1904 23.4841 17.7871L19.7544 14.8034C19.2499 14.3997 18.9976 14.1979 18.7197 14.1267C18.4748 14.0639 18.2172 14.072 17.9767 14.1501C17.7039 14.2387 17.4648 14.456 16.9867 14.8907Z"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="d-flex form-floating col file-inpt displayNoneOnSmallScreens"
          >
            <input
              formControlName="image"
              type="file"
              class="form-control"
              [class.is-invalid]="
                file && img.width != 2000 && img.height != 450
              "
              placeholder="Tenant picture"
              i18n-placeholder="Tenant picture placeholder @@tenantPicture"
            />
            <label for="image" i18n="@@ImageFile"
              >Click to upload image (2000x450)
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4" *ngIf="locationCheck()">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Map Address</div>
        <input
          formControlName="startingAddress"
          ngx-google-places-autocomplete
          [options]="myOptions"
          #palceRef="ngx-places"
          (onAddressChange)="handleAddressChange($event)"
          class="inpt"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Map Zoom (0-20)</div>
        <input formControlName="mapZoom" class="inpt" type="number" />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Use Km as measurement</div>
        <input
          formControlName="useKmAsValues"
          class="form-check-input"
          type="checkbox"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Allow Unauthorized Access</div>
        <input
          formControlName="allowUnauthorizedAccess"
          class="form-check-input"
          type="checkbox"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mt-3">
      <div class="form-group form-floating pb-3 d-grid">
        <button
          type="submit"
          class="btn btn-wfrBlue shadow-sm"
          i18n="Save button @@save"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</form>
