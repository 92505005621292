import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  IReferralSource,
  ReferralSourcesRepository,
} from 'src/app/state/referralSource.repository';
import { ReferralSourcesService } from 'src/app/state/referralSources.service';

@Component({
  selector: 'app-referral-sources-editform',
  templateUrl: './referral-sources-editform.component.html',
  styleUrls: ['./referral-sources-editform.component.scss'],
})
export class ReferralSourcesEditformComponent {
  editForm: UntypedFormBuilder | any;
  @Input() editedReferralSource?: IReferralSource | null;
  submitErrors: string[] | null = null;
  @Output() ReferralSourceSubmit = new EventEmitter<Partial<IReferralSource>>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: ReferralSourcesService,
    private repository: ReferralSourcesRepository
  ) {}

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedReferralSource?.name, Validators.required],
    });
  }
  onSubmit() {
    const id = this.editedReferralSource?.id?.toString();
    const tenantId = this.editedReferralSource?.tenantId?.toString();
    const name = this.editForm.value?.name?.toString();

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.ReferralSourceSubmit.emit({
      id,
      tenantId,
      name,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
