import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  IMWEvent,
  IMWSubevents,
  MWEventsRepository,
  MWEventsSortOptions,
} from 'src/app/state/mwevent.repository';
import { MWEventService } from 'src/app/state/mwevent.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mwevent-admin',
  templateUrl: './mwevent-admin.component.html',
  styleUrls: ['./mwevent-admin.component.scss'],
})
export class MweventAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = MWEventsSortOptions;
  searchFilter: string = '';

  constructor(
    private router: Router,
    public authRepo: AuthRepository,
    public repo: MWEventsRepository,
    public service: MWEventService
  ) {}

  mwevents: IMWEvent[] = [];
  deleteConfirmation: IMWEvent | null = null;

  ngOnInit(): void {
    this.service.reloadPage().subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(event: IMWEvent) {
    this.service.delete(event.id).subscribe((x) => {
      this.refreshData();
    });
  }

  clone(id: string) {
    this.service.clone(id).subscribe((x) => {
      this.refreshData();
    });
  }

  transform(value?: string): any {
    var string = value
      ? value.replace(/<.*?>/g, '').replace(/&nbsp;/g, '')
      : '';
    return string.length > 20 ? string.slice(0, 20) + '...' : string;
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
