import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  CirclesOptions,
  CirclesOptionsKm,
  ColorsOptions,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';

@Component({
  selector: 'app-locations-editform',
  templateUrl: './locations-editform.component.html',
  styleUrls: ['./locations-editform.component.scss'],
})
export class LocationsEditformComponent {
  @Input() editedLocation?: ICompanyLocation | null;
  @Input() isLoading = false;
  @Output() LocationSubmit = new EventEmitter<Partial<ICompanyLocation>>();
  editForm: UntypedFormBuilder | any;
  submitErrors: string[] | null = null;
  locationIndex: number = 1;
  formattedaddress = ' ';

  myOptions: any;
  CirclesOptions: DatalistOption[] = CirclesOptions;
  ColorsOptions: DatalistOption[] = ColorsOptions;

  testsLocation = 'localhost:9876';
  mapInfo: IMapInfo | null = null;

  constructor(
    public datepipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public clientservice: CompaniesService
  ) {}
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      title: [this.editedLocation?.title, Validators.required],
      order: [this.editedLocation?.order],
      timeZoneId: [this.editedLocation?.timeZoneId],
      timeZoneError: [this.editedLocation?.timeZoneError],
      latitude: [this.editedLocation?.latitude, Validators.required],
      longitude: [this.editedLocation?.longitude, Validators.required],
      locationAddress: [this.editedLocation?.address, Validators.required],
      address: [this.editedLocation?.address],
      defaultRadius: [this.editedLocation?.defaultRadius],
      lastEventDescription: [this.editedLocation?.lastEventDescription],
    });
    this.clientservice.loadMapInfo().subscribe((x) => {
      this.mapInfo = x;
      if (x.useKmAsValues) {
        this.CirclesOptions = CirclesOptionsKm;
      }
      if (!this.editedLocation?.defaultRadius) {
        if (x.useKmAsValues) {
          this.editForm.get('defaultRadius').setValue(3000);
        } else {
          this.editForm.get('defaultRadius').setValue(4828.03);
        }
      }
    });
  }
  public handleAddressChange(address: any) {
    (document.getElementById(`location`) as HTMLInputElement).value =
      address.formatted_address;
    this.editForm.get('latitude').setValue(address.geometry.location.lat());
    this.editForm.get('longitude').setValue(address.geometry.location.lng());
    this.editForm.get('locationAddress').setValue(address.formatted_address);
    this.editForm.get('address').setValue(address.formatted_address);
    this.editForm.get('timeZoneId')?.setValue('');
    this.editForm.get('timeZoneError')?.setValue('');
    this.editForm?.markAsTouched();
    this.editForm.updateValueAndValidity(); // force update validity
    return address.formatted_address;
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    var addressNotSet = false;
    if (
      this.editForm.latitude?.value == 0 ||
      this.editForm.longitude?.value == 0
    ) {
      addressNotSet = true;
    }

    if (addressNotSet) {
      this.editForm.markAllAsTouched();
      return;
    }

    const title = this.editForm.value?.title?.toString();

    const order = 999999;
    const latitude = this.editForm.value?.latitude?.toString();
    const longitude = this.editForm.value?.longitude?.toString();
    const address = this.editForm.value?.address?.toString();
    const defaultRadius = this.editForm.value?.defaultRadius?.toString();
    const lastEventDescription =
      this.editForm.value?.lastEventDescription?.toString();
    this.LocationSubmit.emit({
      title,
      order,
      latitude,
      longitude,
      address,
      defaultRadius,
      lastEventDescription,
    });
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }
  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
  setStartDate(event: any) {
    this.editForm.controls.lastEventDate.setValue(event.value);
  }
}
