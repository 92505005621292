import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatDialogModule } from '@angular/material/dialog';
import { MweventComponent } from './mwevent/mwevent.component';
import { MweventPostComponent } from './mwevent-post/mwevent-post.component';
import { MweventFormComponent } from './mwevent-form/mwevent-form.component';
import { MweventEditComponent } from './mwevent-edit/mwevent-edit.component';
import { MweventAdminComponent } from './mwevent-admin/mwevent-admin.component';
import { MweventUserComponent } from './mwevent-user/mwevent-user.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { MweventslistComponent } from './mweventslist/mweventslist.component';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    MweventComponent,
    MweventPostComponent,
    MweventFormComponent,
    MweventEditComponent,
    MweventAdminComponent,
    MweventUserComponent,
    MweventslistComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    MatDialogModule,
    BarRatingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [],
})
export class MweventsModule {}
