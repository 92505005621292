import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import {
  userSortOptions,
  UsersRepository,
} from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss'],
})
export class UsersListPageComponent implements OnInit {
  sortOptions = userSortOptions;
  innerWidth = 0;
  searchFilter: string = '';

  constructor(
    public tenantRepo: TenantsRepository,
    public repo: UsersRepository,
    public service: UsersService,
    private tenantService: TenantsService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    if (this.auth.isInRole(UserRoles.Superamin)) {
      this.tenantService.load().subscribe();
    }
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(id: string) {
    this.service.delete(id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
