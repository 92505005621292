<h2 class="mb-4" *ngIf="type === 'new'" i18n="Create registrantlabel">
  Create registrant
</h2>
<h2 class="mb-4" *ngIf="type !== 'new'" i18n="Edit registrant label">
  Edit registrant
</h2>
<div *ngIf="type == 'new' || subscriber" class="row mb-4">
  <div class="col-12">
    <app-subscriber-editform
      [editedSubscriber]="subscriber"
      (subscriberSubmit)="createSubscriber($event)"
    ></app-subscriber-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the registrant"
      i18n-title="registrant submit error alert"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
