import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { IApv } from 'src/app/state/apv.repository';
import { IEventSchedule } from 'src/app/state/event-schedule.repository';
import { ViewChild } from '@angular/core';
import { LocationsService } from 'src/app/state/locations.service';
@Component({
  selector: 'app-venue-specific-notes',
  templateUrl: './venue-specific-notes.component.html',
  styleUrls: ['./venue-specific-notes.component.scss'],
})
export class VenueSpecificNotesComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  @Input() editedStr?: string | null;
  @Input() editedLocationId?: string | null;

  @ViewChild('closeVSNButton') closeVSNButton: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationsService
  ) {}
  @Output() ConfirmEdit = new EventEmitter<string>();

  resetEditFrom() {
    this.editForm = this.formBuilder.group({
      venueSpecificNotes: [this.editedStr ?? ''],
    });
  }

  ngOnInit(): void {
    this.resetEditFrom();
  }

  onSubmit() {
    if ((this.editForm && !this.editForm.valid) || !this.editedLocationId) {
      this.editForm.markAllAsTouched();
      return;
    }
    const venueSpecificNotes = this.editForm.value?.venueSpecificNotes;
    this.locationService
      .updateClientSpecificNotes(this.editedLocationId, {
        lastEventDescription: venueSpecificNotes,
      })
      .subscribe((x) => {
        this.closeVSNButton?.nativeElement?.click();
        this.ConfirmEdit.emit(venueSpecificNotes);
        this.resetEditFrom();
      });
  }
}
