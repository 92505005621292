import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-comment-actions-pop',
  templateUrl: './comment-actions-pop.component.html',
  styleUrls: ['./comment-actions-pop.component.scss'],
})
export class CommentActionsPopComponent {
  @Input() top = 0;
  @Input() left = 0;
  @Output() mouseLeave = new EventEmitter<void>();
  @Output() edit = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
}
