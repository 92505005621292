import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Quill from 'quill';

@Component({
  selector: 'app-text-editor-modal',
  templateUrl: './text-editor-modal.component.html',
  styleUrls: ['./text-editor-modal.component.scss'],
})
export class TextEditorModalComponent implements OnInit {
  ngOnInit(): void {
    const SizeStyle = Quill.import('attributors/style/align');
    Quill.register(SizeStyle, true);
  }

  isModalOpen = false;
  fadeOut = false;
  @Input() editorContent: string = '';
  editedContent: string = '';
  @Input() forImageUrl = false;
  @Input() forTextOnly = false;
  @Input() title: string = 'Field edit';
  @Input() largeTextField = false;
  @Input() addSpacing = true;
  @Output() text: EventEmitter<string> = new EventEmitter();
  editorModules = {
    toolbar: [
      [{ header: [false, 1, 2, 3, 4] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['bold', 'italic', 'underline'],
      ['link'],
    ],
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
  };

  openModal(): void {
    this.isModalOpen = true;
    this.fadeOut = false;
    this.editedContent = this.editorContent;
  }

  closeModal(): void {
    this.fadeOut = true;
    setTimeout(() => {
      this.isModalOpen = false;
    }, 300);
  }

  sanitizeContent(content: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    doc.body.querySelectorAll('span').forEach((span) => {
      span.replaceWith(span.textContent || '');
    });

    return doc.body.innerHTML.trim();
  }

  save(): void {
    if (!this.forImageUrl && !this.forTextOnly)
      this.editedContent = this.sanitizeContent(this.editedContent || '');
    console.log(this.editedContent);
    this.text.emit(this.editedContent);
    this.closeModal();
  }
}
