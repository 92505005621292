<div
  *ngIf="parsedErrors && parsedErrors.length"
  class="card shadow-sm mb-3 border-danger text-dark border-left-5"
>
  <div class="card-body">
    <div class="d-flex justify-content-between" S>
      <div>
        <h6 *ngIf="title" class="my-1">{{ title }}</h6>
        <p *ngFor="let error of parsedErrors" class="small text-muted mb-1">
          {{ error }}
        </p>
      </div>
      <button
        style="width: 40px; height: 40px"
        *ngIf="enableCancel"
        class="btn btn-danger"
        (click)="cancel.emit()"
      >
        x
      </button>
    </div>
  </div>
</div>
