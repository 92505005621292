<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="max-900 row">
    <div class="col-12 mt-sm-4 mb-sm-3 p-sm-0 ps-2 d-flex align-items-center">
      <h2 class="page-title oneLineTextHeader w-25">AE Stats</h2>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div
          class="p-sm-0 d-flex align-items-center justify-content-center w-75"
        >
          <div class="mx-2 minw-100px">
            <div
              class="d-flex align-items-center justify-content-end minw-100p"
            >
              <label class="w170px inpt-title">Client Code</label>

              <input
                id="clientCodeST"
                value="0"
                [(ngModel)]="activeSearchType"
                type="radio"
                class="form-check-input ms-2"
                (change)="search()"
              />
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <label class="w170px inpt-title">Firm / Client</label>
              <input
                id="firmClientST"
                value="1"
                [(ngModel)]="activeSearchType"
                type="radio"
                class="form-check-input ms-2"
                (change)="search()"
              />
            </div>
          </div>
          <app-search
            class="col m-0 p-0 pe-2 minw-200p"
            (searchChange)="setFilter($event)"
            [enableClearButon]="true"
          ></app-search>
        </div>
        <app-datalist-select
          id="yearsSelect"
          [options]="yearsOptions"
          [multiple]="false"
          class="ms-5 minw-100p"
          [clearable]="false"
          [label]="'Year'"
          [(activeValue)]="selectedYear"
          (activeValueChange)="setYear($event)"
        >
        </app-datalist-select>
        <app-datalist-select
          id="quatersSelect"
          [options]="quatersOptions"
          [multiple]="false"
          [label]="'Quater'"
          class="ms-5 minw-100p"
          [(activeValue)]="quatersRepo.activeQuaterESName"
          (activeValueChange)="quatersRepo.setActiveQuaterES($event); search()"
        >
        </app-datalist-select>

        <app-datalist-select
          id="monthSelect"
          [options]="quatersRepo.activeQuaterES?.subMenu ?? null"
          [multiple]="false"
          [label]="'Month'"
          class="ms-5 minw-200p"
          [(activeValue)]="quatersRepo.activeMonthEsName"
          (activeValueChange)="quatersRepo.setActiveMonthEs($event); search()"
        >
        </app-datalist-select>
      </div>
    </div>
  </div>
</div>
<div class="pb-4"><app-ae-stats-sum-up></app-ae-stats-sum-up></div>
<app-ae-stats></app-ae-stats>
