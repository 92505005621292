<div
  class="modal modal-md fade"
  id="closeTicketModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Close Ticket</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body customScroll d-flex" style="overflow-y: visible">
        <div class="w-100" *ngIf="!isLoading; else loaderBlock">
          <form
            [formGroup]="editForm"
            (ngSubmit)="closeSubmit()"
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <div class="form-group mb-1 row p-0">
              <div
                class="form-floating"
                [appValidateState]="editForm.get('closeReason')"
              >
                <app-datalist-select
                  [options]="reasonOptions"
                  [multiple]="false"
                  [activeValue]="editForm?.value.closeReason"
                  (activeValueChange)="updateValue('closeReason', $event)"
                  [label]="'Please choose reason to close ticket'"
                ></app-datalist-select>
              </div>
            </div>
          </form>
        </div>
        <ng-template #loaderBlock>
          <app-spinner></app-spinner>
        </ng-template>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeSubmit()">
          Close Ticket
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          (click)="resetForm()"
          #ticketCloseBbutton
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
