import { HostListener, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {
  IApv,
  ApvRepository,
  apvSortOptions,
} from 'src/app/state/apv.repository';
import { ApvService } from 'src/app/state/apv.service';
import { AuthRepository } from 'src/app/state/auth.repository';
@Component({
  selector: 'app-apv-admin',
  templateUrl: './apv-admin.component.html',
  styleUrls: ['./apv-admin.component.scss'],
})
export class ApvAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = apvSortOptions;
  searchFilter: string = '';

  constructor(
    public authRepo: AuthRepository,
    public repo: ApvRepository,
    public service: ApvService
  ) {}

  apvs: IApv[] = [];
  deleteConfirmation: IApv | null = null;

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(apv: IApv) {
    this.service.delete(apv.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
