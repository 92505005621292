import { Component, HostListener } from '@angular/core';
import { ITopic } from '../../../../state/event-schedule.repository';
import {
  TopicsRepository,
  TopicsSortOptions,
} from '../../../../state/topics.repository';
import { TopicsService } from '../../../../state/topics.service';
import { SortState } from 'src/app/modules/shared/pipes/sort.pipe';

@Component({
  selector: 'app-admin-topics-page',
  templateUrl: './admin-topics-page.component.html',
  styleUrls: ['./admin-topics-page.component.scss'],
})
export class AdminTopicsPageComponent {
  innerWidth = 0;
  searchInput = '';

  searchForCC: boolean = false;
  searchForGeneral: boolean = false;
  sortOptions = TopicsSortOptions;
  topic?: ITopic;
  deleteConfirmation: ITopic | null = null;

  dataLoading = true;
  paginationData: any;
  currentPaginationPage = 1;
  topicsPage: ITopic[] | null = null;
  searchFilter: string = '';
  sortState: SortState = {
    direction: 'asc',
    parameter: this.sortOptions[0],
  };

  constructor(public service: TopicsService) {}
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.refreshData();
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.dataLoading = true;
    this.searchFilter = `?sort=${
      this.sortState?.parameter.property ?? ''
    }&direction=${this.sortState?.direction ?? 'asc'}&page=${
      this.currentPaginationPage
    }&search=${this.searchInput}&forCC=${this.searchForCC}&forGeneral=${
      this.searchForGeneral
    }`;
    this.service.loadAll(this.searchFilter).subscribe((x) => {
      this.paginationData = x;
      this.dataLoading = false;
    });
  }
  handleDeleteClick(post: ITopic) {
    this.service.delete(post.id).subscribe(() => {
      this.refreshData();
    });
  }
  duplicateTemplate(id: string) {}
}
