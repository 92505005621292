<div class="row h-100">
  <div
    class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center d-grid gap-2 align-self-center"
  >
    <h4 class="mb-4" i18n="Unauthorized label">Unauthorized</h4>
    <p class="text-muted mb-4" i18n="Message for unauthorised user">
      You do not have access to this page. Please try signing in with another
      account or contact site administrator.
    </p>

    <a
      routerLink="/"
      class="btn btn-wfrBlue btn-block"
      i18n="Back home page button @@backHome"
      >Back to Home</a
    >
  </div>
</div>
