<h2 class="mb-4" *ngIf="!id" i18n="Create post label">Create Event</h2>
<h2 class="mb-4" *ngIf="id" i18n="Update post label">Update Event</h2>
<div *ngIf="post">
  <div
    *ngIf="
      post?.createdBy?.toString() == this.authRepo.getId()?.toString() ||
        (authRepo.isAnyAdmin$ || authRepo.isSuperAdmin$ | async);
      else elseBlock
    "
  >
    <app-event-form
      [editedEvent]="post"
      (eventsSubmit)="updatePost($event)"
    ></app-event-form>
    <app-error-alert
      title="Could not submit the post"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
<ng-template #elseBlock>
  <h1>You don't have access to that part</h1>
</ng-template>
