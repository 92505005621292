import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { catchError } from 'rxjs/operators';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  EventScheduleRepository,
  IPageProperties,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-client-event-schedules',
  templateUrl: './client-event-schedules.component.html',
  styleUrls: ['./client-event-schedules.component.scss'],
})
export class ClientEventSchedulesComponent implements OnDestroy {
  constructor(
    public repo: EventScheduleRepository,
    public service: EventScheduleService,
    public authRepo: AuthRepository
  ) {}
  searchFilter: string = '';
  Date: dayjs.Dayjs = dayjs();
  pageProperties: IPageProperties = {} as IPageProperties;
  errorMessage: string = '';
  yearsOptions: DatalistOption[] | null = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];

  selectedYear: number | null = null;
  @Input() set years(value: number[] | null) {
    if (!value) {
      this.yearsOptions = null;
    } else {
      this.yearsOptions = value.map((x) => ({
        value: x,
        label: `${x}`,
      }));
    }
  }

  refreshData() {
    var clientId = this.authRepo.getActiveClientId() ?? undefined;
    this.service.loadYears(undefined, undefined, undefined, clientId).subscribe(
      (res) => {
        this.errorMessage = '';
        this.years = res;
        this.service.getPageProperties().subscribe(
          (x) => {
            this.errorMessage = '';
            this.pageProperties = x;
            this.selectedYear = x.year ?? this.Date.year();
            this.service
              .reloadMonths(
                this.selectedYear,
                this.searchFilter,
                undefined,
                true,
                undefined,
                undefined,
                clientId
              )
              .subscribe(
                (res) => (this.errorMessage = ''),
                (err) => (this.errorMessage = err?.error ?? '')
              );
          },
          (err) => (this.errorMessage = err?.error ?? '')
        );
      },
      (err) => (this.errorMessage = err?.error ?? '')
    );
  }

  ngOnInit(): void {
    this.refreshData();
  }
  reloadMonths() {
    let clientId = this.authRepo.getActiveClientId() ?? undefined;
    this.service
      .reloadMonths(
        this.selectedYear,
        this.searchFilter,
        undefined,
        true,
        undefined,
        undefined,
        clientId
      )
      .subscribe(
        (res) => (this.errorMessage = ''),
        (err) => (this.errorMessage = err?.error ?? '')
      );
  }
  setYear(value: number) {
    let clientId = this.authRepo.getActiveClientId() ?? undefined;

    if (this.pageProperties && this.pageProperties.id) {
      this.pageProperties.year = value;
      this.service.setPageProperties(this.pageProperties).subscribe(
        (res) => (this.errorMessage = ''),
        (err) => (this.errorMessage = err?.error ?? '')
      );
    }

    this.service
      .reloadMonths(
        value,
        this.searchFilter,
        undefined,
        true,
        undefined,
        undefined,
        clientId
      )
      .subscribe(
        (res) => (this.errorMessage = ''),
        (err) => (this.errorMessage = err?.error ?? '')
      );
  }

  setActiveYear(year: number | null) {
    if (this.yearsOptions && this.yearsOptions.length) {
      var yearFromOptions = this.yearsOptions.find(
        (x) => x.value == year
      )?.value;
      if (year && yearFromOptions) {
        this.selectedYear = yearFromOptions;
      } else {
        var currentYearFromOptions = this.yearsOptions.find(
          (x) => x.value == this.Date?.year()
        )?.value;
        if (currentYearFromOptions) {
          this.selectedYear = this.Date?.year();
        } else
          this.selectedYear =
            this.yearsOptions[Math.max(this.yearsOptions.length - 1, 0)].value;
      }
    }
  }
  expand(id: string) {
    this.repo.months$.subscribe((x) => {
      let mon = x.find((x) => x.id == id);
      if (mon) {
        mon!.expanded = !mon.expanded;
        let monthFromProp = this.pageProperties.monthStates.find((x) =>
          mon!.name.includes(x.name)
        );
        if (monthFromProp) {
          this.pageProperties.monthStates.find((x) =>
            mon!.name.includes(x.name)
          )!.expanded = mon.expanded;

          this.service.setPageProperties(this.pageProperties).subscribe(
            (res) => (this.errorMessage = ''),
            (err) => (this.errorMessage = err?.error ?? '')
          );
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.repo.clearMonths();
  }
}
