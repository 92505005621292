import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketClientService } from 'src/app/state/signalR/ticket-client.service';

import {
  CRUD,
  ICommUserInfo,
  ITicket,
  ITicketComment,
  MoreComments,
  OneTicketsPage,
  TicketStatus,
} from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';
import { TicketsListComponent } from '../../components/tickets-list/tickets-list.component';
import { TicketChatComponent } from '../../components/ticket-chat/ticket-chat.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tickets-page',
  templateUrl: './tickets-page.component.html',
  styleUrls: ['./tickets-page.component.scss'],
})
export class TicketsPageComponent implements OnDestroy, OnInit {
  constructor(
    public service: TicketsService,
    public ticketClient: TicketClientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  selectedTicketId = '';
  @ViewChild(TicketsListComponent) ticketList: TicketsListComponent | null =
    null;
  @ViewChild(TicketChatComponent) ticketChat: TicketChatComponent | null = null;
  displayName = 'Opened Tickets';
  pageStat: 'opened' | 'closed' | 'inProgress' = 'opened';
  ticketStatus: TicketStatus = TicketStatus['opened'];
  hasMoreComments = false;
  ticketsAction?: Subscription;
  commentsAction?: Subscription;
  definePageStat() {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.selectedTicket = null;
        let pageStat = value.get('status')!;
        switch (pageStat) {
          case 'opened': {
            this.pageStat = pageStat;
            this.displayName = 'Opened Tickets';
            break;
          }
          case 'closed': {
            this.pageStat = pageStat;
            this.displayName = 'Closed Tickets';
            break;
          }
          case 'inProgress': {
            this.pageStat = pageStat;
            this.displayName = 'Tickets In Progress';
            break;
          }
          default: {
            this.router.navigateByUrl('pagenotfound');
            break;
          }
        }
        this.ticketStatus = TicketStatus[this.pageStat];
        this.selectedTicketId = '';
        this.ticketList?.reload();
        this.ticketChat?.clearForm();
        this.service.loadAllTickets(this.ticketStatus).subscribe((x) => {
          this.set_tickets = x.tickets;
        });
      },
    });
  }

  ngOnInit(): void {
    this.ticketsAction = this.ticketClient.ticketsAction$?.subscribe(
      (action) => {
        if (action.ticketId && this.ticketStatus != TicketStatus['closed'])
          this.service.loadAllTickets(this.ticketStatus).subscribe((x) => {
            this.set_tickets = x.tickets;
          });
      }
    );
    this.commentsAction = this.ticketClient.commentsAction$?.subscribe(
      (action) => {
        if (action.ticketId && action.commentId) {
          let updatedTicket = this.tickets.find((x) => x.id == action.ticketId);
          if (updatedTicket && this.ticketStatus != TicketStatus['closed']) {
            switch (action.action) {
              case CRUD.create: {
                this.service
                  .loadOneComment(action.commentId)
                  .subscribe((comm) => {
                    //update ticket comm on sidemenu
                    updatedTicket!.comments = [comm];
                    if (this.selectedTicketId == action.ticketId) {
                      //update ticket comm if active
                      this.selectedTicket?.comments?.unshift(comm);
                      this.ticketChat?.scrollDownAfterDelay();
                    }
                  });
                break;
              }
              case CRUD.update: {
                if (this.selectedTicket?.id == action.ticketId) {
                  // update comments only in active ticket
                  let commToEditIndex =
                    this.selectedTicket?.comments?.findIndex(
                      (x) => x.id == action.commentId
                    );
                  if (commToEditIndex != -1) {
                    this.service
                      .loadOneComment(action.commentId)
                      .subscribe((comm) => {
                        if (
                          updatedTicket?.comments?.length &&
                          updatedTicket?.comments[0].id == action.commentId
                        ) {
                        }
                        this.selectedTicket!.comments[commToEditIndex] = comm;
                      });
                  }
                }
                break;
              }
              case CRUD.delete: {
                if (this.selectedTicket?.id == action.ticketId) {
                  // delete comments only in active ticket

                  let commToDelete = this.selectedTicket?.comments?.find(
                    (x) => x.id == action.commentId
                  );
                  if (commToDelete) {
                    this.selectedTicket?.comments;
                    const index = this.selectedTicket?.comments.indexOf(
                      commToDelete,
                      0
                    );
                    if (index > -1) {
                      this.selectedTicket?.comments.splice(index, 1);
                    }
                  }
                }
                break;
              }
            }
          }
        }
      }
    );
    this.definePageStat();
    this.ticketClient.openConn();
  }
  tickets: ITicket[] = [];
  set set_tickets(tickets: ITicket[]) {
    this.tickets = tickets;
  }

  selectedTicket: ITicket | null = null;
  loadMoreComms(page = 0) {
    this.tickSubscribtion = this.service
      .loadMoreComments(this.selectedTicketId, page)
      .subscribe({
        next: (data: MoreComments) => {
          // this.selectedTicket = data.ticket;
          for (let i = 0; i < 15; ++i) {
            if (data.comments[i])
              this.selectedTicket?.comments.push(data.comments[i]);
          }

          this.hasMoreComments = data.hasMoreComments;
        },
      });
  }
  tickSubscribtion: any;

  selectTicket(id: string) {
    this.selectedTicketId = id;

    if (this.tickSubscribtion) {
      this.tickSubscribtion.unsubscribe();
    }
    this.hasMoreComments = false;

    this.tickSubscribtion = this.service.loadOneTicketPage(id).subscribe({
      next: (data: OneTicketsPage) => {
        this.ticketChat?.scrollDownAfterDelay();
        this.ticketChat?.clearForm();
        this.selectedTicket = data.ticket;
        this.hasMoreComments = data.hasMoreComments;
      },
    });
  }
  ngOnDestroy(): void {
    this.ticketClient.closeConn();
    this.commentsAction?.unsubscribe();
    this.ticketsAction?.unsubscribe();
  }
}
