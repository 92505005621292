import { Component, HostListener, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Post, PostsRepository } from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';
import { postSortOptions } from 'src/app/state/posts.repository';

import { SimpleCardAction } from '../../../shared/components/simple-card/simple-card.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  DEFAULT_IMAGE = 'assets/img/user_default.svg';
  DEFAULT_NEWS = 'assets/img/news.jpg';
  sortOptions = postSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);
  innerWidth = 0;

  constructor(
    public repo: PostsRepository,
    public service: PostsService,
    public auth: AuthRepository
  ) {}

  post: any[] = [];
  postCards$ = combineLatest([this.repo.posts$, this.auth.isAnyAdmin$]);

  news: Post[] = [];
  ngOnInit(): void {
    this.service.reloadPage().subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
