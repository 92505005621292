<h2 class="mb-4" *ngIf="!id" i18n="Create post label">Create APW</h2>
<h2 class="mb-4" *ngIf="id" i18n="Update post label">Update APW</h2>
<div *ngIf="post">
  <app-apv-form
    [editedAPV]="post"
    (ApvSubmit)="updatePost($event)"
  ></app-apv-form>
  <app-error-alert
    title="Could not submit the post"
    i18n-title="Message post submit error alert"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
