<div
  *ngIf="
    !(repo.isClientUser$ | async) && (repo.isAnyAdmin$ | async);
    else elseBlock
  "
>
  <app-menu class=" "></app-menu>

  <div class="dispayNoneInSmallMode">
    <app-upcoming-calls></app-upcoming-calls>
  </div>
</div>
<ng-template #elseBlock>
  <div class="row page-header d-flex me-sm-1 ms-sm-1">
    <div class="max-900 row">
      <div class="col-12 mt-sm-4 p-sm-0 ps-2 d-flex align-items-center">
        <h1 class="page-title oneLineTextHeader" i18n="Label eventSchedule">
          Welcome {{ ownClient?.title }}
        </h1>
      </div>
      <div class="col-12 p-sm-0 ps-2 d-flex align-items-center">
        <h5>This is your personal event dashboard 😊</h5>
      </div>

      <div class="mt-4 col-12 p-sm-0 ps-2 d-flex align-items-center">
        <h5>
          If you have any questions,
          <br />
          please contact
          <a href="mailto:team@workshopsforretirement.com" target="_blank"
            >team@workshopsforretirement.com</a
          >
        </h5>
      </div>
    </div>
  </div>
</ng-template>
