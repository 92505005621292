<!-- Sidebar main menu -->
<app-sidebar class="sidebar-wrap sidebar-overlay shadow-sm"></app-sidebar>
<!-- Add pushcontent or fullmenu instead overlay , keep 'sidebar-[choice here]' -->
<!-- Sidebar main menu ends-->

<!-- Begin page -->
<main class="has-header home-layout" style="background: #1d2939">
  <!-- Header -->
  <app-headermenu
    style="margin: 0 !important"
    class="container-fluid header bg-dark"
  ></app-headermenu>
  <!-- Header ends -->
  <!-- main page content -->
  <div class="main-container container-fluid has-border-radius">
    <router-outlet></router-outlet>
  </div>

  <!-- main page content ends -->
  <!-- fluid footer at bottom of page -->
  <!--<app-footerinfo class="container-fluid d-block"></app-footerinfo>-->
  <!-- fluid footer at bottom of page ends -->
</main>
<!-- Page ends-->
