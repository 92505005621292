<div class="col p-0 m-0" style="position: relative">
  <input
    id="appsearch"
    name="appsearch"
    type="text"
    class="inpt"
    [(ngModel)]="searchStr"
    (change)="changeSearch()"
  />
  <!-- (keypress)="checkIfEmpty()" -->
  <button
    *ngIf="enableClearButon"
    class="close-icon closeRelative"
    type="reset"
    (click)="clearSearch()"
  ></button>
</div>
