import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository } from 'src/app/state/auth.repository';
import { MailService } from '../../../state/mail.service';
import {
  IMail,
  MailRepository,
  mailsSortOptions,
} from 'src/app/state/mail.repository';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SimpleCardAction } from '../../shared/components/simple-card/simple-card.component';
import { IEvent } from '../../../state/event.repository';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
})
export class MailComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';

  constructor(
    public repo: MailRepository,
    public service: MailService,
    public authRepo: AuthRepository
  ) {}
  sortOptions = mailsSortOptions;

  Messages: IMail[] = [];
  ngOnInit(): void {
    //this.getMessages();
    this.service.reloadPage().subscribe();
  }

  //getMessages() {
  //  this.service.load().subscribe();
  //}
}
