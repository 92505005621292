import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';
import { UserRoles } from './auth.repository';

export const CompanyStatusesOptions: DatalistOption[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Disabled', value: 'Disabled' },
];

export interface IClientUser {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  folder?: string;
  location: string;
  birthday?: Date;
  tenantId?: string;
  createdAt: Date;
  image: File | null;
  imagePath: string;
  roles: UserRoles[];
  clientIds: string[];
  password?: string;
}

export const ClientUsersSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class ClientUsersRepository extends BaseRepository<IClientUser> {
  constructor() {
    super('DocumentsOptions', ClientUsersSortOptions);
  }
}
