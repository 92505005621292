export const environment = {
  production: true,
  name: 'A2App',
  apiUrl: '/core',
  features: {
    news: true,
    offlineChanges: true,
    mail: true,
    whistleblow: true,
    calendar: true,
    apv: true,
    mwevents: true,
    timelogs: true,
    maps: true,
    eventSchedule: true,
    tickets: true,

  },
  locale: 'en-gb',
  releaseV: 1.0,
};
