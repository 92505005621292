import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption, SortState } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';
import {
  withEntities,
  selectAllEntities,
  deleteAllEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { IEventSchedule } from './event-schedule.repository';
import { AssignCall } from './assignerInfo.repository';
export const CompanyStatusesOptions: DatalistOption[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Disabled', value: 'Disabled' },
];

export interface WeekWithAssignCalls {
  id: string;
  weekNum?: number;
  name?: string;
  showEventSchedules: IEventSchedule[];
  assignCalls?: AssignCall[];
}
export interface AssignCallsWithEventsDto {
  events?: IEventSchedule[];
  weeks?: WeekWithAssignCalls[];
}

export interface YearWithWeeks {
  id: string;
  year: number;
  weeks: WeekWithAssignCalls[];
}

export const AssignCallsOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

const { state, config } = createState(
  withEntities<WeekWithAssignCalls>(),
  withRequestsStatus(),
  withRequestsCache()
);

const assignCallsStore = new Store({ name: 'assignCalls', state, config });

@Injectable({ providedIn: 'root' })
export class AssignCallsRepository extends BaseRepository<WeekWithAssignCalls> {
  assignCallsStore$ = assignCallsStore.pipe(selectAllEntities());
  assignActiveWeek: WeekWithAssignCalls | null = null;
  assignActiveYear: string | null = null;

  setActiveWeekSelected(week: WeekWithAssignCalls | null = null) {
    this.assignActiveWeek = week;
  }

  updateUpcomingMonths(weeks: WeekWithAssignCalls[]) {
    assignCallsStore.update(deleteAllEntities());
    assignCallsStore.update(upsertEntities(weeks));
  }
  constructor() {
    super('AssignCallsOptions', AssignCallsOptions);
  }
}
