import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Feature, FeatureRepository } from './feature.repository';

const API = '/api/features';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private http: HttpClient, protected repo: FeatureRepository) {}

  load(): Observable<Feature[]> {
    return this.http.get<Feature[]>(API).pipe(
      tap((res) => {
        this.repo.set(res);
      }),
      this.repo.track(),
      this.repo.skipWhileCached()
    );
  }
}
