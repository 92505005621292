<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
  autocomplete="off"
>
  <div class="form-group mb-1 row p-0">
    <div class="row h-100">
      <div
        class="col-1 form-floating d-flex align-items-center justify-content-between"
      >
        <div class="inpt-title">Urgent</div>
        <input
          id="isUrgent"
          formControlName="isUrgent"
          type="checkbox"
          class="form-check-input"
          autocomplete="off"
        />
      </div>
      <div class="col-2" *ngIf="editedTicket">
        <div class="inpt-title">Order</div>
        <label class="uniqueOrder">
          {{ editedTicket?.uniqueOrder }}
        </label>
      </div>
      <div class="form-floating col" [appValidateState]="editForm.get('topic')">
        <div class="inpt-title">Topic</div>
        <input
          id="topic"
          formControlName="topic"
          type="text"
          class="inpt"
          placeholder="Topic"
          autocomplete="off"
        />
      </div>
    </div>
  </div>
  <div class="form-group mb-1 row p-0">
    <div class="form-floating">
      <div class="inpt-title">Description</div>
      <textarea
        id="description"
        formControlName="description"
        type="text"
        class="inpt inpt-description"
        placeholder="Topic"
        autocomplete="off"
      ></textarea>
    </div>
  </div>
  <div class="form-group mb-1 row p-0">
    <div class="form-floating">
      <app-datalist-select
        [options]="clientsOptions"
        [activeValue]="editForm?.value.clientId"
        (activeValueChange)="updateClient('clientId', $event)"
        [label]="'Clients'"
      ></app-datalist-select>
    </div>
  </div>
  <div
    class="form-group mb-3 row p-0"
    [class.click-not-allowed]="!editForm?.value.clientId"
    [title]="!editForm?.value.clientId ? notValidClient_Title : ''"
  >
    <div class="form-floating doubleContainer">
      <app-datalist-select
        class="app-datalist-select-double-height"
        [options]="eventsOptions"
        [multiple]="true"
        [activeValue]="editForm?.value.eventIds"
        (activeValueChange)="updateValue('eventIds', $event)"
        [label]="'Events'"
      ></app-datalist-select>
    </div>
  </div>
  <div class="form-group mb-1 row p-0">
    <div class="form-floating col">
      <app-datalist-select
        [options]="usersOptions"
        [activeValue]="editForm?.value.userId"
        (activeValueChange)="updateAssignee('userId', $event)"
        [label]="'Assignee'"
      ></app-datalist-select>
    </div>
    <div class="col-6" *ngIf="editForm.get('formatedAssignor')?.value">
      <div class="inpt-title">Assigned By</div>
      <label class="uniqueOrder">
        {{ editForm.get("formatedAssignor")?.value }}
      </label>
    </div>
  </div>

  <div class="mb-3" *ngIf="editedTicket?.formatedCreatedBy">
    <div class="inpt-title">
      Ticket Created By ({{
        editedTicket?.createdAt | date : "MMM-dd, hh:mm a"
      }})
    </div>
    <label class="uniqueOrder">
      {{ editedTicket?.formatedCreatedBy }}
    </label>
  </div>

  <span *ngIf="!isLoadingStatus; else loadingStatusBlock">
    <div class="row mb-2">
      <div *ngIf="editedTicket?.formatedStatus" class="h-100 col">
        <div class="h2 mb-4 h-100 d-flex align-items-center">
          Ticket Status: {{ editedTicket?.formatedStatus }}
        </div>
      </div>
      <div
        *ngIf="editedTicket?.status == 1 && editedTicket?.closedByName"
        class="h-100 pb-3 d-grid col-8"
      >
        <span class="h-100 p-0 m-0">
          This ticket was closed by : {{ editedTicket?.closedByName }}
        </span>
        <span
          class="h-100 p-0 m-0 text-muted"
          *ngIf="editedTicket?.closedAtFormated"
        >
          {{ editedTicket?.closedAtFormated }}
        </span>
        <span
          class="h-100 p-0 m-0 text-muted"
          *ngIf="editedTicket?.formatedCloseReason"
        >
          {{ editedTicket?.formatedCloseReason }}
        </span>
      </div>
      <div class="row w-100 p-0 m-0">
        <div class="form-group form-floating h-100 pb-3 d-grid col">
          <button type="submit" class="btn btn-wfrBlue shadow-sm">Save</button>
        </div>
        <ng-container *ngIf="editedTicket?.id">
          <div
            *ngIf="editedTicket?.status != 2"
            class="form-group form-floating h-100 pb-3 d-grid col-3"
            (click)="changeTicketStatus(2)"
          >
            <div class="btn btn-info">Start Solving Ticket</div>
          </div>
          <div
            *ngIf="editedTicket?.status == 1"
            class="form-group form-floating h-100 pb-3 d-grid col-3"
          >
            <div class="btn btn-info" (click)="changeTicketStatus(0)">
              Open Ticket Again
            </div>
          </div>
          <div
            *ngIf="editedTicket?.status != 1"
            class="form-group form-floating h-100 pb-3 d-grid col-3"
          >
            <div
              class="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#closeTicketModal"
            >
              Close Ticket
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </span>
  <ng-template #loadingStatusBlock>
    <app-spinner></app-spinner>
  </ng-template>
</form>

<app-close-ticket-pop
  (closeTicket)="changeTicketStatus(1, $event)"
></app-close-ticket-pop>
