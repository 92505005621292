import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  EventInfosRepository,
  IEventInfo,
} from '../../../../state/event-infos.repository';
import { EventInfosService } from '../../../../state/event-infos.service';
import { IFunnel } from '../../../../state/event-schedule.repository';
import { FunnelsRepository } from '../../../../state/funnels.repository';
import { FunnelsService } from '../../../../state/funnels.service';

@Component({
  selector: 'app-event-infos-create-page',
  templateUrl: './event-infos-create-page.component.html',
  styleUrls: ['./event-infos-create-page.component.scss'],
})
export class EventInfosCreatePageComponent {
  constructor(
    public service: EventInfosService,
    public repo: EventInfosRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  eventInfo: IEventInfo | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service.loadOneEventInfo(`${value.get('id')}`).subscribe((x) => {
            this.eventInfo = x;
          });
        }
      },
    });
  }

  createEventInfo(event: Partial<IEventInfo>) {
    this.submitErrors = null;
    let updateResult$: Observable<IEventInfo> | undefined;
    if (this.id === 'new') {
      updateResult$ = this.service.postEventInfo(event);
    } else if (this.id) {
      updateResult$ = this.service.updateEventInfo(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.router.navigate(['/admin/event-infos']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
