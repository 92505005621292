import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { IFunnel } from './event-schedule.repository';
import { FunnelsRepository } from './funnels.repository';

const API = '/api/funnels';

@Injectable({ providedIn: 'root' })
export class FunnelsService extends BaseService<IFunnel> {
  constructor(http: HttpClient, repo: FunnelsRepository) {
    super(API, http, repo);
  }

  loadOneFunnel(id: string) {
    return this.http.get<IFunnel>(API + '/loadOneFunnel/' + id);
  }
  loadAllFunnels() {
    return this.http.get<IFunnel[]>(API + '/loadAll');
  }
  postFunnel(contact: Partial<IFunnel>) {
    return this.http.post<IFunnel>(API + '/postFunnel', contact);
  }
  updateFunnel(contact: Partial<IFunnel>) {
    return this.http.put<IFunnel>(API + '/updateFunnel', contact);
  }
  sortCustom(sortBy: SortState, search?: string, take: number = 25) {
    this.repo.setSort(sortBy);
    return this.reloadPage(search, take);
  }
}
