import { Component, Input, OnInit } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  IEventSchedule,
  IFunnel,
  IMonthWithEvents,
  MonthsOptions,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { FunnelsService } from 'src/app/state/funnels.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-backend-teams',
  templateUrl: './backend-teams.component.html',
  styleUrls: ['./backend-teams.component.scss'],
})
export class BackendTeamsComponent {
  constructor(
    public service: EventScheduleService,
    public repo: EventScheduleRepository,
    private funnelsService: FunnelsService
  ) {}
  statusTop: number = 0;
  statusLeft: number = 0;
  TeamsOptions: DatalistOption[] | null = null;
  editedEventId: string | null = null;
  dateIndex: number | null = null;
  clickedMonthIndex: number | null = null;
  clickedEventIndex: number | null = null;
  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  @Input() set years(value: number[] | null) {
    if (!value) {
      this.yearsOptions = null;
    } else {
      this.yearsOptions = value.map((x) => ({
        value: x,
        label: `${x}`,
      }));
    }
  }
  yearsOptions: DatalistOption[] | null = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];
  openEventStatus(
    $event: any,
    dateIndex: number,
    eventIndex: number,
    id: string
  ) {
    this.statusTop = $event.pageY;
    this.statusLeft = $event.pageX;
    this.dateIndex = dateIndex;
    this.clickedEventIndex = eventIndex;
    this.editedEventId = id;
  }
  changeStatus(id: string, value: DatalistOption, date: number) {
    if (this.repo.backendActiveMonth?.eventSchedules.find((x) => x.id == id)) {
      date == 1
        ? (this.repo.backendActiveMonth!.showEventSchedules!.find(
            (x) => x.id == id
          )!.status = value.value)
        : (this.repo.backendActiveMonth!.showEventSchedules!.find(
            (x) => x.id == id
          )!.statusD2 = value.value);
    }
  }
  selectedYear: number | null = null;
  mouseLeave() {
    this.clickedMonthIndex = null;
    this.clickedEventIndex = null;
    this.statusLeft = 0;
    this.statusTop = 0;
    this.dateIndex = null;
    this.editedEventId = null;
  }
  setYear(value: number) {
    this.setActiveYear(value);
    this.refreshTasks();
  }

  setActiveYear(year: number | null) {
    if (this.yearsOptions && this.yearsOptions.length) {
      var yearFromOptions = this.yearsOptions.find(
        (x) => x.value == year
      )?.value;
      if (year && yearFromOptions) {
        this.selectedYear = yearFromOptions;
      } else {
        var currentYearFromOptions = this.yearsOptions.find(
          (x) => x.value == this.Date?.year()
        )?.value;
        if (currentYearFromOptions) {
          this.selectedYear = this.Date?.year();
        } else
          this.selectedYear =
            this.yearsOptions[Math.max(this.yearsOptions.length - 1, 0)].value;
      }
    }
  }
  TeamSelected: string | null = null;
  @Input() set funnels(value: IFunnel[] | null) {
    if (!value) {
      this.TeamsOptions = null;
    } else {
      this.TeamsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }

  refreshTasks() {
    this.eventSchedules = [];
    this.TeamSelected = null;
    this.service
      .loadTasks('backend', this.selectedYear ?? undefined)
      .subscribe();
  }
  monthsOpt: DatalistOption[] = MonthsOptions;
  monthSelectedName: string | null | undefined = null;
  eventScheduleOnPopUp: IEventSchedule | null = null;
  eventSchedules: IEventSchedule[] = [];

  ngOnInit(): void {
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;

      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
    this.funnelsService.loadAllFunnels().subscribe((x) => {
      this.funnels = x;
    });
    this.service.loadYears(false, false, true).subscribe((y) => {
      this.years = y;
      this.setActiveYear(this.Date?.year());
      this.eventSchedules = [];
      this.service
        .loadTasks('backend', this.selectedYear ?? undefined)
        .subscribe((x) => {
          this.repo.backendMonths$.subscribe((up) => {
            if (up && up.length) {
              this.repo.backendActiveMonth = up[0];
              this.monthSelectedName = this.repo.backendActiveMonth?.name;
              x.forEach((y) => {
                this.eventSchedules = this.eventSchedules.concat(
                  y.eventSchedules
                );
              });
            }
          });
        });
    });
  }

  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) modal.style.display = 'none';
  }
  showModal(id: string) {
    let event = this.eventSchedules?.find((x) => x.id == id);
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (event) {
      modal.style.display = 'block';
      this.eventScheduleOnPopUp = event;
    } else {
      console.log('cant find');
    }
  }
}
