<div class="row bg-dark">
  <div class="col-auto pr-0">
    <button
      type="button"
      class="btn btn-link back-btn text-white"
      (click)="backnav()"
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  </div>
  <div class="col text-center align-self-center px-0">
    <app-logo></app-logo>
    <h3 i18n="@@postsHeader" *ngIf="page === '/posts'" class="news">News</h3>
  </div>
  <div *ngIf="page === '/posts'" class="col-auto align-self-center">
    <a routerLink="/home" class="btn btn-link text-white">
      <i class="bi bi-plus-lg"></i>
    </a>
  </div>
  <div class="col-auto align-self-center">
    <a routerLink="/home" class="btn btn-link text-white">
      <i class="bi bi-house"></i>
    </a>
  </div>
</div>
