import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, tap } from 'rxjs';
import { sortBy, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { IDocumentType } from './documenttype.repository';
import { IFolder } from './folders.repository';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface IDocument {
  id: string;
  title: string;
  updatedAt: Date;
  documentType: IDocumentType;
  documentTypeId: string;
  folder: IFolder;
  folderId: string;
  document: File | null;
  documentPath: string;
  isCreatedWithSystemType: boolean;
}

export const DocumentsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
  { label: $localize`:Sort label Updated:Updated`, property: 'updatedAt' },
];

@Injectable({ providedIn: 'root' })
export class DocumentsRepository extends BaseRepository<IDocument> {
  constructor() {
    super('DocumentsOptions', DocumentsSortOptions);
  }
}
