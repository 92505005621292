import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent {
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Input() deleteCandidate: string = '';
  @Input() eventSchedule?: boolean = false;
  @Input() clientCode?: string = '';
  @Input() clientName?: string = '';
  @Input() date?: string = '';
  @Input() location?: string = '';

  constructor() {}
}
