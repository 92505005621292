<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
  autocomplete="off"
>
  <div class="form-group mb-3 row p-0">
    <div class="form-floating mb-3" [appValidateState]="editForm.get('name')">
      <div class="inpt-title" for="Name" i18n="@@Name">Name</div>
      <input
        id="Name"
        formControlName="name"
        type="text"
        class="inpt"
        placeholder="Name"
        i18n-placeholder="@@Name"
        autocomplete="off"
      />
    </div>

    <div class="form-group form-floating h-100 pb-3 d-grid">
      <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
        Save
      </button>
    </div>
  </div>
</form>
