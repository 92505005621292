import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { IEventSchedule, IMonthWithEvents } from './event-schedule.repository';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  upsertEntities,
  deleteAllEntities,
} from '@ngneat/elf-entities';
import {
  withRequestsStatus,
  withRequestsCache,
  updateRequestCache,
  updateRequestsStatus,
} from '@ngneat/elf-requests';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';

export const QuatersEsOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];

export interface QuaterEs {
  id: string;
  name: string;
  year: number;
  eventSchedules: IEventSchedule[];

  months: IMonthWithEvents[];
}
export interface IAESummary {
  exceptClients: ExceptClient[];
  atCost: number;
  rescheduled: number;
  cancelled: number;
  sumEvents: number;
  sumClients: number;
  chargableEvents: number;
  eventsCount: number;
  atCostWithNums: number;
}

const { state, config } = createState(
  withEntities<IEventSchedule>(),
  withRequestsStatus(),
  withRequestsCache()
);

export const quatersOptions: DatalistOption[] = [
  {
    label: 'Q1',
    value: '1',
    subMenu: [
      { label: 'January', value: '1' },
      { label: 'February', value: '2' },
      { label: 'March', value: '3' },
    ],
  },
  {
    label: 'Q2',
    value: '2',
    subMenu: [
      { label: 'April', value: '4' },
      { label: 'May', value: '5' },
      { label: 'June', value: '6' },
    ],
  },
  {
    label: 'Q3',
    value: '3',
    subMenu: [
      { label: 'July', value: '7' },
      { label: 'August', value: '8' },
      { label: 'September', value: '9' },
    ],
  },
  {
    label: 'Q4',
    value: '4',
    subMenu: [
      { label: 'October', value: '10' },
      { label: 'November', value: '11' },
      { label: 'December', value: '12' },
    ],
  },
];

export interface ExceptClient {
  id: number;
  name: number;
  count: number;
  atCost: number;
  atCostNums: number;
  rescheduled: number;
  cancelled: number;
}

const quaterESStore = new Store({
  name: 'AEStatsEventSchedule',
  state,
  config,
});

export interface AeStatsFilter {
  year?: number;
  quater?: number;
  month?: number;
  search?: string;
  searchType?: number;
  getYearsOpt: boolean;
}

export interface BestPerformingEventsSummary {
  lowestCostPerHH?: IEventSchedule;
  highestAttendanceEvent?: IEventSchedule;
  highestAttendanceRate?: number;
  highestAttendanceIndex?: number;
}
export interface IEventsWithStats {
  events?: IEventSchedule[];
  aeSummary?: IAESummary;
  bestPerformingEventsSummary?: BestPerformingEventsSummary;
  years: number[];
}

@Injectable({ providedIn: 'root' })
export class QuatersEsRepository extends BaseRepository<IEventSchedule> {
  activeQuaterESName: string | null = null;
  activeMonthEsName: string | null = null;
  activeAeSummary?: IAESummary;
  activeBestPerforming?: BestPerformingEventsSummary;

  activeQuaterES: DatalistOption | null = null;
  events$ = quaterESStore.pipe(selectAllEntities());
  activeMonthEs =
    this.activeQuaterES?.subMenu?.find(
      (x) => x.label == this.activeMonthEsName
    ) ?? null;

  clearActiveMonth() {
    this.activeMonthEsName = null;
  }
  updateBestPerforming(summ?: BestPerformingEventsSummary) {
    this.activeBestPerforming = summ;
  }
  updateAeSummary(summ?: IAESummary) {
    this.activeAeSummary = summ;
  }
  updateEvents(quaters: IEventSchedule[]) {
    quaterESStore.update(deleteAllEntities());
    quaterESStore.update(upsertEntities(quaters));
  }

  setActiveQuaterES(name: string) {
    var quaterSelected = quatersOptions.find((x) => x.value == name);
    if (quaterSelected) {
      this.activeQuaterESName = name;
      this.activeQuaterES = quaterSelected;
    } else {
      this.activeQuaterESName = null;
      this.activeQuaterES = null;
    }
  }

  setActiveMonthEs(val: string) {
    this.activeMonthEs =
      this.activeQuaterES?.subMenu?.find((x) => x.value == val) ?? null;
  }

  // setActiveMonthEs(name: string) {
  //   var month = this.activeQuaterESs?.months.find((x) => x.name == name);
  //   if (month) {
  //     this.activeMonthES = month;
  //     this.activeMonthEsName = month.name;
  //     this.activeAeSummary = month.aeSummary;
  //   }
  // }

  // setActiveQuaterES(name: string) {
  //   this.clearActiveMonth();
  //   this.quaterESs$.subscribe((quaters) => {
  //     let searchedQuater = quaters?.find((m) => {
  //       return m.name == name;
  //     });
  //     if (searchedQuater) {
  //       this.activeQuaterESs = searchedQuater;
  //       this.setMonthsDataListOptions(searchedQuater.months);
  //     }
  //   });
  // }

  // clearActiveQuaterES() {
  //   this.activeQuaterESName = undefined;
  //   this.activeQuaterESs = undefined;
  //   this.setMonthsDataListOptions([]);
  //   this.clearActiveMonth();
  // }

  clearQuatersES() {
    quaterESStore.update(deleteAllEntities());
  }

  constructor() {
    super('QuatersEsOptions', QuatersEsOptions);
  }
}
