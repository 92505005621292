import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  skipWhileTenantsCached,
  Tenant,
  TenantDocument,
  TenantsRepository,
  trackTenantRequestsStatus,
} from './tenants.repository';

const API = '/api/tenants';

@Injectable({ providedIn: 'root' })
export class TenantsService {
  constructor(private http: HttpClient, private repo: TenantsRepository) {}

  loadTenantDocument(tenantId: string) {
    return this.http.get<TenantDocument>(
      API + `/loadTenantDocument/${tenantId}`
    );
  }

  load() {
    return this.http.get<Tenant[]>(API).pipe(
      tap((tenants) => this.repo.setTenants(tenants)),
      trackTenantRequestsStatus(this.repo.name),
      skipWhileTenantsCached(this.repo.name)
    );
  }

  loadOne(id: string) {
    return this.http.get<Tenant>(`${API}/${id}`).pipe(
      tap((tenant) => this.repo.upsertTenant(tenant)),
      trackTenantRequestsStatus(id),
      skipWhileTenantsCached(id)
    );
  }

  add(tenant: Partial<Tenant>) {
    const formData = new FormData();
    let tenantId = tenant.id;
    formData.append('file', tenant.image!);
    if (tenant.image) {
      return this.http.post<Tenant>(API, tenant).pipe(
        switchMap((tenant1) => {
          tenantId = tenant1.id;
          return this.http.post<Tenant>(`${API}/${tenant1.id}/file`, formData);
        }),
        switchMap((tenant2) => {
          return this.http.post<Tenant>(`${API}/postTenantDocument`, {
            title: tenant.documentTitle,
            tenantId: tenant2.id,
            documentTypeId: tenant.documentTypeId,
          });
        }),
        switchMap((tenant3) =>
          this.http.post<Tenant>(`${API}/${tenantId}/tenantDocument`, formData)
        ),
        tap((tenant) => this.repo.upsertTenant(tenant)),

        trackTenantRequestsStatus(`${this.repo.name}_add`)
      );
    } else {
      return this.http.post<Tenant>(API, tenant).pipe(
        trackTenantRequestsStatus(`${this.repo.name}_add`),
        tap((tenant) => this.repo.upsertTenant(tenant))
      );
    }
  }

  update(id: string, model: Partial<Tenant>) {
    if (model.image) {
      const formData = new FormData();
      formData.append('file', model.image);
      return this.http.patch<Tenant>(`${API}/${id}`, model).pipe(
        switchMap((tenant2) => {
          return this.http.patch<Tenant>(
            `${API}/${id}/updateTenantFile`,
            formData
          );
        }),
        tap((tenant3) => this.repo.upsertTenant(tenant3))
      );
    } else {
      return this.http
        .patch<Tenant>(`${API}/${id}`, model)
        .pipe(tap((tenant2) => this.repo.upsertTenant(tenant2)));
    }
  }

  //update(id: string, model: Partial<Tenant>) {
  //  return this.http
  //    .patch<Tenant>(`${API}/${id}`, model)
  //    .pipe(
  //      tap((tenant) => this.repo.upsertTenant(tenant)),
  //      switchMap((tenant) => {
  //        if (model.image) {
  //          const formData = new FormData();
  //          formData.append("file", model.image);
  //          return this.http.post<Tenant>(`${API}/${id}/file`, formData);
  //        }
  //        return of(tenant);
  //      }),
  //      trackTenantRequestsStatus(id)
  //    );
  //}

  delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${API}/${id}`)
      .pipe(tap(() => this.repo.remove(id)));
  }
}
