<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  aria-hidden="true"
  #confirmUnsaved
  tabindex="-1"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
          Confirm deleting unsaved changes
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body customScroll d-flex" style="overflow-y: visible">
        Seems you have unsaved comment in chat section, Please confirm deleting
        changes by Pressing confirm button, or press Cancel and continue writing
        comment
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="confirmSubmit.emit()"
        >
          Confirm
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          #unsavedCancelBtn
          (click)="cancel.emit()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
