import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelogsComponent } from './timelogs/timelogs.component';
import { CoreModule } from '../core/core.module';
import { TimelogsAdminComponent } from './timelogs-admin/timelogs-admin.component';
import { TimelogsFormComponent } from './timelogs-form/timelogs-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { TimelogsEditComponent } from './timelogs-edit/timelogs-edit.component';
import { TimelogsTasksAdminComponent } from './timelogs-tasks-admin/timelogs-tasks-admin.component';
import { TimelogsOverviewComponent } from './timelogs-overview/timelogs-overview.component';
import { TimelogsUsersOverviewComponent } from './timelogs-users-overview/timelogs-users-overview.component';
import { TimelogsProjectsOverviewComponent } from './timelogs-projects-overview/timelogs-projects-overview.component';
@NgModule({
  declarations: [
    TimelogsComponent,
    TimelogsAdminComponent,
    TimelogsFormComponent,
    TimelogsEditComponent,
    TimelogsTasksAdminComponent,
    TimelogsOverviewComponent,
    TimelogsUsersOverviewComponent,
    TimelogsProjectsOverviewComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    MatDialogModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
  ],
})
export class TimelogsModule {}
