<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="editForm.get('name')"
  >
    <div class="inpt-title">Name</div>
    <input
      id="name"
      formControlName="name"
      class="inpt"
      placeholder="Name"
      i18n-placeholder="Name placeholder @@name"
      autocomplete="off"
    />
  </div>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="editForm.get('parking')"
  >
    <div class="inpt-title">Parking</div>
    <input
      id="locationTitle"
      formControlName="parking"
      class="inpt"
      placeholder="Parking"
      i18n-placeholder="Title placeholder @@parking"
      autocomplete="off"
    />
  </div>

  <div
    class="form-group form-floating mb-3"
    [appValidateState]="editForm.get('desiredBUCapasity')"
  >
    <div class="inpt-title">Desired BU's/Room Capacity</div>
    <input
      id="desiredBUCapasity"
      formControlName="desiredBUCapasity"
      class="inpt"
      placeholder="Desired BU's/Room Capacity"
      autocomplete="off"
    />
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
      Save
    </button>
  </div>
</form>
