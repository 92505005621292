import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RealTimeClientService {
  private hubConnection: signalR.HubConnection;
  private IAmDeletedSubject = new Subject<string>();

  ordersUpdated$: Observable<string> = this.IAmDeletedSubject.asObservable();
  constructor(public http: HttpClient, private env: EnvState) {
    var url = new URL(
      '/core/IsMeDeletedHub',
      new URL(window.location.href).origin
    ).href;

    if (!environment.production) {
      url = 'https://localhost:7260/IsMeDeletedHub';
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,

        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Warning)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.hubConnection?.on('isMeDeleted', (deletedId: string) => {
          this.IAmDeletedSubject.next(deletedId);
        });
      })

      .catch((err) => console.error('Error connecting to SignalR hub:', err));
  }
}
