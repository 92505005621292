import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import { BaseService } from './abstract/base.service';

import {
  CompaniesRepository,
  ICompanyLocation,
  ICompanyMarker,
  IContact,
  IMapInfo,
} from './companies.repository';
import { ContactsRepository } from './contacts.repository';

const DEFAULT_ENTITIES_PER_PAGE = 25;
const API = '/api/contacts';

@Injectable({ providedIn: 'root' })
export class ContactsService extends BaseService<IContact> {
  constructor(http: HttpClient, repo: ContactsRepository) {
    super(API, http, repo);
  }

  loadOneContact(id: string) {
    return this.http.get<IContact>(API + '/loadOneContact/' + id);
  }

  postContact(contact: Partial<IContact>) {
    return this.http.post<IContact>(API + '/postContact', contact);
  }

  updateContact(contact: Partial<IContact>) {
    return this.http.put<IContact>(API + '/updateContact', contact);
  }

  loadPageCustom(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    clientId = ''
  ): Observable<PaginationData & { data: IContact[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `clientId=${clientId}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: IContact[] }>(
        `${API}/customLoadPage/?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sortCustom(sortBy: SortState, search?: string, clientId = '') {
    this.repo.setSort(sortBy);
    return this.reloadPageCustom(search, DEFAULT_ENTITIES_PER_PAGE, clientId);
  }

  reloadPageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    clientId = ''
  ): Observable<(PaginationData & { data: IContact[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        data.currentPage,
        data.perPage,
        search,
        clientId
      );
    }
    return this.loadPageCustom(1, defaultTake, search, clientId);
  }

  searchRelodePageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    clientId = ''
  ): Observable<(PaginationData & { data: IContact[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(1, data.perPage, search, clientId);
    }
    return this.loadPageCustom(1, defaultTake, search, clientId);
  }
}
