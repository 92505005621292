import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRepository } from 'src/app/state/auth.repository';
import { MailService } from '../../../state/mail.service';
import {
  IMail,
  MailRepository,
  mailsSortOptions,
} from 'src/app/state/mail.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mail-admin',
  templateUrl: './mail-admin.component.html',
  styleUrls: ['./mail-admin.component.scss'],
})
export class MailAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = mailsSortOptions;
  searchFilter: string = '';

  constructor(
    public router: Router,
    public authRepo: AuthRepository,
    public repo: MailRepository,
    public service: MailService
  ) {}

  mails: IMail[] = [];
  deleteConfirmation: IMail | null = null;

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(message: IMail) {
    this.service.delete(message.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
