import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IActionPopEmitter,
  ICalcFinancesEmitter,
  IChangePayStatsEmitter,
  IDeleteConfirmation,
  IEnterBell,
  IEnterChangeStatus,
} from 'src/app/state/emitters/emiters-interfaces';
import { IEventSchedule } from 'src/app/state/event-schedule.repository';

@Component({
  selector: 'app-es-todo-double-row',
  templateUrl: './es-todo-double-row.component.html',
  styleUrls: ['./es-todo-double-row.component.scss'],
})
export class EsTodoDoubleRowComponent {
  @Input() paddStart = 0;
  @Input() event: any | null = null;
  @Input() curentMonthIndex: number = 0;
  @Input() eventIndx: number = 0;
  @Input() mainParentId: string = '';

  @Output() showModal = new EventEmitter<string>();
  @Output() deleteConfirmation = new EventEmitter<IDeleteConfirmation>();
  @Output() enterChangeStatus = new EventEmitter<IEnterChangeStatus>();
  @Output() enterBell = new EventEmitter<IEnterBell>();
  @Output() actionPop = new EventEmitter<IActionPopEmitter>();

  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }
  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
}
