import { Component, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';
import {
  CompaniesRepository,
  ICompanyMarker,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  constructor(
    public repo: AuthRepository,
    public service: EventScheduleService,
    public clientService: CompaniesService,
    private router: Router
  ) {}
  ownClient?: ICompanyMarker;
  ngOnInit(): void {
    // this.repo.isAdvisorsExcel$.subscribe((x) => {
    //   if (x) this.router.navigate(['aestatistics']);
    // });
    this.repo.isClientUser$.subscribe((x) => {
      if (x) {
        var clientId = this.repo.getActiveClientId() ?? undefined;
        if (clientId)
          this.clientService
            .loadOneCompany(`${clientId}`)
            .subscribe((y) => (this.ownClient = y));
      }
    });
  }
}
