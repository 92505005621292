<div class="mb-5 d-flex justify-content-center">
  <div class="col-5 d-flex flex-column align-items-center" *ngIf="forWorkshop">
    <div class="d-flex flex-wrap gap-2 col-10">
      <h4 class="col-12 text-center">Reminder process - Workshops</h4>
      <div class="blue-button col-6">
        <app-text-editor-modal
          [title]="'Confirmation - Email'"
          [largeTextField]="true"
          [editorContent]="findValue('email-confirmation', 0)"
          (text)="log('email-confirmation', 0, $event)"
        />
        <p>Confirmation - Email</p>
      </div>

      <div class="blue-button flex-grow">
        <app-text-editor-modal
          [forTextOnly]="true"
          [editorContent]="findValue('text-confirmation', 0)"
          [title]="'Confirmation - Text/SMS'"
          (text)="log('text-confirmation', 0, $event)"
        />
        <p>Confirmation - Text/SMS</p>
      </div>
      <div class="blue-button col-12">
        <app-text-editor-modal
          [forTextOnly]="true"
          [editorContent]="findValue('text-5-day', 0)"
          [title]="'5-day text'"
          (text)="log('text-5-day', 0, $event)"
        />
        <p>5-day text</p>
      </div>
      <div class="blue-button col-12">
        <app-text-editor-modal
          [largeTextField]="true"
          [editorContent]="findValue('email-2-day', 0)"
          [title]="'2-day email'"
          (text)="log('email-2-day', 0, $event)"
        />
        <p>2-day email</p>
      </div>

      <div class="blue-button col-12">
        <app-text-editor-modal
          [forTextOnly]="true"
          [editorContent]="findValue('text-same-day', 0)"
          [title]="'Same day/Day-off text'"
          (text)="log('text-same-day', 0, $event)"
        />
        <p>Same day/Day-off text</p>
      </div>
    </div>
  </div>
  <div
    class="col-7 d-flex flex-column align-items-center"
    *ngIf="forDinnerLunch"
  >
    <div class="d-flex flex-wrap gap-2 col-11">
      <h4 class="col-12 text-center">Reminder process - Dinner/Lunch</h4>
      <div class="blue-button col-6">
        <app-text-editor-modal
          [largeTextField]="true"
          [editorContent]="findValue('email-thank-you', 1)"
          [title]="'Thank You - Email'"
          (text)="log('email-thank-you', 1, $event)"
        />
        <p>"Thank You" Email</p>
      </div>

      <div class="blue-button flex-grow">
        <app-text-editor-modal
          [forTextOnly]="true"
          [editorContent]="findValue('text-thank-you', 1)"
          [title]="'Thank You - Text/SMS'"
          (text)="log('text-thank-you', 1, $event)"
        />
        <p>"Thank You" Text/SMS</p>
      </div>
      <div class="col-12 text-center">
        <img
          src="/assets/img/braces/horizontal_brace2.png"
          alt="brace"
          style="margin-left: 7px"
          height="45px"
          class="col-6"
        />
      </div>
      <div class="d-flex col-12">
        <div class="col-6 me-2">
          <h5 class="col-12 text-center">Non-qualified</h5>
          <div class="blue-button non-qualified mb-2 col-12">
            <app-text-editor-modal
              [largeTextField]="true"
              [editorContent]="findValue('email-non-qualified', 1)"
              [title]="'Non-qualified Email'"
              (text)="log('email-non-qualified', 1, $event)"
            />
            <p>Non-qualified Email</p>
          </div>

          <div class="blue-button non-qualified col-12">
            <app-text-editor-modal
              [forTextOnly]="true"
              [editorContent]="findValue('text-non-qualified', 1)"
              [title]="'Non-qualified Text/SMS'"
              (text)="log('text-non-qualified', 1, $event)"
            />
            <p>Non-qualified Text/SMS</p>
          </div>
        </div>
        <div class="d-flex flex-wrap flex-grow">
          <h5 class="text-center col-12">Qualified</h5>
          <div class="blue-button col-6 me-2 mb-2">
            <app-text-editor-modal
              [largeTextField]="true"
              [editorContent]="findValue('email-confirmation', 1)"
              [title]="'Confirmation Email'"
              (text)="log('email-confirmation', 1, $event)"
            />
            <p>Confirmation - Email</p>
          </div>
          <div class="blue-button flex-grow mb-2">
            <app-text-editor-modal
              [forTextOnly]="true"
              [editorContent]="findValue('text-confirmation', 1)"
              [title]="'Workshop Confirmation Text'"
              (text)="log('text-confirmation', 1, $event)"
            />
            <p>Confirmation - Text</p>
          </div>
          <div class="blue-button col-12 mb-2">
            <app-text-editor-modal
              [largeTextField]="true"
              [editorContent]="findValue('email-2-day', 1)"
              [title]="'2-day email reminder'"
              (text)="log('email-2-day', 1, $event)"
            />
            <p>2-day email reminder</p>
          </div>
          <div class="blue-button col-12">
            <app-text-editor-modal
              [forTextOnly]="true"
              [editorContent]="findValue('text-same-day', 1)"
              [title]="'Same day text reminder'"
              (text)="log('text-same-day', 1, $event)"
            />
            <p>Same day text reminder</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
