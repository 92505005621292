import { Component, HostListener, OnInit } from '@angular/core';

import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import {
  Post,
  PostsRepository,
  postSortOptions,
} from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';

@Component({
  selector: 'app-news-admin',
  templateUrl: './news-admin.component.html',
  styleUrls: ['./news-admin.component.scss'],
})
export class NewsAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = postSortOptions;
  searchFilter: string = '';

  deleteConfirmation: Post | null = null;
  constructor(
    public repo: PostsRepository,
    public service: PostsService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteClick(post: Post) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
