import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-documenttype-post',
  templateUrl: './documenttype-post.component.html',
  styleUrls: ['./documenttype-post.component.scss'],
})
export class DocumenttypePostComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,

    private service: DocumentTypesService,
    private location: Location
  ) {}
  editForm: UntypedFormBuilder | any;
  @Input() editedPost: Partial<IDocumentType> | null = null;
  submitErrors: string[] | null = null;

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedPost?.name, Validators.required],
      isShownOnSideMenu: [this.editedPost?.isShownOnSideMenu],
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const name = this.editForm.value?.name.toString();
    const isShownOnSideMenu = this.editForm.value?.isShownOnSideMenu;
    this.submitErrors = null;
    let updateResult: Observable<IDocumentType> | undefined;
    updateResult = this.service.postDocumentType({ name, isShownOnSideMenu });
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
