import { Component, OnInit } from '@angular/core';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from '../../../../state/auth.repository';
import { TenantsService } from '../../../../state/tenants.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor(
    public tenantservice: TenantsService,
    public authRepo: AuthRepository,

    public env: EnvState
  ) {}
  imagesrc: string = '/assets/img/logoTransparent.png';
  ngOnInit(): void {
    //   this.authRepo.tenant$.subscribe((tenantId) => {
    //     if (!tenantId) {
    //       this.imagesrc = '/assets/img/logoTransparent.png';
    //     } else {
    //       this.tenantservice.loadTenantDocument(`${tenantId}`).subscribe((y) => {
    //         if (y.documentPath) {
    //           this.imagesrc = y.documentPath;
    //         }
    //       });
    //     }
    //   });
  }
}
