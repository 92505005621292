<!--<div class="row mb-4">
  <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
    <app-pagination [page]="(repo.pageNumber$ | async) || 1"
                    [total]="(repo.paginationData$ | async)?.lastPage || 0"
                    (pageChange)="service.loadPage($event).subscribe()"></app-pagination>
  </div>
  <div class="col-12 col-md-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions"
                       [sorting]="repo.sort$ | async"
                       (sortingChange)="service.sort($event).subscribe()"></app-sort-dropdown>
  </div>
</div>-->

<div>
  <div class="row d-flex justify-content-center">
    <div
      class="col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-3 d-flex justify-content-between"
    >
      <div class="">
        <h2 class="page-title">Collaborations</h2>
        <div class="mainSubtext">Something right here</div>
      </div>
      <div class="d-flex align-items-sm-center">
        <!--<app-sort-dropdown [sortingOptions]="sortOptions"
                           [sorting]="repo.sort$ | async"
                           (sortingChange)="service.sort($event).subscribe()"></app-sort-dropdown>-->
      </div>
    </div>
  </div>

  <div
    class="row d-flex justify-content-center me-lg-0 me-1 ms-lg-0 ms-1 pb-3"
    *ngFor="let mwevent of events"
  >
    <div class="row col-12 col-lg-11 col-xl-10 max-900 apvcard">
      <div class="col-12 row pt-4 pb-4">
        <div
          class="col-12 col-lg-2 col-xl-1 col-sm-2 pb-2 pb-md-0 d-flex justify-content-sm-end"
        >
          <div
            class="icon rounded-circle d-flex align-items-center justify-content-center"
          >
            <svg
              width="23"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9999 1.5625L2.09332 12.2504C1.74451 12.6689 1.57011 12.8782 1.56744 13.055C1.56512 13.2086 1.63359 13.3548 1.75312 13.4514C1.89061 13.5625 2.16304 13.5625 2.7079 13.5625H9.99986L8.99986 21.5625L17.9064 10.8746C18.2552 10.4561 18.4296 10.2468 18.4323 10.07C18.4346 9.91638 18.3661 9.7702 18.2466 9.67361C18.1091 9.5625 17.8367 9.5625 17.2918 9.5625H9.99986L10.9999 1.5625Z"
                stroke="#0B5087"
                stroke-width="2.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div class="col-12 col-lg-10 col-xl-11 col-sm-10">
          <div class="col-12 title text-break">
            <span [innerHtml]="mwevent.title"></span>
          </div>
          <div class="col-12 description text-break">
            <span [innerHtml]="mwevent.description?.slice(0, 200)"
              ><span *ngIf="mwevent.description!.length > 200">...</span></span
            >
          </div>
        </div>
      </div>

      <hr class="" />

      <div class="col-12 d-flex justify-content-end pb-2 pt-2" style="">
        <button
          *ngIf="mwevent.isResponsible"
          [routerLink]="['/admin/mwevents/edit/', mwevent.id]"
          class="viewbutt me-1"
        >
          Manage
        </button>
        <button
          *ngIf="mwevent.isViewer || mwevent.isResponsible"
          [routerLink]="['/ideasrating/', mwevent.id]"
          class="viewbutt me-1"
        >
          Ratings
        </button>
        <button class="viewbutt" [routerLink]="['/mwevents/', mwevent.id]">
          View Project
        </button>
      </div>
    </div>
  </div>
</div>
