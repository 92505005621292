<div id="genScroll">
  <div
    *ngFor="let month of months; let curentMonthIndex = index"
    class="p-0 m-0"
  >
    <div class="d-flex align-items-center">
      <button
        class="ms-0 btn mt-2 px-1"
        style="padding-top: 3px; padding-bottom: 3px"
        (mouseleave)="mouseLeave()"
      >
        <div
          class="d-flex align-items-center"
          style="width: 20px; height: 20px"
          (click)="showSortStatus(curentMonthIndex, $event, 'changeMonStatus-')"
        >
          <app-event-status
            [isMonthStatus]="true"
            [status]="month.sortStatus ?? ''"
            [statusNum]="month.sortStatusNum ?? 0"
            [tabSelected]="tabSelected"
          >
          </app-event-status>
        </div>
        <app-change-status-modal
          [isMonthStatus]="true"
          [top]="statusTop"
          [left]="statusLeft"
          [statusIndex]="1"
          [hasFewSpotsSort]="true"
          (submit)="sortmonthStatus(curentMonthIndex, $event); mouseLeave()"
          [hidden]="
            clickedMonthIndex != curentMonthIndex ||
            clickedDateIndex != 'changeMonStatus-'
          "
        ></app-change-status-modal>
      </button>

      <button
        class="ms-0 btn btn-wfrBlue w-100 mt-2"
        style="padding-top: 3px; padding-bottom: 3px"
        (click)="expand.emit(month.id)"
        id="to-Do-button-{{ curentMonthIndex }}"
      >
        <span class="d-flex justify-content-start">
          <span>
            {{ month.name }} | Events: {{ month.eventsCount }} | AE:
            {{ month.aeCount }} | Rescheduled: {{ month.rescheduledCount }} |
            Cancelled: {{ month.cancelledCount }} | Net-Events:
            {{ month.netEventsCount }}
          </span>
        </span>
      </button>
      <div
        class="d-flex align-items-center ms-2"
        style="width: 25px"
        title="Export to CSV"
      >
        <input
          type="checkbox"
          class="form-check-input"
          [checked]="month.exportCheck"
          (change)="exportCheck.emit(month.id)"
        />
      </div>
    </div>
    <div *ngIf="month.expanded">
      <div class="box my-2">
        <div class="es-box-name-header posSticky">
          <div class="h-100 d-flex p-2 align-items-center">
            <div style="width: 200px">
              <span class="txt">Status</span>
            </div>
            <div style="width: 200px">
              <span class="txt mt-1">CC</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">Client</span>
            </div>
            <div style="width: 350px">
              <span class="txt mt-1">Date</span>
            </div>
            <div style="width: 300px">
              <span class="txt mt-1">Urgent</span>
            </div>
            <div class="text-center" style="width: 400px">
              <span class="txt mt-1">Assigned to</span>
            </div>
            <div style="width: 400px">
              <span class="txt mt-1">Time</span>
            </div>
            <div style="width: 100px">
              <img src="assets/img/bell-gray20x20.svg" />
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">Client touch</span>
            </div>
            <div class="text-center" style="width: 500px">
              <span class="txt mt-1">Email Blast 1st</span>
            </div>
            <div class="text-center" style="width: 500px">
              <span class="txt mt-1">Email Blast 2nd</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">Few Spots</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">WFR Call 1</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">Assigned to</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">WFR Call 2</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">Assigned to</span>
            </div>
            <div style="width: 500px">
              <span class="txt mt-1">WFR reminder</span>
            </div>
            <div class="text-center" style="width: 300px">
              <span class="txt mt-1">Ad Spent</span>
            </div>
            <div style="min-width: 50px">
              <span class="txt mt-1" style="min-width: 50px"> </span>
            </div>
          </div>
        </div>
        <div>
          <div
            *ngIf="
              month.showEventSchedules &&
              !month.showEventSchedules.length &&
              month.sortStatus
            "
          >
            <span>There are no events with such status</span>
          </div>
          <div>
            <ng-container *ngVar="month.showEventSchedules as events">
              <div infiniteScroll (scrolled)="onScrollDown(month)">
                <div
                  *ngFor="let event of events; let eventIndx = index"
                  class="p-0 m-0 brdNotFirstChild evenBg"
                >
                  <app-es-todo-double-row
                    (actionPop)="actionPop.emit($event)"
                    (enterBell)="enterReminder($event)"
                    [event]="event"
                    [mainParentId]="event.id"
                    (enterChangeStatus)="enterChangeStatus.emit($event)"
                    (deleteConfirmation)="deleteConfirmation.emit($event)"
                    (showModal)="showModal.emit($event)"
                  ></app-es-todo-double-row>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="reminderPopOver"
  class="changeReminder"
  style="position: absolute; max-width: 500px; max-height: 205px"
  [style.left.px]="reminderLeft"
  [style.top.px]="reminderTop"
  [hidden]="
    reminderLeft == null || reminderTop == null || reminderEvent == null
  "
  (mouseleave)="mouseLeave()"
>
  <input
    type="checkbox"
    class="form-check-input"
    [checked]="reminderEvent?.reminderReady"
    (change)="checkReminder(reminderEvent?.id ?? ''); mouseLeave()"
  />
  {{ reminderEvent?.reminder }}
</div>
