import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
@Component({
  selector: 'app-error-alert-modal',
  templateUrl: './error-alert-modal.component.html',
  styleUrls: ['./error-alert-modal.component.scss'],
  standalone: true,
})
export class ErrorAlertModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) { }
}

