<div class="d-flex justify-content-between minus-padding">
  <div class="listwidth b-top-left-radius">
    <app-tickets-list (selectTicket)="selectTicket($event)" [selectedTicketId]="selectedTicketId" [tickets]="tickets"
      [displayName]="displayName"></app-tickets-list>
  </div>
  <div class="chatwidth" [class.closedNoClick]="this.pageStat == 'closed'">
    <div *ngIf="selectedTicket; else noTicket">
      <app-ticket-chat [closed]="this.pageStat == 'closed'" [selectedTicket]="selectedTicket"
        [hasMoreComments]="hasMoreComments" (loadMore)="loadMoreComms($event)"></app-ticket-chat>
    </div>
    <ng-template #noTicket>
      <div class="responsive-page-height d-flex align-items-center justify-content-between">
        <h1 class="w-100 text-center">
          Select Ticket or Create new one to start conversation
        </h1>
      </div>
    </ng-template>
  </div>
</div>

<app-signalr-status (resfresh)="ticketClient.openConn()" [status]="ticketClient.status"></app-signalr-status>