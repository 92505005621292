import { Pipe, PipeTransform } from '@angular/core';
import { UserRoles } from 'src/app/state/auth.repository';

export const ROLE_NAMES = {
  [UserRoles.Superamin]: $localize`:Role - Superadmin:Superadmin`,
  [UserRoles.TenantAdmin]: $localize`:Role - Administrator:Administrator`,
  [UserRoles.User]: $localize`:Role - User:User`,
  [UserRoles.ClientUser]: $localize`:Role - ClientUser:ClientUser`,
  [UserRoles.Charlie]: $localize`:Role - Charlie:Charlie`,
  [UserRoles.ShowFinancials]: $localize`:Role - ShowFinancials:Financials/Accounting`,
  [UserRoles.AdvisorsExcel]: $localize`:Role - AdvisorsExcel:Advisors Excel`,
  
};
//[UserRoles.ClientUser]: $localize`:Role - Client User:Client User`,

@Pipe({ name: 'translateRoles' })
export class TranslateRolesPipe implements PipeTransform {
  transform(input: UserRoles[] | null | undefined): string[] | undefined {
    return input?.map((x) => ROLE_NAMES[x]);
  }
}
