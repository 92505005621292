import { Component, HostListener } from '@angular/core';
import {
  IReferralSource,
  ReferralSourcesRepository,
  ReferralSourcesSortOptions,
} from 'src/app/state/referralSource.repository';
import { ReferralSourcesService } from 'src/app/state/referralSources.service';

@Component({
  selector: 'app-referral-sources-page',
  templateUrl: './referral-sources-page.component.html',
  styleUrls: ['./referral-sources-page.component.scss'],
})
export class ReferralSourcesPageComponent {
  searchFilter: string = '';
  referralSource?: IReferralSource;
  deleteConfirmation: IReferralSource | null = null;
  deleteError: string | null = null;
  sortOptions = ReferralSourcesSortOptions;

  constructor(
    public repo: ReferralSourcesRepository,
    public service: ReferralSourcesService
  ) {}

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
  }
  handleDeleteClick(post: IReferralSource) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.clearError();
        this.refreshData();
      },
      error: (data) => (this.deleteError = data),
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter, 25).subscribe();
  }
  clearError() {
    this.deleteError = null;
  }
}
