import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { IEventInfo } from '../../../../state/event-infos.repository';
import { ITopic } from '../../../../state/event-schedule.repository';
import { TopicsRepository } from '../../../../state/topics.repository';
import { TopicsService } from '../../../../state/topics.service';

@Component({
  selector: 'app-event-infos-editform',
  templateUrl: './event-infos-editform.component.html',
  styleUrls: ['./event-infos-editform.component.scss'],
})
export class EventInfosEditformComponent {
  editForm: UntypedFormBuilder | any;
  @Input() editedEventInfo?: IEventInfo | null;
  submitErrors: string[] | null = null;
  @Output() EventInfoSubmit = new EventEmitter<Partial<ITopic>>();
  constructor(private formBuilder: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedEventInfo?.name, Validators.required],
    });
  }
  onSubmit() {
    const id = this.editedEventInfo?.id?.toString();
    const tenantId = this.editedEventInfo?.tenantId?.toString();
    const name = this.editForm.value?.name?.toString();

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.EventInfoSubmit.emit({
      id,
      tenantId,
      name,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
