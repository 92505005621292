import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import {
  CirclesOptions,
  CirclesOptionsKm,
  ICompanyLocation,
} from '../../../../state/companies.repository';
import { IEventScheduleLocation } from 'src/app/state/event-schedule.repository';
import { CompaniesService } from 'src/app/state/companies.service';
@Component({
  selector: 'app-edit-location-popover',
  templateUrl: './edit-location-popover.component.html',
  styleUrls: ['./edit-location-popover.component.scss'],
})
export class EditLocationPopoverComponent {
  @Input() posLeft: number = 0;
  @Input() location?: ICompanyLocation;
  _useKmAsValues: boolean = false;
  @Input()
  set useKmAsValues(val: boolean) {
    if (val) {
      this.CirclesOptions = CirclesOptionsKm;
    } else {
      this.CirclesOptions = CirclesOptions;
    }
    this._useKmAsValues = val;
  }
  get useKmAsValues() {
    return this._useKmAsValues;
  }

  @Input() posTop: number = 0;
  @Output() updateLocation = new EventEmitter<Partial<ICompanyLocation>>();
  @Input() shown: boolean = false;
  CirclesOptions: DatalistOption[] = CirclesOptions;
  constructor(public service: CompaniesService) {}
  changeCircleRadius(event: any) {
    // let circle = this.circles.find((x) => x.id == locId);
    // let location = this.locations.find((x) => x.id == locId);
    if (location) {
      this.location!.radius = event;
      this.service
        .updateLocaion(this.location!)
        .subscribe((x) => this.updateLocation.emit(x));
    }
  }
}
