<div
  class="topRight bg-light brdStat w-100px h-30px d-flex justify-content-between align-items-center pointer"
  [class.bg-danger]="status == 'Closed'"
  [class.connected]="status == 'Connected'"
  (click)="emitrefresh()"
>
  <div class="p-1">{{ status }}</div>
  <!-- <div *ngIf="status == 'Closed'">
    <button
      class="btn btn-outline-dark wh-24px p-0 m-0"
      title="refresh"
      (click)="resfresh.emit()"
    >
      <img src="../../../../../assets/img/arrows/refresh.svg" />
    </button>
  </div> -->
</div>
