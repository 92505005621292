import { DOCUMENT, NgForOf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IMail } from 'src/app/state/mail.repository';
import { MailRepository } from 'src/app/state/mail.repository';
import { MailService } from 'src/app/state/mail.service';

import { AuthRepository } from 'src/app/state/auth.repository';
import { User } from 'src/app/state/users.repository';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  AbstractControl,
  UntypedFormBuilder,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.scss'],
})
export class MailFormComponent implements OnInit {
  MailForm: UntypedFormBuilder | any;

  UsersOptions: DatalistOption[] | null = null;

  @Input() editedMail?: IMail | null;

  @Output() MailSubmit = new EventEmitter<Partial<IMail>>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    private repo: MailRepository,
    private service: MailService,
    public authRepo: AuthRepository
  ) {}

  updateValue(control: string, value: any) {
    const controlObject = this.MailForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  ngOnInit() {
    this.MailForm = this.formBuilder.group({
      title: [this.editedMail?.header, Validators.required],
      body: this.editedMail?.body,
    });
  }

  onSubmit() {
    if (this.MailForm && !this.MailForm.valid) {
      this.MailForm.markAllAsTouched();
      return;
    }
    const header = this.MailForm.value?.title.toString();
    const body = this.MailForm.value?.body;
    this.MailSubmit.emit({ header, body });
  }
}
