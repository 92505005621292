import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, tap } from 'rxjs';
import { sortBy, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface IDocumentType {
  id: string;
  name: string;
  updatedAt: string;
  isSystemType: boolean;
  isShownOnSideMenu: boolean;
}

export const DocumentTypeSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Date:Date`, property: 'updatedAt' },
];
const store = createStore(
  { name: 'documentTypesOnSideBar' },
  withEntities<IDocumentType>(),
  withRequestsStatus()
);
persistState(store, { storage: localStorageStrategy });

@Injectable({ providedIn: 'root' })
export class DocumentTypesRepository extends BaseRepository<IDocumentType> {
  constructor() {
    super('documentTypes', DocumentTypeSortOptions);
  }
}
