import { Component, OnInit } from '@angular/core';
import { enterFromTopAnimation } from 'src/app/modules/shared/helpers/animaions';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { ConnectionService } from 'src/app/modules/shared/services/connection.service';
import { RequestsRepository } from 'src/app/state/requests.repository';

@Component({
  selector: 'app-offline-label',
  templateUrl: './offline-label.component.html',
  styleUrls: ['./offline-label.component.scss'],
  animations: [enterFromTopAnimation],
})
export class OfflineLabelComponent implements OnInit {
  features = this.env.features;

  constructor(
    public connection: ConnectionService,
    public repo: RequestsRepository,
    public env: EnvState
  ) {}

  ngOnInit(): void {}
}
