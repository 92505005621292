import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AgmMap, InfoWindowManager, MouseEvent } from '@agm/core';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { extend } from 'dayjs';
import { CompaniesService } from '../../../../state/companies.service';
import {
  CompaniesRepository,
  ICompanyMarker,
  ICompanyLocation,
  IMapInfo,
  CirclesOptions,
  CirclesOptionsKm,
  IScreenOptions,
  ILastUsed,
} from '../../../../state/companies.repository';
import { number } from 'echarts';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { Circle, GoogleMap } from '@agm/core/services/google-maps-types';
import { DatePipe } from '@angular/common';
import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { Observable, of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { trigger, style, animate, transition } from '@angular/animations';
import { LatLng } from 'ngx-google-places-autocomplete/objects/latLng';
import { LocationsService } from 'src/app/state/locations.service';
import { error } from 'console';
@Component({
  selector: 'app-maps-page',
  templateUrl: './maps-page.component.html',
  styleUrls: ['./maps-page.component.scss'],
})
export class MapsPageComponent implements OnInit, AfterViewInit {
  selectedCompany: ICompanyMarker | null = null;
  companies?: ICompanyMarker[] = undefined;
  clientMenuShown: boolean = false;
  clientSearch: string = '';
  editLeft: number = 0;
  editTop: number = 0;
  editInfoLeft: number = 0;
  editInfoTop: number = 0;

  showEditLocationPopover: boolean = false;
  showEditLocationInfo: boolean = false;

  editLocation?: ICompanyLocation;
  editInfoLocation?: ICompanyLocation;

  useKmAsValues: boolean = false;
  showAllDisabled = false;
  showAll = true;
  deleteSearchMarker() {
    let searchm = markers.find((x) => x.get('id') == 'searchmarker');
    if (searchm) {
      const markindex = markers.indexOf(searchm, 0);
      if (markindex > -1) {
        markers.splice(markindex, 1);
      }
      const shownindex = shownMarkers.indexOf(searchm, 0);
      if (shownindex > -1) {
        shownMarkers.splice(shownindex, 1);
        a.removeMarker(searchm);
      }
    }
  }
  addSearchMarker(opt: IScreenOptions) {
    let mrkrMVC = new google.maps.Marker({
      map: mapEl,
      position: { lat: opt.lat, lng: opt.lng },

      visible: true,
      draggable: false,
      clickable: false,
    });
    mrkrMVC.set('id', 'searchmarker');
    markers.push(mrkrMVC);
    shownMarkers.push(mrkrMVC);
    a.addMarker(mrkrMVC);
  }
  ChangeCenterSubmit(opt: IScreenOptions) {
    this.deleteSearchMarker();

    this.addSearchMarker(opt);
    this.ChangeCenter(opt);
  }
  ngAfterViewInit() {
    this._ngZone.runOutsideAngular(async () => {
      await initMap();
      this.service.loadMapInfo().subscribe((x) => {
        this.useKmAsValues = x.useKmAsValues;
        this.ChangeCenter({
          lat: x.startingLat,
          lng: x.startingLng,
          zoom: x.zoom,
        });
      });
    });
  }
  constructor(
    public service: CompaniesService,
    public datepipe: DatePipe,
    public repository: CompaniesRepository,
    public httpClient: HttpClient,
    private _ngZone: NgZone,
    public locService: LocationsService
  ) {}
  async ngOnInit(): Promise<void> {
    if (a) a.clearMarkers();
    circles.forEach((x) => x.setMap(null));
    markers = [];
    circles = [];
    shownMarkers = [];
  }
  ngOnDestroy(): void {
    if (a) a.clearMarkers();
    circles.forEach((x) => x.setMap(null));
    markers = [];
    circles = [];
    shownMarkers = [];
  }
  async handleShowAll() {
    this.showAllDisabled = true;
    if (!this.showAll) {
      this.companies?.forEach((comp) => {
        comp.allCompaniesSelected = false;
        comp.companyLocations.forEach((loc) => {
          loc.checked = false;
        });
      });
      shownMarkers = [];
      circles.forEach((x) => {
        x.setMap(null);
      });
      if (a) a.clearMarkers();
    } else {
      this.companies?.forEach((comp) => {
        comp.allCompaniesSelected = true;
        comp.companyLocations.forEach((loc) => {
          loc.checked = true;
        });
      });

      shownMarkers = markers;
      circles.forEach((x) => {
        x.setMap(mapEl);
      });

      a.addMarkers(
        shownMarkers.filter((x) => x.getVisible()),
        false
      );
    }
    setTimeout(() => {
      this.showAllDisabled = false;
    }, 500);
  }
  filterCompanies() {
    this.repository.all$.subscribe((x) => {
      this.companies = x.filter((x) => {
        return (
          x.title.toUpperCase().includes(this.clientSearch.toUpperCase()) ||
          x.companyLocations.some((loc) => {
            loc.title.toUpperCase().includes(this.clientSearch.toUpperCase());
          })
        );
      });
    });
  }
  ChangeCenter(opt: IScreenOptions) {
    mapEl.moveCamera({
      center: { lat: opt.lat, lng: opt.lng },
      zoom: opt.zoom,
    });
  }
  pushMarkersAndCircles(locs: ICompanyLocation[], fillColor?: string) {
    locs.map((x) => {
      let mrkrMVC = new google.maps.Marker({
        map: mapEl,
        position: { lat: x.latitude, lng: x.longitude },
        title: x.title,
        label: {
          text: x.title.substring(0, 70),
          color: '#3878C7',
          fontWeight: 'bold',
          fontSize: '20px',
          fontFamily: '',
        },
        visible: true,
        draggable: false,
        clickable: true,
      });

      let circle = new google.maps.Circle({
        strokeColor: fillColor ?? 'red',
        strokeWeight: 2,
        fillColor: fillColor ?? 'red',
        fillOpacity: 0.35,
        map: mapEl,
        center: { lat: x.latitude, lng: x.longitude },
        radius: x.radius,
      });
      mrkrMVC.set('id', x.id);
      mrkrMVC.addListener('click', () => {
        this.mrkrClick(mrkrMVC);
      });

      circle.set('id', x.id);
      markers.push(mrkrMVC);
      shownMarkers.push(mrkrMVC);
      circle.setMap(mapEl);
      circles.push(circle);
    });
  }
  editClickInfo(event: any) {
    if (this.companies) {
      if (
        this.companies[this.iwCompIndex] &&
        this.companies[this.iwCompIndex].companyLocations
      ) {
        let cdurentLocation =
          this.companies[this.iwCompIndex].companyLocations[this.iwLocIndex];
        if (cdurentLocation) {
          this.editInfoLocation = cdurentLocation;
        }
        this.showEditLocationInfo = !this.showEditLocationInfo;
        let editbtn = document.getElementById(
          `einfo/${this.iwCompIndex}/${this.iwLocIndex}`
        );
        this.editInfoLeft = editbtn?.getBoundingClientRect().left ?? 0;
        this.editInfoTop = editbtn?.getBoundingClientRect().top ?? 0;
      }
    }
  }
  mouseInfoLeave() {
    this.showEditLocationInfo = false;
    // this.iwCompIndex = 0;
    // this.iwLocIndex = 0;
    // this.editInfoLocation = undefined;
  }
  iwCompIndex = -1;
  iwLocIndex = -1;
  showIWContent(
    mrkr: any,
    location?: ICompanyLocation,
    comp?: ICompanyMarker,
    lastUsed?: ILastUsed
  ) {
    {
      if (comp) {
        this.iwCompIndex = this.companies!.indexOf(comp);
        this.iwLocIndex = comp.companyLocations.indexOf(location!);

        infoWindow.open({ map: mapEl });
        this.editLocation = location;

        let content = `
      <div class="text-center d-flex flex-column" 
      style="max-width:300px;
      
       ">
        
      <div class=" d-flex customFont  w-100 justify-content-center" style="font-weight:999">
        <span style="white-space: pre;
        font-weight:999
      "
          >${comp!.clientCode?.slice(0, 70) ?? ''} - </span>
        
        <span style="white-space: nowrap; max-height:30px; overflow:hidden">  ${
          comp.title ?? ''
        } </span>
        `;

        if (this.iwCompIndex != -1 && this.iwLocIndex != -1) {
          content += `
        <button
        compindex=${this.iwCompIndex}
        locindex=${this.iwLocIndex}
        class="btn p-0 mx-1 d-flex align-items-center justify-content-center"
        onclick="document.getElementById('editclick')?.click()"
        id="einfo/${this.iwCompIndex}/${this.iwLocIndex}"
      >
        <img src="assets/img/edit19x19.svg" style="width:13px; height:13px"/>
      </button>
      `;
        }

        content += `
      </div>
      <br />
      <div >
      <div style="overflow: hidden;
      max-height:30px">
        ${location!.title?.slice(0, 70) ?? ''}
      </div>
      
      <div>${location!.lastEventDescription ?? ''}</div>
      <div>${location!.lastEventDate ?? ''}</div>

    `;
        if (lastUsed?.date) {
          const formattedDate = new Date(lastUsed?.date).toLocaleDateString(
            'en-US',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }
          );
          content += `
      <div>Last Used - ${formattedDate}</div>`;
        }
        content += `
      </div>
      `;
        infoWindow.setContent(content);
        infoWindow.setPosition(mrkr.getPosition());
      }
    }
  }
  mrkrClick(mrkr: any) {
    let location: ICompanyLocation | undefined;
    this.companies?.map((comp) => {
      if (location) return;
      location = comp.companyLocations.find((c) => c.id === mrkr.get('id'));
    });

    if (location) {
      let comp = this.companies?.find((x) => x.id == location!.companyId);
      this.locService.loadLastUsed(location.id).subscribe(
        (x) => this.showIWContent(mrkr, location, comp, x),
        (error) => {
          this.showIWContent(mrkr, location, comp);
        }
      );
    }
  }
  clickchange(event: any, location: ICompanyLocation) {
    this.showEditLocationPopover = !this.showEditLocationPopover;
    this.editLocation = location;
    this.editLeft = event.pageX;
    this.editTop = event.pageY;
  }
  updateCompanyChecked(comp: ICompanyMarker) {
    let locIds = comp!.companyLocations.map((loc) => loc.id);
    comp.allCompaniesSelected = markers
      .filter((m) => locIds.includes(m.get('id')))
      .every((m) => m.getVisible());
  }
  updateLocationCircle(id: string, value: number) {
    let cir = circles.find((x) => x.get('id') == id);
    if (cir) {
      cir.setRadius(value);
    }
    this.showEditLocationPopover = false;
    this.mouseInfoLeave();
  }

  handleShowLocations(
    type: 'location' | 'company',
    id: string,
    value: boolean = false
  ) {
    switch (type) {
      case 'location': {
        let location: ICompanyLocation | undefined;

        this.companies?.map((comp) => {
          if (location) return;
          location = comp.companyLocations.find((c) => c.id === id);
        });

        if (location) {
          let comp = this.companies?.find((x) => x.id == location!.companyId);
          if (comp) {
            if (value) {
              //add location
              let markerToAdd = markers.find((m) => id == m.get('id'));
              // this.updateCompanyChecked(comp);
              if (markerToAdd) {
                a.addMarker(markerToAdd, false);
                shownMarkers = shownMarkers.concat(markerToAdd);
              }

              let circleToAdd = circles.find((c) => id == c.get('id'));
              circleToAdd?.setMap(mapEl);

              // circleToAdd?.setMap(mapEl);
            } else {
              //remove location
              comp.allCompaniesSelected = false;

              let markerToDel = markers.find((m) => id == m.get('id'));

              shownMarkers = shownMarkers.filter((m) => m.get('id') != id);
              if (markerToDel) a.removeMarker(markerToDel, false);
              let circleToAdd = circles.find((c) => id == c.get('id'));
              circleToAdd?.setMap(null);
              // circleToAdd?.setMap(null);
            }
          }
        }
        break;
      }
      case 'company': {
        let comp = this.companies?.find((c) => c.id === id);
        if (comp) {
          comp!.checkboxDisabled = true;
          setTimeout(() => {
            if (comp!.allCompaniesSelected) {
              //add all company`s locations

              comp!.companyLocations.forEach((loc) => (loc.checked = true));
              let locIds = comp!.companyLocations.map((loc) => loc.id);
              let markersToAdd = markers.filter((m) =>
                locIds.includes(m.get('id'))
              );
              markersToAdd.forEach((m) => m.setVisible(true));
              shownMarkers = shownMarkers.concat(markersToAdd);
              a.addMarkers(markersToAdd, false);
              let newCircles = circles.filter((c) =>
                locIds.includes(c.get('id'))
              );
              newCircles.forEach((x) => x.setMap(mapEl));
              comp!.checkboxDisabled = false;
            } else {
              //remove all company`s locations

              comp!.checkboxDisabled = true;
              comp!.companyLocations.forEach((x) => (x.checked = false));
              let locIds = comp!.companyLocations.map((loc) => loc.id);
              a.removeMarkers(
                shownMarkers.filter((m) => locIds.includes(m.get('id'))),
                false
              );
              shownMarkers = shownMarkers.filter(
                (m) => !locIds.includes(m.get('id'))
              );
              let newCircles = circles.filter((c) =>
                locIds.includes(c.get('id'))
              );
              newCircles.forEach((x) => x.setMap(null));
              comp!.checkboxDisabled = false;
            }
          }, 500);
        }
        break;
      }
    }
  }

  initMarkers(company: ICompanyMarker[]) {
    markers = [];
    circles = [];
    if (a) a.clearMarkers();

    company.forEach((com) => {
      this.pushMarkersAndCircles(com.companyLocations, com.fillColor);
    });
    a.addMarkers(markers as Marker[]);
  }

  initLoad() {
    this.service.loadAllActive().subscribe((x) => {
      x.forEach(
        (c) => (
          (c.locationsHiden = true),
          (c.allCompaniesSelected = true),
          c.companyLocations.forEach((l) => (l.checked = true))
        )
      );
      this.companies = x;
      this.initMarkers(x);
    });
  }
  moveToLoc(zoom: number, lat: number, lng: number) {
    mapEl.moveCamera({ zoom, center: { lat, lng } });
  }

  manageExpandClients(id: string, value: boolean) {
    if (this.companies) {
      if (this.companies.find((x) => x.id == id)) {
        this.companies.find((x) => x.id == id)!.locationsHiden = value;
      }
    }
  }
}

function checkVisibleElements(elementsArray: Marker[], bounds: any) {
  //checks if marker is within viewport and displays the marker accordingly - triggered by google.maps.event "idle" on the map Object

  try {
    var changed = false;
    {
      elementsArray.forEach((item: any) => {
        if (bounds.contains(item.position)) {
          if (item.visible != true) {
            item.setVisible(true);
            changed = true;
          }
        } else if (item.visible == true) {
          item.setVisible(false);
          changed = true;
        }
      });
    }

    if (changed) {
      a.removeMarkers(shownMarkers.filter((x) => !x.getVisible()));
      a.addMarkers(elementsArray, false);
    }
  } catch (error) {
    console.log(error);
  }
}
var markers: google.maps.Marker[] = [];
var shownMarkers: google.maps.Marker[] = [];

var circles: google.maps.Circle[] = [];
var a: MarkerClusterer;
var infoWindow: google.maps.InfoWindow;
var mapEl: google.maps.Map;
var zoomNumb: number = 5;
async function initMap() {
  const { Map, InfoWindow } = (await google.maps.importLibrary(
    'maps'
  )) as google.maps.MapsLibrary;
  const { AdvancedMarkerElement, PinElement } =
    (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

  mapEl = new google.maps.Map(document.getElementById('map') as HTMLElement, {
    zoom: zoomNumb,
    center: { lat: 37, lng: -100 },
    mapId: 'DEMO_MAP_ID',
    gestureHandling: 'greedy',
    streetViewControl: false,
    scaleControl: true,
  });
  mapEl.addListener('idle', function (event: any): void {
    if (mapEl) {
      var bounds = mapEl.getBounds();
      checkVisibleElements(shownMarkers, bounds);
    }
  });
  document.getElementById('initloader')?.click();
  infoWindow = new google.maps.InfoWindow({
    content: '',
    disableAutoPan: false,
  });

  a = new MarkerClusterer({
    markers,
    map: mapEl,
    algorithmOptions: { maxZoom: 9 },
  });
}
