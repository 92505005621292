import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import {
  ITicket,
  ITicketComment,
  ITicketCommentDocument,
} from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';
import { EmojiMartPopupComponent } from '../popups/emoji-mart-popup/emoji-mart-popup.component';
import { AttachFilePopupComponent } from '../popups/attach-file-popup/attach-file-popup.component';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-ticket-chat',
  templateUrl: './ticket-chat.component.html',
  styleUrls: ['./ticket-chat.component.scss'],
})
export class TicketChatComponent {
  @Input() selectedTicket: ITicket | null = null;
  @Input() closed = false;
  @Input() hasMoreComments = false;
  @Output() loadMore = new EventEmitter<number>();
  @ViewChild('messageScroll') messageScroll: ElementRef | null = null;
  @ViewChild('commentText') commentText: ElementRef | null = null;
  @ViewChild(AttachFilePopupComponent)
  attachPop: AttachFilePopupComponent | null = null;
  @ViewChild(EmojiMartPopupComponent)
  emojiComp: EmojiMartPopupComponent | null = null;
  showUnsavedPopUp = false;
  editedComment: ITicketComment | null = null;
  sendForm: UntypedFormBuilder | any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: TicketsService,
    private cdr: ChangeDetectorRef
  ) {}

  downloadTicketDocument(id: string) {
    this.service.downloadDoc(id).subscribe((response) => {
      if (response.blob) saveAs(response.blob, response.fileName ?? '');
    });
  }

  ngOnInit(): void {
    this.sendForm = this.formBuilder.group({
      content: [
        this.editedComment?.content,
        [Validators.required, this.noWhitespaceValidator],
      ],
      isEdited: [this.editedComment?.isEdited ?? false],
      documents: [this.editedComment?.documents ?? []],
    });
    // this.setAttachPop()
  }

  sendComment() {
    if ((this.sendForm && !this.sendForm.valid) || !this.selectedTicket?.id) {
      this.sendForm.markAllAsTouched();
      return;
    }
    const comm: Partial<ITicketComment> = {
      id: this.editedComment?.id,
      content: this.sendForm?.value?.content ?? '',
      ticketId: this.selectedTicket?.id ?? '',
      isEdited: this.sendForm?.value?.isEdited ?? false,
      documents:
        this.attachPop?.localDocs ?? this.sendForm?.value?.documents ?? [],
    };

    let updateResult$: Observable<ITicketComment> | undefined;

    if (this.editedComment) {
      updateResult$ = this.service.updateComment(comm);
    } else {
      updateResult$ = this.service.postComment(comm);
    }

    updateResult$?.subscribe({
      complete: () => {
        this.clearForm();
        this.editedComment = null;
      },
    });
  }

  loadMoreComms() {
    let comCount = this.selectedTicket?.comments?.length ?? 0;
    this.loadMore.emit(comCount);
  }

  scrollDown() {
    this.messageScroll!.nativeElement.scrollTop = 0;
  }

  scrollDownAfterDelay() {
    if (this.messageScroll) {
      setTimeout(() => {
        this.scrollDown();
      }, 100);
    }
  }

  // reloadTicketChat() {
  //   this.scrollDownAfterDelay();

  //   this.checkResetForm();
  // }

  clearForm() {
    this.sendForm.reset();
    this.cdr.detectChanges();
    this.showUnsavedPopUp = false;
    this.editedComment = null;
    this.editedCommentId = null;
    if (this.attachPop) {
      this.setDocuments([]);
      this.attachPop!.localDocs = [];
    }
  }

  isUnsavedComment() {
    return (
      this.editedComment ||
      this.sendForm?.value?.content ||
      this.attachPop?.localDocs?.length ||
      this.sendForm?.value?.documents?.length
    );
  }

  noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  comActionsTop = 0;
  comActionsLeft = 0;
  editedCommentId: string | null = null;
  onRightClick(event: any, commId: string, isMyMessage: boolean) {
    if (isMyMessage) {
      this.editedCommentId = commId;
      this.comActionsTop = Math.max(event.pageY - 10, 0);
      this.comActionsLeft = Math.max(event.pageX - 100, 0);
      event.preventDefault();
    }
  }

  mouseLeave() {
    this.editedCommentId = null;
    this.comActionsTop = 0;
    this.comActionsLeft = 0;
  }

  setDeleteComment() {
    this.deleteConfirmation =
      this.selectedTicket?.comments.find((x) => x.id == this.editedCommentId) ??
      null;
  }

  deleteConfirmation: ITicketComment | null = null;

  handleDeleteClick() {
    if (this.deleteConfirmation) {
      this.service.deleteComment(this.deleteConfirmation.id).subscribe((x) => {
        this.mouseLeave();
      });
    }
  }
  stopEditing() {
    this.showUnsavedPopUp = this.isUnsavedComment();
  }

  setEditComment() {
    if (this.isUnsavedComment()) {
      this.showUnsavedPopUp = true;
    } else {
      let toEdit = this.selectedTicket?.comments.find(
        (x) => x.id == this.editedCommentId
      );
      if (toEdit) {
        this.editedComment = toEdit;
        this.sendForm = this.formBuilder.group({
          content: [
            this.editedComment?.content,
            [Validators.required, this.noWhitespaceValidator],
          ],
          isEdited: true,
          documents: [this.editedComment?.documents ?? []],
        });
        this.mouseLeave();
        this.commentText?.nativeElement?.focus();
        this.cdr.detectChanges();
      }
    }
  }

  showEmojiPickerChange(val: boolean) {
    this.showEmojiPicker = val;
    if (this.showEmojiPicker) this.emojiComp?.enableHandler();
    else this.emojiComp?.disableHandler();
  }

  showEmojiPicker = false;
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;

    if (this.showEmojiPicker) this.emojiComp?.enableHandler();
    else this.emojiComp?.disableHandler();
    event?.stopPropagation();
  }

  insertEmoji(emoji: any) {
    const controlObject = this.sendForm?.get('content');
    let text = this.sendForm?.value?.content ?? '';
    text += `${emoji.emoji.native}`;
    controlObject?.setValue(text);
    controlObject?.markAsTouched();
  }

  handleKeyPress(evt: any) {
    if (!evt.shiftKey) {
      this.sendComment();
      evt.preventDefault();
    }
  }

  setDocuments(documents: Partial<ITicketCommentDocument>[]) {
    this.sendForm.get('documents')?.setValue(documents ?? []);
  }
}
