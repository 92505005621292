<div
  class="modal fade"
  id="activityLogModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Activity Logs</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body customScroll d-flex" style="overflow-y: scroll">
        <div class="w-100" *ngIf="!isLoading; else loaderBlock">
          <div
            class="w-100"
            *ngIf="actLogs && actLogs.length; else noLogsBlock"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col-2">
                    <img src="assets/img/time/clock.svg" />
                  </th>
                  <th scope="col-3"><img src="assets/img/users19x19.svg" /></th>

                  <th scope="col-3">
                    <img src="assets/img/braces/braces.svg" />
                  </th>
                  <th scope="col-4"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of actLogs">
                  <td class="col-2">
                    {{ dateToFormat(log.createdAt) }}
                  </td>

                  <td class="col-3">
                    {{ log.user?.name + " " + log.user?.surname }}
                  </td>

                  <td class="col-3">
                    {{ log.type }}
                  </td>

                  <td class="col-4">
                    <div
                      *ngIf="
                        log.type &&
                        log.type.includes('status') &&
                        log.oldStatus &&
                        log.newStatus
                      "
                    >
                      {{ log.oldStatus }}
                      <img src="assets/img/arrows/crevron-right.svg" />
                      {{ log.newStatus }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #noLogsBlock>
            <div
              class="w-100 h-100 d-flex align-items-center justify-content-center text-muted noLogs"
            >
              No Activity Logs avaliable
            </div></ng-template
          >
        </div>
        <ng-template #loaderBlock>
          <app-spinner></app-spinner>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
