<div class="row mrg-row mbody">
  <div class="pt-2 col-12" style="overflow-y: auto; height: 100vh">
    <div *ngIf="id === 'new' || eventSchedule" class="row mb-4">
      <div class="col-12">
        <!-- <app-event-schedule-editform
          [editedEvent]="eventSchedule"
          (EventSubmit)="createEvent($event)"
        ></app-event-schedule-editform> -->

        <app-client-event-schedule-edit-form
          [editedEvent]="eventSchedule"
          (EventSubmit)="createEvent($event)"
        ></app-client-event-schedule-edit-form>
        <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
        <app-error-alert
          title="Could not submit the eventSchedule"
          i18n-title="EventSchedule submit error alert"
          [errors]="submitErrors"
        ></app-error-alert>
      </div>
    </div>
  </div>
</div>
