import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  CirclesOptions,
  CirclesOptionsKm,
  ColorsOptions,
  ICompanyLocation,
  ICompanyMarker,
  IInvoice,
  IMapInfo,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import {
  IEventScheduleLocation,
  IRoom,
} from '../../../../state/event-schedule.repository';
import { LocationsService } from '../../../../state/locations.service';
import { RoomsService } from '../../../../state/rooms.service';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pop-up-iframe',
  templateUrl: './pop-up-iframe.component.html',
  styleUrls: ['./pop-up-iframe.component.scss'],
})
export class PopUpIframeComponent {
  @Input() tabSelected: string | null = null;
  @Input() clientId: string | null = null;
  @Input() locationId: string | null = null;
  @Output() SubmitRoom = new EventEmitter<Partial<IRoom>>();
  @Output() SubmitLocation = new EventEmitter<
    Partial<IEventScheduleLocation>
  >();
  isLoading: boolean = false;
  editForm: UntypedFormBuilder | any;
  editLocationForm: UntypedFormBuilder | any;
  submitErrors: string[] | null = null;
  mapInfo: IMapInfo | null = null;
  testsLocation = 'localhost:9876';
  locationIndex: number = 1;
  formattedaddress = ' ';

  myOptions: any;
  CirclesOptions: DatalistOption[] = CirclesOptions;
  ColorsOptions: DatalistOption[] = ColorsOptions;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public locationsService: LocationsService,
    public roomsService: RoomsService,
    public clientsService: CompaniesService,
    public datepipe: DatePipe
  ) {}
  clientsOptions: DatalistOption[] | null = [];

  @Input() set clients(value: ICompanyMarker[] | null) {
    if (!value) {
      this.clientsOptions = null;
    } else {
      this.clientsOptions = value.map((x) => ({
        value: x.id,
        label: x.clientCode ? x.clientCode + ' - ' + x.title : x.title,
      }));
    }
  }
  locationsOptions: DatalistOption[] | null = [];
  @Input() editedLocation?: ICompanyLocation | null;

  clear() {
    this.clientId = null;
    this.locationId = null;
    this.editLocationForm.reset({});
    this.editForm.reset({});
  }

  @Input() set locations(value: IEventScheduleLocation[] | null) {
    if (!value) {
      this.locationsOptions = null;
    } else {
      this.locationsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }

  public handleAddressChange(address: any) {
    (document.getElementById(`location`) as HTMLInputElement).value =
      address.formatted_address;
    this.editLocationForm
      .get('latitude')
      .setValue(address.geometry.location.lat());
    this.editLocationForm
      .get('longitude')
      .setValue(address.geometry.location.lng());
    this.editLocationForm
      .get('locationAddress')
      .setValue(address.formatted_address);
    this.editLocationForm.get('address').setValue(address.formatted_address);
    this.editLocationForm?.markAsTouched();
    this.editLocationForm.updateValueAndValidity(); // force update validity
    return address.formatted_address;
  }

  ngOnInit(): void {
    this.editLocationForm = this.formBuilder.group({
      title: [this.editedLocation?.title, Validators.required],
      order: [this.editedLocation?.order],
      companyId: [this.editedLocation?.companyId, Validators.required],

      latitude: [this.editedLocation?.latitude, Validators.required],
      longitude: [this.editedLocation?.longitude, Validators.required],
      locationAddress: [this.editedLocation?.address, Validators.required],
      address: [this.editedLocation?.address],
      defaultRadius: [this.editedLocation?.defaultRadius],
      lastEventDescription: [this.editedLocation?.lastEventDescription],
      parking: [this.editedLocation?.parking],
    });
    this.clientsService.loadMapInfo().subscribe((x) => {
      this.mapInfo = x;
      if (x.useKmAsValues) {
        this.CirclesOptions = CirclesOptionsKm;
      }
      if (!this.editLocationForm.defaultRadius) {
        if (x.useKmAsValues) {
          this.editLocationForm.get('defaultRadius').setValue(3000);
        } else {
          this.editLocationForm.get('defaultRadius').setValue(4828.03);
        }
      }
    });

    this.clientsService.loadAll().subscribe((x) => (this.clients = x));

    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyLocationId: ['', Validators.required],
      clientId: ['', Validators.required],
    });
  }
  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const companyLocationId = this.editForm.value?.companyLocationId;
    const name = this.editForm.value?.name?.toString();
    this.isLoading = true;
    this.roomsService.postRoom({ companyLocationId, name }).subscribe({
      complete: () => {
        this.SubmitRoom.emit();
      },
      error: (data) => {
        this.isLoading = false;
        this.submitErrors = data;
      },
    });
  }

  updateCompanyLocation(address: any) {
    const lng = this.editForm?.get('longitude');
    lng?.setValue(address.geometry.location.lng());
    lng?.markAsTouched();
    const lat = this.editForm?.get('latitude');
    lat?.setValue(address.geometry.location.lat());
    lat?.markAsTouched();
    const addressobject = this.editForm?.get('address');
    addressobject?.setValue(address.formatted_address);
    addressobject?.markAsTouched();
  }

  updateValue(
    value: any,
    control: string,
    form: string | undefined = 'location'
  ) {
    let controlObject = this.editForm?.get(control);
    if (form == 'location') controlObject = this.editLocationForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  loadLocations($event: any) {
    if ($event) {
      this.clientId = $event;
      let controlObject = this.editForm?.get('clientId');
      controlObject?.setValue($event);
      controlObject?.markAsTouched();
      this.locationsService
        .loadAllLocationsForClient($event)
        .subscribe((x) => (this.locations = x));
    }
  }

  onSubmitLocation() {
    if (this.editLocationForm && !this.editLocationForm.valid) {
      this.editLocationForm.markAllAsTouched();
      return;
    }
    var addressNotSet = false;
    if (
      this.editLocationForm.latitude?.value == 0 ||
      this.editLocationForm.longitude?.value == 0
    ) {
      addressNotSet = true;
    }

    if (addressNotSet) {
      this.editLocationForm.markAllAsTouched();
      return;
    }

    const title = this.editLocationForm.value?.title?.toString();
    const parking = this.editLocationForm.value?.parking?.toString();

    const order = 999999;
    const latitude = this.editLocationForm.value?.latitude?.toString();
    const longitude = this.editLocationForm.value?.longitude?.toString();
    const address = this.editLocationForm.value?.address?.toString();
    const companyId = this.editLocationForm.value?.companyId?.toString();
    const defaultRadius =
      this.editLocationForm.value?.defaultRadius?.toString();
    const lastEventDescription =
      this.editLocationForm.value?.lastEventDescription?.toString();

    this.locationsService
      .postLocation({
        companyId,
        title,
        order,
        latitude,
        longitude,
        address,
        defaultRadius,
        lastEventDescription,
        parking,
      })
      .subscribe({
        complete: () => {
          this.SubmitLocation.emit();
        },
        error: (data) => (this.submitErrors = data),
      });
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }
}
