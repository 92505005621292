import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IApv, ApvRepository } from 'src/app/state/apv.repository';
import { ApvService } from 'src/app/state/apv.service';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';
@Component({
  selector: 'app-apv-answer',
  templateUrl: './apv-answer.component.html',
  styleUrls: ['./apv-answer.component.scss'],
})
export class ApvAnswerComponent implements OnInit {
  post$: Observable<IApv> | null = null;
  post: IApv | null = null;
  id!: string;
  submitErrors: string[] | null = null;

  constructor(
    private service: ApvService,
    private activatedRoute: ActivatedRoute,
    private repo: ApvRepository,
    private router: Router,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.post$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.service.loadOne(id),
      (id) => this.repo.post(id),
      (id) => this.repo.status(id),
      (id) => (this.id = id)
    );
    this.loadevent(this.id);
  }

  loadevent(id: string) {
    this.service.loadOne(id).subscribe((x) => {
      this.post = x;
    });
  }

  CreateAnswer($event: Partial<IApv>) {
    this.submitErrors = null;
    let updateResult: Observable<IApv> | undefined;
    updateResult = this.service.addanswer($event);
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/apv']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
