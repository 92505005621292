import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { ICompanyLocation, IContact } from './companies.repository';

export const LocationsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
];
export interface ILocationOption {
  id: string;
  title: string;
}
@Injectable({ providedIn: 'root' })
export class LocationsRepository extends BaseRepository<ICompanyLocation> {
  constructor() {
    super('LocationsSortOptions', LocationsSortOptions);
  }
}
