<div class="form-group pe-2">
  <div class="form-group mb-3 row p-0">
    <div class="row p-0 m-0">
      <div class="mb-3 col-12">
        <div class="inpt-title">Status</div>
        <label id="location" class="lbl oneLineTextPopup">
          {{ eventScheduleOnPopUp?.status }}
        </label>
      </div>
      <div class="mb-3 col-12">
        <div class="inpt-title">Status 2</div>
        <label id="location" class="lbl oneLineTextPopup">
          {{ eventScheduleOnPopUp?.statusD2 }}
        </label>
      </div>
      <div class="row mb-3 col-12" *ngIf="eventScheduleOnPopUp">
        <div class="col-4 col-sm-3 text-center">
          <div>Workshops/Seminar</div>
          <input
            id="workshopsSeminarType"
            value="workshopsSeminarType"
            [(ngModel)]="eventScheduleOnPopUp!.eventType"
            type="radio"
            class="form-check-input"
            autocomplete="off"
            [disabled]="true"
          />
        </div>
        <div class="col-4 col-sm-3 text-center">
          <div>Lunch/Dinner</div>
          <input
            id="lunchDinnerType"
            value="lunchDinnerType"
            [(ngModel)]="eventScheduleOnPopUp!.eventType"
            type="radio"
            class="form-check-input"
            autocomplete="off"
            [disabled]="true"
          />
        </div>
        <div class="col-4 col-sm-3 text-center">
          <div>Webinar</div>
          <input
            id="webinarType"
            value="webinarType"
            [(ngModel)]="eventScheduleOnPopUp!.eventType"
            type="radio"
            class="form-check-input"
            autocomplete="off"
            [disabled]="true"
          />
        </div>
      </div>
      <div class="mb-3 col-12">
        <div class="inpt-title">Client</div>
        <label id="clienttitle" class="lbl oneLineTextPopup">
          {{
            eventScheduleOnPopUp?.client?.clientCode
              ? eventScheduleOnPopUp?.client?.clientCode +
                " - " +
                eventScheduleOnPopUp?.client?.title
              : eventScheduleOnPopUp?.client?.title
          }}
        </label>
      </div>
      <div class="mb-3 col-3">
        <div class="inpt-title">Double Event</div>

        <input
          type="checkbox"
          class="form-check-input"
          [checked]="eventScheduleOnPopUp?.doubleEvent"
          disabled
        />
      </div>
      <div class="mb-3 col-3">
        <div class="inpt-title">2-day Event</div>

        <input
          type="checkbox"
          class="form-check-input"
          [checked]="eventScheduleOnPopUp?.doubleSession"
          disabled
        />
      </div>
      <div class="col-3 form-floating mb-3">
        <div class="inpt-title" for="asap">ASAP Setup</div>
        <input
          id="asap"
          type="checkbox"
          class="form-check-input"
          disabled
          [checked]="eventScheduleOnPopUp?.asap"
        />
      </div>
      <div class="col-3 form-floating mb-3">
        <div class="inpt-title">Use Catchlight</div>
        <input
          id="use_catchlight"
          type="checkbox"
          class="form-check-input"
          disabled
          [checked]="eventScheduleOnPopUp?.use_catchlight"
        />
      </div>
      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Date 1</div>
          <label id="date" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.formatedDate }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-6"
        >
          <div class="inpt-title">Date 2</div>
          <label id="date2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.formatedDate2 }}
          </label>
        </div>
      </div>

      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">At cost 1</div>
          <label id="date" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.atCost }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-6"
        >
          <div class="inpt-title">At cost 2</div>
          <label id="date2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.atCostD2 }}
          </label>
        </div>
      </div>

      <div class="form-floating row">
        <div class="mb-3 col-3">
          <div class="inpt-title">Time</div>
          <label id="time" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.time }}
          </label>
        </div>
        <div class="mb-3 col-3">
          <div class="inpt-title">Prebuilt Time 1</div>
          <label id="time" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.prebuildTime?.name }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-3"
        >
          <div class="inpt-title">Time 2</div>
          <label id="time2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.time2 }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-3"
        >
          <div class="inpt-title">Prebuilt Time 2</div>
          <label id="time" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.prebuildTime2?.name }}
          </label>
        </div>
      </div>
      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Start Time 1</div>
          <label id="time2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.startTime }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-6"
        >
          <div class="inpt-title">Start Time 2</div>
          <label id="time" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.startTime2 }}
          </label>
        </div>
      </div>
      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Landing Page Url 1</div>
          <label id="landingPageUrl" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.landingPageUrl }}
          </label>
        </div>
        <div *ngIf="eventScheduleOnPopUp?.doubleEvent" class="mb-3 col-6">
          <div class="inpt-title">Landing Page Url 2</div>
          <label id="landingPageUrl2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.landingPageUrl2 }}
          </label>
        </div>
      </div>

      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Landing Page ID 1</div>
          <label id="landingPageId" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.landingPageId }}
          </label>
        </div>
        <div *ngIf="eventScheduleOnPopUp?.doubleEvent" class="mb-3 col-6">
          <div class="inpt-title">Landing Page ID 2</div>
          <label id="landingPageId2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.landingPageId2 }}
          </label>
        </div>
      </div>

      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Google Sheet 1</div>
          <label id="googleSheet" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.googleSheet }}
          </label>
        </div>
        <div *ngIf="eventScheduleOnPopUp?.doubleEvent" class="mb-3 col-6">
          <div class="inpt-title">Google Sheet 2</div>
          <label id="googleSheetD2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.googleSheetD2 }}
          </label>
        </div>
      </div>

      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Google Sheet ID 1</div>
          <label id="googleSheetId" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.googleSheetId }}
          </label>
        </div>
        <div *ngIf="eventScheduleOnPopUp?.doubleEvent" class="mb-3 col-6">
          <div class="inpt-title">Google Sheet ID 2</div>
          <label id="googleSheetId" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.googleSheetId2 }}
          </label>
        </div>
      </div>
      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Attendees 1</div>
          <label id="attendees" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.attendees }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-6"
        >
          <div class="inpt-title">Attendees 2</div>
          <label id="attendeesD2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.attendeesD2 }}
          </label>
        </div>
      </div>
      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Final Registrants 1</div>
          <label id="finalRegistrants" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.finalRegistrants }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-6"
        >
          <div class="inpt-title">Final Registrants 2</div>
          <label id="finalRegistrantsD2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.finalRegistrantsD2 }}
          </label>
        </div>
      </div>
      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Room 1</div>
          <label id="room1" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.room?.name }}
          </label>
        </div>
        <div
          *ngIf="
            eventScheduleOnPopUp?.doubleEvent ||
            eventScheduleOnPopUp?.doubleSession
          "
          class="mb-3 col-6"
        >
          <div class="inpt-title">Room 2</div>
          <label id="roomD2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.roomD2?.name }}
          </label>
        </div>
      </div>

      <div class="form-floating row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Wfr Dinner / Lunch</div>
          <input
            type="checkbox"
            class="form-check-input"
            disabled
            [checked]="eventScheduleOnPopUp?.wfrDinnerOrLunch"
          />
        </div>
        <div class="mb-3 col-6">
          <div class="inpt-title">2-part setup</div>
          <input
            type="checkbox"
            class="form-check-input"
            disabled
            [checked]="eventScheduleOnPopUp?.twoPartSetup"
          />
        </div>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Location</div>
        <label id="location" class="lbl oneLineTextPopup">
          {{ eventScheduleOnPopUp?.eventScheduleLocation?.title }}
        </label>
      </div>
      <div
        *ngIf="eventScheduleOnPopUp?.eventScheduleLocation"
        class="form-floating mb-3"
      >
        <div class="inpt-title">Address</div>
        <div class="inpt" style="color: #7e7575">
          {{ eventScheduleOnPopUp?.eventScheduleLocation?.address }}
        </div>
      </div>
      <div
        *ngIf="eventScheduleOnPopUp?.eventScheduleLocation"
        class="form-floating mb-3"
      >
        <div class="inpt-title">Time Zone</div>
        <div class="inpt" style="color: #7e7575">
          {{ eventScheduleOnPopUp?.utC_Offset_Date }}
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Parking</div>
          <label id="Parking" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.parking }}
          </label>
        </div>
        <div *ngIf="eventScheduleOnPopUp?.doubleEvent" class="mb-3 col-6">
          <div class="inpt-title">Parking 2</div>
          <label id="Parking2" class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.parkingD2 }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Desired BU's/Room Capacity</div>
          <label class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.desiredBU }}
          </label>
        </div>
        <div *ngIf="eventScheduleOnPopUp?.doubleEvent" class="mb-3 col-6">
          <div class="inpt-title">Desired BU's/Room Capacity 2</div>
          <label class="lbl oneLineTextPopup">
            {{ eventScheduleOnPopUp?.desiredBUD2 }}
          </label>
        </div>
      </div>
      <div class="mb-3 col-12">
        <div class="inpt-title">Topic</div>
        <label class="lbl oneLineTextPopup">
          {{ eventScheduleOnPopUp?.topic }}
        </label>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Topic Notes</div>
        <label class="lbl oneLineTextPopup">
          {{ eventScheduleOnPopUp?.topicNotes }}
        </label>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Venue Specific Notes</div>
        <textarea
          class="inpt-lg inpt enableScrollInpt"
          id="venueSpecificNotes"
          [value]="eventScheduleOnPopUp?.venueSpecificNotes"
          type="text"
          [readonly]="true"
          placeholder="Venue Specific Notes"
          autocomplete="off"
          [disabled]="true"
        ></textarea>
      </div>
      <div class="mb-3 col-12">
        <div class="inpt-title">One Time Notes</div>
        <textarea
          class="inpt-lg inpt enableScrollInpt"
          [value]="eventScheduleOnPopUp?.notes"
          type="text"
          [readonly]="true"
          placeholder="One Time Notes"
          autocomplete="off"
          [disabled]="true"
        ></textarea>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Reminder Process</div>
        <label class="lbl oneLineTextPopup">
          {{ eventScheduleOnPopUp?.wfrReminder }}
        </label>
      </div>

      <h2>To do's / Actions</h2>
    </div>
    <div class="px-2">
      <h4>Process</h4>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="text-center">Date</th>
            <th class="w30per text-center" colspan="2">
              <span>Email Blasts</span>

              <div class="row">
                <div class="col-6">1st</div>
                <div class="col-6">2nd</div>
              </div>
            </th>
            <th class="text-center">Scheduled</th>
            <th class="w15per text-center">Few Spots Left</th>
            <th class="text-center">Scheduled</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <label class="dateLabelTable">
                {{ eventScheduleOnPopUp?.date | date : "M/d/yyyy" }}
              </label>
            </td>
            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ eventScheduleOnPopUp?.emailBlast1st }}"
              />
            </td>

            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ eventScheduleOnPopUp?.emailBlast2nd }}"
              />
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                disabled
                [checked]="eventScheduleOnPopUp?.emailBlastScheduled"
              />
            </td>
            <td>
              <div class="text-center">
                <input
                  class="inpt"
                  disabled
                  autocomplete="off"
                  placeholder="{{ eventScheduleOnPopUp?.fewSpots }}"
                />
              </div>
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                disabled
                [checked]="eventScheduleOnPopUp?.fewSpotsScheduled"
              />
            </td>
          </tr>
          <tr
            style="height: 60px !important"
            *ngIf="eventScheduleOnPopUp?.doubleEvent"
          >
            <td class="text-center">
              <label class="dateLabelTable">
                {{ eventScheduleOnPopUp?.date2 | date : "M/d/yyyy" }}
              </label>
            </td>
            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ eventScheduleOnPopUp?.emailBlast1stD2 }}"
              />
            </td>

            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ eventScheduleOnPopUp?.emailBlast2ndD2 }}"
              />
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                disabled
                [checked]="eventScheduleOnPopUp?.emailBlastD2Scheduled"
              />
            </td>
            <td>
              <div class="text-center">
                <input
                  class="inpt"
                  disabled
                  autocomplete="off"
                  placeholder="{{ eventScheduleOnPopUp?.fewSpotsD2 }}"
                />
              </div>
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                disabled
                [checked]="eventScheduleOnPopUp?.fewSpotsD2Scheduled"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <h4>Calls</h4>
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center">Date</th>

              <th class="text-center">WFR Call 1</th>
              <th class="text-center w18per">Assigned to</th>
              <th class="text-center">Done</th>
              <th class="text-center">WFR Call 2</th>
              <th class="text-center w18per">Assigned to</th>
              <th class="text-center">Done</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <label class="dateLabelTable oneLineTextPopup">
                  {{ eventScheduleOnPopUp?.formatedDate }}
                </label>
              </td>
              <td class="text-center">
                <label class="dateLabelTable oneLineTextPopup">
                  {{ eventScheduleOnPopUp?.wfrCall1Date | date : "M/d/yyyy" }}
                </label>
              </td>
              <td>
                <div class="form-floating mb-3">
                  <label class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.wfrCall1Funnel?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.wfrCall1Done"
                />
              </td>

              <td class="text-center">
                <label class="dateLabelTable oneLineTextPopup">
                  {{ eventScheduleOnPopUp?.wfrCall2Date | date : "M/d/yyyy" }}
                </label>
              </td>
              <td>
                <div class="form-floating mb-3">
                  <label class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.wfrCall2Funnel?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.wfrCall2Done"
                />
              </td>
            </tr>
            <tr *ngIf="eventScheduleOnPopUp?.doubleEvent">
              <td class="text-center">
                <label class="dateLabelTable oneLineTextPopup">
                  {{ eventScheduleOnPopUp?.formatedDate2 }}
                </label>
              </td>
              <td class="text-center">
                <label class="dateLabelTable oneLineTextPopup">
                  {{ eventScheduleOnPopUp?.wfrCall1D2Date | date : "M/d/yyyy" }}
                </label>
              </td>
              <td>
                <div class="form-floating mb-3">
                  <label class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.wfrCall1D2Funnel?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.wfrCall1D2Done"
                />
              </td>
              <td class="text-center">
                <label class="dateLabelTable oneLineTextPopup">
                  {{ eventScheduleOnPopUp?.wfrCall2D2Date | date : "M/d/yyyy" }}
                </label>
              </td>
              <td>
                <div class="form-floating mb-3">
                  <label class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.wfrCall2D2Funnel?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.wfrCall2D2Done"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>Set-Up</h4>
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center dateHeader">Date</th>

              <th
                class="bg-light-blue header-left text-white w18per text-center"
              >
                Funnel & Automation
              </th>
              <th class="bg-light-blue header-right text-white text-center">
                Done
              </th>
              <th
                class="bg-light-green header-left text-white w18per text-center"
              >
                QA by
              </th>
              <th class="bg-light-green header-right text-white text-center">
                QA Done
              </th>
              <th
                class="bg-light-blue header-left text-white w18per text-center"
              >
                Ads
              </th>
              <th class="bg-light-blue header-right text-white text-center">
                Done
              </th>
              <th
                class="bg-light-green header-left text-white w18per text-center"
              >
                QA by
              </th>
              <th class="bg-light-green header-right text-white text-center">
                QA Done
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 60px !important">
              <td
                class="text-center td-date"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <label class="dateLabelTable">
                  {{ eventScheduleOnPopUp?.date | date : "M/d/yyyy" }}
                </label>
              </td>

              <td
                class="w18per td-left"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnel?.name
                  }}</label>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelDone"
                />
              </td>
              <td
                class="w18per td-left"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelQAby?.name
                  }}</label>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelQADone"
                />
              </td>
              <td
                class="w18per td-left"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelAds?.name
                  }}</label>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelAdsDone"
                />
              </td>
              <td
                class="w18per td-left"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelAdsQAby?.name
                  }}</label>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!eventScheduleOnPopUp?.doubleEvent"
                [class.no-border-bottom]="eventScheduleOnPopUp?.doubleEvent"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelAdsQADone"
                />
              </td>
            </tr>
            <tr
              style="height: 60px !important"
              *ngIf="eventScheduleOnPopUp?.doubleEvent"
            >
              <td class="text-center date-bottom">
                <label class="dateLabelTable">
                  {{ eventScheduleOnPopUp?.date2 | date : "M/d/yyyy" }}
                </label>
              </td>

              <td class="w18per td-left-bottom">
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelSecond?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelSecondDone"
                />
              </td>
              <td class="w18per td-left-bottom">
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelSecondQAby?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelSecondQADone"
                />
              </td>
              <td class="w18per td-left-bottom">
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelSecondAds?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelSecondAdsDone"
                />
              </td>
              <td class="w18per td-left-bottom">
                <div class="w8vw form-floating mb-3">
                  <label id="clienttitle" class="lbl oneLineTextPopup">{{
                    eventScheduleOnPopUp?.funnelSecondAdsQAby?.name
                  }}</label>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  disabled
                  [checked]="eventScheduleOnPopUp?.funnelSecondAdsQADone"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h6>Total Ad Spent</h6>
      <div class="row">
        <div class="mb-3 col-6">
          <div class="inpt-title">Meta Ads spend</div>
          <label class="lbl oneLineTextPopup">{{
            eventScheduleOnPopUp?.metaAds
          }}</label>
        </div>
        <div class="mb-3 col-6">
          <div class="inpt-title">Other Ads spend</div>
          <label class="lbl oneLineTextPopup">{{
            eventScheduleOnPopUp?.otherAds
          }}</label>
        </div>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Ad Spent Notes</div>
        <label class="lbl oneLineTextPopup">{{
          eventScheduleOnPopUp?.adSpentNotes
        }}</label>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Paid</div>
        <label class="lbl oneLineTextPopup">{{
          eventScheduleOnPopUp?.payStats
        }}</label>
      </div>

      <div class="mb-3 col-12">
        <div class="inpt-title">Internal WFR reminder</div>
        <label class="lbl oneLineTextPopup">{{
          eventScheduleOnPopUp?.reminder
        }}</label>
      </div>
    </div>
  </div>
</div>
