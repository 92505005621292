<h2 class="mb-4" *ngIf="id" i18n="Update label">Update Event</h2>
<div *ngIf="post">
  <app-mwevent-form
    [editedMWEvent]="post"
    (MWEventSubmit)="updatePost($event)"
  ></app-mwevent-form>
  <app-error-alert
    title="Could not submit the post"
    i18n-title="Message post submit error alert"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
