<div
  class="reminderHover text-break text-center d-block colorBlack max-height600px pos-absolute"
  [style.top.px]="top"
  [style.left.px]="left"
>
  <div
    *ngFor="let status of StatusesOptions; let i = index"
    class="d-flex"
    style="min-width: 100px"
  >
    <div
      [class.gen-cont]="status.subMenu && status.subMenu.length"
      (click)="updateValue(status)"
      class="dropdown-menu-item option w-100"
    >
      {{ status.value }}
    </div>
  </div>

  <div (click)="updateValue()" class="dropdown-menu-item option w-100">
    Clear
  </div>
</div>
