import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { IFunnel } from './event-schedule.repository';

export const FunnelsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];

@Injectable({ providedIn: 'root' })
export class FunnelsRepository extends BaseRepository<IFunnel> {
  constructor() {
    super('FunnelsSortOptions', FunnelsSortOptions);
  }
}
