<div
  class="popup emojiFonts"
  [style.bottom.px]="bottom"
  [style.rigth.px]="right"
  #emojiContainer
>
  <emoji-mart
    [hidden]="!showEmojiPicker"
    showPreview="false"
    sheetSize="32"
    (emojiClick)="pickEmoji.emit($event)"
    showPreview="false"
    exclude="'flags'"
  ></emoji-mart>
</div>
