import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  IEventSchedule,
  IFunnel,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { FunnelsService } from 'src/app/state/funnels.service';

@Component({
  selector: 'app-change-assigner-popup',
  templateUrl: './change-assigner-popup.component.html',
  styleUrls: ['./change-assigner-popup.component.scss'],
})
export class ChangeAssignerPopupComponent implements OnInit {
  @Input() eventScheduleOnPopUp: IEventSchedule | null = null;
  @Input() editField: string = '';
  @Input() top: number = 0;
  @Input() left: number = 0;
  @Output() submit = new EventEmitter<IEventSchedule>();

  constructor(
    public repo: EventScheduleRepository,
    public service: EventScheduleService,
    public funnelService: FunnelsService
  ) {}

  ngOnInit(): void {
    this.funnelService.loadAllFunnels().subscribe((x) => {
      this.assignCalls = x;
    });
  }
  callersOptions: DatalistOption[] | null = null;
  @Input() set assignCalls(value: IFunnel[] | null) {
    if (!value) {
      this.callersOptions = null;
    } else {
      this.callersOptions = value.map((x) => ({
        value: x.id,
        label: x.name ?? '',
      }));
    }
  }
  updateValue($event?: DatalistOption) {
    if (this.eventScheduleOnPopUp?.id && $event?.value && this.editField)
      this.service
        .updateFunnelForEvent(
          this.eventScheduleOnPopUp?.id,
          this.editField,
          $event?.value
        )
        .subscribe((x) => {
          this.submit.emit(x);
        });

    //   if (this.eventScheduleOnPopUp && $event) {
    //     this.service
    //       .changePayStats(this.eventScheduleOnPopUp.id, $event.value)
    //       .subscribe((x) => this.submit.emit($event));
    //   } else if (!$event && this.eventScheduleOnPopUp) {
    //     this.service
    //       .changePayStats(this.eventScheduleOnPopUp.id, 'clear')
    //       .subscribe((x) => this.submit.emit({ label: 'clear', value: 'clear' }));
    //   }
  }
}
