import { Component, Input } from '@angular/core';
import { IMonthWithEvents } from 'src/app/state/event-schedule.repository';
import { StatisticElement } from 'src/app/state/event.repository';

@Component({
  selector: 'app-statistics-grid',
  templateUrl: './statistics-grid.component.html',
  styleUrls: ['./statistics-grid.component.scss'],
})
export class StatisticsGridComponent {
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  @Input() statistics: StatisticElement[] = [];
}
