import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { IMWEvent, MWEventsRepository } from 'src/app/state/mwevent.repository';
import { MWEventService } from 'src/app/state/mwevent.service';

@Component({
  selector: 'app-mwevent-post',
  templateUrl: './mwevent-post.component.html',
  styleUrls: ['./mwevent-post.component.scss'],
})
export class MweventPostComponent implements OnInit {
  mwevent: IMWEvent | null = null;
  post$!: Observable<IMWEvent>;
  submitErrors: string[] | null = null;

  constructor(
    private service: MWEventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: MWEventsRepository
  ) {}

  ngOnInit(): void {}

  post($event: Partial<IMWEvent>) {
    this.submitErrors = null;
    let updateResult: Observable<IMWEvent> | undefined;
    updateResult = this.service.add($event);
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['admin/mwevents']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
