<div class="large mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <button
        (click)="pageChange.emit(page - 1)"
        [disabled]="page === 1"
        [class.text-muted]="page === 1"
        class="btn button-back"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8334 7.00033H1.16675M1.16675 7.00033L7.00008 12.8337M1.16675 7.00033L7.00008 1.16699"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span *ngIf="innerWidth > 1050" class="txt-bold">Previous</span>
      </button>
    </div>

    <div class="only-large" role="group">
      <button
        *ngFor="let pageNumber of calculatePages()"
        class="btn"
        class="button-page"
        [class.current-button-page]="page === pageNumber"
        (click)="pageChange.emit(pageNumber)"
      >
        {{ pageNumber }}
      </button>
    </div>
    <div class="">
      <button
        class="btn button-next"
        (click)="pageChange.emit(page + 1)"
        [disabled]="page === pages.length || pages.length === 0"
        [class.text-muted]="page === pages.length"
      >
        <span class="txt-bold">Next</span
        ><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16675 7.00033H12.8334M12.8334 7.00033L7.00008 1.16699M12.8334 7.00033L7.00008 12.8337"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</div>

<div class="small">
  <div class="d-flex">
    <div class="me-2">
      <button
        class="btn button-back"
        (click)="pageChange.emit(page - 1)"
        [disabled]="page === 1"
        [class.text-muted]="page === 1"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8334 7.00033H1.16675M1.16675 7.00033L7.00008 12.8337M1.16675 7.00033L7.00008 1.16699"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div class="ms-2">
      <button
        class="btn button-next"
        (click)="pageChange.emit(page + 1)"
        [disabled]="page === pages.length"
        [class.text-muted]="page === pages.length || pages.length === 0"
      >
        <span class="txt-bold">Next</span
        ><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16675 7.00033H12.8334M12.8334 7.00033L7.00008 1.16699M12.8334 7.00033L7.00008 12.8337"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
