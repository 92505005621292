import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';
import { ITopic, IWebContent } from 'src/app/state/event-schedule.repository';
import { TopicsService } from 'src/app/state/topics.service';

@Component({
  selector: 'app-custom-template-page',
  templateUrl: './custom-template-page.component.html',
  styleUrls: ['./custom-template-page.component.scss'],
})
export class CustomTemplatePageComponent implements OnInit {
  data: ITopic | null = null;
  customContent?: IWebContent;
  eventId: string | null = null;
  dataChanged = false;
  deleteRequested = false;
  constructor(
    public service: TopicsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.eventId = value.get('id');
        this.loadData();
      },
    });
  }

  loadData() {
    if (this.eventId)
      this.service.loadByEventId(this.eventId).subscribe((x) => {
        this.data = x;
        this.customContent = x.webContent;
      });
  }

  dataCheck(updatedContent: IWebContent) {
    this.customContent = updatedContent;
    this.dataChanged = !isEqual(this.customContent, this.data?.webContent);
  }

  saveContent() {
    if (this.data && this.customContent && this.eventId) {
      if (!this.customContent.eventId) {
        this.customContent.id = undefined;
        this.customContent.reminderContent?.forEach((x) => (x.id = undefined));
      }
      this.customContent.eventId = this.eventId;
    }
    if (this.dataChanged || this.customContent?.eventId) {
      this.service
        .updateCustomContent(this.customContent ?? {})
        .subscribe(() => this.router.navigate(['/eventSchedules']));
    } else {
      this.router.navigate(['/eventSchedules']);
    }
  }

  removeCustomContent() {
    this.deleteRequested = false;
    this.service
      .removeCustomContent(this.eventId!)
      .subscribe((x) => (this.data = x));
  }
}
