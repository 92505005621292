import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { UserRoles } from '../state/auth.repository';
import { MweventComponent } from '../modules/mwevents/mwevent/mwevent.component';
import { MweventPostComponent } from '../modules/mwevents/mwevent-post/mwevent-post.component';
import { MweventEditComponent } from '../modules/mwevents/mwevent-edit/mwevent-edit.component';
import { MweventAdminComponent } from '../modules/mwevents/mwevent-admin/mwevent-admin.component';
import { MweventUserComponent } from '../modules/mwevents/mwevent-user/mwevent-user.component';
import { MweventslistComponent } from '../modules/mwevents/mweventslist/mweventslist.component';
import { TenantFeatures } from '../state/feature.repository';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'mwevents',
        component: MweventComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Collaborations,
        },
      },
      {
        path: 'admin/mwevents/new',
        component: MweventPostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Collaborations,
        },
      },
      {
        path: 'admin/mwevents/edit/:id',
        component: MweventEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Collaborations,
        },
      },
      {
        path: 'admin/mwevents',
        component: MweventAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Collaborations,
        },
      },
      {
        path: 'mwevents/:id',
        component: MweventUserComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.User, UserRoles.TenantAdmin],
          feature: TenantFeatures.Collaborations,
        },
      },
      {
        path: 'ideasrating/:id',
        component: MweventslistComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Collaborations,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MWEventsRoutingModule {}
