import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';
import { NewsEditComponent } from './pages/news-edit/news-edit.component';
import { NewsFormComponent } from './components/news-form/news-form.component';
import { NewsPostComponent } from './pages/news-post/news-post.component';
import { NewsComponent } from './pages/news/news.component';
import { FullCalendarModule } from '@fullcalendar/angular';

import { MatDialogModule } from '@angular/material/dialog';
import { NewsAdminComponent } from './pages/news-admin/news-admin.component';
import { NewsInfoComponent } from './pages/news-info/news-info.component';
import { NewsTabComponent } from './components/news-tab/news-tab.component';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [
    NewsEditComponent,
    NewsFormComponent,
    NewsComponent,
    NewsPostComponent,
    NewsInfoComponent,
    NewsAdminComponent,
    NewsTabComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    FullCalendarModule,
    MatDialogModule,
    CoreModule,
  ],

  exports: [NewsEditComponent, NewsComponent, NewsPostComponent],
})
export class NewsModule {}
