import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';
import {
  withEntities,
  selectAllEntities,
  deleteAllEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
export const CompanyStatusesOptions: DatalistOption[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Disabled', value: 'Disabled' },
];

export const AssignerInfosOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

const { state, config } = createState(
  withEntities<AssignCall>(),
  withRequestsStatus(),
  withRequestsCache()
);
export interface AssignCall {
  id: string;
  funnelName?: string;
  firstCalls?: number;
  secondCalls?: number;
  total?: number;
}
const assignerInfosStore = new Store({ name: 'assignerInfos', state, config });

@Injectable({ providedIn: 'root' })
export class AssignerInfoRepository extends BaseRepository<AssignCall> {
  constructor() {
    super('AssignerInfosOptions', AssignerInfosOptions);
  }
  assignerInfosStore$ = assignerInfosStore.pipe(selectAllEntities());

  updateAssignerInfos(assignerInfos: AssignCall[]) {
    assignerInfosStore.update(deleteAllEntities());
    assignerInfosStore.update(upsertEntities(assignerInfos));
  }
}
