<div
  class="modal fade"
  id="creds2faPop"
  tabindex="-1"
  aria-labelledby="creds2faPopLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="creds2faPopLabel">Modal title</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="box">
          <h2>Step 1</h2>
          <p>
            Using the Google Authenticator app, you should scan the following QR
            code:
          </p>
          <qrcode
            [qrdata]="Tfa?.formattedKey ?? ''"
            [allowEmptyString]="true"
          ></qrcode>
          <p>
            Alternatively, you may enter the following code to the Google
            Authenticator app:
          </p>
          <pre>{{ Tfa?.authenticatorKey }}</pre>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
