import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvState } from '../../shared/helpers/env-state';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(private env: EnvState) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let requestUrl = request.url;
    if (!requestUrl.startsWith('/')) {
      requestUrl = `/${requestUrl}`;
    }
    const apiReq = request.clone({ url: `${this.env.apiUrl}${request.url}` });
    return next.handle(apiReq);
  }
}
