import { Component, Input } from '@angular/core';
import { FunnelStat } from 'src/app/state/event.repository';

@Component({
  selector: 'app-callers-stats-table',
  templateUrl: './callers-stats-table.component.html',
  styleUrls: ['./callers-stats-table.component.scss'],
})
export class CallersStatsTableComponent {
  @Input() FunnelStats?: FunnelStat[] = [];
}
