<div class="row">
  <h1 class="mb-4 col" i18n="Create event topic label">
    Custom Web-Content Edit
  </h1>
  <div class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex me-3">
      <a
        routerLink="/eventSchedules"
        class="d-flex justify-content-center btn dark-wfrBlue text-white"
        style="
          justify-content: end;
          width: 140px !important;
          height: 40px !important;
        "
      >
        <img src="assets/img/backArrow.svg" />
        <span class="ms-2">Back</span>
      </a>
    </div>
  </div>
</div>
<app-landing-page-form
  [dataset]="data?.webContent"
  [topic]="data?.name ?? ''"
  (landingPageData)="dataCheck($event)"
  [forDinnerLunch]="data?.eventType == 'lunchDinnerType'"
  [forWorkshop]="data?.eventType == 'workshopsSeminarType'"
/>
<div class="d-flex w-100 pb-3">
  <button
    class="col-6 btn btn-danger me-2"
    *ngIf="data?.webContent?.eventId"
    (click)="deleteRequested = true"
  >
    Remove Custom Content
  </button>
  <button class="btn btn-wfrBlue" style="flex-grow: 1" (click)="saveContent()">
    Save {{ dataChanged || data?.webContent?.eventId ? "Custom Content" : "" }}
  </button>
</div>
<app-confirm-delete
  *ngIf="deleteRequested"
  [deleteCandidate]="'Custom content for ' + data?.name"
  (confirm)="removeCustomContent()"
  (cancel)="deleteRequested = false"
/>
