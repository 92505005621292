<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">
      <div class="inpt-title">Title</div>
      <input
        id="name"
        formControlName="name"
        type="text"
        class="inpt"
        placeholder="Title"
        i18n-placeholder="@@title"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating d-flex align-items-center"
      [appValidateState]="editForm.get('isShownOnSideMenu')"
    >
      <div>
        <label class="toggle">
          <input
            class="toggle-checkbox"
            type="checkbox"
            formControlName="isShownOnSideMenu"
          />
          <div class="toggle-switch"></div>
        </label>
      </div>
      <div class="ms-2">
        <label>Show on SideBar</label>
      </div>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
      Save
    </button>
  </div>
</form>
