import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { NewsEditComponent } from '../modules/news/pages/news-edit/news-edit.component';
import { NewsPostComponent } from '../modules/news/pages/news-post/news-post.component';
import { NewsComponent } from '../modules/news/pages/news/news.component';
import { UserRoles } from '../state/auth.repository';

import { NewsAdminComponent } from '../modules/news/pages/news-admin/news-admin.component';

import { NewsInfoComponent } from '../modules/news/pages/news-info/news-info.component';
import { TenantFeatures } from '../state/feature.repository';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'posts',
        component: NewsComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.News,
        },
      },
      {
        path: 'admin/posts/edit/:id',
        component: NewsEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.News,
        },
      },
      {
        path: 'admin/posts/new',
        component: NewsPostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.News,
        },
      },
      {
        path: 'posts/:id',
        component: NewsInfoComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.News,
        },
      },

      {
        path: 'admin/posts',
        component: NewsAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.News,
        },
      },
    ],
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    canActivateChild: [AuthGuard],

    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule {}
