import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import {
  IWhistleblow,
  WhistleblowRepository,
} from 'src/app/state/whistleblow.repository';
import { WhistleblowService } from 'src/app/state/whistleblow.service';
import { FormBuilder } from '@angular/forms';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-whistleblow-info',
  templateUrl: './whistleblow-info.component.html',
  styleUrls: ['./whistleblow-info.component.scss'],
})
export class WhistleblowInfoComponent implements OnInit {
  post$: Observable<IWhistleblow> | null = null;
  post: IWhistleblow | null = null;

  id!: string;
  submitErrors: string[] | null = null;

  constructor(
    private service: WhistleblowService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: WhistleblowRepository,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.post$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.service.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
    this.loadWhistleblow(this.id);
  }

  loadWhistleblow(id: string) {
    this.service.customLoadOne(id).subscribe((x) => {
      this.post = x;
    });
  }
}
