import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthRepository, TfaSetup } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';

@Component({
  selector: 'app-gtfa-qrcode',
  templateUrl: './gtfa-qrcode.component.html',
  styleUrls: ['./gtfa-qrcode.component.scss'],
})
export class GtfaQrcodeComponent {
  tfaForm: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required]),
  });
  @Input() Tfa?: TfaSetup;
  @Output() Back = new EventEmitter<void>();
  showError: boolean = false;
  errorMessage: string = '';

  constructor(
    public authRepo: AuthRepository,
    public authService: AuthService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  validateControl(controlName: string) {
    return (
      this.tfaForm.get(controlName)?.invalid &&
      this.tfaForm.get(controlName)?.touched
    );
  }

  hasError(controlName: string, errorName: string) {
    return this.tfaForm.get(controlName)?.hasError(errorName);
  }

  loginUser(tfaFormValue: any) {
    const tfaForm = { ...tfaFormValue };
    const TfaSetup: TfaSetup = {
      email: this.Tfa?.email ?? '',
      code: tfaForm.code,
    };
    this.authService.loginUserTfa(TfaSetup).subscribe({
      next: (res: any) => {
        if (res?.token) {
          this.authRepo.setToken(res.token);
          this.router.navigate(['home']);
        } else {
          this.showErrorAlert('No active token');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showErrorAlert(err.error);
      },
    });
  }
  enableTfa(tfaFormValue: any) {
    const tfaForm = { ...tfaFormValue };
    const TfaSetup: TfaSetup = {
      email: this.Tfa?.email ?? '',
      code: tfaForm.code,
    };
    this.authService.postTfaSetup(TfaSetup).subscribe({
      next: (res: any) => {
        if (res?.token) {
          this.authRepo.setToken(res.token);
          this.router.navigate(['home']);
        } else {
          this.showErrorAlert('No active token');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showErrorAlert(err.error);
      },
    });
  }
  showErrorAlert(errorMessage: string, active: boolean = true) {
    this.showError = active;
    this.errorMessage =
      'Two-factor authentication was not activated for this account (Message: ' +
      errorMessage +
      ')';
  }
}
