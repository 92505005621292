import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { BaseRepository } from './abstract/base.repository';
import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';

export interface IApv {
  id: string;
  apvId: string;
  apvTitle: string;
  title: string;
  description: string;
  createdAt: Date;
  apvQuestions: IQuestion[];
  userEmail?: string | null;
}

export interface IQuestion {
  id: string;
  title: string;
  answer: boolean | null;
}

const { state, config } = createState(
  withEntities<IApv>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'posts', state, config });
export const trackPostRequestsStatus = createRequestsStatusOperator(store);
export const skipWhilePostsCached = createRequestsCacheOperator(store);

export const apvSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class ApvRepository extends BaseRepository<IApv> {
  name = store.name;

  posts$ = store.pipe(selectAllEntities());
  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: IApv['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setPosts(posts: IApv[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: IApv) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }

  constructor() {
    super('apvs', apvSortOptions);
  }
}
