<div class="row page-header d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row">
    <div class="col-6 mt-sm-4 mb-sm-3 p-0">
      <h2 class="page-title" i18n="Label users">User list</h2>
    </div>
    <div
      *ngIf="(auth.isAnyAdmin$ | async) && innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <a
        routerLink="/users/new"
        class="d-flex justify-content-between btn btn-lg dark-wfrBlue text-white"
      >
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00008 1.66699V13.3337M1.16675 7.50033H12.8334"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text-awesome">Add user</span>
      </a>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="service.sort($event).subscribe()"
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <app-users-list
      class="sm-m p-0 mb-2 m-0"
      style="width: 100%"
      [innerWidth]="innerWidth"
      [users]="repo.page$ | async"
      [tenants]="tenantRepo.tenants$ | async"
      [isDeleteDisabled]="!(auth.isSuperAdmin$ | async)"
      [page]="(repo.pageNumber$ | async) || 1"
      [total]="(repo.paginationData$ | async)?.lastPage || 0"
      (pageChange)="service.loadFilterPage(searchFilter, $event).subscribe()"
      (delete)="handleDeleteClick($event)"
    ></app-users-list>
  </div>
</div>

<!--<div class="row mb-4 d-flex justify-content-center">
  <div class="col-xl-8 col-lg-10 col-12">
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <app-users-list [innerWidth]="innerWidth"
                    [users]="repo.page$ | async"
                    [tenants]="tenantRepo.tenants$ | async"
                    [isDeleteDisabled]="!(auth.isSuperAdmin$ | async)"
                    (delete)="service.delete($event).subscribe()"></app-users-list>
  </div>-->

<!-- <div class="row mb-4">
    <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
      <app-pagination [page]="(repo.pageNumber$ | async) || 1"
        [total]="(repo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="service.loadPage($event).subscribe()"></app-pagination>
    </div>
  </div> -->
<!--</div>-->

<!-- <div class="col-12 col-md-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions"
      [sorting]="repo.sort$ | async"
      (sortingChange)="service.sort($event).subscribe()"></app-sort-dropdown>
  </div> -->

<!-- <app-import-export *ngIf="auth.isTenantAdmin$ | async" class="me-2"
      filenamePrefix="users"
      [importFactory]="importFactory"
      [exportFactory]="exportFactory"></app-import-export> -->
