import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEventSchedule } from '../../../../state/event-schedule.repository';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import {
  ISubscriber,
  SubscriberSortOptions,
  SubscribersRepository,
} from '../../../../state/subscribers.repository';
import { SubscribersService } from '../../../../state/subscribers.service';

@Component({
  selector: 'app-admin-subscribers-page',
  templateUrl: './admin-subscribers-page.component.html',
  styleUrls: ['./admin-subscribers-page.component.scss'],
})
export class AdminSubscribersPageComponent {
  innerWidth = 0;
  searchFilter: string = '';
  sortOptions = SubscriberSortOptions;
  Subscriber?: ISubscriber;
  deleteConfirmation: ISubscriber | null = null;
  id: string | null = null;
  eventSchedule: IEventSchedule | null = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    public repo: SubscribersRepository,
    public service: SubscribersService,
    public eventScheduleService: EventScheduleService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        if (this.id) {
          this.service
            .reloadPageCustom(this.searchFilter, undefined, this.id)
            .subscribe();
          this.eventScheduleService
            .loadOneEventSchedule(this.id)
            .subscribe((x) => (this.eventSchedule = x));
        }
      },
    });
  }
  refreshData() {
    this.service
      .reloadPageCustom(
        this.searchFilter,
        undefined,
        this.id ? this.id : undefined
      )
      .subscribe();
  }
  handleDeleteClick(post: ISubscriber) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service
      .searchRelodePageCustom(
        this.searchFilter,
        undefined,
        this.id ? this.id : undefined
      )
      .subscribe();
  }
}
