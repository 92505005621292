import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, tap } from 'rxjs';
import { sortBy, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { IDocumentType } from './documenttype.repository';
import { IFolder } from './folders.repository';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';
import { IContact } from './companies.repository';

export const ContactsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
  { label: $localize`:Sort label Email:Email`, property: 'Email' },
];

@Injectable({ providedIn: 'root' })
export class ContactsRepository extends BaseRepository<IContact> {
  constructor() {
    super('ContactsSortOptions', ContactsSortOptions);
  }
}
