<form
  [formGroup]="ApvForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="ApvForm.get('title')"
  >
    <div class="inpt-title">Title</div>
    <input
      id="title"
      formControlName="title"
      type="text"
      class="inpt"
      placeholder="Title"
      i18n-placeholder="@@title"
      autocomplete="off"
    />
  </div>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="ApvForm.get('description')"
  >
    <div class="inpt-title">Description</div>
    <textarea
      id="description"
      formControlName="description"
      type="text"
      class="inpt inpt-lg"
      placeholder="Description"
      i18n-placeholder="@@description"
      autocomplete="off"
    ></textarea>
  </div>

  <div class="col-12 col-md-6 col-lg-4">
    <div class="row">
      <div class="col-auto d-flex align-items-end">
        <div class="form-group form-floating pb-3 d-grid mr-3">
          <button
            type="button"
            (click)="addNewApvQuestion()"
            class="btn btn-md-lg btn-wfrBlue shadow-sm"
            i18n="Add button @@addQuestion"
          >
            <i class="bi bi-plus"></i> Add Question
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3" formArrayName="apvQuestions">
    <div class="" *ngFor="let item of getControls(); let i = index">
      <div class="row mb-2">
        <div class="col">
          <h6 class="my-1" i18n="Basic info label">Question {{ i + 1 }}</h6>
        </div>
      </div>
      <div class="row mb-2">
        <div [formGroupName]="i" class="col-12 col-md-6 col-lg-8">
          <div
            class="form-group form-floating mb-3"
            [class.is-invalid]="
              item.get('title')?.hasError('required') &&
              item.get('title')?.touched
            "
          >
            <div class="inpt-title">Title</div>
            <input
              formControlName="title"
              type="text"
              class="inpt"
              placeholder="Title"
              i18n-placeholder="Title placeholder @@title"
            />
            <!--<label for="Title" i18n="Title">Title</label>-->
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto d-flex align-items-end mb-2">
              <i class="btn btn-danger" (click)="deleteQuestion(i)">Delete</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
      Save
    </button>
  </div>
</form>
