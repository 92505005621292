import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  IPageProperties,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-admin-submits-page',
  templateUrl: './admin-submits-page.component.html',
  styleUrls: ['./admin-submits-page.component.scss'],
})
export class AdminSubmitsPageComponent {
  constructor(
    public repo: EventScheduleRepository,
    public service: EventScheduleService
  ) {}
  searchFilter: string = '';
  Date: dayjs.Dayjs = dayjs();
  pageProperties: IPageProperties = {} as IPageProperties;

  yearsOptions: DatalistOption[] | null = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];

  selectedYear: number | null = null;
  @Input() set years(value: number[] | null) {
    if (!value) {
      this.yearsOptions = null;
    } else {
      this.yearsOptions = value.map((x) => ({
        value: x,
        label: `${x}`,
      }));
    }
  }
  refreshData() {
    this.service.getPendingEventsCount().subscribe();
    this.service.loadYears(false, true, false).subscribe((y) => {
      this.years = y;
      this.service.getPageProperties().subscribe((x) => {
        this.pageProperties = x;

        this.selectedYear = x.year ?? this.Date.year();

        this.service
          .reloadMonths(
            this.selectedYear,
            undefined,
            undefined,
            false,
            false,
            true
          )
          .subscribe();
      });
    });
  }

  ngOnInit(): void {
    this.refreshData();
  }
  reloadMonths() {
    this.service
      .reloadMonths(
        this.selectedYear ?? this.pageProperties.year,
        undefined,
        undefined,
        false,
        false,
        true
      )
      .subscribe();
  }
  setYear(value: number) {
    this.service
      .reloadMonths(value, undefined, undefined, false, false, true)
      .subscribe();
  }

  setActiveYear(year: number | null) {
    if (this.yearsOptions && this.yearsOptions.length) {
      var yearFromOptions = this.yearsOptions.find(
        (x) => x.value == year
      )?.value;
      if (year && yearFromOptions) {
        this.selectedYear = yearFromOptions;
      } else {
        var currentYearFromOptions = this.yearsOptions.find(
          (x) => x.value == this.Date?.year()
        )?.value;
        if (currentYearFromOptions) {
          this.selectedYear = this.Date?.year();
        } else
          this.selectedYear =
            this.yearsOptions[Math.max(this.yearsOptions.length - 1, 0)].value;
      }
    }
  }
  expand(id: string) {
    this.repo.months$.subscribe((x) => {
      let mon = x.find((x) => x.id == id);
      if (mon) {
        mon!.expanded = !mon.expanded;
        let monthFromProp = this.pageProperties.monthStates.find((x) =>
          mon!.name.includes(x.name)
        );
        if (monthFromProp) {
          this.pageProperties.monthStates.find((x) =>
            mon!.name.includes(x.name)
          )!.expanded = mon.expanded;

          this.service.setPageProperties(this.pageProperties).subscribe();
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.repo.clearMonths();
  }
}
