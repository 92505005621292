<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row mb-2">
    <div class="col">
      <h6 class="my-1" i18n>Change password</h6>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div
        [appValidateState]="form.get('currentPassword')"
        class="form-group form-floating mb-3"
      >
        <input
          formControlName="currentPassword"
          type="password"
          class="form-control"
          placeholder="Current password"
          required
          i18n-placeholder="Current password"
        />
        <label for="currentPassword" i18n="Current password"
          >Current password</label
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        [appValidateState]="form.get('password')"
        class="form-group form-floating mb-3"
      >
        <input
          formControlName="password"
          type="password"
          class="form-control"
          placeholder="New password"
          required
          i18n-placeholder="New password"
        />
        <label for="password" i18n="New password">New password</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="@@update">
          Update
        </button>
      </div>
    </div>
  </div>
</form>
