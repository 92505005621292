import { Component, HostListener } from '@angular/core';
import { IFunnel } from '../../../../state/event-schedule.repository';
import {
  FunnelsRepository,
  FunnelsSortOptions,
} from '../../../../state/funnels.repository';
import { FunnelsService } from '../../../../state/funnels.service';

@Component({
  selector: 'app-admin-funnel-page',
  templateUrl: './admin-funnel-page.component.html',
  styleUrls: ['./admin-funnel-page.component.scss'],
})
export class AdminFunnelPageComponent {
  innerWidth = 0;
  searchFilter: string = '';
  sortOptions = FunnelsSortOptions;
  funnel?: IFunnel;
  deleteConfirmation: IFunnel | null = null;
  deleteError: string | null = null;
  constructor(public repo: FunnelsRepository, public service: FunnelsService) {}
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
    //this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
  }
  handleDeleteClick(post: IFunnel) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.clearError();
        this.refreshData();
      },
      error: (data) => (this.deleteError = data),
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter, 25).subscribe();
  }
  clearError() {
    this.deleteError = null;
  }
}
