<div class="row">
  <h1 class="mb-4 col" i18n="Create event topic label">
    Duplicate event Template
  </h1>
  <div class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex me-3">
      <a
        routerLink="/admin/topics"
        class="d-flex justify-content-center btn dark-wfrBlue text-white"
        style="
          justify-content: end;
          width: 140px !important;
          height: 40px !important;
        "
      >
        <img src="assets/img/backArrow.svg" />
        <span class="ms-2">Back</span>
      </a>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-12">
    <h2 *ngIf="dataLoading; else mainContent"><app-spinner></app-spinner></h2>
    <ng-template #mainContent>
      <app-topics-editform
        [editedTopic]="topic"
        (TopicSubmit)="createTopic($event)"
      ></app-topics-editform>
    </ng-template>
  </div>
</div>
