<div class="form-group form-floating ps-0 pe-0">
  <div *ngIf="label" class="ps-0 pe-0 inpt-title" [attr.for]="id">
    {{ label }}
  </div>
  <ng-select
    class="ps-0 pe-0"
    [labelForId]="id"
    [multiple]="multiple"
    [(ngModel)]="activeValue"
    (ngModelChange)="activeValueChange.emit($event)"
  >
    <ng-option class="" *ngFor="let option of options" [value]="option.value">
      <img
        *ngIf="option.imagePath"
        width="18"
        height="18"
        class="rounded-circle"
        style="margin-bottom: 2px"
        src=" {{ option.imagePath }} "
      />
      <img
        *ngIf="!option.imagePath"
        width="16"
        height="16"
        class="rounded-circle"
        style="margin-bottom: 2px"
        src=" {{ DEFAULT_IMAGE }}  "
      />

      <span class="me-1">
        {{ option.label }}
      </span>

      <br />
      <span class="small text-muted">{{ option.sublabel }}</span>
    </ng-option>
  </ng-select>
</div>
