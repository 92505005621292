import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITopic } from 'src/app/state/event-schedule.repository';
import { TopicsService } from 'src/app/state/topics.service';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-topic-duplicate-page',
  templateUrl: './topic-duplicate-page.component.html',
  styleUrls: ['./topic-duplicate-page.component.scss'],
})
export class TopicDuplicatePageComponent implements OnInit {
  id?: string;
  topic?: ITopic;
  dataLoading = true;
  constructor(
    private service: TopicsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiRepository
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id !== 'new') {
          this.service.loadForDuplicate(`${value.get('id')}`).subscribe((x) => {
            this.topic = x;
            this.dataLoading = false;
          });
        }
      },
    });
  }

  createTopic(dto: Partial<ITopic>) {
    this.service.postTopic(dto).subscribe({
      complete: () => {
        this.router.navigate(['/admin/topics']);
      },
      error: (data) => {
        this.ui.openSnackBar(data.error);
      },
    });
  }
}
