import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRepository } from '../../../state/auth.repository';
import { AuthService } from '../../../state/auth.service';
import {
  IProject,
  ITask,
  TimelogsRepository,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';
import { UiRepository } from '../../../state/ui.repository';
import { UsersRepository } from '../../../state/users.repository';
import { UsersService } from '../../../state/users.service';
import { EnvState } from '../../shared/helpers/env-state';

@Component({
  selector: 'app-timelogs-edit',
  templateUrl: './timelogs-edit.component.html',
  styleUrls: ['./timelogs-edit.component.scss'],
})
export class TimelogsEditComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public usersRepo: UsersRepository,
    private usersservice: UsersService,
    public service: TimelogsService,
    public repository: TimelogsRepository,
    private auth: AuthService,
    public repo: AuthRepository,
    public ui: UiRepository,
    private router: Router,
    private route: ActivatedRoute,
    public env: EnvState
  ) {}
  project: IProject | null = null;
  task: ITask | null = null;
  type: string | null = null;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type');
        if (this.type == 'project') {
          this.service.loadOneProject(`${value.get('id')}`).subscribe((x) => {
            this.project = x;
          });
        } else if (this.type == 'task') {
          this.service.loadOneTask(`${value.get('id')}`).subscribe((x) => {
            this.task = x;
          });
        }
      },
    });
  }
}
