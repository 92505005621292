<div
  class="reminderHover text-break text-center"
  [style.top.px]="top"
  [style.left.px]="left"
  style="display: block; position: absolute; max-height: 600px; z-index: 99999"
  (mouseleave)="cancelConfirmation ? '' : mouseLeave.emit()"
  *ngIf="!cancelConfirmation"
>
  <div
    *ngFor="
      let status of hasFewSpotsSort && isMonthStatus
        ? OptionsWithFewSpots
        : StatusesOptions;
      let i = index
    "
    class="d-flex"
    style="min-width: 100px"
  >
    <div
      [class.gen-cont]="status.subMenu && status.subMenu.length"
      (click)="updateValue(status)"
      class="dropdown-menu-item option w-100"
    >
      {{ status.value }}
    </div>
    <div class="submenu-container" *ngIf="isMonthStatus">
      <span
        style="min-width: 100px"
        *ngFor="let subStat of status.subMenu; let j = index"
        class="dropdown-menu-item option w-100"
        (click)="updateValue(status, j + 1)"
      >
        {{ subStat.label }}
      </span>
    </div>
  </div>

  <div
    (click)="updateValue()"
    *ngIf="isMonthStatus"
    class="dropdown-menu-item option w-100"
  >
    Clear Filter
  </div>
</div>
<app-confirm-event-cancel
  *ngIf="cancelConfirmation"
  (confirm)="
    updateValue(cancelConfirmation.$event, cancelConfirmation.sublabelNum)
  "
  (cancel)="mouseLeave.emit(); cancelConfirmation = null"
/>
