<div
  (mouseleave)="mouseLeave.emit()"
  class="commActionPos"
  [style.top.px]="top"
  [style.left.px]="left"
>
  <div class="acContainer p-2">
    <div class="acOption" (click)="edit.emit()">Edit</div>
    <div class="acOption" (click)="delete.emit()">Delete</div>
  </div>
</div>
