import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IRoom } from '../../../../state/event-schedule.repository';
import { LocationsService } from '../../../../state/locations.service';

@Component({
  selector: 'app-rooms-editform',
  templateUrl: './rooms-editform.component.html',
  styleUrls: ['./rooms-editform.component.scss'],
})
export class RoomsEditformComponent {
  @Input() editedRoom?: IRoom | null;

  @Output() RoomSubmit = new EventEmitter<Partial<IRoom>>();
  editForm: UntypedFormBuilder | any;
  submitErrors: string[] | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public locationsService: LocationsService
  ) {}
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedRoom?.name, Validators.required],
      parking: [this.editedRoom?.parking],
      desiredBUCapasity: [this.editedRoom?.desiredBUCapasity],
    });
  }
  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    const name = this.editForm.value?.name?.toString();
    const parking = this.editForm.value?.parking?.toString();
    const desiredBUCapasity =
      this.editForm.value?.desiredBUCapasity?.toString();

    this.RoomSubmit.emit({ name, parking, desiredBUCapasity });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
