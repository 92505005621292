import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { EventInfosRepository, IEventInfo } from './event-infos.repository';
import {
  AeStatsFilter,
  IEventsWithStats,
  QuaterEs,
  QuatersEsRepository,
} from './quaters.repository';
import { tap } from 'rxjs';
import { IEventSchedule } from './event-schedule.repository';

const API = '/api/eventschedule';

@Injectable({ providedIn: 'root' })
export class QuatersEsService extends BaseService<IEventSchedule> {
  constructor(http: HttpClient, public repo: QuatersEsRepository) {
    super(API, http, repo);
  }
  getAeStatistics(filter: AeStatsFilter) {
    return this.http.put<IEventsWithStats>(`${API}/aestats`, filter).pipe(
      tap((res: IEventsWithStats) => {
        this.repo.updateAeSummary(res?.aeSummary);
        this.repo.updateBestPerforming(res?.bestPerformingEventsSummary);
        this.repo.updateEvents(res?.events ?? []);
      })
    );
  }
}
