import { Component, Input, OnInit } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  IEventSchedule,
  IFunnel,
  IMonthWithEvents,
  MonthsOptions,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { FunnelsService } from 'src/app/state/funnels.service';

@Component({
  selector: 'app-upcoming-calls',
  templateUrl: './upcoming-calls.component.html',
  styleUrls: ['./upcoming-calls.component.scss'],
})
export class UpcomingCallsComponent implements OnInit {
  constructor(
    public service: EventScheduleService,
    public repo: EventScheduleRepository,
    private funnelsService: FunnelsService
  ) {}
  TeamsOptions: DatalistOption[] | null = null;
  TeamSelected: string | null = null;
  @Input() set funnels(value: IFunnel[] | null) {
    if (!value) {
      this.TeamsOptions = null;
    } else {
      this.TeamsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }

  monthsOpt: DatalistOption[] = MonthsOptions;
  monthSelectedName: string | null | undefined = null;
  eventScheduleOnPopUp: IEventSchedule | null = null;
  eventSchedules: IEventSchedule[] = [];

  ngOnInit(): void {
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;

      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
    this.funnelsService.loadAllFunnels().subscribe((x) => {
      this.funnels = x;
    });
    this.service.loadTasks('upcoming').subscribe((x) => {
      this.repo.upcomingMonths$.subscribe((up) => {
        if (up && up.length) {
          this.repo.upcomingActiveMonth = up[0];
          this.monthSelectedName = this.repo.upcomingActiveMonth?.name;
          x.forEach((y) => {
            this.eventSchedules = this.eventSchedules.concat(y.eventSchedules);
          });
        }
      });
    });
  }

  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) modal.style.display = 'none';
  }
  showModal(id: string) {
    let event = this.eventSchedules?.find((x) => x.id == id);
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (event) {
      modal.style.display = 'block';
      this.eventScheduleOnPopUp = event;
    } else {
      console.log('cant find');
    }
  }
}
