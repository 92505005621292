import { Injectable } from '@angular/core';
import { Store, createState, withProps, select } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  upsertEntities,
  selectEntity,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { combineLatest, map } from 'rxjs';
import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';
import { UserRoles } from './auth.repository';
import { BaseRepository } from './abstract/base.repository';
import { ICompanyMarker } from './companies.repository';

export interface UserOption {
  id: string;
  userName: string;
}
export interface User {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  location: string;
  birthday?: Date;
  tenantId?: string;
  createdAt: Date;
  image: File | null;
  imagePath: string;
  roles: UserRoles[];
  clientId?: string;
  twoFactorEnabled: boolean;
}
export interface ClientInfo {
  folder: string;
  allCompanies: ICompanyMarker[];
}

export const userSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Surname:Surname`, property: 'surname' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class UsersRepository extends BaseRepository<User> {
  constructor() {
    super('users', userSortOptions);
  }
}
