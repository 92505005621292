import { Component, Input, OnInit } from '@angular/core';
import {
  IEventSchedule,
  IMonthWithEvents,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { StatisticElement } from 'src/app/state/event.repository';
import {
  AeStatsFilter,
  IEventsWithStats,
  QuatersEsRepository,
} from 'src/app/state/quaters.repository';
import { QuatersEsService } from 'src/app/state/quaters.service';

@Component({
  selector: 'app-ae-stats',
  templateUrl: './ae-stats.component.html',
  styleUrls: ['./ae-stats.component.scss'],
})
export class AeStatsComponent implements OnInit {
  constructor(
    public service: QuatersEsService,
    public esService: EventScheduleService,

    public repo: QuatersEsRepository
  ) {}
  eventScheduleOnPopUp: IEventSchedule | null = null;
  eventsWithStats: IEventsWithStats | null = null;

  ngOnInit(): void {}
  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }
  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) modal.style.display = 'none';
  }
  showModal(id: string) {
    this.esService.loadOneEventSchedule(id).subscribe((x) => {
      var modal = document.getElementById('myModal') as HTMLModElement;
      modal.style.display = 'block';
      this.eventScheduleOnPopUp = x;
    });
  }
}
