import { AuthRepository, UserRoles } from '../../../state/auth.repository';
import {
  IProject,
  ITask,
  ProjectSortOptions,
  TimelogsRepository,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Post, PostsRepository } from 'src/app/state/posts.repository';
import { PostsService } from 'src/app/state/posts.service';
import { EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-timelogs-admin',
  templateUrl: './timelogs-admin.component.html',
  styleUrls: ['./timelogs-admin.component.scss'],
})
export class TimelogsAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = ProjectSortOptions;
  searchFilter: string = '';
  constructor(
    public service: TimelogsService,
    public repo: TimelogsRepository
  ) {}
  projects: IProject[] = [];
  tasks: ITask[] = [];
  deleteConfirmation: IProject | null = null;
  deleteProjectConfirmation: IProject | null = null;

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshDataProject() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteProjectClick(post: IProject) {
    this.service.deleteProject(post.id).subscribe((x) => {
      this.refreshDataProject();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
