import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export const EventInfosOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];

export interface IEventInfo {
  id: string;
  name: string;
  tenantId: string;
}

@Injectable({ providedIn: 'root' })
export class EventInfosRepository extends BaseRepository<IEventInfo> {
  constructor() {
    super('EventInfosOptions', EventInfosOptions);
  }
}
