<app-event-schedules-title
  [selectedYear]="selectedYear"
  [yearsOptions]="yearsOptions"
  (setYear)="setYear($event)"
  [isDraft]="true"
>
</app-event-schedules-title>
<div class="padx075rem">
  <app-client-drafts-month-table
    [months]="(repo.months$ | async) || []"
    (expand)="expand($event)"
    (refreshData)="reloadMonths()"
  ></app-client-drafts-month-table>
</div>
