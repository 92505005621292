import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { IFunnel, IRoom } from './event-schedule.repository';

export const RoomsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
];

@Injectable({ providedIn: 'root' })
export class RoomsRepository extends BaseRepository<IRoom> {
  constructor() {
    super('RoomsSortOptions', RoomsSortOptions);
  }
}
