import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ElementRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Feature, TenantFeatures } from 'src/app/state/feature.repository';
import { Tenant } from 'src/app/state/tenants.repository';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { DocumentTypesService } from '../../../../state/documenttype.service';

const FEATURE_NAMES: { [f: string]: string } = {
  [TenantFeatures.News]: $localize`:News label:News`,
  [TenantFeatures.Calendar]: $localize`:Calendar label:Calendar`,
  [TenantFeatures.TimeLogs]: $localize`:TimeLogs label:TimeLogs`,
  [TenantFeatures.Collaborations]: $localize`:Collaboration label:Collaborations`,
  [TenantFeatures.Whistleblow]: $localize`:Whistleblow label:Whistleblow`,
  [TenantFeatures.Messages]: $localize`:Messages label:Messages`,
  [TenantFeatures.Apv]: $localize`:Apv label:Apv`,
  [TenantFeatures.Documents]: $localize`:Documents label:Documents`,
  [TenantFeatures.Maps]: $localize`:Maps label:Maps`,
  [TenantFeatures.Tickets]: $localize`:Tickets label:Tickets`,
};

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.scss'],
})
export class TenantFormComponent implements OnInit {
  testsLocation = 'localhost:9876';
  @Input() editedTenant?: Tenant | null;
  form?: UntypedFormGroup;
  file: File | null = null;
  myOptions: any;
  documentTypeId: string | undefined = undefined;
  @Input() set features(value: Feature[] | null) {
    if (!value) {
      this.featureOptions = null;
    } else {
      this.featureOptions = value.map((x) => ({
        value: x.id,
        label: FEATURE_NAMES[x.name],
      }));
    }
  }

  @Output() tenantSubmit = new EventEmitter<Partial<Tenant>>();

  img: HTMLImageElement = document.createElement('img');
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.form) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        if (this.file?.type) {
          if (this.file?.type.slice(0, 5) !== 'image') {
            this.form.controls['image'].setValue(null);
            this.form.updateValueAndValidity();
            this.file = null;
          }

          if (this.file?.size) {
            var blob = URL.createObjectURL(this.file);
            this.img.src = blob;
          }
        }
        this.form.value.image = this.file;
      }
    }
  }

  featureOptions: DatalistOption[] | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public documenttypesService: DocumentTypesService
  ) {
    this.myOptions = new Options({});
  }

  ngOnInit() {
    this.documenttypesService.loadDefaultDoctype('Tenants').subscribe((x) => {
      this.documentTypeId = x.id;
    });

    this.form = this.formBuilder.group({
      name: [this.editedTenant?.name, Validators.required],
      image: [this.editedTenant?.image],
      cvr: [this.editedTenant?.cvr],
      address: [this.editedTenant?.address],
      mainMail: [this.editedTenant?.mainMail],
      remarks: [this.editedTenant?.remarks],
      phoneNumber: [this.editedTenant?.phoneNumber],
      mapZoom: [this.editedTenant?.mapZoom],
      startingAddress: [this.editedTenant?.startingAddress],
      startingLat: [this.editedTenant?.startingLat],
      startingLng: [this.editedTenant?.startingLng],
      featureIds: [this.editedTenant?.features?.map((x) => x.id)],
      useKmAsValues: [
        this.editedTenant?.useKmAsValues != null
          ? this.editedTenant?.useKmAsValues
          : true,
      ],
      allowUnauthorizedAccess: [
        this.editedTenant?.allowUnauthorizedAccess != null
          ? this.editedTenant?.allowUnauthorizedAccess
          : false,
      ],
      //documentTitle: [this.form?.value?.documentTitle],
      documentTypeId: [this.documentTypeId],
      tenantDocumentId: [this.editedTenant?.tenantDocumentId],
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  public handleAddressChange(address: any) {
    this.form?.controls['startingAddress'].setValue(address.formatted_address);
    this.form?.controls['startingLat'].setValue(
      address.geometry.location.lat()
    );
    this.form?.controls['startingLng'].setValue(
      address.geometry.location.lng()
    );
    return address.formatted_address;
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.tenantSubmit.emit({
      name: this.form?.value?.name.toString(),
      image: this.file,
      cvr: this.form?.value?.cvr,
      address: this.form?.value?.address,
      mainMail: this.form?.value?.mainMail,
      remarks: this.form?.value?.remarks,
      phoneNumber: this.form?.value?.phoneNumber,
      mapZoom: this.form?.value?.mapZoom,
      startingAddress: this.form?.value?.startingAddress,
      startingLat: this.form?.value?.startingLat,
      startingLng: this.form?.value?.startingLng,
      featureIds: this.form?.value?.featureIds,
      useKmAsValues: this.form?.value?.useKmAsValues,
      allowUnauthorizedAccess: this.form?.value?.allowUnauthorizedAccess,
      //documentTitle: this.form?.value?.documentTitle,
      documentTypeId: this.documentTypeId,
      imageWidth: this.img.width,
      imageHeight: this.img.height,
      tenantDocumentId: this.editedTenant?.tenantDocumentId,
    });
  }
}
