import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { WhistleblowService } from '../../../state/whistleblow.service';
import {
  IWhistleblow,
  WhistleblowRepository,
} from '../../../state/whistleblow.repository';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AfterViewInit } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-whistleblow',
  templateUrl: './whistleblow.component.html',
  styleUrls: ['./whistleblow.component.scss'],
})
export class WhistleblowComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  editor: any;
  file: File | null = null;
  @Input() editedEvent?: IWhistleblow | null;

  constructor(
    private router: Router,
    private service: WhistleblowService,
    private repository: WhistleblowRepository,
    public authRepo: AuthRepository,
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder
  ) {}

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.editForm) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.editForm.value.image = this.file;
      }
    }
  }

  AllWhistleblows: IWhistleblow[] = [];
  deleteConfirmation: string | null = null;

  refresh(): void {
    this.service
      .getAllWhistleblows()
      .subscribe((x) => (this.AllWhistleblows = x));
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      title: [this.editedEvent?.title, Validators.required],
      description: [this.editedEvent?.description, Validators.required],
      isAnonymous: true,
      file: [this.editedEvent?.file],
    });
    var modal = document.getElementById('myModal') as HTMLModElement;
    // Get the button that opens the modal
    var btn = document.getElementById('myBtn') as HTMLButtonElement;

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName('close')[0] as HTMLSpanElement;
    var cancel = document.getElementById('cancel') as HTMLButtonElement;

    // When the user clicks on the button, open the modal
    btn.onclick = function () {
      modal.style.display = 'block';
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = 'none';
    };
    cancel.onclick = function () {
      modal.style.display = 'none';
    };
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
    if (this.authRepo.isAnyAdmin$) {
      this.refresh();
    }
  }

  handleDeleteClick(whistleblowId: string) {
    this.service.delete(whistleblowId).subscribe((x) => {
      this.refresh();
    });
  }

  Resolve(id: string) {
    this.service.resolve(id).subscribe();
  }
  onSubmit(): void {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const title = this.editForm.value?.title.toString();
    const description = this.editForm.value?.description.toString();
    const isAnonymous = Boolean(this.editForm.value?.isAnonymous);
    let updateResult: Observable<IWhistleblow> | undefined;
    updateResult = this.service.add({
      title,
      description,
      isAnonymous,
      file: this.file,
    });
    modal.style.display = 'none';
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/whistleblow']);
        },
      });
    }
  }
}
