<div *ngIf="CurrentEvent" style="min-height: 288px; position: relative">
  <div
    [class.DisplayNone]="Events!.length < 2 || index === 0"
    id="prevButton"
    style="position: absolute; left: -34px; top: 40%"
  >
    <button class="btn" (click)="prev()">
      <svg
        width="18"
        height="50"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.85718 10.8542L0.857178 5.85425L5.85718 0.854248"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <div
    [class.DisplayNone]="Events!.length < 2 || index === Events!.length - 1"
    id="nextButton "
    style="position: absolute; right: -35px; top: 40%"
  >
    <button class="btn" (click)="next()">
      <svg
        width="18"
        height="50"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.857178 10.8542L5.85718 5.85425L0.857178 0.854248"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <div class="w-100 d-flex row align-items-center ps-2">
    <div
      class="d-flex justify-content-center align-items-center"
      style="
        width: 48px !important;
        height: 48px !important;
        background-color: #fff4ed;
        border-radius: 28px;
      "
    >
      <div
        class="d-flex justify-content-center align-items-center"
        style="
          width: 48px !important;
          height: 35px !important;
          background-color: #ffe6d5;
          border-radius: 28px;
          min-width: 35px;
        "
      >
        <svg
          style
          width="24"
          height="24"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
            stroke="#0B5087"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <p class="col">{{ CurrentEvent.eventDate | date : "d of MMMM, y" }}</p>
  </div>
  <button
    [mat-dialog-close]="true"
    class="btn"
    style="position: absolute; top: 2px; right: 0px"
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
  <div class="p-1">
    <div class="d-flex flex-column" style="position: absolute; bottom: 0">
      <div class="overflow-auto" style="min-height: 110px; max-height: 170px">
        <h4 class="text-break">{{ CurrentEvent.title }}</h4>
        <p class="text-break" [innerHTML]="CurrentEvent.description"></p>
      </div>

      <div class="mt-3">
        <label class="toggle">
          <input
            class="toggle-checkbox"
            type="checkbox"
            [checked]="CurrentEvent.subscribe"
            (change)="subscribe(CurrentEvent, CurrentEvent.subscribe)"
            id="Subscription"
          />
          <span class="toggle-switch"></span>
          <label class="ms-2 form-check-label" for="Subscription"
            >Subscribe</label
          >
        </label>
      </div>
    </div>
  </div>
</div>
