import { Injectable, Input } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { User } from './users.repository';
import {
  sortBy,
  SortOption,
  SortState,
} from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface IMail {
  id: string;
  header: string;
  body: string;
  createdAt: Date;
}

const { state, config } = createState(
  withEntities<IMail>(),
  withRequestsStatus(),
  withRequestsCache()
);
const store = new Store({ name: 'mails', state, config });
export const trackPostRequestsStatus = createRequestsStatusOperator(store);
export const skipWhilePostsCached = createRequestsCacheOperator(store);

export const mailsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Header:Header`, property: 'header' },
];
@Injectable({ providedIn: 'root' })
export class MailRepository extends BaseRepository<IMail> {
  name = store.name;
  posts$ = store.pipe(selectAllEntities());

  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: IMail['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setPosts(posts: IMail[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: IMail) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }
  constructor() {
    super('mails', mailsSortOptions);
  }
}
