import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IFunnel } from '../../../../state/event-schedule.repository';
import { FunnelsRepository } from '../../../../state/funnels.repository';
import { FunnelsService } from '../../../../state/funnels.service';

@Component({
  selector: 'app-funnel-create-page',
  templateUrl: './funnel-create-page.component.html',
  styleUrls: ['./funnel-create-page.component.scss'],
})
export class FunnelCreatePageComponent {
  constructor(
    public service: FunnelsService,
    public repo: FunnelsRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  funnel: IFunnel | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service.loadOneFunnel(`${value.get('id')}`).subscribe((x) => {
            this.funnel = x;
          });
        }
      },
    });
  }

  createFunnel(event: Partial<IFunnel>) {
    this.submitErrors = null;
    let updateResult$: Observable<IFunnel> | undefined;
    if (this.id === 'new') {
      updateResult$ = this.service.postFunnel(event);
    } else if (this.id) {
      updateResult$ = this.service.updateFunnel(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.router.navigate(['/admin/teams']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
