<div class="form-group form-floating ps-0 pe-0">
  <div *ngIf="label" class="inpt-title ps-0 pe-0" [attr.for]="id">
    {{ label }}
  </div>
  <ng-select
    class="ps-0 pe-0"
    [class.disablePadBot]="disablePadBot"
    [clearable]="clearable"
    [labelForId]="id"
    [multiple]="multiple"
    [(ngModel)]="activeValue"
    (ngModelChange)="activeValueChange.emit($event)"
  >
    <ng-option *ngFor="let option of options" [value]="option.value">
      <span [class.smallText]="smallText"> {{ option.label }}</span
      ><br />
      <span class="small text-muted">{{ option.sublabel }}</span>
    </ng-option>
  </ng-select>
</div>
