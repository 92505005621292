<div class="m-0 p-0 row h-100 w-100 vw-100 scroll-y">
  <div
    class="marginOnBigScreen col-11 col-md-11 col-lg-4 col-xl-4 mx-auto pt-5 text-center mb-100px"
    *ngVar="settings$ | async as settings"
    style="z-index: 999 !important"
  >
    <div *ngIf="!isTfaProccess; else tfaProccessBlock">
      <div class="d-flex justify-content-center align-items-center">
        <img
          width="320"
          class="mb-4 showOnSmallScreen"
          [alt]="env.name"
          src="/assets/img/logo_wfr.png"
        />
      </div>
      <h3 class="mb-4" i18n="Welcome Agian Label">Welcome Again</h3>
      <p class="text-muted mb-4" i18n="Sign in label">
        Sign in now by entering<br />your email and password
      </p>
      <form
        class="d-grid gap-2 mb-4"
        [formGroup]="loginForm"
        (ngSubmit)="login()"
      >
        <div
          class="form-group form-floating"
          [class.is-invalid]="
            loginForm.get('email')?.errors && loginForm.get('email')?.touched
          "
        >
          <input
            formControlName="email"
            type="text"
            class="border8 inpt"
            id="email"
            placeholder="Email"
            i18n-placeholder="Email placeholder @@email"
          />
        </div>
        <div
          class="form-group form-floating"
          [class.is-invalid]="
            loginForm.get('password')?.errors &&
            loginForm.get('password')?.touched
          "
        >
          <input
            formControlName="password"
            type="password"
            class="border8 inpt"
            id="password"
            placeholder="Password"
            i18n-placeholder="Password placeholder @@password"
          />

          <button
            type="button"
            class="btn btn-link text-danger tooltip-btn"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            id="passworderror"
            [hidden]="!errorMessage"
            [title]="errorMessage"
          >
            <i class="bi bi-info-circle"></i>
          </button>
        </div>
        <div
          class="form-check float-start d-flex align-items-center justify-content-between"
        >
          <div class="border8">
            <!--<input
              class="form-check-input"
              formControlName="rememberMe"
              id="rememberMe"
              type="checkbox"
            />
            <label
              class="float-start"
              for="rememberMe"
              i18n="Remember user label"
              >Remember me</label
            >-->
          </div>
          <div>
            <p class="mb-0">
              <a
                routerLink="/forgotpassword"
                class="btn btn-link"
                style="
                  font-family: 'Inter', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                "
                i18n="Button forgot password"
                >Forgot your password?</a
              >
            </p>
          </div>
        </div>
        <button
          type="submit"
          [disabled]="repo.isLoading$ | async"
          class="btn btn-wfrBlue border8 btntxt btn-default btn-block mt-3"
          style="background-color: #0b5087 !important; z-index: 3"
        >
          <ng-container
            class="textBold"
            *ngIf="!(repo.isLoading$ | async)"
            i18n="Button sign in"
            >Sign in</ng-container
          >
          <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
        </button>
      </form>
    </div>
    <ng-template #tfaProccessBlock>
      <app-gtfa-qrcode (Back)="back()" [Tfa]="activeGtfa"></app-gtfa-qrcode>
    </ng-template>
  </div>
  <div
    class="col-12 col-md-12 col-lg-12 col-xl-6 showOnBigScreen"
    style="background-color: #0b5087; z-index: 1 !important"
  >
    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
      <img class="" style="width: 90%" src="/assets/img/logo_wfr.png" />
    </div>
  </div>
</div>
