import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Tenant } from 'src/app/state/tenants.repository';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss'],
})
export class TenantListComponent implements OnInit {
  @Input() innerWidth = 0;
  @Input() tenants: Tenant[] | null = null;
  @Input() isDeleteDisabled = true;
  @Output() delete = new EventEmitter<string>();
  deleteConfirmation: Tenant | null = null;

  constructor(public auth: AuthRepository) {}

  ngOnInit(): void {}

  handleDeleteClick(tenant: Tenant) {
    if (!this.isDeleteDisabled) {
      this.delete.emit(tenant.id);
    }
  }
}
