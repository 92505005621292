import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CompaniesRepository,
  ICompanyMarker,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { IReferralSource } from 'src/app/state/referralSource.repository';
import { ReferralSourcesService } from 'src/app/state/referralSources.service';

@Component({
  selector: 'app-clients-create-page',
  templateUrl: './clients-create-page.component.html',
  styleUrls: ['./clients-create-page.component.scss'],
})
export class ClientsCreatePageComponent {
  constructor(
    public service: CompaniesService,
    public RefSourceService: ReferralSourcesService,

    public repo: CompaniesRepository,

    private route: ActivatedRoute,
    private router: Router
  ) { }
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  referralSources: IReferralSource[] = [];
  company: ICompanyMarker | null = null;
  ngOnInit(): void {
    this.RefSourceService.loadAllReferralSources().subscribe(
      (x) => (this.referralSources = x)
    );
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service.loadOneCompany(`${value.get('id')}`).subscribe((x) => {
            this.company = x;
          });
        }
      },
    });
  }
  isLoading = false;

  createClient(client: Partial<ICompanyMarker>) {
    if (this.isLoading) {
      return;
    }
    this.submitErrors = null;
    let updateResult$: Observable<ICompanyMarker> | undefined;

    if (this.id === 'new') {
      updateResult$ = this.service.postCompany(client);
    } else if (this.id) {
      updateResult$ = this.service.updateCompany(client);
    }
    if (updateResult$) {
      this.isLoading = true
      updateResult$.subscribe({
        complete: () => {
          this.router.navigate(['/admin/clients']);
          this.isLoading = false;
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
