<h2 class="mb-4" *ngIf="type === 'new'">Create Client User</h2>
<h2 class="mb-4" *ngIf="type !== 'new'" i18n="Edit tenant label">
  Edit Client User
</h2>
<div *ngIf="type === 'new' || user" class="row mb-4">
  <div class="col-12">
    <app-client-users-editform
      [clients]="clients"
      (ClientUserSubmit)="createClientUser($event)"
      [editedClientUser]="user"
    ></app-client-users-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the client user profile"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
