import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-signalr-status',
  templateUrl: './signalr-status.component.html',
  styleUrls: ['./signalr-status.component.scss'],
})
export class SignalrStatusComponent {
  @Input() status: string = 'Closed';
  @Output() resfresh = new EventEmitter<void>();
  emitrefresh() {
    if (this.status != 'Connected') {
      this.resfresh.emit();
    }
  }
}
