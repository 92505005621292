<div
  class="modal fade"
  id="vsnModal"
  tabindex="-1"
  aria-labelledby="vsnLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="vsnLabel">Venue Specific Notes</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="modal-body">
        <div *ngIf="!editedLocationId" class="alert alert-danger">
          Cannot get Location
        </div>
        <form
          [formGroup]="editForm"
          (ngSubmit)="onSubmit()"
          enctype="multipart/form-data"
        >
          <div class="form-group">
            <div class="row mb-3">
              <div class="col-12 form-floating">
                <textarea
                  class="inpt-lg inpt h-200px enableScrollInpt"
                  id="venueSpecificNotes"
                  formControlName="venueSpecificNotes"
                  type="text"
                  placeholder="Venue Specific Notes"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button (click)="onSubmit()" type="button" class="btn btn-wfrBlue">
          Save
        </button>
        <button
          #closeVSNButton
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
