<div class="row pt-4 pe-4">
  <h1 *ngIf="!this.editedEvent?.id" class="mb-4 col">Create event</h1>
  <h1 class="mb-4 col" *ngIf="this.editedEvent?.id">Update event</h1>

  <div class="col-6 p-0 d-flex justify-content-end">
    <button
      class="btn btn-180 dark-wfrBlue text-white shadow-sm me-1"
      (click)="onSubmit(false, true, false)"
    >
      Submit Events
    </button>
    <button
      (click)="onSubmit(false, false, true)"
      class="btn btn-140 dark-wfrBlue text-white shadow-sm me-1"
    >
      Save Draft
    </button>
    <div class="d-flex" (click)="locationBack()">
      <a
        class="d-flex btn-100 justify-content-around btn dark-wfrBlue text-white"
      >
        <img src="assets/img/backArrow.svg" />
        <span class="">Back</span>
      </a>
    </div>
  </div>
</div>
<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group row p-0">
    <div class="row p-0 m-0">
      <div class="col-12 form-floating">
        <ol class="orderedlabel pe-4">
          <li class="pb-16px">
            <label class="pb-2">What type of event is this?</label>
            <div class="row col-12 col-sm-6">
              <div class="col-4 col-sm-3 text-center">
                <div>Workshops/Seminar</div>
                <input
                  id="workshopsSeminarType"
                  value="workshopsSeminarType"
                  formControlName="eventType"
                  type="radio"
                  class="form-check-input"
                  placeholder="Double Event"
                  autocomplete="off"
                />
              </div>
              <div class="col-4 col-sm-3 text-center">
                <div>Lunch/Dinner</div>
                <input
                  id="lunchDinnerType"
                  value="lunchDinnerType"
                  formControlName="eventType"
                  type="radio"
                  class="form-check-input"
                  autocomplete="off"
                />
              </div>
              <div class="col-4 col-sm-3 text-center">
                <div>Webinar</div>
                <input
                  id="webinarType"
                  value="webinarType"
                  formControlName="eventType"
                  type="radio"
                  class="form-check-input"
                  autocomplete="off"
                />
              </div>
            </div>
          </li>
          <li class="pb-16px">
            <label>If this is a double event, check the box </label>
            <input
              id="doubleEvent"
              formControlName="doubleEvent"
              type="checkbox"
              class="ms-2 form-check-input"
              autocomplete="off"
            />
          </li>
          <li>
            <div class="form-floating row">
              <div
                [class.col-6]="editForm.get('doubleEvent').value"
                [class.col-12]="!editForm.get('doubleEvent').value"
              >
                <div class="inpt-title">
                  Date
                  <span *ngIf="editForm.get('doubleEvent').value"
                    >(event 1)</span
                  >
                </div>
                <div class="d-flex pe-50">
                  <input
                    class="inpt"
                    (dateChange)="setStartDate($event)"
                    formControlName="date"
                    matInput
                    [matDatepicker]="picker"
                  />
                  <!-- readonly
                  (click)="picker.open()" -->
                  <!-- <div
                    class="ms-2 btn hovering-button"
                    [title]="'Clear Date  (event 1)'"
                  >
                    <img
                      type="button"
                      class="deleteBinding"
                      src="assets/img/delete18x20.svg"
                    />
                  </div> -->
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div
                  *ngIf="!isDate1Valid"
                  class="mt-2 alert alert-danger d-flex w-100 p-0 m-0 align-items-center justify-content-between h-25px"
                >
                  <span class="ps-1"
                    ><small>This event is in the past, please check date</small>
                  </span>
                  <button
                    type="button"
                    class="btn"
                    (click)="isDate1Valid = true"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div class="col-6" *ngIf="editForm.get('doubleEvent').value">
                <div class="inpt-title">Date (event 2)</div>

                <div class="d-flex pe-50">
                  <input
                    class="inpt"
                    (dateChange)="setStartDate2($event)"
                    formControlName="date2"
                    matInput
                    [matDatepicker]="picker"
                  />
                  <!-- readonly
                  (click)="picker.open()" -->
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>

                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div
                  *ngIf="!isDate2Valid"
                  class="mt-2 alert alert-danger d-flex w-100 p-0 m-0 align-items-center justify-content-between h-25px"
                >
                  <span class="ps-1"
                    ><small>This event is in the past, please check date</small>
                  </span>
                  <button
                    type="button"
                    class="btn"
                    (click)="isDate2Valid = true"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </li>

          <li>
            <label
              >Location (If this is a new venue, pick "New Venue" from the
              dropdown menu and add the venue NAME and ADDRESS in the notes
              section below)</label
            >
            <div
              class="form-floating mb-3"
              [appValidateState]="editForm.get('eventScheduleLocationId')"
            >
              <app-datalist-select
                class="form-floating"
                [appValidateState]="editForm.get('eventScheduleLocationId')"
                [activeValue]="editForm?.value.eventScheduleLocationId"
                [options]="scheduleLocationsOptions"
                (activeValueChange)="
                  changeLocation('eventScheduleLocationId', $event)
                "
                [multiple]="false"
              ></app-datalist-select>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="form-floating col-6">
                <app-datalist-select
                  [options]="TopicsOptions"
                  [activeValue]="editForm?.value.prebuildTopicId"
                  (activeValueChange)="prebuiltTopic($event)"
                  [multiple]="false"
                  label="Topic (Prebuilt)"
                ></app-datalist-select>
              </div>
              <div
                class="col-6 form-floating"
                [appValidateState]="editForm.get('topic')"
              >
                <div class="inpt-title" for="topic">
                  New Topic (type in any suggested new topic)
                </div>
                <input
                  id="topic"
                  formControlName="newTopic"
                  type="text"
                  class="inpt"
                  placeholder="Topic"
                  autocomplete="off"
                  (input)="changeTopic()"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="form-floating row">
              <div
                class="form-floating"
                [class.col-3]="editForm.get('doubleEvent').value"
                [class.col-6]="!editForm.get('doubleEvent').value"
              >
                <app-datalist-select
                  class=""
                  [options]="EventInfosOptions"
                  (activeValueChange)="
                    handleTimeChange('prebuildTimeId', 'time', $event)
                  "
                  [activeValue]="editForm?.value.prebuildTimeId"
                  [multiple]="false"
                  label="{{
                    editForm.get('doubleEvent').value
                      ? 'Prebuilt Time (event 1)'
                      : 'Prebuilt Time'
                  }}"
                ></app-datalist-select>
              </div>
              <div
                [class.col-3]="editForm.get('doubleEvent').value"
                [class.col-6]="!editForm.get('doubleEvent').value"
              >
                <div class="inpt-title" for="time">
                  Time
                  <span *ngIf="editForm.get('doubleEvent').value"
                    >(event 1)</span
                  >
                </div>
                <input
                  id="time"
                  formControlName="time"
                  type="text"
                  class="inpt"
                  placeholder="Time"
                  autocomplete="off"
                  (input)="updateValue('prebuildTimeId', null)"
                />
              </div>
              <div
                *ngIf="editForm.get('doubleEvent').value"
                class="form-floating"
                [class.col-3]="editForm.get('doubleEvent').value"
                [class.col-6]="!editForm.get('doubleEvent').value"
              >
                <app-datalist-select
                  class=""
                  [activeValue]="editForm?.value.prebuildTime2Id"
                  [options]="EventInfosOptions"
                  (activeValueChange)="
                    handleTimeChange('prebuildTime2Id', 'time2', $event)
                  "
                  [multiple]="false"
                  label="Prebuilt Time (event 2)"
                ></app-datalist-select>
              </div>
              <div
                *ngIf="editForm.get('doubleEvent').value"
                [class.col-3]="editForm.get('doubleEvent').value"
                [class.col-6]="!editForm.get('doubleEvent').value"
                [appValidateState]="editForm.get('time2')"
              >
                <div class="inpt-title" for="time2">Time (event 2)</div>
                <input
                  (input)="updateValue('prebuildTime2Id', null)"
                  id="time2"
                  formControlName="time2"
                  type="text"
                  class="inpt"
                  placeholder="Time"
                  autocomplete="off"
                />
              </div>
            </div>
          </li>
          <li class="pb-16px">
            <label
              >Is parking free? (and briefly add necessary parking instructions)
            </label>
            <div *ngIf="!SelectedLocation" class="form-floating">
              <input
                class="inpt"
                placeholder="Please set Location"
                disabled
                autocomplete="off"
              />
            </div>
            <div
              *ngIf="SelectedLocation"
              class="form-floating"
              [appValidateState]="editForm.get('parking')"
            >
              <input
                id="parking"
                formControlName="parking"
                type="text"
                class="inpt"
                placeholder="Parking"
                autocomplete="off"
              />
            </div>
          </li>
          <li class="pb-16px">
            <div class="row">
              <div
                class="form-floating"
                [class.col-6]="editForm.get('doubleEvent').value"
                [class.col-12]="!editForm.get('doubleEvent').value"
              >
                <div *ngIf="!RoomsOptions" class="form-floating">
                  <div class="inpt-title" for="location">
                    Room name/number
                    <span *ngIf="editForm.get('doubleEvent').value"
                      >(event 1)</span
                    >
                  </div>
                  <input
                    class="inpt"
                    placeholder="Please set Location"
                    disabled
                    autocomplete="off"
                  />
                </div>
                <div
                  class="form-floating"
                  [appValidateState]="editForm.get('roomId')"
                  *ngIf="RoomsOptions"
                >
                  <app-datalist-select
                    class=""
                    [activeValue]="editForm?.value.roomId"
                    [options]="RoomsOptions"
                    (activeValueChange)="changeRoom('roomId', $event)"
                    [multiple]="false"
                    label="Room"
                  ></app-datalist-select>
                </div>
              </div>
              <div
                class="col-6 form-floating"
                *ngIf="editForm.get('doubleEvent').value"
              >
                <div *ngIf="!RoomsOptions" class="form-floating">
                  <div class="inpt-title">Room name/number(event 2)</div>
                  <input
                    class="inpt"
                    placeholder="Please set Location"
                    disabled
                    autocomplete="off"
                  />
                </div>
                <div
                  class="form-floating"
                  [appValidateState]="editForm.get('roomD2Id')"
                  *ngIf="RoomsOptions"
                >
                  <app-datalist-select
                    class=""
                    [activeValue]="editForm?.value.roomD2Id"
                    [options]="RoomsOptions"
                    (activeValueChange)="changeRoom('roomD2Id', $event)"
                    [multiple]="false"
                    label="Room (event 2)"
                  ></app-datalist-select>
                </div>
              </div>
            </div>
          </li>
          <li class="pb-16px">
            <div class="row">
              <div
                [class.col-6]="editForm.get('doubleEvent').value"
                [class.col-12]="!editForm.get('doubleEvent').value"
              >
                <label
                  >Room capacity
                  <span *ngIf="editForm.get('doubleEvent').value"
                    >(event 1)</span
                  ></label
                >
                <div class="form-floating row">
                  <div
                    class="col-12"
                    [appValidateState]="editForm.get('desiredBU')"
                  >
                    <input
                      id="desiredBU"
                      formControlName="desiredBU"
                      type="text"
                      class="inpt"
                      placeholder="Rooms Capacity"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div *ngIf="editForm.get('doubleEvent').value" class="col-6">
                <label>Room capacity (event 2)</label>
                <div class="form-floating row">
                  <div
                    class="col-12"
                    [appValidateState]="editForm.get('desiredBU')"
                  >
                    <input
                      id="desiredBUD2"
                      formControlName="desiredBUD2"
                      type="text"
                      class="inpt"
                      placeholder="Rooms Capacity"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-floating col-12">
              <app-datalist-select
                class=""
                [options]="reminderProcessOptions"
                [activeValue]="editForm?.value.wfrReminder"
                (activeValueChange)="updateValue('wfrReminder', $event)"
                [multiple]="false"
                label="Reminder process"
              ></app-datalist-select>
            </div>
          </li>
          <li>
            <label>Notes section</label>
            <div class="form-floating">
              <textarea
                class="inpt4line inpt"
                id="notes"
                formControlName="notes"
                type="text"
                placeholder="Notes"
                autocomplete="off"
              ></textarea>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</form>
<div class="pb-3 d-flex">
  <button
    class="col btn btn-wfrBlue shadow-sm"
    (click)="onSubmit(false, true, false)"
  >
    Submit events
  </button>
  <button
    class="col btn btn-wfrBlue shadow-sm mx-2"
    (click)="onSubmit(false, false, true)"
  >
    Save Draft
  </button>

  <a
    class="d-flex col justify-content-center align-items-center btn dark-wfrBlue text-white"
    (click)="locationBack()"
  >
    <img src="assets/img/backArrow.svg" />
    <span class="ms-3">Back</span>
  </a>
</div>
