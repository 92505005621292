<!--<div class="row mb-4">
  <div class="col">
    <h2 i18n="Tenants label">Tenants</h2>
  </div>
  <div class="col-auto d-flex align-items-center">
    <a routerLink="/tenants/new" class="btn btn-sm btn-success">
      <i class="bi bi-plus"></i> <ng-container i18n="Add tenant button @@add">Add</ng-container>
    </a>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <app-sort-dropdown [sortingOptions]="sortOptions" 
      [sorting]="repo.sort$ | async"
      (sortingChange)="repo.setSort($event)"></app-sort-dropdown>
  </div>
</div>

<div class="row mb-4">
  <div class="col">
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-tenant-list *ngIf="!(repo.isLoading$ | async)" 
      [tenants]="repo.tenantsSorted$ | async"
      [isDeleteDisabled]="!(auth.isSuperAdmin$ | async)"
      (delete)="service.delete($event).subscribe()"></app-tenant-list>
  </div>
</div>-->

<div class="row page-header d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row">
    <div class="col-6 mt-sm-4 mb-sm-3 p-0">
      <h2 class="page-title" i18n="Tenants label">Tenants</h2>
    </div>
    <div
      *ngIf="auth.isAnyAdmin$ | async"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <a
        routerLink="/tenants/new"
        class="btn btn-lg dark-wfrBlue text-white"
        style="justify-content: space-between"
      >
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00008 1.66699V13.3337M1.16675 7.50033H12.8334"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="text-awesome" i18n="@@addTenant">Add tenant</div>
      </a>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-end">
      <app-sort-dropdown
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="repo.setSort($event)"
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <app-tenant-list
      *ngIf="!(repo.isLoading$ | async)"
      class="sm-m p-0 m-0"
      style="width: 100%"
      [innerWidth]="innerWidth"
      [tenants]="repo.tenantsSorted$ | async"
      [isDeleteDisabled]="!(auth.isSuperAdmin$ | async)"
      (delete)="service.delete($event).subscribe()"
    ></app-tenant-list>
  </div>
</div>
