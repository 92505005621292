import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { User } from './users.repository';

export interface daysTotal {
  monHours: number;
  tueHours: number;
  wedHours: number;
  thuHours: number;
  friHours: number;
  satHours: number;
  sunHours: number;
  totHours: number;
  name: string;
}

export interface projectTasksTotal {
  id: string;
  projectName: string;
  tasksTotals: daysTotal[];
  monTotal: number;
  tueTotal: number;
  wedTotal: number;
  thuTotal: number;
  friTotal: number;
  satTotal: number;
  sunTotal: number;
  genTotal: number;
}

export interface IProject {
  id: string;
  name: string;
  description: string;
  applicationUsers: User[];
  applicationUsersIds: string[];
  tasksIds: string[];
  createdAt: Date;
  startDate: Date;
  updatedAt: Date;
  deletedAt: Date;
  tasks: ITask[];
  projectTasks: ITask[];
  taskNames: String[];
  dateString: string;
  totalHours: string;
  usersTotals: IUsersHours[];
}

export interface ITask {
  id: string;
  name: string;
  marks: IMark[];
  total: number;
  totalMinutes: number;
}
export interface IMark {
  id: string;
  date: string;
  hours: any;
  dateString: string;
  taskName: string;
  taskId: string;
  projectId: string;
  hoursTest: Date;
  decimalHours: number;
}
export interface IUsersHours {
  id: string;
  name: string;
  hours: number;
}

export const ProjectSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  // { label: $localize`:Sort label Updated Date:Updated Date`, property: 'updatedAt' },
  { label: $localize`:Sort label Date: Date`, property: 'startDate' },
];

@Injectable({ providedIn: 'root' })
export class TimelogsRepository extends BaseRepository<IProject> {
  constructor() {
    super('projects', ProjectSortOptions);
  }
}
