<div
  class="modal fade"
  bsModal
  [config]="{ show: true }"
  id="deleteModal"
  tabindex="-1"
  #deleteModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0 br-16">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="deleteModalLabel"
          i18n="Title for confirmation dialog"
        >
          Confirm your action
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <p class="mb-1" i18n="Confirm delete message start">
            You are about to delete the following entry:
          </p>

          <p *ngIf="!eventSchedule" class="mb-1">
            <strong class="text-break"
              >{{ deleteCandidate | slice : 0 : 100 }}...</strong
            >
          </p>
          <div *ngIf="eventSchedule" class="mb-1">
            <span *ngIf="clientCode">
              <p class="d-flex">
                <strong class="pe-1">Client Code:</strong>
                {{ clientCode | slice : 0 : 15 }}
                <span *ngIf="clientCode.length > 15">...</span>
              </p>
            </span>
            <span *ngIf="clientName">
              <p class="d-flex">
                <strong class="pe-1">Client Name:</strong>
                {{ clientName | slice : 0 : 15 }}
                <span *ngIf="clientName.length > 15">...</span>
              </p>
            </span>
            <span *ngIf="date">
              <p class="d-flex">
                <strong class="pe-1">Date:</strong>
                {{ date | slice : 0 : 15 }}
                <span *ngIf="date.length > 15">...</span>
              </p>
            </span>
            <span *ngIf="location">
              <p class="d-flex">
                <strong class="pe-1">Location:</strong>
                {{ location | slice : 0 : 15 }}
                <span *ngIf="location.length > 15">...</span>
              </p>
            </span>
          </div>
          <p class="mb-0" i18n="Confirm delete message end">
            Please confirm your action by clicking Delete.
          </p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          class="btn btn-wfrBlue me-2"
          (click)="confirm.emit()"
          i18n="Delete button"
        >
          Delete
        </button>
        <button class="btn btn-d" (click)="cancel.emit()" i18n="@@cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
