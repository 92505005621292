<h1>Google Two-Factor Authentication</h1>
<div *ngIf="showError" class="alert alert-danger" role="alert">
  {{ errorMessage }}
</div>

<div *ngIf="!this.Tfa?.isTfaEnabled; else enabledBlock">
  <div>
    <div class="box">
      <h2>Step 1</h2>
      <p>
        Using the Google Authenticator app, you should scan the following QR
        code:
      </p>
      <qrcode
        [qrdata]="Tfa?.formattedKey ?? ''"
        [allowEmptyString]="true"
      ></qrcode>

      <p>
        Alternatively, you may enter the following code to the Google
        Authenticator app:
      </p>
      <pre>{{ this.Tfa?.authenticatorKey }}</pre>
    </div>
  </div>

  <div class="box">
    <h2>Step 2</h2>
    <div>
      <form [formGroup]="tfaForm">
        <div class="mb-3">
          <label for="code">
            Enter here the code provided by the Google Authenticator app:
          </label>
          <div>
            <input
              type="text"
              id="code"
              formControlName="code"
              class="form-control"
            />
          </div>
          <div class="col-md-5">
            <em *ngIf="validateControl('code') && hasError('code', 'required')">
              Code is required
            </em>
          </div>
        </div>
        <button
          class="btn btn-wfrBlue border8 btntxt btn-default btn-block w-100"
          [disabled]="!tfaForm.valid"
          (click)="enableTfa(tfaForm.value)"
        >
          Enable Two-Factor Authentication
        </button>
      </form>
    </div>
  </div>
</div>
<ng-template #enabledBlock>
  <div>
    <form
      [formGroup]="tfaForm"
      autocomplete="off"
      novalidate
      (ngSubmit)="loginUser(tfaForm.value)"
    >
      <label for="twoFactorCode" class="col-form-label col-sm-2">Code:</label>
      <input
        autofocus
        type="text"
        id="twoFactorCode"
        formControlName="code"
        class="form-control mb-3"
      />
      <em
        class="text-danger"
        *ngIf="validateControl('code') && hasError('code', 'required')"
      >
        The Code is required
      </em>

      <button
        type="submit"
        class="my-3 btn btn-wfrBlue border8 btntxt btn-default btn-block w-100"
        [disabled]="!tfaForm.valid"
      >
        Submit
      </button>
    </form>
  </div>
</ng-template>
<button
  class="btn mb-3 btn-wfrBlue border8 btntxt btn-default btn-block w-100"
  (click)="Back.emit()"
>
  Back
</button>
