<!--<div class="">
  <div class="row mb-4">
    <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
      <app-pagination [page]="(repo.pageNumber$ | async) || 1"
                      [total]="(repo.paginationData$ | async)?.lastPage || 0"
                      (pageChange)="service.loadPage($event).subscribe()"></app-pagination>
    </div>
    <div class="col-12 col-md-auto">
      <app-sort-dropdown [sortingOptions]="sortOptions"
                         [sorting]="repo.sort$ | async"
                         (sortingChange)="service.sort($event).subscribe()"></app-sort-dropdown>
    </div>
  </div>
</div>-->

<div class="row d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3">
    <h2 class="page-title sm-del">Messages</h2>
    <div class="mainSubtext mb-sm-4 sm-del">
      Here you can see all new team messages
    </div>

    <div class="d-flex flex-row align-items-center mb-4" style="width: 100%">
      <div class="line col"></div>
      <div class="unread me-2 ms-2">Unread</div>
      <div class="line col"></div>
    </div>
    <div class="" *ngFor="let message of repo.page$ | async">
      <div class="d-flex flex-row flex-start me-2 mb-4 mt-1">
        <img
          class="rounded-circle me-2"
          src="{{ DEFAULT_IMAGE }}"
          alt="avatar"
          width="40"
          height="40"
          style="background: #ff6a00"
        />
        <div class="d-flex flex-column align-items-start" style="width: 100%">
          <div class="flex-grow-1 order-1" style="width: 100%">
            <div
              class="d-flex flex-row align-items-center justify-content-between mb-1"
            >
              <div class="commname">Admin</div>
              <div class="commdate">
                {{ message.createdAt | date : "EEEE HH:mma" }}
              </div>
            </div>
            <div
              class="text-break d-flex align-items-center flex-row commtext pt-2 pb-3 p-3"
            >
              {{ message.body }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mt-5" style="width: 100%">
      <div class="line col"></div>
      <div class="read me-2 ms-2">Read</div>
      <div class="line col"></div>
    </div>
  </div>
</div>
