<div class="row d-flex justify-content-center">
  <h2
    class="col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-3 d-flex justify-content-between page-title"
    style=""
  >
    Calendar
  </h2>
</div>
<div class="pt-3 row justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900">
    <full-calendar
      id="calendar"
      style="
        border-collapse: separate !important;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);
      "
      [options]="calendarOptions"
    ></full-calendar>
  </div>
</div>
