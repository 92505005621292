<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div *ngIf="!this.editedEvent?.id" class="row pt-4 pe-4">
    <h1 class="mb-4 col">Create event</h1>
    <div class="col-6 p-0 d-flex justify-content-end">
      <a
        *ngIf="SelectedClient?.clientSpecificInstructions"
        class="btn dark-wfrBlue text-white shadow-sm mx-1 h40px nowrap"
        (click)="openUrlInNewTab(SelectedClient?.clientSpecificInstructions)"
      >
        {{ SelectedClient?.clientCode }} - CSI
      </a>
      <button class="btn dark-wfrBlue text-white shadow-sm me-1 h40px">
        Save
      </button>
      <div class="d-flex ms-1" (click)="locationBack()">
        <a
          class="d-flex justify-content-around btn dark-wfrBlue text-white h40px"
        >
          <img src="assets/img/backArrow.svg" class="me-2" />
          <span>Back</span>
        </a>
      </div>
    </div>
  </div>

  <div class="row pt-2 pe-4">
    <h1 class="mb-4 col" *ngIf="this.editedEvent?.id">Update event</h1>
    <div
      class="col d-flex align-items-bottom"
      *ngIf="editedEvent?.rescheduledFromId"
    >
      <span class="alert alert-warning p-1 d-flex align-items-center">
        You are Editing a Rescheduled Event</span
      >
    </div>
    <div
      *ngIf="
        this.editedEvent?.id &&
        !this.editedEvent?.isDraft &&
        !this.editedEvent?.isInReview
      "
      class="col-6 p-0 d-flex justify-content-end"
    >
      <a
        *ngIf="SelectedClient"
        class="btn dark-wfrBlue text-white shadow-sm mx-1 h40px nowrap"
        (click)="openUrlInNewTab(SelectedClient?.clientSpecificInstructions)"
      >
        {{ SelectedClient?.clientCode }} - CSI
      </a>

      <button class="btn dark-wfrBlue text-white shadow-sm me-1 h40px">
        Save
      </button>

      <a
        *ngIf="!isDuplicated"
        (click)="onSubmit(true)"
        class="btn dark-wfrBlue text-white shadow-sm mx-1 h40px"
      >
        Duplicate
      </a>
      <div class="d-flex ms-1" (click)="locationBack()">
        <a
          class="d-flex justify-content-around btn dark-wfrBlue text-white h40px justifyContentEnd"
        >
          <img src="assets/img/backArrow.svg" class="me-2" />
          <span>Back</span>
        </a>
      </div>
    </div>
    <div
      *ngIf="
        this.editedEvent?.id &&
        (this.editedEvent?.isDraft || this.editedEvent?.isInReview)
      "
      class="col-6 p-0 d-flex justify-content-end"
    >
      <a
        *ngIf="SelectedClient"
        class="btn dark-wfrBlue text-white shadow-sm mx-1 h40px nowrap"
        (click)="openUrlInNewTab(SelectedClient?.clientSpecificInstructions)"
      >
        {{ SelectedClient?.clientCode }} - CSI
      </a>
      <button class="btn dark-wfrBlue text-white shadow-sm h40px">
        Approve
      </button>
      <div class="d-flex ms-1" (click)="saveWithoutApprove()">
        <a
          class="d-flex justify-content-around btn dark-wfrBlue text-white h40px justifyContentEnd"
        >
          <span>Save</span>
        </a>
      </div>
      <div class="d-flex h40px ms-1">
        <a
          *ngIf="!deleteLoading; else deleteLoadingBlock"
          class="d-flex justify-content-around btn btn-danger text-white h40px justifyContentEnd"
          (click)="deleteConfirmation = true"
        >
          <span>Delete</span>
        </a>

        <ng-template #deleteLoadingBlock
          ><app-spinner></app-spinner>
        </ng-template>
      </div>
      <div class="d-flex ms-1" (click)="locationBack()">
        <a
          class="d-flex justify-content-around btn dark-wfrBlue text-white h40px justifyContentEnd"
        >
          <img src="assets/img/backArrow.svg" class="me-2" />
          <span>Back</span>
        </a>
      </div>
    </div>
  </div>

  <div class="form-group mb-3 row p-0">
    <div class="form-floating mb-3">
      <div class="inpt-title" for="waitComplianceApproval">
        Wait for compliance approval
      </div>
      <input
        id="waitComplianceApproval"
        formControlName="waitComplianceApproval"
        type="checkbox"
        class="form-check-input"
        placeholder="Wait for compliance approval"
        autocomplete="off"
      />
    </div>
    <div class="row p-0 m-0">
      <div
        class="form-floating mb-3"
        [appValidateState]="editForm.get('status')"
      >
        <app-datalist-select
          [activeValue]="editForm?.value.status"
          [options]="StatusesOptions"
          (activeValueChange)="updateValue('status', $event)"
          [multiple]="false"
          label="Status"
        ></app-datalist-select>
      </div>
      <div
        *ngIf="isDoubleEvent || isDoubleSession"
        class="form-floating mb-3"
        [appValidateState]="editForm.get('statusD2')"
      >
        <app-datalist-select
          [activeValue]="editForm?.value.statusD2"
          [options]="StatusesOptions"
          (activeValueChange)="updateValue('statusD2', $event)"
          [multiple]="false"
          label="Status 2"
        ></app-datalist-select>
      </div>
      <div class="row mb-3 col-12 col-sm-6">
        <div class="col-4 col-sm-3 text-center">
          <div>Workshops/Seminar</div>
          <input
            id="workshopsSeminarType"
            value="workshopsSeminarType"
            formControlName="eventType"
            type="radio"
            class="form-check-input"
            placeholder="Double Event"
            autocomplete="off"
          />
        </div>
        <div class="col-4 col-sm-3 text-center">
          <div>Lunch/Dinner</div>
          <input
            id="lunchDinnerType"
            value="lunchDinnerType"
            formControlName="eventType"
            type="radio"
            class="form-check-input"
            placeholder="Double Event"
            autocomplete="off"
          />
        </div>
        <div class="col-4 col-sm-3 text-center">
          <div>Webinar</div>
          <input
            id="webinarType"
            value="webinarType"
            formControlName="eventType"
            type="radio"
            class="form-check-input"
            placeholder="Double Event"
            autocomplete="off"
          />
        </div>
        <!-- <div
          class="col-3"
          *ngIf="editedEvent?.id && editedEvent?.prebuildTopicId"
        >
          <a
            [routerLink]="
              '/admin/eventSchedule/custom-template/' + editedEvent?.id
            "
            class="btn btn-wfrBlue"
            >Edit Custom Web-Content</a
          >
        </div> -->
      </div>
      <!--<div class="form-floating mb-3" [appValidateState]="editForm.get('ads')">
    <app-datalist-select 
                         [activeValue]="editForm?.value.ads"
                         [options]="AdsOptions"
                         (activeValueChange)="updateValue('ads', $event)"
                         [multiple]="false"
                         label="Ads"
                         ></app-datalist-select>
  </div>-->
      <div
        class="form-floating mb-3"
        [appValidateState]="editForm.get('clientId')"
      >
        <app-datalist-select
          [activeValue]="editForm?.value.clientId"
          [options]="ClientsOptions"
          (activeValueChange)="changeClient('clientId', $event)"
          [multiple]="false"
          label="Client"
        ></app-datalist-select>
      </div>

      <div
        class="col-6 form-floating mb-3 row"
        [appValidateState]="editForm.get('doubleEvent')"
      >
        <div class="col-3">
          <div class="inpt-title" for="doubleEvent">Double Event</div>
          <input
            id="doubleEvent"
            formControlName="doubleEvent"
            type="checkbox"
            class="form-check-input"
            placeholder="Double Event"
            autocomplete="off"
            (change)="doubleCheck('Event')"
          />
        </div>
        <div class="col-3">
          <div class="inpt-title" for="doubleSession">2-day Event</div>
          <input
            id="doubleSession"
            formControlName="doubleSession"
            type="checkbox"
            class="form-check-input"
            autocomplete="off"
            (change)="doubleCheck('Session')"
          />
        </div>
        <span *ngIf="this.editedEvent?.id" class="col-6">
          <a
            *ngIf="!isDoubleSession"
            class="btn dark-wfrBlue text-white shadow-sm mx-1"
            (click)="rescheduleDate(1)"
          >
            Reschedule 1
          </a>
          <span *ngIf="isDoubleEvent">
            <a
              class="btn dark-wfrBlue text-white shadow-sm mx-1"
              (click)="rescheduleDate(2)"
            >
              Reschedule 2
            </a>
            <a
              class="btn dark-wfrBlue text-white shadow-sm mx-1"
              (click)="rescheduleDate(3)"
            >
              Reschedule Both
            </a>
          </span>
          <span *ngIf="isDoubleSession">
            <a
              class="btn dark-wfrBlue text-white shadow-sm mx-1"
              (click)="rescheduleDate(3)"
            >
              Reschedule Both
            </a>
          </span>
        </span>
      </div>
      <div
        class="col-6 form-floating mb-3"
        [appValidateState]="editForm.get('asap')"
      >
        <div class="inpt-title" for="asap">ASAP Setup</div>
        <input
          id="asap"
          formControlName="asap"
          type="checkbox"
          class="form-check-input"
          placeholder="ASAP Setup"
          autocomplete="off"
        />
      </div>

      <div class="form-floating mb-3 row">
        <div
          class="col-6 form-floating"
          [appValidateState]="editForm.get('date')"
        >
          <div class="inpt-title">Date 1</div>

          <div class="d-flex pe-50">
            <input
              class="inpt"
              (dateChange)="setStartDate($event)"
              formControlName="date"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>

        <div
          class="col-6 form-floating"
          *ngIf="isDoubleEvent || isDoubleSession"
          [appValidateState]="editForm.get('date2')"
        >
          <div class="inpt-title">Date 2</div>

          <div class="d-flex pe-50">
            <input
              class="inpt"
              (dateChange)="setStartDate2($event)"
              formControlName="date2"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-floating mb-3 row">
        <div class="col-6">
          <div class="form-floating mb-3">
            <div class="inpt-title">UTC Offset</div>
            <div class="inpt inputMutedColor h50px">
              {{ editForm?.value.utC_Offset_Date }}
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf="isDoubleEvent">
          <div class="form-floating mb-3">
            <div class="inpt-title">UTC Offset</div>
            <div class="inpt inputMutedColor h50px">
              {{ editForm?.value.utC_Offset_Date2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-floating mb-3 row">
        <div
          class="col-6 form-floating"
          [appValidateState]="editForm.get('atCost')"
        >
          <div class="inpt-title" for="atCost">At cost 1</div>
          <app-datalist-select
            [options]="AtCostOptions"
            [activeValue]="editForm?.value.atCost"
            (activeValueChange)="updateValue('atCost', $event)"
            [multiple]="false"
          ></app-datalist-select>
        </div>
        <div
          class="col-6 form-floating"
          [appValidateState]="editForm.get('atCostD2')"
          *ngIf="isDoubleEvent || isDoubleSession"
        >
          <div class="inpt-title" for="atCostD2">At cost 2</div>
          <app-datalist-select
            [options]="AtCostOptions"
            [activeValue]="editForm?.value.atCostD2"
            (activeValueChange)="updateValue('atCostD2', $event)"
            [multiple]="false"
          ></app-datalist-select>
        </div>
      </div>
      <div class="form-floating row mb-2">
        <div class="col-3" [appValidateState]="editForm.get('time')">
          <div class="inpt-title" for="time">Time 1</div>
          <input
            id="time"
            formControlName="time"
            type="text"
            class="inpt"
            placeholder="Time"
            autocomplete="off"
          />
        </div>
        <div class="form-floating mb-2 col-3">
          <app-datalist-select
            [options]="EventInfosOptions"
            (activeValueChange)="updateValue('prebuildTimeId', $event)"
            [activeValue]="editForm?.value.prebuildTimeId"
            [multiple]="false"
            label="Prebuilt Time 1"
          ></app-datalist-select>
        </div>
        <div
          *ngIf="isDoubleEvent || isDoubleSession"
          class="col-3"
          [appValidateState]="editForm.get('time2')"
        >
          <div class="inpt-title" for="time2">Time 2</div>
          <input
            id="time2"
            formControlName="time2"
            type="text"
            class="inpt"
            placeholder="Time"
            autocomplete="off"
          />
        </div>
        <div
          *ngIf="isDoubleEvent || isDoubleSession"
          class="form-floating col-3"
        >
          <app-datalist-select
            [options]="EventInfosOptions"
            (activeValueChange)="updateValue('prebuildTime2Id', $event)"
            [activeValue]="editForm?.value.prebuildTime2Id"
            [multiple]="false"
            label="Prebuilt Time 2"
          ></app-datalist-select>
        </div>
      </div>

      <div class="row form-floating mb-2">
        <div class="col-6 mb-2 form-floating">
          <div class="inpt-title">Start Time 1</div>
          <div class="ngx-timepicker-field-example brdrGray brdr-rad-8px">
            <ngx-timepicker-field
              [class.invalid-hui]="editForm.get('startTime')?.invalid"
              formControlName="startTime"
              [format]="24"
              [defaultTime]="'09:00'"
            ></ngx-timepicker-field>
          </div>
        </div>
        <div
          class="col-6 form-floating"
          *ngIf="isDoubleEvent || isDoubleSession"
        >
          <div class="inpt-title">Start Time 2</div>
          <div class="ngx-timepicker-field-example brdrGray brdr-rad-8px">
            <ngx-timepicker-field
              [class.invalid-hui]="editForm.get('startTime2')?.invalid"
              formControlName="startTime2"
              [format]="24"
              [defaultTime]="'09:00'"
            ></ngx-timepicker-field>
          </div>
        </div>
      </div>
      <div class="form-floating mb-3 row">
        <div class="col-6" [appValidateState]="editForm.get('landingPageUrl')">
          <div class="inpt-title" for="landingPageUrl">Landing Page Url 1</div>
          <input
            id="landingPageUrl"
            formControlName="landingPageUrl"
            type="text"
            class="inpt"
            placeholder="Landing Page Url"
            autocomplete="off"
          />
        </div>
        <div
          *ngIf="isDoubleEvent"
          class="col-6"
          [appValidateState]="editForm.get('landingPageUrl2')"
        >
          <div class="inpt-title" for="landingPageUrl2">Landing Page Url 2</div>
          <input
            id="landingPageUrl2"
            formControlName="landingPageUrl2"
            type="text"
            class="inpt"
            placeholder="Landing Page Url"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="form-floating mb-3 row">
        <div class="col-6">
          <div class="inpt-title" for="landingPageId">Landing Page ID 1</div>
          <input
            id="landingPageId"
            formControlName="landingPageId"
            type="text"
            class="inpt"
            placeholder="Landing Page ID"
            autocomplete="off"
            readonly
          />
        </div>
        <div *ngIf="isDoubleEvent" class="col-6">
          <div class="inpt-title">Landing Page ID 2</div>
          <input
            formControlName="landingPageId2"
            type="text"
            class="inpt"
            autocomplete="off"
            placeholder="Landing Page ID"
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-floating mb-3">
          <div class="inpt-title">Google Sheet 1</div>
          <input
            id="googleSheet"
            formControlName="googleSheet"
            type="text"
            class="inpt"
            placeholder="Google Sheet Url"
            autocomplete="off"
          />
        </div>
        <div
          *ngIf="isDoubleEvent"
          class="col-6 form-floating mb-3"
          [appValidateState]="editForm.get('googleSheetD2')"
        >
          <div class="inpt-title" for="googleSheetD2">Google Sheet 2</div>
          <input
            id="googleSheetD2"
            formControlName="googleSheetD2"
            type="text"
            class="inpt"
            placeholder="Google Sheet Url"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-floating mb-3">
          <div class="inpt-title">Google Sheet ID 1</div>
          <input
            id="googleSheet"
            formControlName="googleSheetId"
            type="text"
            class="inpt"
            placeholder="Google Sheet ID"
            autocomplete="off"
          />
        </div>
        <div *ngIf="isDoubleEvent" class="col-6 form-floating mb-3">
          <div class="inpt-title" for="googleSheetID2">Google Sheet ID 2</div>
          <input
            id="googleSheetID2"
            formControlName="googleSheetId2"
            type="text"
            class="inpt"
            placeholder="Google Sheet ID"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="row">
        <div
          class="col-3 form-floating mb-3"
          [appValidateState]="editForm.get('use_catchlight')"
        >
          <div class="inpt-title" for="asap">Use Catchlight 1</div>
          <input
            id="use_catchlight"
            formControlName="use_catchlight"
            type="checkbox"
            class="form-check-input"
            autocomplete="off"
          />
        </div>
        <div
          *ngIf="isDoubleEvent"
          class="col-3 form-floating mb-3"
          [appValidateState]="editForm.get('use_catchlight')"
        >
          <div class="inpt-title" for="asap">Use Catchlight 2</div>
          <input
            id="use_catchlight"
            formControlName="use_catchlight_2"
            type="checkbox"
            class="form-check-input"
            autocomplete="off"
          />
        </div>

        <div
          class="col-3 form-floating mb-3"
          [appValidateState]="editForm.get('promptInvestibleAssets')"
        >
          <div class="inpt-title" for="asap">Ask Investible Assets</div>
          <input
            id="promptInvestibleAssets"
            formControlName="promptInvestibleAssets"
            type="checkbox"
            class="form-check-input"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="row">
        <div
          class="col-6 form-floating mb-3"
          [appValidateState]="editForm.get('attendees')"
        >
          <div class="inpt-title" for="attendees">Attendees 1</div>
          <input
            id="attendees"
            formControlName="attendees"
            type="number"
            min="0"
            class="inpt"
            placeholder="Attendees"
            autocomplete="off"
          />
        </div>
        <div
          *ngIf="isDoubleEvent || isDoubleSession"
          class="col-6 form-floating mb-3"
          [appValidateState]="editForm.get('attendeesD2')"
        >
          <div class="inpt-title" for="attendeesD2">Attendees 2</div>
          <input
            id="attendeesD2"
            formControlName="attendeesD2"
            type="number"
            min="0"
            class="inpt"
            placeholder="Attendees 2"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="col-6 form-floating mb-3"
          [appValidateState]="editForm.get('finalRegistrants')"
        >
          <div class="inpt-title" for="finalRegistrants">
            Final Registrants 1
          </div>
          <input
            id="finalRegistrants"
            formControlName="finalRegistrants"
            type="number"
            min="0"
            class="inpt"
            placeholder="Final Registrants 1"
            autocomplete="off"
          />
        </div>
        <div
          *ngIf="isDoubleEvent || isDoubleSession"
          class="col-6 form-floating mb-3"
          [appValidateState]="editForm.get('finalRegistrantsD2')"
        >
          <div class="inpt-title" for="finalRegistrantsD2">
            Final Registrants 2
          </div>
          <input
            id="finalRegistrantsD2"
            formControlName="finalRegistrantsD2"
            type="number"
            min="0"
            class="inpt"
            placeholder="Final Registrants 2"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 form-floating mb-3">
          <div *ngIf="!RoomsOptions" class="form-floating">
            <div class="inpt-title" for="location">Room</div>
            <input
              class="inpt"
              placeholder="Please set Location"
              disabled
              autocomplete="off"
            />
          </div>
          <div
            class="form-floating"
            [appValidateState]="editForm.get('roomId')"
            *ngIf="RoomsOptions"
          >
            <app-datalist-select
              [activeValue]="editForm?.value.roomId"
              [options]="RoomsOptions"
              (activeValueChange)="changeRoom('roomId', $event)"
              [multiple]="false"
              label="Room"
            ></app-datalist-select>
          </div>
        </div>
        <div
          class="col-6 form-floating mb-3"
          *ngIf="isDoubleEvent || isDoubleSession"
        >
          <div *ngIf="!RoomsOptions" class="form-floating">
            <div class="inpt-title">Room 2</div>
            <input
              class="inpt"
              placeholder="Please set Location"
              disabled
              autocomplete="off"
            />
          </div>
          <div
            class="form-floating"
            [appValidateState]="editForm.get('roomD2Id')"
            *ngIf="RoomsOptions"
          >
            <app-datalist-select
              [activeValue]="editForm?.value.roomD2Id"
              [options]="RoomsOptions"
              (activeValueChange)="changeRoom('roomD2Id', $event)"
              [multiple]="false"
              label="Room 2"
            ></app-datalist-select>
          </div>
        </div>
      </div>
      <div
        class="col-6 form-floating mb-3"
        [appValidateState]="editForm.get('wfrDinnerOrLunch')"
      >
        <div class="inpt-title" for="wfrDinnerOrLunch">WFR Dinner / Lunch</div>
        <input
          id="wfrDinnerOrLunch"
          formControlName="wfrDinnerOrLunch"
          type="checkbox"
          class="form-check-input"
          placeholder="WFR Dinner / Lunch"
          autocomplete="off"
          (change)="
            changeWFRor2part('WFR', editForm.get('wfrDinnerOrLunch').value)
          "
        />
      </div>
      <div
        class="col-6 form-floating mb-3"
        [appValidateState]="editForm.get('twoPartSetup')"
      >
        <div class="inpt-title" for="twoPartSetup">2-part setup</div>
        <input
          id="twoPartSetup"
          formControlName="twoPartSetup"
          type="checkbox"
          class="form-check-input"
          placeholder="WFR Dinner / Lunch"
          autocomplete="off"
          (change)="
            changeWFRor2part('twoPartSetup', editForm.get('twoPartSetup').value)
          "
        />
      </div>
      <div *ngIf="!scheduleLocationsOptions" class="form-floating mb-3">
        <div class="inpt-title" for="location">Location</div>
        <input
          class="inpt"
          placeholder="Please set Client"
          disabled
          autocomplete="off"
        />
      </div>
      <div class="px-3" *ngIf="editedEvent && editedEvent!.newVenue">
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>Holy Guacamole!</strong> - Client is requesting to use new
          venue.
          <button
            type="button"
            class="btn close"
            data-dismiss="alert"
            aria-label="Close"
            (click)="editedEvent!.newVenue = false"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div
        class="form-floating mb-3"
        [appValidateState]="editForm.get('eventScheduleLocationId')"
        *ngIf="scheduleLocationsOptions"
      >
        <app-datalist-select
          [activeValue]="editForm?.value.eventScheduleLocationId"
          [options]="scheduleLocationsOptions"
          (activeValueChange)="
            changeLocation('eventScheduleLocationId', $event)
          "
          [multiple]="false"
          label="Location"
        ></app-datalist-select>
      </div>

      <div *ngIf="SelectedLocation" class="form-floating mb-3">
        <div class="inpt-title">Address</div>
        <div class="inpt inputMutedColor">
          {{ SelectedLocation.address }}
        </div>
      </div>

      <div
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('parking')"
      >
        <div class="inpt-title" for="parking">Parking</div>
        <input
          id="parking"
          formControlName="parking"
          type="text"
          class="inpt"
          placeholder="Parking"
          autocomplete="off"
        />
      </div>

      <div
        *ngIf="isDoubleEvent"
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('parkingD2')"
      >
        <div class="inpt-title" for="parking2">Parking 2</div>
        <input
          id="parking2"
          formControlName="parkingD2"
          type="text"
          class="inpt"
          placeholder="Parking 2"
          autocomplete="off"
        />
      </div>

      <div
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('desiredBU')"
      >
        <div class="inpt-title" for="desiredBU">Desired BU's/Room Capacity</div>
        <input
          id="desiredBU"
          formControlName="desiredBU"
          type="text"
          class="inpt"
          placeholder="Desired BU's/Room Capacity"
          autocomplete="off"
        />
      </div>
      <div
        *ngIf="isDoubleEvent"
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('desiredBUD2')"
      >
        <div class="inpt-title" for="desiredBUD2">
          Desired BU's/Room Capacity 2
        </div>
        <input
          id="desiredBUD2"
          formControlName="desiredBUD2"
          type="text"
          class="inpt"
          placeholder="Desired BU's/Room Capacity 2"
          autocomplete="off"
        />
      </div>
      <div class="mb-3 d-flex">
        <span (click)="showModal('location')" class="btn btn-wfrBlue mx-5"
          >Add Venue</span
        >
        <span (click)="showModal('room')" class="btn btn-wfrBlue mx-5"
          >Add Room</span
        >
      </div>
      <div
        class="col-6 form-floating mb-3"
        [appValidateState]="editForm.get('topic')"
      >
        <div class="inpt-title" for="topic">Topic</div>
        <input
          id="topic"
          formControlName="newTopic"
          type="text"
          class="inpt"
          placeholder="Topic"
          autocomplete="off"
        />
        <!-- (input)="changeTopic()" -->
      </div>
      <div class="form-floating mb-3 col-6">
        <app-datalist-select
          [options]="TopicsOptions"
          [activeValue]="editForm?.value.prebuildTopicId"
          (activeValueChange)="prebuiltTopic($event)"
          [multiple]="false"
          label="Prebuilt Topic"
        ></app-datalist-select>
      </div>
      <div
        class="col-12 form-floating mb-3"
        [appValidateState]="editForm.get('topicNotes')"
      >
        <div class="inpt-title" for="topicNotes">Topic Notes</div>
        <input
          id="topicNotes"
          formControlName="topicNotes"
          type="text"
          class="inpt"
          placeholder="Topic Notes"
          autocomplete="off"
        />
      </div>
      <div class="form-floating col mb-3" *ngIf="SelectedClient">
        <a
          class="btn dark-wfrBlue text-white shadow-sm mx-1 h40px nowrap"
          (click)="openUrlInNewTab(SelectedClient?.clientSpecificInstructions)"
        >
          {{ SelectedClient?.clientCode }} - CSI
        </a>
      </div>
      <div *ngIf="SelectedLocation" class="row mb-3">
        <div class="form-floating col">
          <div class="inpt-title">Venue Specific Notes</div>
          <textarea
            class="inpt-lg inpt enableScrollInpt"
            id="venueSpecificNotes"
            formControlName="venueSpecificNotes"
            type="text"
            [readonly]="true"
            placeholder="Venue Specific Notes"
            autocomplete="off"
          ></textarea>
        </div>
        <div class="d-flex w50px">
          <div class="wh-50px">
            <div class="form-group form-floating mb-3 w-100 pt-3">
              <div
                (click)="VSNComponent?.resetEditFrom()"
                class="btn btn-wfrBlue shadow-sm w-100"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#vsnModal"
              >
                <img
                  class="whiteColorFilter"
                  src="../../../../../assets/img/edit19x19.svg"
                />
              </div>
            </div>

            <!-- <a
              class="d-flex justify-content-around btn dark-wfrBlue text-white"
            >
            </a> -->
          </div>
        </div>
      </div>
      <div class="form-floating mb-3">
        <div class="inpt-title" for="notes">One Time Notes</div>
        <textarea
          class="inpt-lg inpt"
          id="notes"
          formControlName="notes"
          type="text"
          placeholder="Notes"
          autocomplete="off"
        ></textarea>
      </div>
      <div
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('wfrReminder')"
      >
        <div class="inpt-title" for="remiderProccess">Reminder Process</div>
        <input
          id="remiderProccess"
          formControlName="wfrReminder"
          type="text"
          class="inpt"
          placeholder="Reminder Process"
          autocomplete="off"
        />
      </div>
      <div class="form-floating mb-3 col-6">
        <app-datalist-select
          [options]="EventInfosOptions"
          (activeValueChange)="prebuiltEventInfo($event, 'wfrReminder')"
          [multiple]="false"
          label="Pre-built Reminder Process"
        ></app-datalist-select>
      </div>
      <h2>To do's / Actions</h2>
    </div>
    <div class="px-2">
      <h4>Process</h4>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="text-center">Date</th>
            <th class="w30per text-center" colspan="2">
              <span>Email Blasts</span>

              <div class="row">
                <div class="col-6">1st</div>
                <div class="col-6">2nd</div>
              </div>
            </th>
            <th class="text-center">Scheduled</th>
            <th class="w15per text-center">Few Spots Left</th>
            <th class="text-center">Scheduled</th>
            <!-- <th class="w15per text-center">VM Code</th>
            <th class="w15per text-center">5 day WFR Call</th>
            <th class="text-center">Done</th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label class="dateLabelTable">
                {{ editForm.get("formatedDate").value }}
              </label>
            </td>
            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ editForm.get('emailBlast1st').value }}"
              />
            </td>

            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ editForm.get('emailBlast2nd').value }}"
              />
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="emailBlastScheduled"
              />
            </td>
            <td>
              <div class="text-center">
                <input
                  class="inpt"
                  disabled
                  autocomplete="off"
                  placeholder="{{ editForm.get('fewSpots').value }}"
                />
              </div>
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="fewSpotsScheduled"
              />
            </td>
          </tr>
          <tr *ngIf="isDoubleEvent">
            <td>
              <label class="dateLabelTable">
                {{ editForm.get("formatedDate2").value }}
              </label>
            </td>
            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ editForm.get('emailBlast1stD2').value }}"
              />
            </td>

            <td>
              <input
                class="inpt"
                disabled
                autocomplete="off"
                placeholder="{{ editForm.get('emailBlast2ndD2').value }}"
              />
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="emailBlastD2Scheduled"
              />
            </td>
            <td>
              <div class="text-center">
                <input
                  class="inpt"
                  disabled
                  autocomplete="off"
                  placeholder="{{ editForm.get('fewSpotsD2').value }}"
                />
              </div>
            </td>
            <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="fewSpotsD2Scheduled"
              />
            </td>
            <!-- <td>
              <input
                class="inpt"
                autocomplete="off"
                placeholder="Vm Code 2"
                formControlName="vmCodeD2"
              />
            </td> -->
            <!-- <td>
              <div class="d-flex pe-50">
                <input
                  class="inpt"
                  (dateChange)="setFiveDay($event, 2)"
                  formControlName="fiveDayWfrCallD2Date"
                  matInput
                  [matDatepicker]="fiveDayWfrCallD2"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="fiveDayWfrCallD2"
                ></mat-datepicker-toggle>
                <mat-datepicker #fiveDayWfrCallD2></mat-datepicker>
              </div>
            </td> -->
            <!-- <td class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="fiveDayWfrCallD2Done"
              />
            </td> -->
          </tr>
        </tbody>
      </table>
      <h4>Calls</h4>
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center">Date</th>

              <th class="text-center">WFR Call 1</th>
              <th class="text-center w18per">Assigned to</th>
              <th class="text-center">Done</th>
              <th class="text-center">WFR Call 2</th>
              <th class="text-center w18per">Assigned to</th>
              <th class="text-center">Done</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label class="dateLabelTable">
                  {{ editForm.get("formatedDate").value }}
                </label>
              </td>
              <td class="text-center">
                <div class="d-flex pe-50">
                  <input
                    class="inpt"
                    matInput
                    [matDatepicker]="WfrCall1"
                    formControlName="wfrCall1Date"
                    (dateChange)="updateValue('wfrCall1Date', $event.value)"
                  />

                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="WfrCall1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WfrCall1></mat-datepicker>
                </div>
              </td>
              <td>
                <div
                  class="form-floating mb-3"
                  [appValidateState]="editForm.get('wfrCall1FunnelId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [options]="FunnelsOptions"
                    [multiple]="false"
                    label=""
                    [activeValue]="editForm?.value.wfrCall1FunnelId"
                    (activeValueChange)="
                      updateValue('wfrCall1FunnelId', $event)
                    "
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="wfrCall1Done"
                />
              </td>

              <td class="text-center">
                <div class="d-flex pe-50">
                  <input
                    class="inpt"
                    matInput
                    [matDatepicker]="WfrCall2"
                    formControlName="wfrCall2Date"
                    (dateChange)="updateValue('wfrCall2Date', $event.value)"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="WfrCall2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WfrCall2></mat-datepicker>
                </div>
              </td>
              <td>
                <div
                  class="form-floating mb-3"
                  [appValidateState]="editForm.get('wfrCall2FunnelId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [options]="FunnelsOptions"
                    [multiple]="false"
                    label=""
                    [activeValue]="editForm?.value.wfrCall2FunnelId"
                    (activeValueChange)="
                      updateValue('wfrCall2FunnelId', $event)
                    "
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="wfrCall2Done"
                />
              </td>
            </tr>
            <tr *ngIf="isDoubleEvent">
              <td>
                <label class="dateLabelTable">
                  {{ editForm.get("formatedDate2").value }}
                </label>
              </td>
              <td class="text-center">
                <div class="d-flex pe-50">
                  <input
                    class="inpt"
                    matInput
                    [matDatepicker]="WfrCall1"
                    formControlName="wfrCall1D2Date"
                    (dateChange)="updateValue('wfrCall1D2Date', $event.value)"
                  />

                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="WfrCall1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WfrCall1></mat-datepicker>
                </div>
              </td>
              <td>
                <div
                  class="form-floating mb-3"
                  [appValidateState]="editForm.get('wfrCall1D2FunnelId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [options]="FunnelsOptions"
                    [multiple]="false"
                    label=""
                    [activeValue]="editForm?.value.wfrCall1D2FunnelId"
                    (activeValueChange)="
                      updateValue('wfrCall1D2FunnelId', $event)
                    "
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="wfrCall1D2Done"
                />
              </td>

              <td class="text-center">
                <div class="d-flex pe-50">
                  <input
                    class="inpt"
                    matInput
                    [matDatepicker]="WfrCall2"
                    formControlName="wfrCall2D2Date"
                    (dateChange)="updateValue('wfrCall2D2Date', $event.value)"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="WfrCall2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WfrCall2></mat-datepicker>
                </div>
              </td>
              <td>
                <div
                  class="form-floating mb-3"
                  [appValidateState]="editForm.get('wfrCall2D2FunnelId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [options]="FunnelsOptions"
                    [multiple]="false"
                    label=""
                    [activeValue]="editForm?.value.wfrCall2D2FunnelId"
                    (activeValueChange)="
                      updateValue('wfrCall2D2FunnelId', $event)
                    "
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="wfrCall2D2Done"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>Set-Up</h4>
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center dateHeader">Date</th>

              <th
                class="bg-light-blue header-left text-white w18per text-center"
              >
                Funnel & Automation
              </th>
              <th class="bg-light-blue header-right text-white text-center">
                Done
              </th>
              <th
                class="bg-light-green header-left text-white w18per text-center"
              >
                QA by
              </th>
              <th class="bg-light-green header-right text-white text-center">
                QA Done
              </th>
              <th
                class="bg-light-blue header-left text-white w18per text-center"
              >
                Ads
              </th>
              <th class="bg-light-blue header-right text-white text-center">
                Done
              </th>
              <th
                class="bg-light-green header-left text-white w18per text-center"
              >
                QA by
              </th>
              <th class="bg-light-green header-right text-white text-center">
                QA Done
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="text-center td-date"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <label class="dateLabelTable">
                  {{ editForm.get("formatedDate").value }}
                </label>
              </td>

              <td
                class="w18per td-left"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="updateValue('funnelId', $event)"
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelDone"
                />
              </td>
              <td
                class="w18per td-left"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelQAbyId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelQAbyId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="updateValue('funnelQAbyId', $event)"
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelQADone"
                />
              </td>
              <td
                class="w18per td-left"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelAdsId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelAdsId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="updateValue('funnelAdsId', $event)"
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelAdsDone"
                />
              </td>
              <td
                class="w18per td-left"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelAdsQAbyId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelAdsQAbyId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="updateValue('funnelAdsQAbyId', $event)"
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td
                class="text-center td-right"
                [class.td-bottom]="!editForm.get('doubleEvent').value"
                [class.no-border-bottom]="editForm.get('doubleEvent').value"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelAdsQADone"
                />
              </td>
            </tr>
            <tr *ngIf="isDoubleEvent">
              <td class="text-center date-bottom">
                <label class="dateLabelTable">
                  {{ editForm.get("formatedDate2").value }}
                </label>
              </td>

              <td class="w18per td-left-bottom">
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelSecondId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelSecondId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="updateValue('funnelSecondId', $event)"
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelSecondDone"
                />
              </td>
              <td class="w18per td-left-bottom">
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelSecondQAbyId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelSecondQAbyId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="
                      updateValue('funnelSecondQAbyId', $event)
                    "
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelSecondQADone"
                />
              </td>
              <td class="w18per td-left-bottom">
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelSecondAdsId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelSecondAdsId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="
                      updateValue('funnelSecondAdsId', $event)
                    "
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelSecondAdsDone"
                />
              </td>
              <td class="w18per td-left-bottom">
                <div
                  class="w8vw form-floating mb-3"
                  [appValidateState]="editForm.get('funnelSecondAdsQAbyId')"
                >
                  <app-datalist-select
                    class="w-100"
                    [activeValue]="editForm?.value.funnelSecondAdsQAbyId"
                    [options]="FunnelsOptions"
                    (activeValueChange)="
                      updateValue('funnelSecondAdsQAbyId', $event)
                    "
                    [multiple]="false"
                    label=""
                  ></app-datalist-select>
                </div>
              </td>
              <td class="text-center td-right-bottom">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="funnelSecondAdsQADone"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h6>Total Ad Spent</h6>
    <div class="form-group mb-3 row p-0 m-0">
      <div
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('metaAds')"
      >
        <div class="inpt-title" for="metaAds">Meta Ads spend</div>
        <input
          id="metaAds"
          formControlName="metaAds"
          type="text"
          class="inpt"
          placeholder="Meta Ads Spend"
          autocomplete="off"
        />
      </div>
      <div
        class="form-floating mb-3 col-6"
        [appValidateState]="editForm.get('otherAds')"
      >
        <div class="inpt-title" for="otherAds">Other Ads spend</div>
        <input
          id="otherAds"
          formControlName="otherAds"
          type="text"
          class="inpt"
          placeholder="Other Ads Spend"
          autocomplete="off"
        />
      </div>
    </div>

    <!-- adSpentNotes -->
    <div class="form-floating mb-3">
      <div class="inpt-title" for="adSpentNotes">Ad Spent Notes</div>
      <textarea
        class="inpt-lg inpt"
        id="adSpentNotes"
        formControlName="adSpentNotes"
        type="text"
        placeholder="Ad Spent Notes"
        autocomplete="off"
      ></textarea>
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('payStats')"
    >
      <app-datalist-select
        [activeValue]="editForm?.value.payStats"
        [options]="payStatsOptions"
        (activeValueChange)="updateValue('payStats', $event)"
        [multiple]="false"
        label="Paid"
      ></app-datalist-select>
    </div>

    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('reminder')"
    >
      <div class="inpt-title" for="reminder">Internal WFR reminder</div>
      <input
        id="reminder"
        formControlName="reminder"
        type="text"
        class="inpt"
        placeholder="Internal WFR reminder"
        autocomplete="off"
      />
    </div>
    <div class="h-100 pb-3 d-flex justify-content-center">
      <button type="submit" class="btn btn-wfrBlue shadow-sm w-100">
        <span *ngIf="editedEvent?.isInReview"> Approve </span>
        <span *ngIf="!editedEvent?.isInReview">Save</span>
      </button>
      <ng-container *ngIf="editedEvent?.isInReview">
        <div
          class="d-flex ms-1 btn dark-wfrBlue shadow-sm d-flex w-100"
          (click)="saveWithoutApprove()"
        >
          <a
            class="d-flex justify-content-center dark-wfrBlue text-white w-100 textDecorationNone"
          >
            <span>Save</span>
          </a>
        </div>
        <div
          *ngIf="editedEvent?.isInReview"
          class="d-flex ms-1 shadow-sm d-flex w-100"
        >
          <a
            *ngIf="!deleteLoading; else deleteLoadingBlockBotton"
            class="d-flex justify-content-center text-white w-100 btn btn-danger textDecorationNone"
            (click)="deleteConfirmation = true"
          >
            <span>Delete</span>
          </a>
          <ng-template #deleteLoadingBlockBotton
            ><app-spinner></app-spinner>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</form>

<div id="myModal" class="modal">
  <div class="responsive">
    <div class="modal-content hide-scrollbar">
      <div class="d-flex modalHeader pb-2">
        <div
          class="d-flex justify-content-center align-items-center BlowModalIcon"
        >
          <svg
            width="22"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              s
            />
          </svg>
        </div>
        <div
          class="w-100 d-flex justify-content-between align-items-center ps-2"
        >
          <h5 class="m-0">Add {{ popUpTab }}</h5>
        </div>
        <span class="close" (click)="hidemodal()">
          <svg
            width="12"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <div class="marginsModal">
        <form enctype="multipart/form-data">
          <app-pop-up-iframe
            (SubmitRoom)="reloadRooms()"
            (SubmitLocation)="reloadlocations()"
            [tabSelected]="popUpTab"
          ></app-pop-up-iframe>
          <!--[clientId]="SelectedClientId" [locationId]="SelectedLocationId"-->
        </form>
      </div>
    </div>
  </div>
</div>

<app-venue-specific-notes
  [editedStr]="editForm.get('venueSpecificNotes').value"
  [editedLocationId]="editForm.get('eventScheduleLocationId').value"
  (ConfirmEdit)="updateVenueSpecifiNotes('venueSpecificNotes', $event)"
></app-venue-specific-notes>

<app-confirm-delete
  id="pendingConfirmModal"
  *ngIf="deleteConfirmation"
  [deleteCandidate]="'Pending Event'"
  (confirm)="deleteEvent()"
  (cancel)="deleteConfirmation = false"
></app-confirm-delete>
