import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timelogs',
  templateUrl: './timelogs.component.html',
  styleUrls: ['./timelogs.component.scss'],
})
export class TimelogsComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    console.log();
  }
}
