import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-seed-time-zones-page',
  templateUrl: './seed-time-zones-page.component.html',
  styleUrls: ['./seed-time-zones-page.component.scss'],
})
export class SeedTimeZonesPageComponent implements OnInit {
  constructor(private service: EventScheduleService, private router: Router) {}
  responseGet = false;
  ngOnInit(): void {
    this.service.runTimeZoneSeeder().subscribe((x) => {
      this.router.navigate(['home']);
    });
  }
}
