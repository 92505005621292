<div class="w-100 btn-group btn-group-lg p-0 m-0 col" role="group">
  <button
    [disabled]="tabSelected2 === 'overview'"
    (click)="setTab('overview')"
    class="btn btn-wfrBlue p-1 tabSize"
  >
    Overview
  </button>
  <button
    [disabled]="tabSelected2 === 'ads-view'"
    class="btn btn-wfrBlue p-1 tabSize"
    (click)="setTab('ads-view')"
  >
    Ads-view
  </button>
  <button
    [disabled]="tabSelected2 === 'pay/stats'"
    (click)="setTab('pay/stats')"
    class="btn btn-wfrBlue p-1 tabSize"
  >
    Pay/stats
  </button>
  <button
    [disabled]="tabSelected2 === 'to-Do'"
    (click)="setTab('to-Do')"
    class="btn btn-wfrBlue p-1 tabSize"
  >
    To-Do
  </button>
  <button
    *ngIf="authRepo.isShowFinancials$ | async"
    [disabled]="tabSelected2 === 'financials'"
    (click)="setTab('financials')"
    class="btn btn-wfrBlue p-1 tabSize"
  >
    Financials
  </button>
</div>
<div title="Export" class="w-100 d-flex justify-content-end">
  <button class="btn" (click)="exportEmit.emit()">
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21H21M12 3V17M12 17L19 10M12 17L5 10"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>
