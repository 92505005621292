import { Component, OnInit } from '@angular/core';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';

@Component({
  selector: 'app-footerinfo',
  templateUrl: './footerinfo.component.html',
  styleUrls: ['./footerinfo.component.scss'],
})
export class FooterinfoComponent implements OnInit {
  year = new Date().getFullYear();

  constructor(public env: EnvState) {}

  ngOnInit(): void {}
}
