<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="row mb-2">
    <div class="col">
      <h6 class="my-1" i18n="Basic info label">Basic Information</h6>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Name</div>
        <input
          formControlName="name"
          type="text"
          class="inpt"
          placeholder="Name"
          i18n-placeholder="User name placeholder @@name"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Surname</div>
        <input
          formControlName="surname"
          type="text"
          class="inpt"
          placeholder="Surname"
          i18n-placeholder="User surname placeholder @@userSurname"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        [appValidateState]="form.get('email')"
        class="form-group form-floating mb-3"
      >
        <div class="inpt-title">Email</div>
        <input
          formControlName="email"
          type="email"
          class="inpt"
          placeholder="Email"
          i18n-placeholder="User email placeholder @@userEmail"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Phone number</div>
        <input
          formControlName="phone"
          type="tel"
          class="inpt"
          placeholder="Phone number"
          i18n-placeholder="User phone number placeholder @@userPhoneNum"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="d-flex p-0">
        <div
          class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6667 2.99984H7.4C5.15979 2.99984 4.03969 2.99984 3.18404 3.43581C2.43139 3.8193 1.81947 4.43123 1.43597 5.18388C1 6.03952 1 7.15963 1 9.39984V20.5998C1 22.84 1 23.9602 1.43597 24.8158C1.81947 25.5685 2.43139 26.1804 3.18404 26.5639C4.03969 26.9998 5.15979 26.9998 7.4 26.9998H19.6667C20.9066 26.9998 21.5266 26.9998 22.0353 26.8635C23.4156 26.4937 24.4938 25.4155 24.8637 24.0351C25 23.5264 25 22.9065 25 21.6665M22.3333 9.6665V1.6665M18.3333 5.6665H26.3333M11 10.3332C11 11.8059 9.80609 12.9998 8.33333 12.9998C6.86057 12.9998 5.66667 11.8059 5.66667 10.3332C5.66667 8.86041 6.86057 7.6665 8.33333 7.6665C9.80609 7.6665 11 8.86041 11 10.3332ZM16.9867 14.8907L5.7082 25.1439C5.07382 25.7206 4.75663 26.009 4.72857 26.2588C4.70425 26.4753 4.78727 26.69 4.95091 26.8339C5.13971 26.9998 5.56837 26.9998 6.42571 26.9998H18.9413C20.8602 26.9998 21.8196 26.9998 22.5732 26.6775C23.5193 26.2728 24.2729 25.5191 24.6776 24.5731C25 23.8195 25 22.86 25 20.9411C25 20.2955 25 19.9727 24.9294 19.672C24.8407 19.2942 24.6706 18.9403 24.431 18.635C24.2403 18.3921 23.9883 18.1904 23.4841 17.7871L19.7544 14.8034C19.2499 14.3997 18.9976 14.1979 18.7197 14.1267C18.4748 14.0639 18.2172 14.072 17.9767 14.1501C17.7039 14.2387 17.4648 14.456 16.9867 14.8907Z"
              stroke="#475467"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="d-flex form-floating col file-inpt displayNoneOnSmallScreens"
        >
          <input
            id="custom-file-input"
            formControlName="picture"
            type="file"
            placeholder="Profile picture"
            i18n-placeholder="
              User profile picture placeholder @@userProfilePict"
            class="form-control mt-3"
          />
          <label for="file" i18n="@@ImageFile"
            >Click to upload or drag and drop image
          </label>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Phone number</div>
        <input
          formControlName="location"
          type="text"
          class="inpt"
          placeholder="Location"
          i18n-placeholder="User location placeholder @@userLocation"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Title</div>
        <input
          formControlName="title"
          type="text"
          class="inpt"
          placeholder="Title"
          i18n-placeholder="Title placeholder @@title"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">Date of birth</div>
        <input
          formControlName="birthday"
          type="date"
          class="inpt"
          placeholder="Date of birth"
          i18n-placeholder="User birthday @@userBirthday"
        />
      </div>
    </div>
    <div
      *ngIf="active2Fa?.isTfaEnabled"
      class="col-12 col-md-6 col-lg-4 d-flex align-self-center d-flex"
    >
      <div class="w-50 me-1">
        <div class="form-group form-floating mb-3 w-100 pt-3">
          <div (click)="disableTfa()" class="btn btn-wfrBlue shadow-sm w-100">
            Delete 2FA
          </div>
        </div>
      </div>
      <div class="w-50 ms-1">
        <div class="form-group form-floating mb-3 w-100 pt-3">
          <div
            class="btn btn-wfrBlue shadow-sm w-100"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#creds2faPop"
          >
            Show 2Fa Credentials
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="!initialValue">
      <div
        [appValidateState]="form.get('password')"
        class="form-group form-floating mb-3"
      >
        <div class="inpt-title">Password</div>
        <input
          formControlName="password"
          type="password"
          class="inpt"
          placeholder="Password"
          required
          i18n-placeholder="User password @@userPassword"
        />
      </div>
    </div>
    <div
      class="col-12 col-md-6 col-lg-4"
      *ngIf="tenantOptions && tenantOptions.length"
    >
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('tenant')?.hasError('required') &&
          form.get('tenant')?.touched
        "
      >
        <app-datalist-select
          id="tenantSelect"
          [options]="tenantOptions"
          [(activeValue)]="form.value.tenant"
          (activeValueChange)="updateValue('tenant', $event)"
          label="Tenant"
          i18n-label="Label tenant"
        ></app-datalist-select>
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="roleOptions && roleOptions.length">
    <div class="col">
      <h6 class="my-1" i18n="Label roles">Roles</h6>
    </div>
  </div>
  <div class="row mb-3" *ngIf="roleOptions && roleOptions.length">
    <div class="col">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <div class="card">
          <ul class="list-group list-group-horizontal bg-none">
            <li
              class="no-outer-border list-group-item flex-fill w-100"
              *ngFor="let role of roleOptions; let roleIndex = index"
              formArrayName="roles"
            >
              <div class="row">
                <div class="col-auto pr-0 align-self-center text-end">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [formControlName]="roleIndex"
                      [attr.id]="'checkbox-' + role"
                    />
                    <!-- (change)="validateRoles()" -->

                    <label
                      class="form-check-label"
                      [attr.for]="'checkbox-' + role"
                    ></label>
                  </div>
                </div>
                <div class="col">
                  <h6 class="mb-1">{{ ROLE_NAMES[role] }}</h6>
                  <p class="text-muted small">{{ ROLE_DESCRIPTIONS[role] }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
          Save
        </button>
      </div>
    </div>
    <div
      class="col-12 col-md-6 col-lg-4"
      *ngIf="initialValue?.twoFactorEnabled == true && !editProfile"
    >
      <div
        class="btn btn-wfrBlue shadow-sm w-100"
        (click)="disableTfaForEditUser(initialValue?.email ?? '')"
      >
        Delete 2fa
      </div>
    </div>
  </div>
</form>
<app-show2fa-creds-pop-up [Tfa]="active2Fa"></app-show2fa-creds-pop-up>
