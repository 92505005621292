import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ICompanyMarker,
  IContact,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { ContactsRepository } from '../../../../state/contacts.repository';
import { ContactsService } from '../../../../state/contacts.service';
import { Location } from '@angular/common';
import { ClientUsersService } from 'src/app/state/clientUsers.service';
import {
  ClientUsersRepository,
  IClientUser,
} from 'src/app/state/clientUsers.repository';
@Component({
  selector: 'app-client-users-create-page',
  templateUrl: './client-users-create-page.component.html',
  styleUrls: ['./client-users-create-page.component.scss'],
})
export class ClientUsersCreatePageComponent {
  constructor(
    public service: ClientUsersService,
    public clientService: CompaniesService,
    public repo: ClientUsersRepository,
    public location: Location,
    private route: ActivatedRoute
  ) {}
  user: IClientUser | null = null;
  company: ICompanyMarker | null = null;
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  clients: ICompanyMarker[] = [];
  ngOnInit(): void {
    this.clientService.loadAll().subscribe((x) => (this.clients = x));
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type')!;
        this.id = value.get('id')!;
        if (this.id && this.type != 'new') {
          this.service
            .loadOneClientUser(`${value.get('id')}`)
            .subscribe((y) => {
              this.user = y;
            });
        }
      },
    });
  }

  createClientUser(edituser: Partial<IClientUser>) {
    this.submitErrors = null;
    let updateResult$: Observable<IClientUser> | undefined;

    if (this.type === 'new') {
      updateResult$ = this.service.postClientUser(edituser);
    } else if (this.id) {
      updateResult$ = this.service.updateClientUser(edituser);
    }

    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
