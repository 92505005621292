<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">
      <div class="inpt-title">Title</div>
      <input
        id="title"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="Title"
        i18n-placeholder="@@title"
        autocomplete="off"
      />
    </div>
    <div>
      <div class="inpt-title">Description</div>
      <textarea
        id="editor"
        formControlName="editor"
        type="text"
        class="form-control"
        placeholder="Description"
        i18n-placeholder="Label description"
        autocomplete="off"
      >
      </textarea>
    </div>

    <!--<div class="d-flex form-floating col file-inpt displayNoneOnSmallScreens">
    <input id="custom-file-input" formControlName="image" type="file"  class=" form-control mt-3">
    <label for="file" i18n="@@ImageFile">Click to upload or drag and drop image  </label>
  </div>-->

    <div class="form-group form-floating mb-3">
      <div
        class="form-floating mb-4 mt-4"
        [appValidateState]="editForm.get('documentTitle')"
      >
        <div class="inpt-title">Document Title</div>
        <input
          id="name"
          formControlName="documentTitle"
          type="text"
          class="inpt"
          placeholder="Title"
          i18n-placeholder="@@title"
          autocomplete="off"
        />
      </div>
      <!--<app-datalist-select class="inpt-title"
                           [options]="DocumentTypeOptions"
                           [multiple]="false"
                           [activeValue]="editForm?.value.documentTypeId"
                           (activeValueChange)="updateValue('documentTypeId', $event)"
                           label="Document Type" i18n-label="DocumentTypeLabel"></app-datalist-select>-->
      <!--<app-datalist-select class="inpt-title"
                           [options]="ParentFolderOptions"
                           [multiple]="false"
                           [activeValue]="editForm?.value.folderId"
                           (activeValueChange)="updateValue('folderId', $event)"
                           label="Document Folder" i18n-label="FolderLabel"></app-datalist-select>-->
      <div class="col-12 row mt-2 mb-3 p-0 m-0">
        <div class="d-flex p-0">
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
            *ngIf="!editedPost?.documentPath"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6667 2.99984H7.4C5.15979 2.99984 4.03969 2.99984 3.18404 3.43581C2.43139 3.8193 1.81947 4.43123 1.43597 5.18388C1 6.03952 1 7.15963 1 9.39984V20.5998C1 22.84 1 23.9602 1.43597 24.8158C1.81947 25.5685 2.43139 26.1804 3.18404 26.5639C4.03969 26.9998 5.15979 26.9998 7.4 26.9998H19.6667C20.9066 26.9998 21.5266 26.9998 22.0353 26.8635C23.4156 26.4937 24.4938 25.4155 24.8637 24.0351C25 23.5264 25 22.9065 25 21.6665M22.3333 9.6665V1.6665M18.3333 5.6665H26.3333M11 10.3332C11 11.8059 9.80609 12.9998 8.33333 12.9998C6.86057 12.9998 5.66667 11.8059 5.66667 10.3332C5.66667 8.86041 6.86057 7.6665 8.33333 7.6665C9.80609 7.6665 11 8.86041 11 10.3332ZM16.9867 14.8907L5.7082 25.1439C5.07382 25.7206 4.75663 26.009 4.72857 26.2588C4.70425 26.4753 4.78727 26.69 4.95091 26.8339C5.13971 26.9998 5.56837 26.9998 6.42571 26.9998H18.9413C20.8602 26.9998 21.8196 26.9998 22.5732 26.6775C23.5193 26.2728 24.2729 25.5191 24.6776 24.5731C25 23.8195 25 22.86 25 20.9411C25 20.2955 25 19.9727 24.9294 19.672C24.8407 19.2942 24.6706 18.9403 24.431 18.635C24.2403 18.3921 23.9883 18.1904 23.4841 17.7871L19.7544 14.8034C19.2499 14.3997 18.9976 14.1979 18.7197 14.1267C18.4748 14.0639 18.2172 14.072 17.9767 14.1501C17.7039 14.2387 17.4648 14.456 16.9867 14.8907Z"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
            *ngIf="editedPost?.documentPath"
          >
            <img
              class="rounded-circle"
              height="100%"
              fill="none"
              src="{{ editedPost?.documentPath }}"
              style="object-fit: contain; overflow: hidden"
            />
          </div>

          <div
            class="d-flex form-floating col file-inpt displayNoneOnSmallScreens"
          >
            <input
              id="custom-file-input"
              formControlName="documentInserted"
              type="file"
              class="form-control mt-3"
            />
            <label for="file" i18n="@@ImageFile"
              >Click to upload or drag and drop image
            </label>
          </div>
          <div class="pt-2 displayNoneOnBigScreens">
            <div
              class="form-floating file-inpt-sm d-flex align-items-center ps-2"
              style=""
            >
              <input
                id="custom-file-input"
                formControlName="documentInserted"
                type="file"
                class="custom-file-input"
                style="width: 150px"
              />
              <label class="label" for="custom-file-input">
                <svg
                  style="flex: none"
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613 "
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Upload File
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-wfrBlue shadow-sm" i18n="@@save">
      Save
    </button>
  </div>
</form>
